var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "div_tax_area" } }, [
    _c("h2", { staticClass: "content_title" }, [
      _vm._v(_vm._s(_vm.$t("msg.ADD20220219.019"))),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "tbl_row" }, [
      _vm._m(0),
      _c("tbody", [
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm.$t("msg.ADD20220219.020")))]),
          _vm._v(" "),
          _c("td", { attrs: { colspan: "3" } }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.taxTemplateSelect,
                    expression: "taxTemplateSelect",
                  },
                ],
                style: _vm.customInputWidth,
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.taxTemplateSelect = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { domProps: { value: 9999 } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.050"))),
                ]),
                _vm._l(_vm.taxTemplateList, function (template, i) {
                  return _c(
                    "option",
                    { key: "tax-template-option-" + i, domProps: { value: i } },
                    [_vm._v(_vm._s(template.templateNm))]
                  )
                }),
              ],
              2
            ),
            _c(
              "a",
              {
                staticClass: "button sm mr2 ml2",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.taxTemplateAlert("I")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.033")))]
            ),
            _c(
              "a",
              {
                staticClass: "button sm mr2",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.taxTemplateAlert("U")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CSCT060T070.016")))]
            ),
            _c(
              "a",
              {
                staticClass: "button sm",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.taxTemplateAlert("D")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ADMN040G020.016")))]
            ),
          ]),
        ]),
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM050G020.009")))]),
          _c("td", { attrs: { colspan: "3" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.rcivNm,
                  expression: "taxTemplate.rcivNm",
                },
              ],
              style: _vm.customInputWidth,
              attrs: { type: "text", id: "fbt-rcivNm", maxlength: "30" },
              domProps: { value: _vm.taxTemplate.rcivNm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.taxTemplate, "rcivNm", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.066")))]),
          _vm._v(" "),
          _c("td", { attrs: { colspan: "3" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.rcivAddr,
                  expression: "taxTemplate.rcivAddr",
                },
              ],
              attrs: { type: "text", id: "fbt-rcivAddr", maxlength: "70" },
              domProps: { value: _vm.taxTemplate.rcivAddr },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.taxTemplate, "rcivAddr", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK019")) + " ")]),
          _vm._v(" "),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.rcivBzrgNo,
                  expression: "taxTemplate.rcivBzrgNo",
                },
              ],
              attrs: {
                type: "text",
                id: "fbt-rcivBzrgNo",
                oninput:
                  "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                maxlength: "20",
              },
              domProps: { value: _vm.taxTemplate.rcivBzrgNo },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.taxTemplate, "rcivBzrgNo", $event.target.value)
                },
              },
            }),
          ]),
          _c("th", [_vm._v(_vm._s(_vm.$t("msg.ADD20220219.022")))]),
          _vm._v(" "),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.uploadFileName,
                  expression: "uploadFileName",
                },
              ],
              staticClass: "upload_name",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.uploadFileName },
              on: {
                click: function ($event) {
                  return _vm.fileUploadAlert()
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.uploadFileName = $event.target.value
                },
              },
            }),
            _c(
              "a",
              {
                class:
                  _vm.uploadFileInfo.length > 0
                    ? "button sm blue"
                    : "button sm gray",
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.fileUploadAlert()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONEX060P012.055")))]
            ),
            _vm._v(" "),
            _c("span", { attrs: { id: "fbt-file" } }),
            _c(
              "div",
              { staticStyle: { color: "red", "font-weight": "bold" } },
              [_vm._v("※ " + _vm._s(_vm.$t("tem.CMAT038")) + " ")]
            ),
          ]),
        ]),
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm.$t("msg.ADD20220219.021")))]),
          _vm._v(" "),
          _c("td", { attrs: { colspan: "3" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.tpopBsnCd,
                  expression: "taxTemplate.tpopBsnCd",
                },
              ],
              style: _vm.customInputWidth,
              attrs: {
                type: "text",
                id: "fbt-tpopBsnCd",
                oninput:
                  "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                maxlength: "4",
              },
              domProps: { value: _vm.taxTemplate.tpopBsnCd },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.taxTemplate, "tpopBsnCd", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _c("tr", [
          _c("th", [_vm._v("email")]),
          _c("td", { attrs: { colspan: "3" } }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.emlAddr,
                  expression: "taxTemplate.emlAddr",
                },
              ],
              style: _vm.customInputWidth,
              attrs: { type: "text", id: "fbt-emlAddr", maxlength: "200" },
              domProps: { value: _vm.taxTemplate.emlAddr },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.taxTemplate, "emlAddr", $event.target.value)
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.extblIssYn,
                  expression: "taxTemplate.extblIssYn",
                },
              ],
              attrs: { type: "radio", id: "extblIssYn-N", value: "N" },
              domProps: { checked: _vm._q(_vm.taxTemplate.extblIssYn, "N") },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.taxTemplate, "extblIssYn", "N")
                },
              },
            }),
            _c(
              "label",
              { staticClass: "mr20", attrs: { for: "extblIssYn-N" } },
              [_c("span"), _vm._v(_vm._s(_vm.$t("msg.ADD20220219.023")))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.extblIssYn,
                  expression: "taxTemplate.extblIssYn",
                },
              ],
              attrs: { type: "radio", id: "extblIssYn-Y", value: "Y" },
              domProps: { checked: _vm._q(_vm.taxTemplate.extblIssYn, "Y") },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.taxTemplate, "extblIssYn", "Y")
                },
              },
            }),
            _c("label", { attrs: { for: "extblIssYn-Y" } }, [
              _c("span"),
              _vm._v(_vm._s(_vm.$t("msg.ADD20220219.024"))),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM070P020.005")) + " ")]),
          _vm._v(" "),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.rcivPicNm,
                  expression: "taxTemplate.rcivPicNm",
                },
              ],
              attrs: { type: "text", id: "fbt-rcivPicNm", maxlength: "30" },
              domProps: { value: _vm.taxTemplate.rcivPicNm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.taxTemplate, "rcivPicNm", $event.target.value)
                },
              },
            }),
          ]),
          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.161")) + " ")]),
          _vm._v(" "),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.rcivCtfc,
                  expression: "taxTemplate.rcivCtfc",
                },
              ],
              attrs: {
                type: "text",
                id: "fbt-rcivCtfc",
                oninput:
                  "this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*)\\./g, '$1');",
                maxlength: "100",
              },
              domProps: { value: _vm.taxTemplate.rcivCtfc },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.taxTemplate, "rcivCtfc", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _c("tr", [
          _c("th", [_vm._v("발행여부")]),
          _vm._v(" "),
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taxTemplate.rcvProcCatCd,
                  expression: "taxTemplate.rcvProcCatCd",
                },
              ],
              attrs: { type: "radio", id: "rcvProcCatCd-01", value: "01" },
              domProps: { checked: _vm._q(_vm.taxTemplate.rcvProcCatCd, "01") },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.taxTemplate, "rcvProcCatCd", "01")
                },
              },
            }),
            _c(
              "label",
              { staticClass: "mr20", attrs: { for: "rcvProcCatCd-01" } },
              [_c("span"), _vm._v(_vm._s(_vm.$t("msg.ADD20220219.026")))]
            ),
          ]),
          _c("th", [_vm._v(_vm._s(_vm.$t("msg.ADD20220219.028")))]),
          _vm._v(" "),
          _c("td", [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.taxTemplate.acshIssKind,
                      expression: "taxTemplate.acshIssKind",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    id: "acshIssKind-01",
                    disabled: _vm.isDisableAcshIssDt,
                    value: "01",
                  },
                  domProps: {
                    checked: _vm._q(_vm.taxTemplate.acshIssKind, "01"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.taxTemplate, "acshIssKind", "01")
                    },
                  },
                }),
                _c(
                  "label",
                  { staticClass: "mr20", attrs: { for: "acshIssKind-01" } },
                  [_c("span"), _vm._v(_vm._s(_vm.$t("msg.MYIN020T010.006")))]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.taxTemplate.acshIssKind,
                      expression: "taxTemplate.acshIssKind",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    id: "acshIssKind-02",
                    disabled: _vm.isDisableAcshIssDt,
                    value: "02",
                  },
                  domProps: {
                    checked: _vm._q(_vm.taxTemplate.acshIssKind, "02"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.taxTemplate, "acshIssKind", "02")
                    },
                  },
                }),
                _c("label", { attrs: { for: "acshIssKind-02" } }, [
                  _c("span"),
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.050"))),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "input_calendar mid" },
              [
                _c("e-date-picker", {
                  attrs: {
                    disabled: _vm.isDisableCalendar,
                    "date-range": _vm.dateRange,
                    readonly: true,
                  },
                  on: { input: _vm.chanageAcshIssDt },
                  model: {
                    value: _vm.taxTemplate.acshIssDt,
                    callback: function ($$v) {
                      _vm.$set(_vm.taxTemplate, "acshIssDt", $$v)
                    },
                    expression: "taxTemplate.acshIssDt",
                  },
                }),
              ],
              1
            ),
            _c("span", { attrs: { id: "fbt-acshIssDt" } }),
          ]),
        ]),
        (_vm.btnType === "DO" || _vm.btnType === "BL") &&
        _vm.taxTemplate.reqAddCont
          ? _c("tr", [
              _c("td", { staticStyle: { "border-left": "0px" } }),
              _vm._v(" "),
              _c(
                "td",
                {
                  staticStyle: { "border-left": "0px" },
                  attrs: { colspan: "3" },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        `${_vm.$t("msg.ADD20220219.055")} "${
                          _vm.taxTemplate.reqAddCont
                        }" ${_vm.$t("msg.ADD20220219.056")} `
                      )
                    ),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
        _vm.btnType !== "DO" && _vm.btnType !== "BL"
          ? _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.051")))]),
              _c("td", { attrs: { colspan: "3" } }, [
                _vm.taxTemplate.reqAddCont
                  ? _c("div", { staticStyle: { "margin-bottom": "2px" } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            `${_vm.$t("msg.ADD20220219.055")} "${
                              _vm.taxTemplate.reqAddCont
                            }" ${_vm.$t("msg.ADD20220219.056")} `
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.taxTemplate.reqCont,
                      expression: "taxTemplate.reqCont",
                    },
                  ],
                  attrs: { id: "taxReqCont" },
                  domProps: { value: _vm.taxTemplate.reqCont },
                  on: {
                    keyup: function ($event) {
                      return _vm.$ekmtcCommon.fnKeyupByteCheck($event, 2000)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.taxTemplate, "reqCont", $event.target.value)
                    },
                  },
                }),
                _vm._m(1),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowDext5Pop,
            expression: "isShowDext5Pop",
          },
        ],
        attrs: { id: "fb_dext5_pop" },
      },
      [
        _c("div", { ref: "popup", staticClass: "popup_dim" }, [
          _c(
            "div",
            {
              staticClass: "popup_wrap",
              staticStyle: { width: "800px", height: "520px" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "layer_close",
                  on: {
                    click: function ($event) {
                      return _vm.closeFileUploadr()
                    },
                  },
                },
                [_vm._v("close")]
              ),
              _c("div", { staticClass: "popup_cont" }, [
                _c("h1", { staticClass: "page_title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.FARE020T010.009"))),
                ]),
                _c(
                  "div",
                  { staticClass: "content_box" },
                  [
                    _vm.isShowDext5
                      ? _c("DEXT5Upload", {
                          ref: "dextupload",
                          attrs: {
                            id: "dext5-uploader-fb-" + _vm.dext5Idx,
                            single: true,
                            category: false,
                            width: "100%",
                            height: "200px",
                            "file-info": _vm.uploadFileInfo,
                            "category-info": [],
                          },
                          on: { completed: _vm.uploadFile },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mt10 text_center" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    on: {
                      click: function ($event) {
                        return _vm.closeFileUploadr()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.MYIN050T010.057")))]
                ),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col", { attrs: { width: "45%" } }),
      _c("col", { attrs: { width: "120" } }),
      _c("col", { attrs: { width: "55%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "div_req_byte" }, [
      _c(
        "span",
        {
          staticClass: "color_black font_medium",
          attrs: { id: "byte_taxReqCont" },
        },
        [_vm._v("0")]
      ),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("/")]),
      _c("span", { staticClass: "color_lightgray" }, [_vm._v("2,000")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }