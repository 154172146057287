<template>
  <div class="content">
    <template>
      <e-breadcrumbs v-show="!!parentData.isWorking && !!parentData.isCalc" />
      <div v-if="$router.history.current.name !== 'calcIssueMain'" class="flex_box" v-show="!parentData.isWorking">
        <h1 v-show="$router.history.current.name !== 'calcIssueMain'" class="page_title">{{ $t('menu.MENU06.070') }}</h1>
        <span v-show="$router.history.current.name !== 'calcIssueMain'" class="ml_auto mt20">
          <!--a :class="auth.login ? 'button md' : 'button md disabled'" href="javascript:void(0)" @click="openPopup('DemDetTariffPop', null)">DEM/DET Tariff</a-->
          <a class="button md" href="javascript:void(0)" @click="openPopup('DemDetTariffPop', null)">DEM/DET Tariff</a><!-- Free Time Tariff -->
          <a :class="auth.login ? 'button md print ml5' : 'button md print ml5 disabled'" @click="screenToPdfDownload()" href="javascript:void(0)">
            <span class="btn_icon print"></span>{{ $t('msg.SETT060G010.002') }}
          </a>
        </span>
      </div>
    </template>
    <div class="content_box" v-show="!parentData.isWorking">
      <!-- content_box -->
      <table class="tbl_search t2">
        <colgroup>
          <col width="*"><col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <td>
              <div class="tbl_form">
                <span class="dv wid150" style="width: 200px !important;">
                  <input type="radio" id="eiCatCd-O" name="type" v-model="params.eiCatCd" value="O" @change="changeCalcEiCatCd()">
                  <label for="eiCatCd-O" class="mr20"><span></span>{{ $t('msg.SETT060G010.003') }}</label>

                  <input type="radio" id="eiCatCd-I" name="type" v-model="params.eiCatCd" value="I" @change="changeCalcEiCatCd()">
                  <label for="eiCatCd-I"><span></span>{{ $t('msg.SETT060G010.004') }}</label>

                </span>
                <span class="dv wid150">
                  <select v-model="params.dtKnd">
                    <option value="BL">B/L No.</option>
                    <!-- <option value="CN">Container No.</option> -->
                  </select>
                </span>
                <span class="dv wid70" v-if="params.dtKnd == 'BL'">
                  <input type="text" value="KMTC" disabled="disabled">
                </span>
                <span class="dv">
                  <input type="text" class="wid150" style="margin:0;text-transform: uppercase;" id="num" v-model="params.num" @blur="toUpperCase($event)">
                </span>
              </div>
            </td>

            <td class="text_right">
              <a href="javascript:void(0)" class="button blue sh" @click="getFreeTime()">{{ $t('msg.SETT060G010.005') }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div><!-- content_box // -->
    <div class="content_box" :class="{ mt10 : !parentData.isWorking}">
      <template v-for="(t, i) in tableArr">
        <calc-table :ctr-cd="ctrCd"
                    :is-first-calc-table-index="i === firstCalcTableIndex"
                    :table="t"
                    :query="freeTimeData.csbl400quiryinfo"
                    :an-check="freeTimeData.anCheck"
                    :user-type="userType"
                    :key="'calcTable-' + i"
                    :auth="auth"
                    :table-info="calcTableInfo(i)"
                    :vat-chk="vatChk"
                    v-if="t.isShow"
                    @child="getChildData"
                    @_openPopup="_openPopup"
                    @_screenToPdfDownload="screenToPdfDownload"
        >
        </calc-table>
      </template>
    </div>

    <div class="content_box mt10" v-if="ctrCd == 'KR' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            수출(START DATE)
            <br />
            DEM : 실제 컨테이너 반입일
            <br />
            DET : 빈 컨테이너 반출일
            <br />
            MOR : RF컨테이너 전원 공급일
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            OVER DAY 요율은 상단 "DEM/DET Tariff" 클릭 하시면 조회 가능합니다.
            <br />
            최초 정산 이 후 Freetime 연장요청은 불가하오니 정산 전 연장요청 바랍니다.
            <br />
            입금 후 정산건은 "입금/계산서 정보입력" 바랍니다.
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'KR' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            수입(START DATE)
            <br />
            OFC : 컨테이너 입항일
            <br />
            (OFC= DEM+DET 통합적용)
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            OVER DAY 요율은 상단 "DEM/DET Tariff" 클릭 하시면 조회 가능합니다.
            <br />
            최초 정산 이 후 Freetime 연장요청은 불가하오니 정산 전 연장요청 바랍니다.
            <br />
            입금 후 정산건은 "입금/계산서 정보입력" 바랍니다.
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'JP' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1.Start Date
            <br />
            DEMの場合 : 実入りコンテナの搬入日
            <br />
            DETの場合  : 空コンテナの搬出日
            <br />
            2. 計算方法
            <br />
            港によりFreetime のカウント方法が異なるため、費用発生時には弊社より別途ご連絡致します。
            <br />
            不明な点がありましたら弊社 Customer Service Teamまでお問い合わせください。
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1. こちらは輸出のPOL側のFreetimeをご確認いただくページとなります。
            <br />
            2. POD側のFreetimeにつきましては"Freetime Request Detail"ボタン(延長している場合)、または "DEM/DET Tariff" メニュー から日数をご確認下さい。
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'JP' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1. Start Date
            <br />
            DEMの場合 : 本船の入港日
            <br />
            DETの場合  : 実入りコンテナの搬出日
            <br />
            2.  計算方法 : Start Dateからカウントします。 (* 土日祭日を含む）
            <br />
            3.  OT/FRのOOG CargoはDirect Deliveryとなります。
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1. DEMのStart Dateは本船の実際の入港日によって変動します。
            <br />
            それにより、Gate-out Deadlineが変わる場合もございますので、ご注意ください。
            <br />
            2. 請求書はPODの代理店にて発行しますので、発行のご依頼は代理店にお問い合わせください。
            <br />
            * 連絡先 :  http://www.kmtcjapan.com/home/port_agent.asp
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'TH' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Freetime DEM/DET start date :
            <br />
            DEM start actual berth date
            <br />
            DET start gate out full date
            <br />
            <br />
            ***LCH HZ move to HZ warehouse immediately after discharge***
            <br />
            ****DLY port LKB/SCT/BMT start gate in full at DLY port date****
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            DEM/DET combined cannot apply at TH side
            <br />
            <br />
            ***LCH HZ cargo often occurs DET charge (is incorrect)
            <br />
            because cargo movement shows gate out full from LCH to HZ warehouse
            <br />
            but not show gate in full date at HZ warehouse***
            <br />
            <br />
            ****BMT/SCT/BKK by BARGE did not show any detail on the website,
            <br />
            Cnee should contact CS team to check free time****
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'VN' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1.Export
            <br />
            Start: Pick up date
            <br />
            End:ETD
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'VN' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Start Date
            <br />
            DEM: actual berthing date
            <br />
            DET: gate out date
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1. Free time DET (Combined) is not applied in VN.
            <br />
            2. Free time application after invoice issue is not applied.
            <br />
            3. Storage Occurrence Sensing : STORAGE included in DEM, Direct calculation ICD, TCC.
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'MY' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            DEM  : From actual container discharge until container gate out port
            <br />
            DET : From container gate out port until empty return to depo
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            D&D in eKMTC is for reference only
            <br />
            Actual amount need to check with our contractor agent (DND Control (M) Sdn Bhd)
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'PH' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            DET Start Date - Actual GTO-OB-E
            <br />
            DET End Date - Actual GTI-OB-F
            <br />
            DEM Start Date - Actual GTI-OB-F
            <br />
            DEM End Date - Actual LDG-OB-F
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            12% VAT is included in the Grand Total calculation for DEM/DET.
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'PH' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            DEM Start Date - Actual Vesse's Berthing
            <br />
            DEM End Date - Actual GTO-IB-F
            <br />
            DET Start Date - Actual GTO-IB-F
            <br />
            DET End Date - Actual GTI-IB-E
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            12% VAT is included in the Grand Total calculation for DEM/DET.
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'ID' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Export : DET (Combined)
            <br />
            Start Date : Gate out Depot
            <br />
            End Date : Load on Vessel
            <br />
            <br />
            Tariff : Available in link Local Brochure KMTC 2023
            <br />
            Home -> Customer Service -> FAQ (JKT Inbound link guidance)
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Pickup Empty container within normal freetime (7 Days)
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'ID' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Import : DET (Combined)
            <br />
            Start Date : Actual Vessel Berthing
            <br />
            End Date : Container returned at Depot KMTC
            <br />
            <br />
            Tariff : Available in link Local Brochure KMTC 2023
            <br />
            Home -> Customer Service -> FAQ (JKT Inbound link guidance)
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Empty return to Depot as per D/O validation date and deliver to KMTC Depot address as mentioned on D/O.
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'SG' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Export : DET (Combined) - 06 calendar days (for all types)
            <br />
            Start Date : 6 days before vessel ETA SIN
            <br />
            <br />
            Depot PIC can access to ICC, depot will release container according to pick up date which is reflected under the ICC menu
            <br />
            <br />
            Depot KMTC operating hour (Mon – Fri 8am ~ 5pm, Sat 8am ~ 12pm, closed on Sun)
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'SG' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Import : DET (Combined) - 06 calendar days (for all types)
            <br />
            Start Date : Vessel completion of discharge
            <br />
            <br />
            1) Consignee / clearing agent / haulier may call or email KMTC SG office for checking of free detention or detention charges may arise.
            <br />
            2) Depot PIC can access to ICC, when haulier return container, actual detention charges will be informed for their direct settlement to depot.
            <br />
            <br />
            We start guide consignee / clearing agent / haulier to check free detention or detention charges thru KMTC website (https://www.ekmtc.com/index.html#/main)
            <br />
            <br />
            Depot KMTC operating hour (Mon – Fri 8am ~ 5pm, Sat 8am ~ 12pm, closed on Sun)
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'IN' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Detention
            <br />
            Start Date - Empty gate-out depot
            <br />
            End Date - (1) Laden gate-in at Terminal if POR is Port (2) Laden gate-in at ICD for POR-ICDs
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'IN' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            DET (Combined)
            <br />
            Start Date - (1) Actual Vessel Berthing if FPD is Port (2) Laden arrival at FPD if ICD
            <br />
            End Date - Actual empty return to depot
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'HK' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            DEM start date - Full container GTI date
            <br />
            DET start date - Empty container GTO date
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'HK' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            DEM start date : follow the start date in [Demurrage start correction]
            <br />
            DET start date : full container GTO date.
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1. Do not accept the freetime approval of DET(Combined) for HKG local cargo.
            <br />
            2. The freetime of HZ and OOG is 72hrs and 24 hrs, the DEM invoice may not correct in e-KMTC, cnee should check with IMP team first.
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'CN' && memberDetail.bkgPlcCd == 'NBO' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            DET(conbined)
            <br />
            Start date: Empty pick-up date
            <br />
            End date: Estimated time of departure
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Shipback cargo
            <br />
            Start: Arrival date(imbound),End:Departure date(shipback)
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'CN' && memberDetail.bkgPlcCd == 'XMN' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Dem (incl DET)
            <br />
            Start:Emty pick up date
            <br />
            End:departure date
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            1. Shipback cargo
            <br />
            Start: Arrival date(imbound),End:Departure date(shipback)
            <br />
            2. If you apply to get EIR in advance, you are responsible for dem(incl det)charge.
            <br />
            3. Free time tariff of DG same as one of non DG.
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'CN' && memberDetail.bkgPlcCd == 'XMN' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Dem (incl DET)
            <br />
            Start:Arrival date
            <br />
            End:Return to depot date
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Free time tariff of DG same as one of non DG.
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'CN' && memberDetail.bkgPlcCd == 'NKG' && params.eiCatCd == 'O'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Export (Combined)---Feeder
            <br />
            Start:Empty pick up time---Movements updated manually
            <br />
            End:Export full on board date---Automatically link leg vessel time
            <br />
            (Declared shipment date, not actual shipment date)
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Normal 7 days free time
            <br />
            If need extend freetime,pls send request
            <br />
            through EKMTC asap
            <br />
          </li>
        </ul>
      </div>
    </div>
    <div class="content_box mt10" v-if="ctrCd == 'CN' && memberDetail.bkgPlcCd == 'NKG' && params.eiCatCd == 'I'">
      <span class="content_title_span">Freetime Calculation</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            Import (Combined)---Feeder
            <br />
            Start:IB Full discharge time(Actual DIS time)---Manually
            <br />
            End:Empty return time---Manually
            <br />
          </li>
        </ul>
      </div>
      <br />
      <span class="content_title_span">Precautions</span>
      <div class="cont text_left">
        <ul class="bul_list_sm" style="list-style: none;">
          <li>
            D&D in eKMTC is only for reference.
            <br />
            Actuacl amount pls contact with local KMTC office.
            <br />
          </li>
        </ul>
      </div>
    </div>

    <div id="freetime_fn_pop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          @child="getChildData"
          :parent-info="parentInfo"
          :freight-bill-info="parentInfo"
          :user-dmd-info="userDmdInfo"
          :ei-cat-cd="eiCatCd"
          :freetime-exp-imp-rtn-sac-yn="freetimeExpImpRtnSacYn"
        />
      </win-layer-pop>
    </div>
    <div id="freetime_fn_pop_child">
      <win-layer-pop>
        <component
          v-if="customComponent2"
          :is="customComponent2"
          @close="closePopup2"
          @child="getChildData"
          :parent-info="parentInfo2"
          :freetime-exp-imp-rtn-sac-yn="freetimeExpImpRtnSacYn"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>
import CalcTable from './CalcTable'
import { rootComputed } from '@/store/helpers'
import FreeTimeApi from '@/api/rest/settle/freeTime'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import FreeTimeExtensReqPop from '@/pages/settle/popup/FreeTimeExtensReqPop'
import FreeTimePrintPop from '@/pages/settle/FreeTimePrintPop'
import PreviousIssuedInvoicePop from '@/pages/settle/PreviousIssuedInvoicePop'
import ProformaInvoicePop from '@/pages/settle/popup/ProformaInvoicePop'
import DemDetTariffPop from '@/pages/settle/popup/DemDetTariffPop'
import InputTaxInvoiceInfo from '@/pages/settle/popup/InputTaxInvoiceInfo'
import VirtualAccountInvoiceInfo from '@/pages/settle/popup/VirtualAccountInvoiceInfo'
import FreeTimePop from '@/pages/pdf/popup/FreeTimePop'
import CalcIssueInvMsgPop from '@/pages/settle/popup/CalcIssueInvMsgPop'
import CalcIssue from '@/api/rest/settle/calcIssue'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'
import countryCode from '@/plugins/country.json'
import store from '@/store/index'
import FreeTimeExtensReq from '@/api/rest/settle/freeTimeExtensReq'

export default {
  name: 'FreeTimeRequest',
  components: {
    CalcTable,
    WinLayerPop,
    FreeTimeExtensReqPop,
    FreeTimePrintPop,
    PreviousIssuedInvoicePop,
    ProformaInvoicePop,
    DemDetTariffPop,
    InputTaxInvoiceInfo,
    FreeTimePop,
    CalcIssueInvMsgPop,
    EBreadcrumbs,
    VirtualAccountInvoiceInfo
  },
  props: {
    parentData: {
      type: Object,
      default: function () {
        return {
          isWorking: false,
          isCalc: false
        }
      }
    }
  },
  data: function () {
    return {
      tab: '',
      tableArr: [],
      params: {
        eiCatCd: 'O',
        dtKnd: 'BL',
        num: '',
        blNo: '',
        cntrNo: ''
      },
      freeTimeData: {},
      customComponent: null,
      customComponent2: null,
      parentInfo: {},
      parentInfo2: {},
      reqRno: '',
      dmdCatCd: {
        etDmdCatCd: null,
        imDmdCatCd: null
      },
      eiCatCd: '',
      userType: 'T',
      ctrCd: 'KR',
      vatChk: false,
      // 정산완료 체크
      paidArr: [],
      noPaidArr: [],

      isPaidInvoice: 0,
      isReqSameCntr: 0,
      orgFreeTimeInvoiceList: [],
      srcCntr: '',
      freetimeExpImpRtnSacYn: null,
      relYn: true // JamSin
    }
  },
  computed: {
    searchParams () {
       return this.$store.getters['settle/freetimeParams']
    },
    searchParamsForLogin () {
       return this.isEmpty(sessionStorage.getItem('freetimeParamsForLogin')) ? null : JSON.parse(sessionStorage.getItem('freetimeParamsForLogin'))
    },
    isLogin () {
      return !this.isEmpty(this.$store.state.auth.jwt)
    },
    selectedProfile () {
      return this.$store.getters['auth/selectedProfile']
    },
    calcEiCatCd () {
      return this.$store.getters['auth/getCalcEiCatCd']
    },
    firstCalcTableIndex () {
      let idx = -1
      let isEmpty = true

      if (Array.isArray(this.tableArr)) {
        for (let i = 0; i < this.tableArr.length; i++) {
          console.log(this.tableArr[i].payload)
          if (this.tableArr[i].payload && this.tableArr[i].payload.rows.length !== 0) {
            isEmpty = false
            break
          }
        }
      }

      if (isEmpty === false && Array.isArray(this.tableArr)) {
        for (let i = 0; i < this.tableArr.length; i++) {
          if (this.tableArr[i].isShow) {
            idx = i
            break
          }
        }
      }
      return idx
    },
    ...rootComputed
  },
  watch: {
    searchParams: {
      deep: true,
      handler () {
        if (!this.isEmpty(this.searchParams.num)) {
          this.setParentParams()
        }
      }
    },
    calcEiCatCd () {
      if (this.parentData.isCalc) {
        this.params.eiCatCd = this.calcEiCatCd

        // 데이터 초기화
        let searchParams = {
          eiCatCd: '',
          dtKnd: '',
          num: '',
          blNo: '',
          cntrNo: ''
        }

        this.$store.commit('settle/updateFreetimeParams', searchParams)

        this.params.num = ''
        this.freeTimeData = {}

        for (let i = 0; i < this.tableArr.length; i++) {
          this.tableArr[i].payload.rows = []
          this.tableArr[i].payload.freeTimeInvoice = null
          // this.tableArr[i].eiCatCd = this.eiCatCd
        }
      }
    }
  },
  destroyed () {
    localStorage.setItem('freetimeParamsForLogin', null)
    window.removeEventListener('keydown', this.handleKeydown)
  },
  created () {
    this._ = require('lodash')
    this.createTable()
    if (!this.isEmpty(this.searchParams.num)) {
      this.setParentParams()
    } else {
      if (this.parentData.isCalc) {
        // 프로필에서 설정한 수출/수입 값으로 세팅
        this.initEiCatCd()
      }
    }
  },
  mounted: function () {
    if (Object.keys(this.$route.query).length > 0 && this.$route.query.tab !== '1') {
      this.routerSearch(this.$route.query)
    }
    this.setSearchParamsForLogin()
    if (this.isLogin) {
      this.getUserDmd()
    }

    if (this.isLogin) {
      this.ctrCd = this.memberDetail.userCtrCd
      //this.memberDetail.bkgPlcCd
    } else {
      let type = navigator.appName
      if (type === 'Netscape') {
        if (navigator.language === 'ko') {
          this.ctrCd = 'KR'
        } else {
          this.ctrCd = navigator.language.split('-')[1]
        }
      } else {
        this.ctrCd = navigator.userLanguage.split('-')[1]
      }
    }

    this.$nextTick(function () {
      window.addEventListener('keydown', this.handleKeydown)
    })
  },
  methods: {
    handleKeydown (e) {
      // JamSin
      if (this.relYn) {
        if (e.key === 'Enter' && ($('#tab7').hasClass('on') || $('#tab7').length <= 0)) {
          this.getFreeTime()
        }
      }
    },
    initEiCatCd () {
      if (this.calcEiCatCd !== '') {
        this.params.eiCatCd = this.calcEiCatCd
      } else {
        if (this.selectedProfile.eiCatCd === 'A') {
          // 프로필 값이 수출/수입이면 '수출'
          this.params.eiCatCd = 'O'
        } else {
          // 프로필에서 설정한 수출/수입 값으로 세팅
          this.params.eiCatCd = this.selectedProfile.eiCatCd
        }
      }
    },
    calcTableInfo (idx) {
      let info = {}
      let tmpArr = []

      this.tableArr.forEach((el) => {
        if (el.isShow) {
          tmpArr.push(el)
        }
      })

      tmpArr = tmpArr[tmpArr.length - 1]

      info = {
        idx: idx,
        tmpIdx: tmpArr.idx
      }

      return info
    },
    async getUserDmd () {
      await CalcIssue.getUserDmd({})
        .then(async response => {
             this.userDmdInfo = response.data
        })
    },
    toUpperCase (e) {
      this.params.num = e.target.value.toUpperCase().trim()
    },
    setSearchParamsForLogin () {
      if (this.searchParamsForLogin != null) {
        this.params = {
          eiCatCd: this.searchParamsForLogin.eiCatCd,
          dtKnd: this.searchParamsForLogin.dtKnd,
          num: this.searchParamsForLogin.num
        }
        this.eiCatCd = this.searchParamsForLogin.eiCatCd
        this.getFreeTime()
        // this.freeTimeData = JSON.parse(sessionStorage.getItem('freetimeDataForLogin'))
        //  this.parseFreeTimeData(this.freeTimeData)
      }
    },
    setParentParams () {
      this.params = {
        eiCatCd: this.searchParams.eiCatCd,
        dtKnd: this.searchParams.dtKnd,
        num: this.searchParams.num,
        blNo: this.searchParams.blNo,
        cntrNo: this.searchParams.cntrNo
      }

      let searchParams = {
        eiCatCd: '',
        dtKnd: '',
        num: '',
        blNo: '',
        cntrNo: ''
      }
      this.$store.commit('settle/updateFreetimeParams', searchParams)
      this.getFreeTime()
    },
    createTable () {
      this.tableArr = [
          { id: 'DEM', idx: 0, eiCatCd: 'O', demDetCatCd: '01', title: 'DEM(Demurrage)', typeText: this.$i18n.t('msg.SETT060G010.010'), isShow: true, payload: { rows: [], showCnt: 0 } },
          { id: 'DET', idx: 1, eiCatCd: 'O', demDetCatCd: '02', title: 'DET(Detention)', typeText: this.$i18n.t('msg.SETT060G010.009'), isShow: true, payload: { rows: [], showCnt: 0 } },
          { id: 'COM', idx: 2, eiCatCd: 'O', demDetCatCd: '03', title: 'DET(Combined)', typeText: this.$i18n.t('msg.SETT060G010.010') + '/' + this.$i18n.t('msg.SETT060G010.009'), isShow: true, payload: { rows: [], showCnt: 0 } },
          { id: 'OFC', idx: 3, eiCatCd: 'O', demDetCatCd: '14', title: 'OFC(OverFreetime Charge)', typeText: this.$i18n.t('msg.SETT060G010.010') + '/' + this.$i18n.t('msg.SETT060G010.009'), isShow: true, payload: { rows: [], showCnt: 0 } },
          { id: 'MOR', idx: 4, eiCatCd: 'O', demDetCatCd: '04', title: 'MOR(Monitoring)', typeText: this.$i18n.t('msg.SETT060G010.010'), isShow: true, payload: { rows: [], showCnt: 0 } }
      ]
    },
    getIsShow (data) {
      if (parseInt(data.dem) > 0) {
        this.tableArr[0].isShow = true
      } else {
        this.tableArr[0].isShow = false
      }
      if (parseInt(data.det) > 0) {
        this.tableArr[1].isShow = true
      } else {
        this.tableArr[1].isShow = false
      }
      if (parseInt(data.comb) > 0) {
        this.tableArr[2].isShow = true
      } else {
        this.tableArr[2].isShow = false
      }
      if (parseInt(data.ofc) > 0) {
        this.tableArr[3].isShow = true
      } else {
        this.tableArr[3].isShow = false
      }
      if (parseInt(data.mor) > 0) {
        this.tableArr[4].isShow = true
      } else {
        this.tableArr[4].isShow = false
      }

      let eta = this.freeTimeData.anCheck.eta
      let podCtrCd = this.freeTimeData.anCheck.podCtrCd

      if (this.ctrCd === 'KR' && this.params.eiCatCd === 'I' && podCtrCd === 'KR') {
        if (parseInt(eta.substr(0, 8)) >= 20210810) {
          this.tableArr[0].isShow = false
          this.tableArr[1].isShow = false
          this.tableArr[2].isShow = false
          this.tableArr[3].isShow = true
          this.tableArr[4].isShow = false
        } else {
          this.tableArr[3].isShow = false
        }
      } else {
        this.tableArr[3].isShow = false
      }
    },
    getIsShow_bak2 (type) {
      let lang = this.auth.serviceLang
      // let ctrCd = this.memberDetail.userCtrCd
      let eiCatCd = this.params.eiCatCd

      if (this.isEmpty(this.freeTimeData.anCheck)) {
        return false
      }

      let eta = this.freeTimeData.anCheck.eta
      // let podCtrCd = this.freeTimeData.anCheck.podCtrCd
      let demDetCatCd = this.freeTimeData.asDemDetCatCd
      let detCFixdYn = this.freeTimeData.detCFixdYn
      let fixdRateYn = this.freeTimeData.fixdRateYn // fixd유무 (고정요율 - det(combined)대상인 경우)
      let detcYn = '' // as is 에서 값 셋팅 안함
      let polTrmlCd = this.freeTimeData.findpolTrmlCd

      if (type === 'OFC') {
        if (demDetCatCd === '14') {
          return true
        } else {
          return false
        }
      } else if (type === 'DEM') {
        if (demDetCatCd !== '14') {
          return true
        } else {
          return false
        }
      } else if (type === 'COM') {
        if (detCFixdYn === 'Y' && lang === 'KOR') {
          return true
        } else if (fixdRateYn === 'Y' || detcYn === 'Y') {
          return true
        } else {
          return false
        }
      } else if (type === 'DET') {
        if (demDetCatCd === '14') {
          return false
        } else if (detCFixdYn === 'Y' && lang === 'KOR') {
          return false
        } else if (fixdRateYn === 'Y' || detcYn === 'Y') {
          return false
        } else {
          return true
        }
      } else if (type === 'MOR') {
        if (lang === 'KOR' && eiCatCd !== 'I') {
          return true
        } else {
          return false
        }
      } else if (type === 'STR') {
        if (lang === 'KOR' && eiCatCd !== 'I' && polTrmlCd === 'DPCT') {
          return true
        } else {
          return false
        }
      }

      return false
    },
    getIsShow_bak (type) {
      let lang = this.auth.serviceLang
      let ctrCd = this.memberDetail.userCtrCd
      if (type === 'DEM' || type === 'DET') {
        console.log(type + ' | ' + lang + ' | ' + ctrCd)
      }
      if (this.isEmpty(this.freeTimeData.anCheck)) {
        return false
      } else {
        let eta = this.freeTimeData.anCheck.eta
        let podCtrCd = this.freeTimeData.anCheck.podCtrCd
        if (this.params.eiCatCd === 'O') {
          if (type === 'OFC') {
            return false
          } else if (type === 'DEM' || type === 'DET' || type === 'COM') {
            return true
          } else if (type === 'MOR') {
            if (lang === 'KOR') {
              return true
            } else {
              return false
            }
          } else if (type === 'STR') {
            return false
          }
        } else if (this.params.eiCatCd === 'I') {
          if (lang === 'KOR') {
            if (type === 'OFC') {
              if (podCtrCd === 'KR' && parseInt(eta.substr(0, 8)) >= 20210810) {
                return true
              } else {
                return false
              }
            } else if (type === 'MOR') {
              if (ctrCd === 'TH') {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            if (type === 'OFC') {
              return false
            } else if (type === 'DEM' || type === 'DET' || type === 'COM') {
              if (podCtrCd === 'KR' && parseInt(eta) >= 20210810000000) {
                return false
              } else {
                return true
              }
            } else if (type === 'MOR') {
              if (ctrCd === 'TH') {
                return true
              } else {
                return false
              }
            } else if (type === 'STR') {
              return false
            }
          }
        }
      }
      return true
    },
    async getFreeTimeExtdtAll (arr, isCheck) {
      for (let i = 0; i < arr.length; i++) {
        await FreeTimeApi.getFreeTimeExtdt(this.makeQueryParams({ type: 'getFreeTimeExtdt', idx: arr[i].idx, cntrNo: arr[i].cntrNo, extsDt: arr[i].extsDt, invRno: arr[i].invRno })).then(async response => {
          this.tableArr[arr[i].idx].payload.rows[arr[i].rowIdx].extsDt = arr[i].extsDt
          this.tableArr[arr[i].idx].payload.rows[arr[i].rowIdx].totAmt = response.data.rstMap.totAmt
          this.tableArr[arr[i].idx].payload.rows[arr[i].rowIdx].vatAmt = this.getVat(response.data.rstMap.totAmt)
          this.tableArr[arr[i].idx].payload.rows[arr[i].rowIdx].ovrDds = response.data.rstMap.overDds
        }).catch(e => {
          console.log(e)
        })
      }

      if (isCheck) {
        this.$store.commit('settle/updateFreetimeVat', 'C')
      }

      this.tableArr = this._.cloneDeep(this.tableArr)
    },
    getFreeTimeExtdt (idx, rowIdx, cntrNo, extsDt, invRno, isCheck) {
      FreeTimeApi.getFreeTimeExtdt(this.makeQueryParams({ type: 'getFreeTimeExtdt', idx: idx, cntrNo: cntrNo, extsDt: extsDt, invRno: invRno })).then(response => {
        this.tableArr[idx].payload.rows[rowIdx].extsDt = extsDt
        this.tableArr[idx].payload.rows[rowIdx].totAmt = response.data.rstMap.totAmt
        this.tableArr[idx].payload.rows[rowIdx].vatAmt = this.getVat(this.tableArr[idx].payload.rows[rowIdx].totAmt)
        this.tableArr[idx].payload.rows[rowIdx].ovrDds = response.data.rstMap.overDds
      }).catch(e => {
        console.log(e)
      })

      if (isCheck) {
        this.$store.commit('settle/updateFreetimeVat', 'C')
      }
    },
    async getFreeTime () {
      this.createTable()
      if (this.params.num === '') {
        if (!this.parentData.isWorking) {
            let obj = {
              onClose: () => { document.getElementById('num').focus() },
              alertType: 'simple',
              customCloseBtnClass: 'button blue lg simplert-test1',
              customCloseBtnText: this.$i18n.t('msg.SETT060G010.018'),
              useIcon: false,
              customClass: 'simple_alert',
              message: this.$i18n.t('msg.SETT060G010.017'),
              type: 'info'
            }
            this.$ekmtcCommon.alert(obj)
        }
        return false
      } else {
         if (this.params.dtKnd === 'CN') {
           this.srcCntr = this.params.num
            await FreeTimeApi.getTempBl(this.params.num).then(response => {
              console.log('getTempBl >>> ', response)
              this.params.num = response.data.blno
              this.params.dtKnd = 'BL'
            }).catch(e => {
              console.log(e)
            })
         } else {
           this.srcCntr = ''
         }
        //FreeTimeApi.getTempBl()
        FreeTimeApi.getFreeTime(this.makeQueryParams({ type: 'getFreeTime' })).then(response => {
          console.log('getFreeTime >>> ', response)
          // 24.08.28 Freetime 검색시, 검색하는 사람의 수출/수입 구분과 관계없이 해당 B/L 의 Freetime 검색되도록 수정
          // if (response.data.userType === 'T' || response.data.userType === this.params.eiCatCd) {
            this.userType = response.data.userType
            this.freeTimeData = response.data
            this.eiCatCd = this.params.eiCatCd
            this.parseFreeTimeData(response.data)
            sessionStorage.setItem('freetimeParamsForLogin', JSON.stringify(this.params))
            sessionStorage.setItem('freetimeDataForLogin', JSON.stringify(response.data))
          // } else {
          //   let msg = ''
          //   let ctrNm = ''
          //   if (this.ctrCd === 'KR') {
          //     ctrNm = countryCode[localStorage.getItem('service_ctrcd')].CountryNameKR
          //   } else {
          //     ctrNm = countryCode[localStorage.getItem('service_ctrcd')].CountryNameEN
          //   }
          //   if (this.params.eiCatCd === 'O') {
          //     msg = this.$t('msg.SETT060G010.056', { ctrNm: ctrNm }) //(국가)발 선적건이 아닙니다. B/L 번호를 확인해주시기 바랍니다.
          //   } else {
          //     msg = this.$t('msg.SETT060G010.057', { ctrNm: ctrNm }) //(국가)향 선적건이 아닙니다. B/L 번호를 확인해주시기 바랍니다.
          //   }
          //   this.customAlert(msg)
          // }
        }).catch(e => {
          console.log(e)
        })
      }
    },
    parseFreeTimeData (data) {
      this.getIsShow(data.isShow)
      for (let i = 0; i < this.tableArr.length; i++) {
        this.tableArr[i].payload.rows = []
        this.tableArr[i].payload.freeTimeInvoice = null
        this.tableArr[i].eiCatCd = this.eiCatCd
        //this.tableArr[i].isShow = this.getIsShow(this.tableArr[i].id)
        // console.log(this.tableArr[i].id, this.tableArr[i].isShow)
        let subTotal = [0, 0]
        let grandTotal = 0
        if (this.tableArr[i].id === 'DEM') {
          for (let row of JSON.parse(JSON.stringify(data.demdetail4list))) {
            if (this.isEmpty(row.extsDt)) {
              row.extsDt = ''
            }
            this.tableArr[i].payload.rows.push(this.parseRow(row, 'DEM', i))
            this.tableArr[i].payload.rows[this.tableArr[i].payload.rows.length - 1].reqRno = row.wb227DemReqRno
          }
        } else if (this.tableArr[i].id === 'DET') {
          for (let row of JSON.parse(JSON.stringify(data.detdetail4list))) {
            if (this.isEmpty(row.extsDt)) {
              row.extsDt = ''
            }
            this.tableArr[i].payload.rows.push(this.parseRow(row, 'DET', i))
            this.tableArr[i].payload.rows[this.tableArr[i].payload.rows.length - 1].reqRno = row.wb227DetReqRno
          }
        } else if (this.tableArr[i].id === 'COM') {
          for (let row of JSON.parse(JSON.stringify(data.combDetailList))) {
            if (this.isEmpty(row.extsDt)) {
              row.extsDt = ''
            }
            this.tableArr[i].payload.rows.push(this.parseRow(row, 'DEM', i))
            this.tableArr[i].payload.rows[this.tableArr[i].payload.rows.length - 1].reqRno = ''
            //TODO this.tableArr[i].payload.rows[this.tableArr[i].payload.rows.length - 1].reqRno = row.wb227DemReqRno
          }
        } else if (this.tableArr[i].id === 'OFC') {
          for (let row of JSON.parse(JSON.stringify(data.demdetail4list))) {
            if (this.isEmpty(row.extsDt)) {
              row.extsDt = ''
            }
            this.tableArr[i].payload.rows.push(this.parseRow(row, 'DEM', i))
            this.tableArr[i].payload.rows[this.tableArr[i].payload.rows.length - 1].reqRno = row.wb227DemReqRno
          }
        } else if (this.tableArr[i].id === 'MOR') {
          for (let row of JSON.parse(JSON.stringify(data.monitoringDetailList))) {
            if (this.isEmpty(row.extsDt)) {
              row.extsDt = ''
            }
            this.tableArr[i].payload.rows.push(this.parseRow(row, 'MOR', i))
            this.tableArr[i].payload.rows[this.tableArr[i].payload.rows.length - 1].reqRno = ''
            //TODO this.tableArr[i].payload.rows[this.tableArr[i].payload.rows.length - 1].reqRno = row.wb227DemReqRno
          }
        }
        this.tableArr[i].payload.showCnt = this.tableArr[i].payload.rows.length
      }

        for (let i = 0; i < this.tableArr.length; i++) {
          this.orgFreeTimeInvoiceList = []

          if (data.freeTimeInvoiceList.length > 0) {
              for (let iv of data.freeTimeInvoiceList) {
                if (this.tableArr[i].demDetCatCd === iv.demDetCatCd) {
                  this.tableArr[i].payload.freeTimeInvoice = iv

                  // demDetCatCd 일치하는 인보이스 리스트 전체 담기
                  this.orgFreeTimeInvoiceList.push(iv)
                  this.tableArr[i].payload.orgFreeTimeInvoice = this.orgFreeTimeInvoiceList
                }

                // 정산완료 내역 체크
                if (iv.rcvCpltYn === 'Y') {
                  this.paidArr.push(iv)
                } else { // 정산완료 안된 건
                  this.noPaidArr.push(iv)
                }
              }
          } else { // 프리타임 인보이스 없을때,
              this.tableArr[i].payload.orgFreeTimeInvoice = []
          }
      }

      this.freetimeExpImpRtnSacYn = data.freetimeExpImpRtnSacYn

      // this.freetimeExpImpRtnSacYn.rcvDemExpYn = 'Y'
    },
    parseRow (row, type, idx) {
      row.isChecked = true // 최초 체크상태 JamSin
      row.isShow = true
      // 퀵 메뉴에서 cntr로 진입 cntr만 체크하고 나머지는 no 체크
      if (this.srcCntr !== '') {
        row.isChecked = false
        if (row.cntrNo === this.srcCntr) {
          row.isChecked = true
        }
      }

      if (row.freeEndDt === '00000000') {
        if (type === 'DEM') {
          row.parseFreeEndDt = '-'
        } else {
          row.parseFreeEndDt = this.tableArr[idx].typeText === '未返却' ? this.tableArr[idx].typeText : this.$i18n.t('msg.SETT060G010.024') + this.tableArr[idx].typeText
        }
      } else {
        row.parseFreeEndDt = row.freeEndDt.substr(0, 4) + '.' + row.freeEndDt.substr(4, 2) + '.' + row.freeEndDt.substr(6, 2)
      }
      // 시작날짜 추가
      if (row.gateStrDt === '00000000') {
        row.parseGateStrDt = ''
      } else {
        row.parseGateStrDt = row.gateStrDt.substr(0, 4) + '.' + row.gateStrDt.substr(4, 2) + '.' + row.gateStrDt.substr(6, 2)
      }

      //port 추가
      if (this.params.eiCatCd === 'I') {
        row.parsePortNm = row.podPortNm
      } else {
        row.parsePortNm = row.polPortNm
      }

      if (row.extsDt === '00000000') {
        row.extsDt = ''
      }
      row.vatAmt = this.getVat(row.totAmt)
      //row.status = row.cntrSts === 'Y' ? this.tableArr[idx].typeText : this.tableArr[idx].typeText === '未搬出' || this.tableArr[idx].typeText === '未返却' ? this.tableArr[idx].typeText : this.$i18n.t('msg.SETT060G010.024') + this.tableArr[idx].typeText
      row.status = this.getStatus(row, this.tableArr[idx].id)

      return row
    },
    getStatus (row, type) {
      let status = ''
      if (type === 'DEM') {
        if (row.cntrSts === 'Y') {
          status = this.$i18n.t('msg.SETT060G010.010') //반출
        } else {
          status = this.$i18n.t('msg.SETT060G010.058') //미반출
        }
      } else if (type === 'DET' || type === 'COM') {
        if (row.extsDt === '') {
          status = this.$i18n.t('msg.SETT060G010.058') //미반출
        } else if (row.cntrSts === 'Y') {
          status = this.$i18n.t('msg.SETT060G010.009') //반납
        } else {
          status = this.$i18n.t('msg.SETT060G010.059') //미반납
        }
      } else if (type === 'OFC') {
        if (row.ofcCntrSts === 'Y') {
          status = this.$i18n.t('msg.SETT060G010.009') //반납
        } else if (row.ofcCntrSts === 'N') {
          status = this.$i18n.t('msg.SETT060G010.059') //미반납
        } else if (row.ofcCntrSts === 'O') {
          status = this.$i18n.t('msg.SETT060G010.010') //반출
        } else if (row.ofcCntrSts === 'X') {
          status = this.$i18n.t('msg.SETT060G010.058') //미반출
        } else {
          status = this.$i18n.t('msg.SETT060G010.059') //미반납
        }
      } else if (type === 'MOR') {
        if (row.extsDt === '') {
          status = this.$i18n.t('msg.SETT060G010.058') //미반출
        } else if (row.cntrSts === 'Y') {
          status = this.$i18n.t('msg.SETT060G010.010') //반출
        } else {
          status = this.$i18n.t('msg.SETT060G010.058') //미반출
        }
      } else {
        status = '-'
      }
      return status
    },
    getVat (totAmt) {
      this.vatChk = false
      let vatAmt = 0

      if (this.ctrCd === 'KR') {
        vatAmt = parseInt(parseInt(totAmt) + parseInt(totAmt) / 10)
      } else if (this.ctrCd === 'PH') {
        vatAmt = parseInt(parseInt(totAmt) * 1.12)
      } else {
        vatAmt = parseInt(totAmt)
      }

      // 아래 미로그인 필리핀 VAT 12% 적용 기준
      // EXPORT - POL 필리핀인 경우 12% 적용
      // IMPORT - POD 필리핀인 경우 12% 적용
      // 로그인시는 국가 설정으로 적용됨
      // 수출, 수입 구분없이 podCtrCd로 체크하는 이유는 anCheck 쿼리에서 수출 수입 구분에 따라 pol, pod 변경해서 전달함(아래 참고)
      // DECODE( #{eiCatCd} , 'I' ,CS2.POD_CTR_CD, 'O', CS2.POL_CTR_CD) POD_CTR_CD
      if (this.params.eiCatCd === 'O' && this.freeTimeData.anCheck.podCtrCd === 'PH') {
        this.vatChk = true // 비로그인시 화면 VAT 항목 노출 구분. false-미노출, true-노출
        vatAmt = parseInt(parseInt(totAmt) * 1.12)
      }

      if (this.params.eiCatCd === 'I' && this.freeTimeData.anCheck.podCtrCd === 'PH') {
        this.vatChk = true
        vatAmt = parseInt(parseInt(totAmt) * 1.12)
      }

      return vatAmt
    },
    makeQueryParams (data) {
      let params = '?'
      switch (data.type) {
        case 'getFreeTime':
          if (this.params.dtKnd === 'BL') {
            params += 'blNo=' + this.params.num
          } else if (this.params.dtKnd === 'CN') {
            params += 'cntrNo=' + this.params.num
          }
          params += '&dtKnd=' + this.params.dtKnd
          params += '&eiCatCd=' + this.params.eiCatCd
        break
        case 'getFreeTimeExtdt':
          params += 'blNo=' + this.freeTimeData.blno
          params += '&cntrNo=' + data.cntrNo
          // params += '&demDetCatCd=0' + (parseInt(this.tableArr[data.idx].idx) + 1) ????? this.tableArr[].demDetCatCd 이걸 만들어 놓고 이렇게 하는 이유는?
          params += '&demDetCatCd=' + this.tableArr[data.idx].demDetCatCd
          params += '&eiCatCd=' + this.freeTimeData.asEiCatCd
          params += '&extsDt=' + data.extsDt
          params += '&invRno=' + data.invRno
        break
        case 'fnDoKLNetSend':
          params += 'blNo=' + data.blNo
          params += '&cntrNo=' + data.cntrNo
          params += '&flag=' + data.flag
        break
      }

      return params
    },
    fnDoKLNetSend (idx) {
      // console.log(this.tableArr[idx].payload.rows)
      let detNotCnt = 0
      let blCheckCnt = 0
      let blNo = (this.isEmpty(this.freeTimeData.blno) ? '' : this.freeTimeData.blno.replace('KMTC', ''))
      let cntrNo = ''
      let parm = ''
      let rtnStr = ''
      let now = new Date()
      let year = now.getFullYear()
      let mon = (now.getMonth() + 1) > 9 ? '' + (now.getMonth() + 1) : '0' + (now.getMonth() + 1)
      let day = now.getDate() > 9 ? '' + now.getDate() : '0' + now.getDate()
      let to_day = year + mon + day
      let det_break = '' //01. 미반출, 02. 반납기한 지남.
      let dem_break = '' //01. 미반출, 02. 반납기한 지남.

      let flag = (this.tableArr[idx].id === 'DEM' || this.tableArr[idx].id === 'DET') ? this.tableArr[idx].id : 'DEM'
      if (flag === 'DET') {
        for (let row of this.tableArr[idx].payload.rows) {
          if (row.isChecked) {
            blCheckCnt++
            if (row.freeEndDt === '00000000') {
              det_break = '01'
              break
            }
            if (row.freeEndDt !== '00000000' && parseInt(row.freeEndDt) < parseInt(to_day)) {
              det_break = '02'
              break
            }
          }
        }
        if (det_break === '01') {
          this.customAlert(this.$i18n.t('msg.SETT060G010.025'))
          return false
        } else if (det_break === '02') {
          this.customAlert(this.$i18n.t('msg.SETT060G010.026'))
          return false
        }
        if (blCheckCnt === 0) {
          this.customAlert(this.$i18n.t('msg.SETT060G010.027'))
          return false
        }
      } else {
        for (let row of this.tableArr[idx].payload.rows) {
          if (row.isChecked) {
            blCheckCnt++
            if (row.freeEndDt === '00000000') {
              dem_break = '01'
              break
            }
            if (row.freeEndDt !== '00000000' && parseInt(row.freeEndDt) < parseInt(to_day)) {
              dem_break = '02'
              break
            }
          }
        }
        if (dem_break === '01') {
          this.customAlert(this.$i18n.t('msg.SETT060G010.025'))
          return false
        } else if (dem_break === '02') {
          this.customAlert(this.$i18n.t('msg.SETT060G010.028'))
          return false
        }
        if (blCheckCnt === 0) {
          this.customAlert(this.$i18n.t('msg.SETT060G010.027'))
          return false
        }
      }
      FreeTimeApi.sendKLNetDo(this.makeQueryParams({ type: 'fnDoKLNetSend', blNo: blNo, cntrNo: cntrNo, flag: flag })).then(response => {
          switch (response.data) {
            case '01':
              this.customAlert(this.$t('msg.SETT060G010.048')) // 전송되었습니다.
              break
            case '02':
              this.customAlert(this.$t('msg.SETT060G010.029')) // D/O가 발행되지 않았습니다.
              break
            case '03':
              this.customAlert(this.$t('msg.SETT060G010.030')) // Demurrage가 초과되었습니다.
              break
            case '04':
              this.customAlert(this.$t('msg.SETT060G010.031')) // Detention이 초과되었습니다.
              break
            case '05':
              this.customAlert(this.$t('msg.SETT060G010.049')) // 전송에 실패 하였습니다.
              break
            default:
              this.customAlert(this.$t('msg.SETT060G010.049')) // 전송에 실패 하였습니다.
              break
          }
        }).catch(e => {
          console.log(e)
          this.customAlert(this.$i18n.t('msg.SETT060G010.032'))
        })
    },
    async getChildData (data) {
      let showCnt = 0
      switch (data.type) {
        case 'onlyCost':
          for (let i = 0; i < this.tableArr[data.payload.idx].payload.rows.length; i++) {
            if (data.payload.isOnlyCost) {
              if (parseInt(this.tableArr[data.payload.idx].payload.rows[i].totAmt) === 0) {
                this.tableArr[data.payload.idx].payload.rows[i].isShow = false
              } else {
                showCnt++
                this.tableArr[data.payload.idx].payload.rows[i].isShow = true
              }
            } else {
              showCnt++
              this.tableArr[data.payload.idx].payload.rows[i].isShow = true
            }
          }
          this.tableArr[data.payload.idx].payload.showCnt = showCnt
          break
        case 'changeExts':
          this.getFreeTimeExtdt(data.payload.idx, data.payload.rowIdx, data.payload.cntrNo, data.payload.extsDt, data.payload.invRno, data.isCheck)
          break
        case 'AllChangeExts':
          this.getFreeTimeExtdtAll(data.payload, data.isCheck)
          break
        case 'openPopup':
          if (data.payload.compNm === 'FreeTimePop') {
            this.openPopup(data.payload.compNm, data.payload.demDetAppNo)
          } else {
            if (data.payload.compNm === 'InputTaxInvoiceInfo' || data.payload.compNm === 'FreeTimeExtensReqPop') {
              this.isPaidInvoice = 0
              this.isReqSameCntr = 0

              console.log('InputTaxInvoiceInfo @@@@@@ ', data)

              // 인보이스 paid / no paid (입금여부) 여부 체크
              let invoiceCntrList = []
              let gridCheckedRows = []

              // Invoice가 없는 항목일 경우 스크립트 오류 발생하여 수정
              // invoiceCntrList = this.tableArr[data.payload.idx].payload.orgFreeTimeInvoice
              invoiceCntrList = this.isEmpty(this.tableArr[data.payload.idx].payload.orgFreeTimeInvoice) ? [] : this.tableArr[data.payload.idx].payload.orgFreeTimeInvoice

              if (invoiceCntrList.length > 0) {
                // INVOICE 발급된 컨테이너 비교 작업
                this.tableArr[data.payload.idx].payload.rows.forEach(vo => {
                  if (vo.isChecked) {
                    gridCheckedRows.push(vo)
                  }
                })

                invoiceCntrList.forEach(v => {
                  if (v.rcvCpltYn === 'Y') { // 프리타임 연장요청 팝업 오픈시 정산완료 체크
                    this.isPaidInvoice += 1
                  }

                  // 체크된 값
                  gridCheckedRows.forEach(v2 => {
                    if (v.cntrNo === v2.cntrNo) { // 세금계산어 팝업 오픈시 Request, pending 체크
                      if (v.apvStsCd === '02' || v.apvStsCd === '07') {
                        this.isReqSameCntr += 1
                      }
                    }
                  })
                })
              }

              if (data.payload.compNm === 'FreeTimeExtensReqPop') {
                const param = {
                  blNo: this.replaceKmtcBlNo(this.freeTimeData.csbl400quiryinfo.blNo),
                  eiCatCd: this.freeTimeData.csbl400quiryinfo.eiCatCd,
                  sr: '',
                  demDetCatCd: this.tableArr[data.payload.idx].demDetCatCd
                }

                  const demDetCatCd = this.tableArr[data.payload.idx].demDetCatCd

                  // await FreeTimeExtensReq.freeTimeExtensReqList(param).then(response => {
                  //   const list = response.data
                  //   const reqInfoList = list.appReqInfoList

                  //   // console.log('reqInfoList=' + JSON.stringify(response.data))
                  // }).catch(err => {
                  //   console.log(err)
                  // })

                  let isRcvChk = this.checkDemDetRcv([], demDetCatCd)

                  /*if (isRcvChk) {
                    this.$ekmtcCommon.alertDefault(this.$t('msg.SETT060G010.060'))
                    return
                  }*/
              }

              // console.log('openPopup invoiceCntrList>>> ', invoiceCntrList)
              // console.log('openPopup gridCheckedRows >>> ', gridCheckedRows)
            }

            this.openPopup(data.payload.compNm, data.payload.idx)
          }
          break
        case 'fnDoKLNetSend':
          this.fnDoKLNetSend(data.payload.idx)
          break
        case 'returnDateCancel':
          this.returnDateCancel(data.payload.idx)
          break
        case 'returnDateSubmit':
          this.returnDateSubmit(data.payload.idx)
          break
      }
    },
    // DEM, DET, DEC, OFC 정산완료 여부 체크
    checkDemDetRcv (reqInfoList, demDetCatCd) {
      let isRcvChk = false
      const freeTimeReqList = reqInfoList
      // for (var i = 0; i < freeTimeReqList.length; i++) {
          // if (this.reqParams.freeTimeReqList[i].reqDds != null) {
            console.log('demDetCatCd @@@@@ ', demDetCatCd)

            if (demDetCatCd === '01') {
              if (this.freetimeExpImpRtnSacYn.rcvDemExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDemImpYn === 'Y') {
                isRcvChk = true
              }
            } else if (demDetCatCd === '02') {
              if (this.freetimeExpImpRtnSacYn.rcvDetExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDetImpYn === 'Y') {
                isRcvChk = true
              }
            } else if (demDetCatCd === '03') {
              if (this.freetimeExpImpRtnSacYn.rcvDecExpYn === 'Y' || this.freetimeExpImpRtnSacYn.rcvDecImpYn === 'Y') {
                isRcvChk = true
              }
            } else if (demDetCatCd === '14') {
              if (this.freetimeExpImpRtnSacYn.rcvOfcImpYn === 'Y') {
                isRcvChk = true
              }
            }
          // }
      // }
      return isRcvChk
    },
    async returnDateCancel (idx) {
      await this.$Simplert.close()
      let checkLen = 0
      let ar216iTotAmtYn = 'N'
      for (let row of this.tableArr[idx].payload.rows) {
        if (row.isChecked) {
          checkLen++
          if (!this.isEmpty(row.ar216iTotAmt)) {
            ar216iTotAmtYn = 'Y'
          }
        }
      }

      if (checkLen === 0) {
        this.customAlert(this.$t('msg.SETT060G010.046')) // 컨테이너를 선택해주세요.
        return
      }

      if (ar216iTotAmtYn === 'N') {
        this.customAlert('Cannot be reset.')
        return
      }

      FreeTimeApi.deleteDecInv(this.freeTimeData.csbl400quiryinfo.blNo).then(response => {
        if (response.data === '1') {
          this.customAlert('Completed to reset.')
          this.getFreeTime()
          //this.customAlert('success.')
        } else if (response.data === '2') {
          this.customAlert('Payment request is received and in process.')
          //this.customAlert('That\'s impossible.')
        } else {
          this.customAlert('Reset failed. Please get in contact with administrator.')
          //this.customAlert('Error.')
        }
      }).catch(e => {
        console.log(e)
      })
    },
    async returnDateSubmit (idx) {
      await this.$Simplert.close()
      let arr = []
      let totAmt = 0
      for (let row of this.tableArr[idx].payload.rows) {
        if (row.isChecked) {
          arr.push(row)
          totAmt += row.totAmt * 1
        }
      }
      if (arr.length === 0) {
        this.customAlert(this.$t('msg.SETT060G010.046')) // 컨테이너를 선택해주세요.
        return
      }
      if (totAmt === 0) {
        this.customAlert('Please Check Amount.')
        return
      }

      // 컨펌창 추가
      if (await this.$ekmtcCommon.asyncAlertMessage({
                                 message: 'After submit the return date, please upload payment evidence in D/O request. <br/> Would you like to register?',
                                 useConfirmBtn: true
          })) {
              FreeTimeApi.insertDecInv(arr).then(response => {
                  if (response.data === '1') {
                    this.customAlert('Completed to save.')
                    this.getFreeTime()
                    //this.customAlert('success.')
                  } else if (response.data === '2') {
                    this.customAlert('Payment request is received and in process.')
                  } else {
                    this.customAlert('Save failed. Please get in contact with administrator.')
                    //this.customAlert('Error.')
                  }
                }).catch(e => {
                  console.log(e)
                })
              }
    },
    async getInputData (idx, compNm, blNo) {
      // FreeTimeApi.getInputData({ blNo: blNo, reqRno: this.reqRno, demDetCatCd: this.tableArr[idx].demDetCatCd }).then(response => {
      //     this.parentInfo = this.parseInputTaxInvoiceInfo(idx, response.data)
      //     this.customComponent = compNm
      //     this.$ekmtcCommon.layerOpen('#freetime_fn_pop > .popup_dim')
      // }).catch(e => {
      //   console.log(e)
      // })

      // 입금/계산서 정보입력 팝업 오픈시. 이전 저장된 데이터를 불러오지 않고 팝업을 오픈한다.
      this.parentInfo = this.parseInputTaxInvoiceInfo(idx, null)
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('#freetime_fn_pop > .popup_dim')
    },
    replaceKmtcBlNo (blNo) { // BL번호에서 KMTC 제거
      if (blNo) {
        return blNo.replace('KMTC', '')
      } else {
        return blNo
      }
    },
    async openPopup (compNm, idx) {
      console.log('openPopup @@@@@')

      let isOpen = true
      let divId = '#freetime_fn_pop'
      switch (compNm) {
        case 'FreeTimeExtensReqPop':
          this.parentInfo = {
            blNo: this.replaceKmtcBlNo(this.freeTimeData.csbl400quiryinfo.blNo),
            eiCatCd: this.freeTimeData.csbl400quiryinfo.eiCatCd,
            sr: '',
            demDetCatCd: this.tableArr[idx].demDetCatCd,
            freeTimeInvoice: this.tableArr[idx].freeTimeInvoice,
            tableArr: this.tableArr
          }
        break
        case 'InputTaxInvoiceInfo':
        if (this.getCheckCnt(idx) === 0) {
          this.customAlert(this.$t('msg.SETT060G010.046')) // 컨테이너를 선택해주세요.
        } else if (this.isReqSameCntr > 0) {
          // 입금계산서 요청한 건중 requset, pending 상태인 건
          setTimeout(() => {
            this.customAlert('이미 입금 요청한 컨테이너가 존재합니다.')
          }, 100)
        } else {
          let isZero = false
          let isFeeChg = false
          let freeChgYn = 'N'
          let cntrReturn = false

          // console.log('this.tableArr[idx].id @@@ ', this.tableArr[idx].id)

          for (let row of this.tableArr[idx].payload.rows) {
            // console.log('payload.rows @@@@ ', row)
            // 반납완료된 컨테이너 포함이면 팝업
            if (row.isChecked && row.cntrSts === 'Y' && this.freeTimeData.anCheck.podCtrCd === 'KR' && this.tableArr[idx].id === 'OFC') {
              cntrReturn = true
            }

            if (row.isChecked && row.vatAmt === 0) {
                isZero = true
            }
            freeChgYn = await this.freeDaySync(row, this.tableArr[idx].demDetCatCd)
            if (freeChgYn === 'Y') {
              isFeeChg = true
            }
          }

          if (cntrReturn) {
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.SETT060G010.067'), useConfirmBtn: true })) {
              return
            }
          }

          if (isZero) {
            setTimeout(() => {
              this.customAlert(this.$t('msg.SETT060G010.047')) // 금액이 0인 컨테이너가 포함되어 있습니다.
            }, 100)
          } else if (isFeeChg) {
            setTimeout(() => {
              this.$ekmtcCommon.alertCallback(this.$t('경과일이 상이합니다. 변경된 경과일을 확인 후 신청하시기 바랍니다.'), () => {
                this.getFreeTime()
              })
            }, 100)
          } else {
            this.getInputData(idx, compNm, this.freeTimeData.csbl400quiryinfo.blNo)
          }
        }
          isOpen = false
        break
        case 'FreeTimePrintPop':
          this.parentInfo = {
            blNo: this.freeTimeData.csbl400quiryinfo.blNo,
            demDetCatCd: '', // ??
            eiCatCd: this.freeTimeData.csbl400quiryinfo.eiCatCd,
            ctrCd: this.memberDetail.userCtrCd
          }
        break
        case 'PreviousIssuedInvoicePop':
          this.parentInfo = {
            blNo: this.freeTimeData.csbl400quiryinfo.blNo,
            demDetCatCd: this.tableArr[idx].demDetCatCd,
            eiCatCd: this.freeTimeData.csbl400quiryinfo.eiCatCd,
            lang: this.auth.serviceLang,
            usrNm: this.selectedProfile.picEnm,
            ctrCd: this.memberDetail.userCtrCd
          }
        break
        case 'ProformaInvoicePop':
          var calExts = ''
          var comma = ''
          var cnt = 0
          var cntrReturn = false

          for (let i = 0; i < this.tableArr[idx].payload.rows.length; i++) {
            if (this.tableArr[idx].payload.rows[i].isChecked) {
              cnt++
              var cntrNo = this.tableArr[idx].payload.rows[i].cntrNo
              var extsDt = this.tableArr[idx].payload.rows[i].extsDt
              var ovrDds = this.tableArr[idx].payload.rows[i].ovrDds
              var totAmt = this.tableArr[idx].payload.rows[i].totAmt
              var vatAmt = this.tableArr[idx].payload.rows[i].vatAmt
              calExts += comma + cntrNo + '_' + extsDt + '_' + ovrDds + '_' + totAmt + '_' + vatAmt
              comma = ','

              // 반납완료된 컨테이너 포함이면 팝업
              if (this.tableArr[idx].payload.rows[i].cntrSts === 'Y' && this.freeTimeData.anCheck.podCtrCd === 'KR' && this.tableArr[idx].id === 'OFC') {
                cntrReturn = true
              }
            }
          }

          if (cntrReturn) {
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.SETT060G010.067'), useConfirmBtn: true })) {
              return
            }
          }

          if (cnt === 0) {
            this.customAlert(this.$t('msg.SETT060G010.046')) // 컨테이너를 선택해주세요.
            return false
          }
          this.parentInfo = {
            blNo: this.freeTimeData.csbl400quiryinfo.blNo,
            demDetCatCd: this.tableArr[idx].demDetCatCd,
            eiCatCd: this.freeTimeData.csbl400quiryinfo.eiCatCd,
            calExts: calExts,
            invIssNo: '',
            idx: idx
          }
        break
        case 'FreeTimePop':
          divId = '#freetime_fn_pop_child'
          this.parentInfo2 = {
            items: [
              {
                blNo: this.replaceKmtcBlNo(this.freeTimeData.csbl400quiryinfo.blNo),
                pDemDetAppNo: idx
              }
            ]
          }
        break
        case 'CalcIssueInvMsgPop':
          var ofcInvoice = this.freeTimeData.freeTimeInvoiceGroupList.filter(v => v.demDetCatCd === '14')
          this.parentInfo = {
            apvStsCd: ofcInvoice[0].apvStsCd,
            reqRspsLst: ofcInvoice[0].reqRspsLst,
            blNo: this.freeTimeData.csbl400quiryinfo.blNo
          }
        break
        case 'VirtualAccountInvoiceInfo':
          if (this.getCheckCnt(idx) === 0) {
            this.customAlert(this.$t('msg.SETT060G010.046')) // 컨테이너를 선택해주세요.
          } else if (this.isReqSameCntr > 0) {
            // 입금계산서 요청한 건중 requset, pending 상태인 건
            setTimeout(() => {
              this.customAlert('이미 입금 요청한 컨테이너가 존재합니다.')
            }, 100)
          } else {
            let isZero = false
            let isFeeChg = false
            let freeChgYn = 'N'
            let cntrReturn = false
            let totalAmt = 0

            for (let row of this.tableArr[idx].payload.rows) {
              // 반납완료된 컨테이너 포함이면 팝업
              if (row.isChecked && row.cntrSts === 'Y' && this.freeTimeData.anCheck.podCtrCd === 'KR' && this.tableArr[idx].id === 'OFC') {
                cntrReturn = true
              }

              if (row.isChecked && row.vatAmt === 0) {
                isZero = true
              }

              if (row.isChecked && row.vatAmt !== 0) {
                totalAmt = totalAmt + row.vatAmt
              }
              freeChgYn = await this.freeDaySync(row, this.tableArr[idx].demDetCatCd)
              if (freeChgYn === 'Y') {
                isFeeChg = true
              }
            }

            if (cntrReturn) {
              if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.SETT060G010.067'), useConfirmBtn: true })) {
                return
              }
            }

            if (isZero) {
              setTimeout(() => {
                this.customAlert(this.$t('msg.SETT060G010.047')) // 금액이 0인 컨테이너가 포함되어 있습니다.
              }, 100)
            } else if (isFeeChg) {
              setTimeout(() => {
                this.$ekmtcCommon.alertCallback(this.$t('경과일이 상이합니다. 변경된 경과일을 확인 후 신청하시기 바랍니다.'), () => {
                  this.getFreeTime()
                })
              }, 100)
            } else {
              totalAmt = this.$ekmtcCommon.changeNumberFormat(totalAmt + 1100, { isComma: true })
              let msg = '가상계좌 이용 시 수수료 1,100원(부가세포함) 발생합니다.<br><br>'
              msg += totalAmt + '원(수수료포함)의 결제를 진행 하시겠습니까?<br>'
              msg += '가상계좌 입금 이후 단순 변심으로 인한 취소 시 환불 불가하며,'
              msg += '<div style="height: 20px">'
              msg += '<input id="virtualAccountAgreeYn" type= "checkbox" / ><label for="virtualAccountAgreeYn">동의</label>'
              msg += '</div>'

              let isOk = true
              isOpen = false
              isOk = await this.$ekmtcCommon.asyncAlertMessage({
                message: msg,
                useConfirmBtn: true,
                onConfirmSelf: (resolve, $Simplert) => {
                  const virtualAccountAgreeYn = document.querySelector('#virtualAccountAgreeYn')

                  if (!virtualAccountAgreeYn.checked) {
                    this.$ekmtcCommon.alertDefault('동의 되지 않았습니다.')
                    isOpen = false
                  } else {
                    isOpen = true
                    resolve(true)
                    $Simplert.close()
                  }
                },
                onCloseSelf: (resolve, $Simplert) => {
                  resolve(false)
                  $Simplert.close()
                  isOpen = false
                }
              })

              if (isOpen) this.getInputData(idx, compNm, this.freeTimeData.csbl400quiryinfo.blNo)
            }
          }
          isOpen = false
        break
      }
      if (isOpen) {
        if (divId === '#freetime_fn_pop_child') {
          this.customComponent2 = compNm
        } else {
          this.customComponent = compNm
        }

        this.$ekmtcCommon.layerOpen(divId + ' > .popup_dim')
      }
    },
    getCheckCnt (idx) {
      let cnt = 0
      let reqRno = ''

      for (let row of this.tableArr[idx].payload.rows) {
        if (row.isChecked) {
          cnt++
          this.reqRno = row.reqRno
        }
      }

      return cnt
    },
    closePopup (obj) {
      if (this.$store.state.settle.isRefreshFreetimeReq) {
        this.getFreeTime()
        this.$store.commit('settle/updateIsRefreshFreetimeReq', false)
      }
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#freetime_fn_pop > .popup_dim')
    },
    closePopup2 (obj) {
      this.customComponent2 = null
      this.$ekmtcCommon.layerClose('#freetime_fn_pop_child > .popup_dim')
    },
    screenToPdfDownload () {
      let datepickerList = document.querySelectorAll('.datepicker')
      datepickerList.forEach((element, index, array) => {
        document.getElementById(element.id).style.height = '32px'
      })
      this.$ekmtcCommon.screenToPdf({ cssSelector: 'body > div .content', fileName: 'Freetime_Req.pdf', download: 'Y' })
      datepickerList.forEach((element, index, array) => {
        document.getElementById(element.id).style.height = '24px'
      })
    },
    parseInputTaxInvoiceInfo (idx, data) {
      let krw = 0
      //let totAmt = 0
      //let dcAmt = 0
      let cntrList = []
      let inputDefaultData = {}
      if (!this.isEmpty(data)) {
        inputDefaultData = data[data.length - 1]
      }
      //console.log('this.tableArr[idx].payload.rows===================>', this.tableArr[idx].payload.rows)
      for (let row of this.tableArr[idx].payload.rows) {
        // console.log(row)
        let r = JSON.parse(JSON.stringify(row))

        // 컨테이너 전체 입금계산서 팝업으로 넘겨줌.
        if (row.isChecked) {
          r.cfmYn = 'Y'

          // 컨테이너 중 체크된 row
          r.cntrQty = 1
          // console.log(r)

          // cntrList.push({
          //   blNo: row.blNo,
          //   invRno: row.invRno,
          //   cntrNo: row.cntrNo,
          //   extsDt: row.extsDt,
          //   freeDds: row.freeDds,
          //   ovrDds: row.ovrDds,
          //   totAmt: parseInt(row.totAmt),
          //   dcAmt: parseInt(row.dcAmt),
          //   cfmYn: 'Y',
          //   dcRt: parseInt(row.dcRt)
          // })
          //krw += row.vatAmt // 팝업에 보내는 금액을 DC 가격 포함

          //totAmt += parseInt(row.vatAmt)
          //dcAmt += parseInt(row.vatAmt) * parseInt(row.dcRt) / 100

          krw += parseInt(row.vatAmt) - (parseInt(row.vatAmt) * parseInt(row.dcRt) / 100)
          r.dcAmt = parseInt(row.totAmt) * parseInt(row.dcRt) / 100
          r.totAmt = parseInt(row.totAmt) - (parseInt(row.totAmt) * parseInt(row.dcRt) / 100)
        } else {
          r.cntrQty = 0
        }

        cntrList.push(r)
      }

      //console.log('this.tableArr[idx].payload.cntrList===================>', cntrList)
      //krw = totAmt - dcAmt
      const eiCatCd = this.freeTimeData.csbl400quiryinfo.eiCatCd

      let info = {
        dpId: 'SETT060G010',
        dpType: 'F',
        inputDefaultData: inputDefaultData,
        btnType: eiCatCd === 'O' ? 'DEMDET' : 'OFC',
        totalAmt: krw,
        demDetCatText: this.tableArr[idx].id,
        isShow: {
          info: false,
          method: true,
          tax: true
        },
        blList: [{
          blNo: this.replaceKmtcBlNo(this.freeTimeData.csbl400quiryinfo.blNo),
          krw: krw,
          usd: 0,
          exRate: 1136,
          currency: 'KRW',
          obrdDt: '', //TODO
          demDetCatCd: this.tableArr[idx].demDetCatCd,
          eiCatCd: this.freeTimeData.csbl400quiryinfo.eiCatCd,
          cntrList: cntrList
        }],
        bookingInfo: null
      }
      return info
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
          return true
      } else {
          return false
      }
    },
    customAlert (msg) {
      let obj = {
          alertType: 'simple',
          customCloseBtnClass: 'button blue lg simplert-test1',
          customCloseBtnText: this.$i18n.t('msg.SETT060G010.018'),
          useIcon: false,
          customClass: 'simple_alert',
          message: msg,
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
    },
    routerSearch (data) {
      // console.log('routerSearch ::: ', data)

      this.params = { ...this.params, ...data }
      this.getFreeTime()
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ query: {} })
      }
    },
    changeCalcEiCatCd () {
      if (this.parentData.isCalc) {
        this.$store.commit('auth/updateCalcEiCatCd', this.params.eiCatCd)
      }
    },
    _openPopup () {
      this.openPopup('DemDetTariffPop', null)
    },
    _screenToPdfDownload () {
      this.screenToPdfDownload()
    },
    async freeDaySync (row, demDetCatCd) {
       const params = {
        blNo: row.blNo,
        cntrNo: row.cntrNo,
        demSeq: row.demSeq,
        freeDds: row.freeDds,
        freeEndDt: row.freeEndDt,
        invRno: row.invRno,
        demDetCatCd: demDetCatCd
      }
      let result = ''
      await FreeTimeApi.getFreeDaySync(params).then(async response => {
        result = response.data.freeDayRtn
      }).catch(() => {
        result = ''
      })
      return result
    }
  }
}
</script>
<style scoped>
.content_title_span {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.bul_list_sm >li:before {
  content: none;
}
</style>
