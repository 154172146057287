<template>
  <div class="mb10">
    <h2 class="content_title">{{ $t('msg.ONEX070G040.070') }}</h2><!-- 결제수단 선택 -->
    <table class="tbl_row">
      <tbody>
        <tr>
          <td class="border_left_none">
            <input type="radio" id="paytype-V" v-model="method.payType" value="V" :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'">
            <label for="paytype-V" class="mr20"><span></span>{{ $t('menu.MENU06.060') }}</label><!-- 가상계좌 -->
            <span style="color: red; font-weight: bold">※ 선수금 사용시 직접입금으로 선택 하시기 바랍니다.</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div id="vtl_pop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          @child="getChildData"
          :parent-info="parentInfo"
          :freight-bill-info="parentInfo"
        />
      </win-layer-pop>
    </div>
    <div id="fb_dext5_pop2" v-show="isShowDext5Pop">
      <div class="popup_dim" ref="popup">
        <div class="popup_wrap" style="width:800px; height:520px;">
          <button class="layer_close" @click="closeFileUploadr()">close</button>
          <div class="popup_cont">
            <h1 class="page_title">{{ $t('msg.SETT010T010.181') }}</h1><!-- 파일첨부 -->
            <div class="content_box">
              <DEXT5Upload
                ref="dextupload"
                v-if="isShowDext5"
                :id="'dext5-uploader-fb2-' + dext5Idx"
                :single="false"
                :category="false"
                width="100%"
                height="200px"
                @completed="uploadFile"
                :file-info="uploadFileInfo"
                :category-info="[]"
              />
            </div>
          </div>
          <div class="mt10 text_center">
            <a class="button blue lg" @click="closeFileUploadr()">{{ $t('msg.MYIN050T010.057') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'
import EDatePicker from '@/components/common/EDatePickerCustom'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import VtlAccPop from '@/pages/settle/popup/VtlAccPop'
import EInputNumber from '@/components/common/EInputNumber'
import { rootComputed } from '@/store/helpers'
import DEXT5Upload from '@/components/DEXT5Upload'
export default {
  name: 'VirtualAccountFreightBillMethod',
  components: {
    EDatePicker,
    WinLayerPop,
    VtlAccPop,
    EInputNumber,
    DEXT5Upload
  },
  props: [
    'errArr',
    'clearArr',
    'validationMethod',
    'dpId',
    'inputDefaultData',
    'btnType'
  ],
  data: function () {
    return {
      dateRange: {
        min: '',
        max: ''
      },
      method: {
        payType: 'V', // D: "직접입금", V: "가상계좌", N : "즉시이체"
        vrAccount: { usd: null, krw: null },
        depositInfoArr: [{
              payCur: '',
              bank: '',
              bankArr: [],
              accNo: '',
              accNoArr: [],
              depositPnm: '',
              depositAmt: '',
              depositAmtComma: '',
              depositDt: this.dateFormat(new Date(), false),
              rowIdx: 0,
              inputError: false
        }]
      },
      bankInfoArr: [],
      bankArr: [],
      accNoArr: [],
      payCur: [],
      currIdx: null,
      rowIdx: 0,
      bankLoginInfo: {
        bnkId: '',
        bnkPswd: '',
        bnkAcct: '',
        bnkCmsYn: ''
      },
      customComponent: null,
      parentInfo: null,
      isShowTooltip: false,
      inputError: false,
      uploadFileInfo: [],
      uploadFileName: '',
      dext5Idx: 0,
      isShowDext5: true,
      isShowDext5Pop: true,
      isOverseasFile: true,
      drwotAcnInfo: {
        rtnCode: '',
        drwotAcnList: []
      },
      params: {
        bnkId: '',
        bnkPswd: '',
        bnkAcct: '',
        bnkCmsYn: ''
      },
      linkPopupPage: null,
      joinDrwotAcnInfo: null,
      bankLoginInfoOpen: true,
      bankLoginInfoAcctOpen: true
    }
  },
  computed: {
    ...rootComputed,
    setRadioBtn () {
      let rtnVal = true

      // d/o발행, dem/det, OFC, 반납, 부킹캔슬 일때, 가상계좌/즉시이체 숨김
      if (this.btnType === 'DO' || this.btnType === 'OFC' || this.btnType === 'DEMDET' || this.btnType === 'RETURN' || this.btnType === 'BOOKINGCANCEL') {
        rtnVal = false
      }

      return rtnVal
    }
  },
  watch: {
    uploadFileInfo () {
      if (this.uploadFileInfo.length > 0) {
        let fileNm = ''
        let isFirst = true
        for (let file of this.uploadFileInfo) {
          if (!isFirst) {
            fileNm += ', '
          }
          fileNm += file.originalName
          isFirst = false
        }
        this.uploadFileName = fileNm
      } else {
        this.uploadFileName = ''
      }
    },
    dpId: function () {
      //
    },
    method: {
      deep: true,
      handler () {
        if (typeof (this.validationMethod) === 'function') {
          this.validationMethod(this.method, 'inputData')
        }

        this.$emit('child', { type: 'updateMethodPayType', payload: { payType: this.method.payType } })
      }
    },
    errArr: {
      deep: true,
      handler () {
        for (let err of this.errArr) {
          if (err.id.indexOf('fbt-depositDt') !== -1) {
            this.method.depositInfoArr[err.id.split('-')[2]].inputError = true
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + err.id), err.msg)
          } else {
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + err.id), err.msg)
          }
        }
      }
    },
    clearArr: {
      deep: true,
      handler () {
        for (let id of this.clearArr) {
          if (id.indexOf('fbt-depositDt') !== -1) {
            this.method.depositInfoArr[id.split('-')[2]].inputError = false
            this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#' + id))
          } else {
            this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#' + id))
          }
        }
      }
    }
  },
  created () {
    this.resetDext5()
    this.isOverseasFile = this.memberDetail.userCtrCd !== 'KR'
  },
  mounted: function () {
     this.getVrAccount()
     this.getBankInfo()
     this.getBankLoginInfo()
     this.setToday()
  },
  methods: {
    setToday () {
      this.dateRange = {
        min: '',
        max: this.dateFormat(new Date(), true)
      }
    },
    dateFormat (dt, isDot) {
      let year = dt.getFullYear() //연도
      let month = ('0' + (dt.getMonth() + 1)).slice(-2) //월
      let date = ('0' + dt.getDate()).slice(-2) //날짜
      let res = ''
      if (isDot) {
        res = year + '.' + month + '.' + date
      } else {
        res = year + month + date
      }
      return res
    },
    resetDext5 () {
      this.isShowDext5Pop = false
      this.openFileUploader()
      this.closeFileUploadr()
      this.isShowDext5Pop = true
    },
    openFileUploader () {
      this.isShowDext5 = true
      this.dext5Idx++
      this.$ekmtcCommon.layerOpen('#fb_dext5_pop2 > .popup_dim')
    },
    closeFileUploadr () {
      this.isShowDext5 = false
      this.$ekmtcCommon.layerClose('#fb_dext5_pop2 > .popup_dim')
    },
    uploadFile (fileInfo) {
      this.uploadFileInfo[0].isNew = true
    },
    getChildData (data) {
       switch (data.type) {
        case 'close':
          this.closePopup()
          break
       }
    },
    openPopup (compNm) {
        this.customComponent = compNm
        this.$ekmtcCommon.layerOpen('#vtl_pop > .popup_dim')
    },
    closePopup (obj) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('#vtl_pop > .popup_dim')
        this.getVrAccount()
    },
    isShowPayType (type) {
      let isShow = true
      if (type === 'D') {
        //
      } else if (type === 'V') {
        if (this.dpId === 'SETT060G010') {
          //isShow = false
        }
      } else if (type === 'N') {
        if (this.dpId === 'SETT060G010') {
          //isShow = false
        }
      }
      return isShow
    },
    async getBankLoginInfo () {
      await CalcIssue.getBankLoginInfo().then(response => {
        this.bankLoginInfo = { ...response.data }
        console.log('this.bankLoginInfo Info--------')
        console.log(this.bankLoginInfo)

        if (this.bankLoginInfo !== null && this.bankLoginInfo.bnkId !== null && this.bankLoginInfo.bnkPswd != null) {
          CalcIssue.getDrwotAcnInfo(this.bankLoginInfo.bnkId, this.bankLoginInfo.bnkPswd).then(response2 => {
            // 로그인 에러
            if (response2.data.drwotAcnListOutInfo.rtnCode === '0000') {
              if (response2.data.drwotAcnListRecList !== null || response2.data.drwotAcnListRecList.length > 0) {
                this.drwotAcnInfo.drwotAcnList = response2.data.drwotAcnListRecList
                if (this.bankLoginInfo !== null && this.bankLoginInfo.bnkAcct !== null && this.bankLoginInfo.bnkAcct !== '') {
                  this.params.bnkAcct = this.bankLoginInfo.bnkAcct
                } else {
                  this.params.bnkAcct = this.drwotAcnInfo.drwotAcnList[0].drwotAcno
                }
              }
            } else {
              if (response2.data.rtnCode === 'CAVVA975') {
                console.log('CAVVA975')
              }
            }

            this.bankLoginInfoOpen = false
            this.bankLoginInfoAcctOpen = false
          })
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getVrAccount () {
       CalcIssue.getVrAccount().then(response => {
         this.method.vrAccount = { usd: {}, krw: {} }
         for (let vrAccount of response.data) {
           if (vrAccount.currencyCode === 'WON') {
             this.method.vrAccount.krw = vrAccount
           } else if (vrAccount.currencyCode === 'USD') {
             this.method.vrAccount.usd = vrAccount
           }
         }
      }).catch(e => {
        console.log(e)
      })
    },
    getBankInfo () {
      CalcIssue.getBankInfo().then(response => {
        this.bankInfoArr = response.data
        this.setInputData()
      }).catch(e => {
        console.log(e)
      })
    },
    async setInputData () {
      if (this.isEmpty(this.inputDefaultData)) {
        //
      } else {
        if (this.inputDefaultData.setlMethCd !== undefined && this.inputDefaultData.setlMethCd !== null && this.inputDefaultData.setlMethCd !== '') {
          this.method.payType = this.inputDefaultData.setlMethCd
        }

        let depositInfoArr = JSON.parse(JSON.stringify(this.inputDefaultData.payInfoList))
        if (!this.isEmpty(depositInfoArr)) {
          this.method.depositInfoArr = []
          for (let d of depositInfoArr) {
            let selectedArr = await this.getBankInfoArr(d.payCur, d.rcvBnkNm)
            let obj = {
              payCur: d.payCur || '',
              bank: d.rcvBnkNm || '',
              bankArr: selectedArr.bankArr,
              accNo: d.payAcctNo || '',
              accNoArr: selectedArr.accNoArr,
              depositPnm: d.rcvUsrNm,
              depositAmt: '',
              depositAmtComma: this.numberFormat(d.payAmt),
              depositDt: d.rcvDt,
              rowIdx: this.rowIdx,
              inputError: false
            }

            this.method.depositInfoArr.push(obj)
            this.setComma(this.rowIdx)
            this.rowIdx++
          }
        }
      }
    },
    getBankInfoArr (payCur, bankName) {
      let res = {
        bankArr: [],
        accNoArr: []
      }
      for (let p of this.bankInfoArr) {
        if (payCur === p.paycur) {
          res.bankArr = p.bankList
          for (let b of p.bankList) {
            if (bankName === b.bank) {
              res.accNoArr = b.accNoList
              break
            }
          }
          break
        }
      }

      return res
    },
    changePayCur (idx) {
      let payCur = this.method.depositInfoArr[idx].payCur
      let bankArr = []
      this.method.depositInfoArr[idx].bank = ''
      this.method.depositInfoArr[idx].bankArr = []
      this.method.depositInfoArr[idx].accNo = ''
      this.method.depositInfoArr[idx].accNoArr = []
      for (let p of this.bankInfoArr) {
        if (payCur === p.paycur) {
          this.method.depositInfoArr[idx].bankArr = p.bankList
          break
        }
      }
    },
    changeBank (idx) {
      let bank = this.method.depositInfoArr[idx].bank
      let payCur = this.method.depositInfoArr[idx].payCur
      this.method.depositInfoArr[idx].accNo = ''
      this.method.depositInfoArr[idx].accNoArr = []
      for (let p of this.bankInfoArr) {
        if (payCur === p.paycur) {
          for (let b of p.bankList) {
            if (bank === b.bank) {
              this.method.depositInfoArr[idx].accNoArr = b.accNoList
              break
            }
          }
        }
      }
    },
    addDepositInfo () {
      if (this.method.depositInfoArr.length >= 3) {
        let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$t('msg.ONEX020P130.068'), //'닫기',
            useIcon: false,
            customClass: 'simple_alert',
            message: this.$t('msg.ADD20220219.018'), // 입금정보는 최대 3개까지 추가할 수 있습니다.
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
      } else {
        this.rowIdx++
        this.method.depositInfoArr.push(
        {
          payCur: '',
          bank: '',
          bankArr: [],
          accNo: '',
          accNoArr: [],
          depositPnm: '',
          depositAmt: '',
          depositAmtComma: '',
          depositDt: this.dateFormat(new Date(), false),
          rowIdx: this.rowIdx,
          inputError: false
        }
      )
      }
    },
    deleteDepositInfo (idx) {
      if (idx > 0) {
         this.method.depositInfoArr.splice(idx, 1)
      }
    },
    setCurrIdx (idx) {
      this.currIdx = idx
    },
    chnageDepositDt () {
      this.$emit('child', { type: 'updateDepoistDt', payload: { depositInfoArr: this.method.depositInfoArr } })
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
        return true
      } else {
        return false
      }
    },
    emitData (type) {
      let res = null
      let method = null
      switch (type) {
        case 'method':
          method = JSON.parse(JSON.stringify(this.method))
          method.erpBankName = ''
          method.bankLoginInfo = this.bankLoginInfo
          method.overseasFileList = this.uploadFileInfo
          res = method
          break
      }
      return res
    },
    setComma (idx) {
      this.method.depositInfoArr[idx].depositAmt = this.method.depositInfoArr[idx].depositAmtComma.replace(/,/gi, '')
      this.method.depositInfoArr[idx].depositAmtComma = this.numberFormat(this.method.depositInfoArr[idx].depositAmt)
    },
    numberFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    reset () {
      this.resetDext5()
      this.method.payType = 'V'
      this.method.depositInfoArr = [
        {
          payCur: '',
          bank: '',
          bankArr: [],
          accNo: '',
          accNoArr: [],
          depositPnm: '',
          depositAmt: '',
          depositDt: this.dateFormat(new Date(), false),
          rowIdx: 0
        }
      ]

      this.bankArr = []
      this.accNoArr = []
      this.currIdx = null
      this.rowIdx = 0
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-payCur-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-bank-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-accNo-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-depositPnm-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-depositAmt-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-depositDt-0'))
    },
    async updateDrwotAcnInfoBnkAcct () {
      // this.params.bnkId = this.bankLoginInfo.bnkId
      // this.params.bnkPswd = this.bankLoginInfo.bnkPswd
      // this.params.bnkCmsYn = this.bankLoginInfo.bnkCmsYn

      if (this.bankLoginInfo.bnkCmsYn !== 'Y') {
        this.openAlert(app.$t('msg.SETT010T010.182')) // Star CMS 및 바로ERP 약정을 먼저 진행 하셔야 등록이 가능합니다.
      }

      if (this.params.bnkAcct === null || this.params.bnkAcct === '') {
        this.openAlert(app.$t('msg.SETT010T010.183')) // 출금계좌를 선택해주세요.
      }

      await CalcIssue.updateDrwotAcnInfoBnkAcct(this.params).then(response => {
        this.openAlert(app.$t('msg.SETT010T010.184')) // 등록되었습니다.
      }).catch(e => {
        console.log(e)
      })
    },
    openLinkPopup (cnt) {
      if (cnt === 1) {
        this.linkPopupPage = window.open('https://cms.kbstar.com/quics?page=C064285')
      } else if (cnt === 2) {
        this.linkPopupPage = window.open('https://obiz.kbstar.com/quics?page=C017207')
      } else if (cnt === 3) {
        this.linkPopupPage = window.open('http://www.ekmtc.com/KMTC410/InformDetail.do?notice_id=529&action=view&table_id=A')
      }

      this.linkPopupPage.addEventListener('beforeunload', this.closeLinkPopup)
    },
    closeLinkPopup () {
      if (this.linkPopupPage) {
        this.linkPopupPage.close()
        this.linkPopupPage.removeEventListener('beforeunload', this.closeLinkPopup)
        this.linkPopupPage = null
      }
    },
    kbErpSave () {
      if (typeof this.bankLoginInfo.bnkId === 'undefined' || this.bankLoginInfo.bnkId === null || this.bankLoginInfo.bnkId === '') {
        this.openAlert(app.$t('msg.SETT010T010.185')) // KB 기업인터넷뱅킹 ID를 입력해주세요.

        return
      }

      if (typeof this.bankLoginInfo.bnkPswd === 'undefined' || this.bankLoginInfo.bnkPswd === null || this.bankLoginInfo.bnkPswd === '') {
        this.openAlert(app.$t('msg.SETT010T010.186')) // KB 기업인터넷뱅킹 비밀번호를 입력해주세요.

        return
      }

      this.params.bnkId = this.bankLoginInfo.bnkId
      this.params.bnkPswd = this.bankLoginInfo.bnkPswd

      CalcIssue.joinDrwotAcnInfo(this.params).then(response => {
        this.joinDrwotAcnInfo = response.data.joinInfoInqOutInfo

        if (this.joinDrwotAcnInfo.rtnCode !== '0000') {
          this.openAlert(this.joinDrwotAcnInfo.rtnMsg.replace(/\+/g, ' '))
        } else {
          if (response.data.erpYn === 'Y' && response.data.starCmsYn === 'Y') {
            this.openAlert(app.$t('msg.SETT010T010.187')) // 정상적으로 등록되었습니다.

            this.bankLoginInfo.bnkCmsYn = 'Y'
            this.getBankLoginInfo()
          } else {
            this.openAlert(app.$t('msg.SETT010T010.188')) // Star CMS 및 바로ERP 약정을 먼저 진행 하셔야 등록이 가능합니다.
            this.openLinkPopup(1)
          }
        }
      }).catch(e => {
        console.log(e)
      })
    },
    changeBankLoginInfoOpen () {
      this.bankLoginInfoOpen = !this.bankLoginInfoOpen
    },
    changeBankLoginInfoAcctOpen () {
      this.bankLoginInfoAcctOpen = !this.bankLoginInfoAcctOpen
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    changeBnkAcct () {
      this.bankLoginInfo.bnkAcct = this.params.bnkAcct
    }
  }
}
</script>
<style>
  .acc-tr-border-bottom {
   border-bottom: 1px solid #e1e3eb
  }
</style>
