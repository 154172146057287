<template>
  <div class="bl_area capture_area">
    <form
      id="frm"
      @submit.prevent
    >
      <div class="content_box beforetab">
        <table class="tbl_search">
          <colgroup>
            <col style="width:120px">
            <col>
            <col style="width:120px">
            <col style="width:180px">
          </colgroup>
          <tbody>
            <tr>
              <th>B/L No.</th>
              <td>
                <input
                  v-model="regParams.blNo"
                  type="text"
                  class="wid200"
                  readonly
                >
                <a class="button sm ml2" @click="fnCommonBtnAction('LOG')">Log {{ $t('btn.CMBTK011') }}</a>
                <a class="button sm ml2" @click="fnCAInquiryPop()">C/A {{ $t('btn.CMBTK011') }}</a>
                <a v-show="isBlAdd" class="button sm ml2" @click="fnCommonBtnAction('PBL')">Part S/R+</a>
                <a v-show="isBlAdd" class="button sm ml2" @click="fnCommonBtnAction('NBL')">Normal S/R+</a>
              </td>
              <th>{{ $t('msg.CSBK100.005') }}</th>
              <td>
                <span v-if="regParams.addBkgNoList.length === 0" class="span_bkg">{{ detailBkgNo }}</span>
                <span v-if="regParams.addBkgNoList.length > 0" class="position_relative span_tooltip span_bkg">
                  <a class="txt_underline" @click="$ekmtcCommon.showTooltip($event)">{{ detailBkgNo }} <span v-if="regParams.addBkgNoList.length > 0">외 {{ regParams.addBkgNoList.length }}건</span></a>
                  <div class="tooltip_wrap short position_absolute"><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <button
                        type="button"
                        class="close"
                        @click="$ekmtcCommon.hideTooltip($event)"
                      >close</button>
                      <ul class="bul_list_sm t2">
                        <li>
                          <p v-for="(vo, idx) in regParams.addBkgNoList" :key="'bkgNoList_' + idx">{{ vo }}</p>
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content_box mt10">
        <div class="booking_call_wrap">
          <!-- 불러오기 -->
          <div class="call_search">
            <input v-show="vueMode"
                   type="radio"
                   id="templateCd_1"
                   v-model="templateCd"
                   value="A"
            />
            <label for="templateCd_1">{{ $t("msg.CSBK100.505") }}</label>
            <!-- 불러오기 -->
            <input
              v-model="templateNo"
              type="text"
              placeholder="B/L No."
              @keypress.enter="templateEnter"
              @input="templateNo = templateNo.toUpperCase()"
              @click="templateCd ='A'"
            />
            <button
              type="button"
              class="search_btn01"
              @click.prevent="templateSearch()"
              @click="templateCd ='A'"
            >
              <span class="offscreen">{{ $t("msg.CSBK100.121") }}</span>
              <!-- 부킹템플릿 불러오기 -->
            </button>
            <input v-show="vueMode"
                   type="radio"
                   id="templateCd_2"
                   v-model="templateCd"
                   value="B"
            />
            <label v-show="vueMode" for="templateCd_2" style="margin-left:20px;">Template</label>
            <select v-show="vueMode"
                    id="templateList"
                    style="width: 200px; margin-left:20px;"
                    v-model="templateNo_2"
                    @click="templateCd ='B'"
            >
              <option value="">
                {{ this.$t("msg.CSBL200.078") }}
              </option>
              <option
                v-for="vo in templateList"
                :key="vo.tplRno"
                :value="vo.tplRno"
              >
                {{ vo.tplNm }}
              </option>
            </select>
            <a class="button blue sh ml15" @click.prevent="setTemplateInfo()">{{
              $t("btn.CMBTK010")
            }}</a>
            <a v-show="vueMode" class="button blue sh" style="margin-left:5px;" @click.prevent="deleteTemplate()">{{
              $t("art.CMBA660.007")
            }}</a>
            <!-- 삭제 -->
          </div>
        </div>
        <!-- 적용 -->
      </div>
      <!--(CN) 담당자 정보 입력-->
      <div v-if="memberDetail.userCtrCd === 'CN'" class="content_box mt10">
        <div class="flex_box mt5">
          <h2 class="content_title">Job(document) Pic</h2>
        </div>
        <table class="tbl_row mt5">
          <colgroup>
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:50%">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ this.$t("msg.CSBK100.126") }}</th>
              <th>{{ $t("msg.CSBK100.148") }}</th>
              <th>email</th>
            </tr>
            <tr>
              <!-- 성함 -->
              <td class="position_relative">
                <select
                  v-model="docPicNo"
                  id="docPicNo"
                  class="col_3"
                  @change="changeDocPicNo"
                >
                  <option value="">
                    {{ this.$t("msg.CSBK100.050") }}
                  </option>
                  <option
                    v-for="vo in cstInfoList"
                    :key="vo.picNo"
                    :value="vo.picNo"
                  >
                    {{ vo.picNm }}
                  </option>
                </select>

                <input
                  id="docPicNm"
                  class="inp_space70"
                  v-model="regParams.docPicNm"
                  type="text"
                  maxlength="30"
                  @keyup="checkValidationCharger('docPicNm', 'KEYUP')"
                />
              </td>
              <!-- 전화번호 -->
              <td class="position_relative">
                <e-input-number
                  id="docPicTelPlcNo"
                  class="col_3"
                  v-model="regParams.docPicTelPlcNo"
                  :is-phone="true"
                  maxlength="4"
                  @input="
                    checkValidationCharger('docPicTelPlcNo', 'KEYUP')
                  "
                >
                </e-input-number>

                <e-input-number
                  id="docPicTelNo"
                  class="inp_space70"
                  v-model="regParams.docPicTelNo"
                  :is-phone="true"
                  maxlength="12"
                  @input="checkValidationCharger('docPicTelNo', 'KEYUP')"
                >
                </e-input-number>
              </td>
              <td>
                <input
                  id="docPicEmlAddr"
                  v-model="regParams.docPicEmlAddr"
                  type="text"
                  :placeholder="$t('msg.CSBK100.152')"
                  maxlength="100"
                  class="inp_98"
                  @blur="checkValidationCharger('docPicEmlAddr', 'KEYUP')"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <p class="txt_desc">{{ $t("msg.CSBK100.150") }}</p>
      </div>
      <div class="content_box mt10">
        <div class="flex_box mt20">
          <h2 class="content_title">{{ $t('msg.CSBL200.182') }}</h2> <!-- Shipping Information -->
        </div>

        <booking-schedule-info
          ref="schdInfo"
          :bkg-no="regParams.bkgNo"
          :bl-no="regParams.blNo"
          save-flag="PICKUP"
        />

        <table class="tbl_row mt20 tbl_btm_none">
          <colgroup>
            <col style="width:170px">
            <col>
            <col style="width:170px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.183') }}</th><!-- Place of Receipt -->
              <td class="position_relative">
                <!-- <div id="porTooltip"
                     style="display:none;
                              color:black;
                              right: 10px;
                              width: 280px;
                              top: 35px;"
                     class="tooltip_wrap position_absolute"
                >
                  <div class="kmtcCont text_left">
                    <ul>
                      <li>
                        POR/POL/POD/DLYは、BOOKING 時のPORTと異なるPORTの記載をすることはできません。異なるPORTを記載する場合は必ずBOOKING 内容の変更をお願いします。貨物はBOOKING 時に登録されているPORTにのみ手配が可能です。
                      </li>
                    </ul>
                  </div>
                </div> -->
                <div class="tbl_form div_plc">
                  <span class="dv col_3">
                    <select
                      v-model="porPlcNmType"
                      @change="changeInputType($event, 'porPlcNm')"
                    >
                      <option value="select">Select</option>
                      <option value="input">Input</option>
                    </select>
                  </span>
                  <span class="dv col_7 span_plc">
                    <select
                      v-if="porPlcNmType === 'select'"
                      id="porPlcNm"
                      v-model="regParams.porPlcNm"
                      @change="checkValidationInfo('porPlcNm')"
                    >
                      <option v-for="(vo, idx) in plcEnmCodes[regParams.porPlcCd]" :key="'porPlcNm_' + idx" :value="vo.plcEnm">{{ vo.plcEnm }}</option>
                    </select>
                    <e-trans-string-check-cnee-input
                      v-if="porPlcNmType === 'input'"
                      :id="'porPlcNm'"
                      v-model="regParams.porPlcNm"
                      :is-upper-case="true"
                      :is-required="true"
                      :message-required="$t('msg.CSBK100.147')"
                      maxlength="50"
                      :is-jp-pop-msg="true"
                      @blur="checkJPplcPopMsg"
                    />
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBL200.184') }}</th> <!-- Port of Loading -->
              <td class="position_relative">
                <div class="tbl_form div_plc">
                  <span class="dv col_3">
                    <select
                      v-model="polPortNmType"
                      @change="changeInputType($event, 'polPortNm')"
                    >
                      <option value="select">Select</option>
                      <option value="input">Input</option>
                    </select>
                  </span>
                  <span class="dv col_7 span_plc">
                    <select
                      v-if="polPortNmType === 'select'"
                      id="polPortNm"
                      v-model="regParams.polPortNm"
                      @change="checkValidationInfo('polPortNm')"
                    >
                      <option v-for="(vo, idx) in plcEnmCodes[regParams.polPortCd]" :key="'polPortNm_' + idx" :value="vo.plcEnm">{{ vo.plcEnm }}</option>
                    </select>
                    <e-trans-string-check-cnee-input
                      v-if="polPortNmType === 'input'"
                      :id="'polPortNm'"
                      v-model="regParams.polPortNm"
                      :is-upper-case="true"
                      :is-required="true"
                      :message-required="$t('msg.CSBK100.147')"
                      maxlength="50"
                      :is-jp-pop-msg="true"
                      @blur="checkJPplcPopMsg"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBL200.185') }}</th> <!-- Port of Discharge -->
              <td class="position_relative">
                <div class="tbl_form div_plc">
                  <span class="dv col_3">
                    <select
                      v-model="podPortNmType"
                      @change="changeInputType($event, 'podPortNm')"
                    >
                      <option value="select">Select</option>
                      <option value="input">Input</option>
                    </select>
                  </span>
                  <span class="dv col_7 span_plc">
                    <select
                      v-if="podPortNmType === 'select'"
                      id="podPortNm"
                      v-model="regParams.podPortNm"
                      @change="checkValidationInfo('podPortNm')"
                    >
                      <option v-for="(vo, idx) in plcEnmCodes[regParams.podPortCd]" :key="'podPortNm_' + idx" :value="vo.plcEnm">{{ vo.plcEnm }}</option>
                    </select>
                    <e-trans-string-check-cnee-input
                      v-if="podPortNmType === 'input'"
                      :id="'podPortNm'"
                      v-model="regParams.podPortNm"
                      :is-upper-case="true"
                      :is-required="true"
                      :message-required="$t('msg.CSBK100.147')"
                      maxlength="50"
                      :is-jp-pop-msg="true"
                      @blur="checkJPplcPopMsg"
                    />
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBL200.186') }}</th> <!-- Place of Delivery -->
              <td class="position_relative">
                <div class="tbl_form div_plc">
                  <span class="dv col_3">
                    <select
                      v-model="dlyPlcNmType"
                      @change="changeInputType($event, 'dlyPlcNm')"
                    >
                      <option value="select">Select</option>
                      <option value="input">Input</option>
                    </select>
                  </span>
                  <span class="dv col_7 span_plc">
                    <select
                      v-if="dlyPlcNmType === 'select'"
                      id="dlyPlcNm"
                      v-model="regParams.dlyPlcNm"
                    >
                      <option v-for="(vo, idx) in plcEnmCodes[regParams.dlyPlcCd]" :key="'dlyPlcNm_' + idx" :value="vo.plcEnm">{{ vo.plcEnm }}</option>
                    </select>
                    <e-trans-string-check-cnee-input
                      v-if="dlyPlcNmType === 'input'"
                      :id="'dlyPlcNm'"
                      v-model="regParams.dlyPlcNm"
                      maxlength="50"
                      :is-upper-case="true"
                      :is-required="true"
                      :message-required="$t('msg.CSBK100.147')"
                      :is-jp-pop-msg="true"
                      @blur="checkJPplcPopMsg"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBK100.006') }}</th>
              <td>
                <input
                  v-model="regParams.obrdDtTemp"
                  class="col_5"
                  type="text"
                  readonly
                >
                <a
                  class="button sm ml2"
                  @click="onBoardDatePop()"
                >{{ $t('btn.CMBTK004') }}</a>
              </td>
              <th>Final destination</th>
              <td>
                <e-trans-string-check-cnee-input
                  :id="'lstDestPlcNm'"
                  :is-upper-case="true"
                  v-model="regParams.lstDestPlcNm"
                  maxlength="50"
                />
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBL200.228') }}</th>
              <td>
                <select
                  v-model="regParams.polShtmCd"
                  class="col_3"
                  id="polShtmCd"
                  @change="fnChangeTerm()"
                >
                  <option v-for="(vo, idx) in commonCodes['01033']" :key="'polShtmCd_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
                <span class="slash">/</span>
                <select
                  v-model="regParams.podShtmCd"
                  class="col_3"
                  id="podShtmCd"
                >
                  <option v-for="(vo, idx) in commonCodes['01033']" :key="'podShtmCd_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <!-- <th>{{ $t('msg.CSBL200.226') }}</th>
              <td>
                <select
                  v-model="regParams.blTypCd"
                  id="blTypCd"
                  class="col_5"
                  @change="changeBlTypCd($event)"
                >
                  <option value="06">ORIGINAL B/L</option>
                  <option value="02">SURRENDER B/L</option>
                  <option value="03">{{ $t('msg.ONEX070G040.031') }}</option>
                </select>
              </td> -->
              <th>{{ $t('msg.CSBL200.227') }}</th>
              <td>
                <select
                  v-model="regParams.frtPncCd"
                  class="col_5"
                  :disabled="true"
                >
                  <option v-for="(vo, idx) in commonCodes['01032']" :key="'frtPncCd_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
            </tr>
            <tr v-if="lang === 'JPN'">
              <th>{{ $t('msg.CSBL200.229') }}</th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_5">
                    <select
                      v-model="regParams.issCtrCd"
                      id="issCtrCd"
                      @change="changeCtrCd($event, 'issPlcList')"
                    >
                      <option value="">{{ $t('msg.CSBK100.062') }}</option>
                      <option v-for="(vo, idx) in ctrCdList" :key="'issCtrCd_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                    </select>
                  </span>
                  <span class="dv col_5">
                    <select
                      v-model="regParams.issPlcCd"
                      id="issPlcCd"
                    >
                      <option value="">{{ $t('msg.CSBK100.062') }}</option>
                      <option v-for="(vo, idx) in issPlcList" :key="'issPlcCd_' + idx" :value="vo.plcCd">{{ vo.plcEnm }}</option>
                    </select>
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBL200.230') }}</th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_5">
                    <select
                      v-model="regParams.frtPayCtrCd"
                      id="frtPayCtrCd"
                      @change="changeCtrCd($event, 'frtPayPlcList')"
                    >
                      <option value="">{{ $t('msg.CSBK100.062') }}</option>
                      <option v-for="(vo, idx) in ctrCdList" :key="'frtPayCtrCd_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                    </select>
                  </span>
                  <span class="dv col_5">
                    <select
                      v-model="regParams.frtPayPlcCd"
                      id="frtPayPlcCd"
                    >
                      <option value="">{{ $t('msg.CSBK100.062') }}</option>
                      <option v-for="(vo, idx) in frtPayPlcList" :key="'frtPayPlcCd_' + idx" :value="vo.plcCd">{{ vo.plcEnm }}</option>
                    </select>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="td_noline" colspan="2" v-show="!blTypeVisibleContry.includes(auth.userCtrCd)"></td>
              <th class="line_bottom" v-show="blTypeVisibleContry.includes(auth.userCtrCd)">B/L type</th>
              <td class="line_bottom" v-show="blTypeVisibleContry.includes(auth.userCtrCd)">
                <div class="tbl_form">
                  <div v-for="(item, index) in commonCodes['CS105']" :key="index" style="float:left;">
                    <input type="radio" :id="'blTypCd'+index" name="blTypCd" v-model="regParams.blTypCd" :value="item.cd" :disabled="item.cd === '03'">
                    <label :for="'blTypCd'+index" class="mr20">{{ item.cdNm }}</label>
                  </div>
                </div>
              </td>
              <th class="line_bottom">S/C No.</th>
              <td class="line_bottom">{{ regParams.frtAppNo }}</td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">{{ $t('msg.CSBL200.231') }}</h2>

        <table class="tbl_col">
          <colgroup>
            <col width="36%">
            <col width="14%">
            <col width="14%">
            <col width="14%">
            <col width="22%">
          </colgroup>
          <tbody>
            <tr>
              <th class="text_left">
                {{ $t('msg.NEWB020G010.043') }}
                <select
                  class="wid150 ml20 bg_white"
                  @change="changeLatestInfo($event, 'shpr')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in cstEnmCodes['SHPR']" :key="'shpr_' + idx" :value="vo.cstNm">{{ vo.cstNm }}</option>
                </select>
              </th>
              <th>{{ $t('msg.CSBL200.188') }}</th> <!-- 지역 -->
              <th>{{ $t('msg.CSBL200.189') }}</th> <!-- City(시/군) -->
              <th>{{ $t('msg.CSBL200.190') }}</th> <!-- State/Province(도) -->
              <th>{{ $t('msg.CSBL200.191') }}</th> <!-- Postal Code(우편번호) -->
            </tr>
            <tr>
              <td>
                <e-trans-address-input :id="'shprCstEnm'"
                                       ref="shprCstEnm"
                                       v-model="regParams.shprCstEnm"
                                       :is-required="true"
                                       :is-char="true"
                                       :max-length="50"
                                       :message-required="$t('msg.CSBK100.147')"
                />
              </td>
              <td>
                <select
                  v-model="regParams.actShprCstCtrCd"
                  id="actShprCstCtrCd"
                  @change="fnChangeActCatId(regParams.actShprCstCtrCd);fnAddInfoStateSet('ACT')"
                  @input="checkErrorVisible('actShprCstCtrCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in ctrCdIccList" :key="'actCst_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input
                  :id="'actShprCstCityNm'"
                  v-model="regParams.actShprCstCityNm"
                  maxlength="100"
                  @input="checkErrorVisible('actShprCstCityNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input
                  v-show="regParams.actShprCstCtrCd !== 'IN'"
                  :id="'actShprStateNm'"
                  v-model="regParams.actShprStateNm"
                  maxlength="35"
                  @input="checkErrorVisible('actShprStateNm')"
                />
                <select
                  v-if="regParams.actShprCstCtrCd === 'IN'"
                  id="actShprStateCd"
                  v-model="regParams.actShprStateCd"
                  @input="checkErrorVisible('actShprStateCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in indiaCityList" :key="'inAct_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'actShprPostNo'"
                                                 v-model="regParams.actShprPostNo"
                                                 maxlength="6"
                                                 @input="checkErrorVisible('actShprPostNo')"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="2" class="text_left td_shpr">
                <e-trans-address-textarea :id="'shprCstAddr'"
                                          ref="shprCstAddr"
                                          :max-line="5"
                                          :column-length="50"
                                          v-model="regParams.shprCstAddr"
                                          :is-char="true"
                                          @input="checkShpperLOI"
                />
                <div v-if="isShipperLOIPlc && isShipperLOI" class="shp_msg">
                  <span v-if="$ekmtcCommon.isEmpty(saveShipperLOI)" class="shp_msg_txt">{{ $t('msg.CSBL200.222') }}</span>
                  <div class="d_inline_flex">
                    <a class="button sm mt5" @click="fnCommonBtnAction('TRD_SHIPPER')">{{ $t('msg.CSBL200.272') }}</a>
                    <a v-if="$ekmtcCommon.isNotEmpty(saveShipperLOI)" class="btn_doc mt5" @click.prevent="fnCommonBtnAction('TRD_SHIPPER_FILE')">Shipper LOI 첨부 확인</a>
                  </div>
                </div>
              </td>
              <th>{{ $t('msg.CMBK100_M1.023_1') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.KMTC118.003') }}</th> <!-- 전화 -->
              <th>{{ $t('msg.CSBL200.187') }}</th> <!-- Fax -->
              <th>{{ $t('msg.NEWB020G010.003') }}</th> <!-- Email -->
            </tr>
            <tr>
              <td class="border_left">
                <e-trans-string-check-cnee-input :id="'actShprCstPicNm'"
                                                 v-model="regParams.actShprCstPicNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('actShprCstPicNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'actShprCstTelNo'"
                                                 v-model="regParams.actShprCstTelNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('actShprCstTelNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'actShprCstFaxNo'"
                                                 v-model="regParams.actShprCstFaxNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('actShprCstFaxNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <input
                  v-model="regParams.actShprCstEmlAddr"
                  id="actShprCstEmlAddr"
                  type="text"
                  @input="checkErrorVisible('actShprCstEmlAddr')"
                  @blur="checkValidationEmail('actShprCstEmlAddr')"
                >
              </td>
            </tr>

            <tr>
              <th class="text_left">
                {{ $t('msg.NEWB020G010.044') }}
                <select
                  class="wid150 ml20 bg_white"
                  @change="changeLatestInfo($event, 'cne')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in cstEnmCodes['CNE']" :key="'cne_' + idx" :value="vo.cstNm">{{ vo.cstNm }}</option>
                </select>
                <span class="ml10 color_black font_light">
                  <input
                    type="checkbox"
                    id="order"
                    @click="changeCneCstEnmToOrder"
                  >
                  <label for="order"><span>To Order</span></label>
                </span>
              </th>
              <th>{{ $t('msg.CSBL200.188') }}</th> <!-- 지역 -->
              <th>{{ $t('msg.CSBL200.189') }}</th> <!-- City(시/군) -->
              <th>{{ $t('msg.CSBL200.190') }}</th> <!-- State/Province(도) -->
              <th>{{ $t('msg.CSBL200.191') }}</th> <!-- Postal Code(우편번호) -->
            </tr>
            <tr>
              <td>
                <e-trans-address-input :id="'cneCstEnm'"
                                       ref="cneCstEnm"
                                       v-model="regParams.cneCstEnm"
                                       :is-required="true"
                                       :is-char="true"
                                       :max-length="50"
                                       :message-required="$t('msg.CSBK100.147')"
                />
              </td>
              <td>
                <select
                  v-model="regParams.cneCstCtrCd"
                  id="cneCstCtrCd"
                  @change="fnChangeCneCatId(regParams.cneCstCtrCd);fnAddInfoStateSet('CNE')"
                  @input="checkErrorVisible('cneCstCtrCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in ctrCdList" :key="'cneCst_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input id="cneCstCityNm"
                                                 v-model="regParams.cneCstCityNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('cneCstCityNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstStateNm'"
                                                 v-show="regParams.cneCstCtrCd !== 'IN'"
                                                 v-model="regParams.cneCstStateNm"
                                                 maxlength="35"
                                                 @input="checkErrorVisible('cneCstStateNm')"
                />
                <select
                  v-show="regParams.cneCstCtrCd === 'IN'"
                  id="cneCstStateCd"
                  v-model="regParams.cneCstStateCd"
                  @change="changeStateCd('cneCstStateCd', 'cneCstStateNm')"
                  @input="checkErrorVisible('cneCstStateCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in indiaCityList" :key="'inCne_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstPostNo'"
                                                 v-model="regParams.cneCstPostNo"
                                                 maxlength="6"
                                                 @input="checkErrorVisible('cneCstPostNo')"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                <e-trans-address-textarea :id="'cneCstAddr'"
                                          ref="cneCstAddr"
                                          :max-line="5"
                                          :column-length="50"
                                          :is-char="true"
                                          v-model="regParams.cneCstAddr"
                />
              </td>
              <th>{{ $t('msg.CMBK100_M1.023_1') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.KMTC118.003') }}</th> <!-- 전화 -->
              <th>{{ $t('msg.CSBL200.187') }}</th> <!-- Fax -->
              <th>{{ $t('msg.NEWB020G010.003') }}</th> <!-- Email -->
            </tr>
            <tr>
              <td class="border_left">
                <e-trans-string-check-cnee-input :id="'cneCstPicNm'"
                                                 v-model="regParams.cneCstPicNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('cneCstPicNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstTelNo'"
                                                 v-model="regParams.cneCstTelNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('cneCstTelNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstFaxNo'"
                                                 v-model="regParams.cneCstFaxNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('cneCstFaxNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <input
                  v-model="regParams.cneCstEmlAddr"
                  id="cneCstEmlAddr"
                  class="col_9"
                  type="text"
                  maxlength="300"
                  @input="checkErrorVisible('cneCstEmlAddr')"
                  @blur="checkValidationEmail('cneCstEmlAddr')"
                >
                <span class="position_relative d_inline_block ml5">
                  <button class="tbl_icon help btn_tooltip">도움말</button>
                  <div class="tooltip_wrap short position_absolute text_left div_tooltip"><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <ul class="bul_list_sm t2">
                        <li>
                          {{ $t('msg.CSBL200.232') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>
              </td>
            </tr>

            <tr>
              <th class="text_left">
                {{ $t('msg.NEWB020G010.045') }}
                <select
                  class="wid150 ml20 bg_white"
                  @change="changeLatestInfo($event, 'ntif')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in cstEnmCodes['NTIF']" :key="'ntif_' + idx" :value="vo.cstNm">{{ vo.cstNm }}</option>
                </select>
                <span class="ml10 color_black font_light">
                  <input
                    type="checkbox"
                    id="consi"
                    @click="copyConsignee"
                  >
                  <label for="consi"><span>{{ $t('msg.NEWB020G010.018') }}</span></label>
                </span>
              </th>
              <th>{{ $t('msg.CSBL200.188') }}</th> <!-- 지역 -->
              <th>{{ $t('msg.CSBL200.189') }}</th> <!-- City(시/군) -->
              <th>{{ $t('msg.CSBL200.190') }}</th> <!-- State/Province(도) -->
              <th>{{ $t('msg.CSBL200.191') }}</th> <!-- Postal Code(우편번호) -->
            </tr>
            <tr>
              <td>
                <e-trans-address-input :id="'ntifCstEnm'"
                                       ref="ntifCstEnm"
                                       v-model="regParams.ntifCstEnm"
                                       :is-required="true"
                                       :is-char="true"
                                       :max-length="50"
                                       :message-required="$t('msg.CSBK100.147')"
                />
              </td>
              <td>
                <select
                  v-model="regParams.ntifCstCtrCd"
                  id="ntifCstCtrCd"
                  @change="fnChangeNotiCatId(regParams.ntifCstCtrCd);fnAddInfoStateSet('NTIF')"
                  @input="checkErrorVisible('ntifCstCtrCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in ctrCdList" :key="'ntifCst_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstCityNm'"
                                                 v-model="regParams.ntifCstCityNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('ntifCstCityNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstStateNm'"
                                                 v-show="regParams.ntifCstCtrCd !== 'IN'"
                                                 v-model="regParams.ntifCstStateNm"
                                                 maxlength="35"
                                                 @input="checkErrorVisible('ntifCstStateNm')"
                />
                <select
                  v-show="regParams.ntifCstCtrCd === 'IN'"
                  id="ntifCstStateCd"
                  v-model="regParams.ntifCstStateCd"
                  @change="changeStateCd('ntifCstStateCd', 'ntifCstStateNm')"
                  @input="checkErrorVisible('ntifCstStateCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in indiaCityList" :key="'inNtif_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstPostNo'"
                                                 v-model="regParams.ntifCstPostNo"
                                                 maxlength="6"
                                                 @input="checkErrorVisible('ntifCstPostNo')"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                <e-trans-address-textarea :id="'ntifCstAddr'"
                                          ref="ntifCstAddr"
                                          :max-line="5"
                                          :column-length="50"
                                          :is-char="true"
                                          v-model="regParams.ntifCstAddr"
                />
              </td>
              <th>{{ $t('msg.CMBK100_M1.023_1') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.KMTC118.003') }}</th> <!-- 전화 -->
              <th>{{ $t('msg.CSBL200.187') }}</th> <!-- Fax -->
              <th>{{ $t('msg.NEWB020G010.003') }}</th> <!-- Email -->
            </tr>
            <tr>
              <td class="border_left">
                <e-trans-string-check-cnee-input :id="'ntifCstPicNm'"
                                                 v-model="regParams.ntifCstPicNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('ntifCstPicNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstTelNo'"
                                                 v-model="regParams.ntifCstTelNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('ntifCstTelNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstFaxNo'"
                                                 v-model="regParams.ntifCstFaxNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('ntifCstFaxNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <input
                  v-model="regParams.ntifCstEmlAddr"
                  id="ntifCstEmlAddr"
                  class="col_9"
                  type="text"
                  @input="checkErrorVisible('ntifCstEmlAddr')"
                  @blur="checkValidationEmail('cneCstEmlAddr')"
                >
                <span class="position_relative d_inline_block ml5">
                  <button class="tbl_icon help btn_tooltip">도움말</button>
                  <div class="tooltip_wrap short position_absolute text_left div_tooltip"><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <ul class="bul_list_sm t2">
                        <li>
                          {{ $t('msg.CSBL200.232') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-if="isCstId"
          class="tbl_row mt10"
        >
          <colgroup>
            <col style="width:170px">
            <col>
            <col style="width:180px">
            <col>
            <col style="width:150px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.233') }}</th> <!-- Shipper Company ID -->
              <td>
                <select
                  v-model="regParams.actShprIdCatCd"
                  id="actShprIdCatCd"
                  class="col_4"
                  @change="fnChangeCatId($event, 'ACT')"
                >
                  <option value="">select</option>
                  <option v-for="(vo, idx) in actCstIdList" :key="'actShprCatCd_' + idx" :value="vo.cstIdCatCd">{{ vo.cstIdCatNm }}</option>
                </select>

                <e-trans-string-check-cnee-input :id="'actShprIdNo'"
                                                 class="inp_space60 ml3 float_right"
                                                 v-model="regParams.actShprIdNo"
                                                 maxlength="200"
                                                 @input="checkErrorVisible('actShprIdNo')"
                />
              </td>
              <th>{{ $t('msg.CSBL200.234') }}</th> <!-- Consignee Company ID -->
              <td>
                <select
                  v-model="regParams.cneIdCatCd"
                  id="cneIdCatCd"
                  class="col_4"
                  @change="fnChangeCatId($event, 'CNE')"
                >
                  <option value="">select</option>
                  <option v-for="(vo, idx) in cneCstIdList" :key="'cneCatCd_' + idx" :value="vo.cstIdCatCd">{{ vo.cstIdCatNm }}</option>
                </select>

                <e-trans-string-check-cnee-input :id="'cneIdNo'"
                                                 class="inp_space60 ml3 float_right"
                                                 v-model="regParams.cneIdNo"
                                                 maxlength="200"
                                                 @input="checkErrorVisible('cneIdNo')"
                />
              </td>
              <th>{{ $t('msg.CSBL200.235') }}</th> <!-- Notify Company ID -->
              <td>
                <select
                  v-model="regParams.ntifIdCatCd"
                  id="ntifIdCatCd"
                  class="col_4"
                  @change="fnChangeCatId($event, 'NTIF')"
                >
                  <option value="">select</option>
                  <option v-for="(vo, idx) in notiCstIdList" :key="'ntifCatCd_' + idx" :value="vo.cstIdCatCd">{{ vo.cstIdCatNm }}</option>
                </select>

                <e-trans-string-check-cnee-input :id="'ntifIdNo'"
                                                 class="inp_space60 ml3 float_right"
                                                 v-model="regParams.ntifIdNo"
                                                 maxlength="200"
                                                 @input="checkErrorVisible('ntifIdNo')"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-if="regParams.polCtrCd === 'ID'"
          class="tbl_row mt10"
        >
          <colgroup>
            <col width="170px;">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.236') }}</th> <!-- Exporter's Tax ID -->
              <td>
                <div class="col_4">
                  <!--                  <e-trans-string-check-cnee-input :id="'etTaxNo'"-->
                  <!--                                                   v-model="regParams.etTaxNo"-->
                  <!--                                                   maxlength="30"-->
                  <!--                  />-->
                  <e-input-number
                    v-model="regParams.etTaxNo"
                    id="etTaxNo"
                    :is-comma="false"
                    :is-phone="true"
                    maxlength="16"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-if="regParams.podCtrCd === 'ID'"
          class="tbl_row mt10"
        >
          <colgroup>
            <col width="170px;">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.237') }}</th> <!-- Importer's Tax ID -->
              <td>
                <div class="col_4">
                  <!--                  <e-trans-string-check-cnee-input :id="'idTaxNo'"-->
                  <!--                                                   v-model="regParams.idTaxNo"-->
                  <!--                                                   maxlength="30"-->
                  <!--                  />-->
                  <e-input-number
                    v-model="regParams.idTaxNo"
                    id="idTaxNo"
                    :is-comma="false"
                    :is-phone="true"
                    maxlength="16"
                    @blur="checkValidationInfo('idTaxNo')"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="regParams.podCtrCd === 'VN'"
          class="mt10"
        >
          <table class="tbl_row mt10">
            <colgroup>
              <col width="170px;">
              <col>
              <col width="170px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.CSBL200.237') }}</th> <!-- Importer's Tax ID -->
                <td colspan="3">
                  <div class="d_flex">
                    <e-trans-string-check-cnee-input :id="'idTaxNo'"
                                                     v-model="regParams.idTaxNo"
                                                     class="col_5"
                                                     maxlength="30"
                    />
                    <input
                      v-model="regParams.wstCgoYn"
                      id="wstCgoYn"
                      type="checkbox"
                      true-value="Y"
                      false-value="N"
                      @click="changeWstCgoYn"
                    >
                    <label for="wstCgoYn">{{ $t('msg.CSBL200.238') }}</label> <!-- WASTE/SCRAP Cargo -->
                  </div>
                </td>
              </tr>
              <tr v-if="regParams.wstCgoYn === 'Y'">
                <th>{{ $t('msg.CSBL200.239') }}</th> <!-- License No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'imLicNo'"
                                                   v-model="regParams.imLicNo"
                                                   maxlength="20"
                  />
                </td>
                <th>{{ $t('msg.CSBL200.240') }}</th> <!-- Bank Guarantee No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'imBnkGurNo'"
                                                   v-model="regParams.imBnkGurNo"
                                                   maxlength="20"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="regParams.polCtrCd === 'IN' || regParams.podCtrCd === 'IN'"
          class="mt10"
        >
          <h2 class="content_title">{{ $t('msg.CSBL200.241') }}</h2> <!-- INDIA Info -->

          <table class="tbl_row mt10">
            <colgroup>
              <col width="170px">
              <col>
              <col width="210px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ regParams.polCtrCd === 'IN' ? $t('msg.CSBL200.192') : $t('msg.CSBL200.193') }}</th>
                <td>
                  <e-trans-string-check-cnee-input :id="'iecNo'"
                                                   v-model="regParams.iecNo"
                                                   maxlength="10"
                                                   @input="setIndiaInfo()"
                  />
                </td>
                <th>{{ $t('msg.CSBL200.242') }} <span class="txt">({{ $t('msg.CSBK100.062') }})</span></th> <!-- FWDR/NVOCC PAN No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'panNo'"
                                                   v-model="regParams.panNo"
                                                   maxlength="10"
                  />
                </td>
              </tr>
              <tr v-if="regParams.polCtrCd === 'IN'">
                <th>{{ $t('msg.ONEX050P190.015') }}</th> <!-- Invoice Value -->
                <td colspan="3">
                  <select
                    v-model="regParams.invValCurCd"
                    class="col_5"
                    id="invValCurCd"
                    @change="checkErrorVisible('invValCurCd')"
                  >
                    <option value="">select</option>
                    <option v-for="(vo, idx) in commonCodes['00003']" :key="'invValCurCd_' + idx" :value="vo.cd">{{ vo.cd }}</option>
                  </select>
                  <e-input-number
                    v-model="regParams.invValExp"
                    class="inp_space50_2 ml3"
                    id="invValExp"
                    :is-comma="true"
                    :point="3"
                    maxlength="18"
                    @input="checkErrorVisible('invValExp')"
                  >
                  </e-input-number>
                </td>
              </tr>
              <tr v-if="regParams.podCtrCd === 'IN'">
                <th>{{ $t('msg.CSBL200.243') }}</th> <!-- Notify's PAN No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'ntifPanNo'"
                                                   v-model="regParams.ntifPanNo"
                                                   maxlength="10"
                                                   @input="setIndiaInfo()"
                  />
                </td>
                <th>{{ $t('msg.ONEX050P190.015') }}</th> <!-- Invoice Value -->
                <td>
                  <select
                    v-model="regParams.invValCurCd"
                    id="invValCurCd"
                    class="col_5"
                    @change="checkErrorVisible('invValCurCd')"
                  >
                    <option value="">select</option>
                    <option v-for="(vo, idx) in commonCodes['00003']" :key="'invValCurCd_' + idx" :value="vo.cd">{{ vo.cd }}</option>
                  </select>
                  <e-input-number
                    v-model="regParams.invValExp"
                    id="invValExp"
                    class="inp_space50_2 ml3"
                    :is-comma="true"
                    :point="3"
                    maxlength="18"
                    @input="checkErrorVisible('invValExp')"
                  >
                  </e-input-number>
                </td>
              </tr>
              <tr v-if="regParams.podCtrCd === 'IN' && regParams.podPortCd === 'NSA'">
                <th>{{ $t('msg.CSBL200.244') }}</th> <!-- GST No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'gstNo'"
                                                   v-model="regParams.gstNo"
                                                   maxlength="20"
                                                   @input="setIndiaInfo()"
                  />
                </td>
                <th>{{ $t('msg.MAIN100.202') }}</th> <!-- E-Mail -->
                <td>
                  <input
                    v-model="regParams.iecPicEmlAddr"
                    id="iecPicEmlAddr"
                    type="text"
                    maxlength="100"
                    @input="checkErrorVisible('iecPicEmlAddr')"
                    @blur="checkValidationEmail('iecPicEmlAddr')"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="regParams.podCtrCd === 'EG'"
          class="mt10"
        >
          <table class="tbl_row mt10">
            <colgroup>
              <col width="170px;">
              <col>
              <col width="170px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>ACID No</th> <!-- Importer's Tax ID -->
                <td colspan="2">
                  <e-trans-string-check-cnee-input :id="'acidNo'"
                                                   v-model="regParams.acidNo"
                                                   maxlength="19"
                                                   :is-upper-case="true"
                  />
                </td>
                <th>{{ $t('msg.CSBL200.237') }}</th> <!-- Importer's Tax ID -->
                <td colspan="2">
                  <e-trans-string-check-cnee-input :id="'cneAcidNo'"
                                                   v-model="regParams.cneAcidNo"
                                                   maxlength="9"
                                                   :is-upper-case="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="regParams.podCtrCd === 'EG'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.040') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.041') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.042') }}</p>
        </div>

        <div v-if="regParams.jpPodCount > 0">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.001') }}</p>
        </div>

        <div v-if="regParams.cnPodfCount > 0 && regParams.shaPodTsCount === 0">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.002') }}</p>
        </div>

        <div v-if="(regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') && regParams.cnPodCount < 1">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.003') }}</p>
        </div>

        <div v-if="regParams.polCtrCd === 'ID'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.004') }}</p>
        </div>

        <div v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'VN'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.027') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.028') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.029') }}<br />{{ $t('msg.CSBL200_M.030') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.031') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.032') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.033') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.034') }}</p>
          <br />
        </div>
        <div v-else-if="regParams.podCtrCd === 'VN'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.005') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.006') }}</p>
        </div>

        <div v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'ID'">
          <p class="txt_desc_normal">{{ $t('msg.CSBL200_M.027') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.007') }}</p>
        </div>
        <div v-else-if="regParams.podCtrCd === 'ID'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.007') }}</p>
        </div>

        <div v-else-if="regParams.podPortCd === 'DMM'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.039') }}</p>
        </div>

        <div v-if="regParams.polCtrCd === 'IN'">
          <p class="txt_desc">
            {{ $t('msg.CSBL200_M.008') }}<br>
            {{ $t('msg.CSBL200_M.009') }}<br>
            {{ $t('msg.CSBL200_M.010') }}
          </p>
        </div>

        <div v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'IN'">
          <p class="txt_desc_normal">
            {{ `&lt;${$t('msg.CSBL200_M.017')}&gt;` }}<br />
            {{ $t('msg.CSBL200_M.018') }}<br>
            {{ $t('msg.CSBL200_M.019') }}<br>
            {{ $t('msg.CSBL200_M.020') }}<br>
            {{ $t('msg.CSBL200_M.021') }}<br>
            {{ $t('msg.CSBL200_M.022') }}<br>
            {{ $t('msg.CSBL200_M.023') }}<br>
            {{ $t('msg.CSBL200_M.024') }}<br>
          </p>
        </div>
        <div v-else-if="regParams.podCtrCd === 'IN'">
          <p class="txt_desc">
            {{ $t('msg.CSBL200_M.011') }}<br>
            {{ $t('msg.CSBL200_M.012') }}<br>
            {{ $t('msg.CSBL200_M.013') }}<br>
            {{ $t('msg.CSBL200_M.014') }}<br>
            {{ $t('msg.CSBL200_M.015') }}
          </p>
        </div>

        <template>
          <template v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'ID'">
            <!--
            <p class="txt_desc">{{ $t('msg.CSBL200_M.026') }}</p>
            <p class="txt_desc">{{ $t('msg.CSBL200_M.024') }}</p>-->
          </template>
          <template v-else>
            <p class="txt_desc">{{ $t('msg.CSBL200_M.016') }}</p>
          </template>
        </template>
        <!-- 출발지가 일본이고 도착지가 파키스탄일 경우 -->
        <template v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'PK'">
          <p class="txt_desc_normal" style="margin-top: 5px;">{{ `&lt;${$t('msg.CSBL200_M.035')}&gt;` }}</p>
          <p class="txt_desc_normal">{{ `1. ${$t('msg.CSBL200_M.036')}` }}</p>
          <p class="txt_desc_normal">{{ `2. ${$t('msg.CSBL200_M.037')}` }}</p>
        </template>

        <h2 class="content_title">{{ $t('msg.CSBL200.245') }}</h2> <!-- Cargo Information -->

        <table class="tbl_row mt10">
          <colgroup>
            <col style="width:170px">
            <col>
            <col style="width:180px">
            <col>
            <col style="width:150px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.246') }}</th> <!-- 품명(세관신고 품목) -->
              <td colspan="5">
                <e-trans-string-check-cnee-input
                  :id="'cgoCont'"
                  v-model="regParams.cgoCont"
                  maxlength="200"
                  :is-upper-case="true"
                  @blur="fnByTextMsg()"
                />
              </td>
            </tr>
            <tr>
              <th>Cargo Weight</th> <!-- Cargo Weight -->
              <td>
                <e-input-number
                  v-model="regParams.grsWt"
                  :is-comma="true"
                  :point="3"
                  id="grsWt"
                  class="col_6"
                  maxlength="14"
                  @blur="fnSumValue('grsWt')"
                  @input="checkValidationInfo('grsWt')"
                >
                </e-input-number> Kgs
              </td>
              <th>{{ $t('art.CMATK100') }}</th> <!-- Package -->
              <td>
                <e-input-number
                  v-model="regParams.pkgQty"
                  :is-comma="true"
                  id="pkgQty"
                  class="col_3"
                  maxlength="10"
                  @blur="fnSumValue('pkgQty');fnPackage('CHANGE')"
                  @input="checkValidationInfo('pkgQty')"
                >
                </e-input-number>

                <select
                  v-model="regParams.pkgCd"
                  id="pkgCd"
                  class="inp_space70"
                  @change="checkValidationInfo('pkgCd');fnPackage('CHANGE')"
                >
                  <option value="">{{ $t('msg.CSBL220.003') }}</option>
                  <option v-for="(vo, idx) in pkgCdList" :key="'pkgCd_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <th>{{ $t('art.CMATK098') }}</th> <!-- Measure -->
              <td>
                <e-input-number
                  class="dv col_5"
                  v-model="regParams.grsCbm"
                  id="grsCbm"
                  :is-comma="true"
                  :point="4"
                  maxlength="13"
                  @input="checkErrorVisible('grsCbm')"
                >
                </e-input-number>
                CBM
              </td>
            </tr>
            <tr>
              <th>POL HS Code</th>
              <td>
                <div class="tbl_form">
                  <span :class="isPolHsMulti ? 'dv col_8' : 'dv'">
                    <e-trans-string-check-input :id="'hsCd'"
                                                v-model="regParams.hsCd"
                                                :is-upper-case="true"
                                                :maxlength="hsChkParams.maxlengthPolHsCd"
                                                :error-message="hsChkParams.hscdPolErrorMessage"
                                                @input="checkHsCd($event, 'POL')"
                    />
                  </span>
                  <span
                    v-if="isPolHsMulti"
                    class="dv"
                  >
                    <button
                      class="tbl_icon plus"
                      type="button"
                      @click="hsCdMultiPop('01')"
                    >
                      plus
                    </button>
                  </span>
                </div>
              </td>
              <th>POD HS Code</th>
              <td>
                <div class="tbl_form">
                  <span :class="isPodHsMulti ? 'dv col_8' : 'dv'">
                    <e-trans-string-check-input :id="'podHsCd'"
                                                v-model="regParams.podHsCd"
                                                :is-upper-case="true"
                                                :maxlength="hsChkParams.maxlengthPodHsCd"
                                                :error-message="hsChkParams.hscdPodErrorMessage"
                                                @input="checkHsCd($event, 'POD')"
                    />
                  </span>
                  <span
                    v-if="isPodHsMulti"
                    class="dv"
                  >
                    <button
                      class="tbl_icon plus"
                      type="button"
                      @click="hsCdMultiPop('02')"
                    >
                      plus
                    </button>
                  </span>
                  <span
                    v-if="!isPodHsMulti"
                    class="dv"
                  >
                    <div class="position_relative">
                      <input type="text"
                             v-model="regParams.podHsCdNm"
                             :readonly="true"
                      />
                    </div>
                  </span>
                </div>
              </td>
              <td colspan="2" class="border_left_none"></td>
            </tr>
          </tbody>
        </table>

        <div class="desc_list" v-if="lang === 'KOR'">
          <ul>
            <li>{{ $t('msg.CSBL200.247') }}</li>
          </ul>
          <ul v-if="regParams.podCtrCd === 'MY'">
            <li style="color:red;">말레이시아향은 품명(세관신고 품목)란과 디스크립션의 품명이 수입신고 품명으로 일치해야 합니다.</li>
          </ul>
        </div>

        <h2 class="content_title mt20">{{ $t('msg.CSBL200.248') }}</h2> <!-- E/L Information -->
        <table class="tbl_row mt10">
          <colgroup>
            <col style="width:170px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.249') }}</th> <!-- E/L Type -->
              <td colspan="5">
                <select
                  v-model="regParams.blEdiTypCd"
                  class="wid200"
                  id="blEdiTypCd"
                  @change="changeBlEdiTypCd"
                >
                  <option v-for="(vo, idx) in blEdiTypCdList" :key="'blEdiTypCd_' + idx" :value="vo.cd">{{ vo.dsc }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_col mt10 table_elInfo" v-if="isExportLicense()">
          <colgroup>
            <col style="width:5%">
            <col style="width:12%">
            <col style="width:18%">
            <col style="width:10%">
            <col style="width:18%">
            <col style="width:18%">
            <col style="width:14%">
            <col style="width:5%">
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>{{ $t('msg.CSBL220.002') }}</th> <!-- 수출신고필증 -->
              <th>{{ $t('art.CMATK100') }}</th> <!-- Package -->
              <th>{{ $t('msg.ARAI100_M1.005') }}</th> <!-- 총중량(Kg) -->
              <th>{{ $t('msg.ARAI100_M1.006') }}</th> <!-- 분할유무 -->
              <th>{{ $t('msg.CSBL200.082') }}</th> <!-- 동시포장 -->
              <th>{{ $t('msg.CSBL200.083') }}</th> <!-- 신고일 -->
              <th>
                <button
                  v-show="!closeEl"
                  type="button"
                  class="tbl_icon plus"
                  @click="addExportLicense"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(vo, idx) in regParams.exportLicenseList" :key="'expLicense_' + idx">
            <tr>
              <td>
                {{ idx + 1 }}
              </td>
              <td>
                <input
                  v-model="vo.elNo"
                  :id="'elNo_' + idx"
                  type="text"
                  @input="checkValidationExportLicense('elNo', idx)"
                  @blur="checkElNo(idx)"
                >
              </td>
              <td>
                <e-input-number
                  v-model="vo.pkgQty"
                  :is-comma="true"
                  class="inp_space30"
                  :id="'lcsPkgQty_' + idx"
                  maxlength="10"
                  @input="checkValidationExportLicense('lcsPkgQty', idx)"
                >
                </e-input-number>

                <select
                  v-model="vo.pkgCd"
                  class="inp_responsive_space7 ml3"
                  :id="'lcsPkgCd_' + idx"
                  @input="checkValidationExportLicense('lcsPkgCd', idx)"
                >
                  <option value="">{{ $t('msg.CSBL220.003') }}</option>
                  <option v-for="(cvo, i) in pkgCdList" :key="'lcsPkg_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-input-number
                  v-model="vo.grsWt"
                  :is-comma="true"
                  :id="'lcsGrsWt_' + idx"
                  :point="3"
                  maxlength="13"
                  @input="checkValidationExportLicense('lcsGrsWt', idx)"
                >
                </e-input-number>
              </td>
              <td>
                <select
                  v-model="vo.prtlLdgYn"
                  :id="'prtlLdgYn_' + idx"
                  class="inp_space50"
                >
                  <option value="N">No</option>
                  <option value="Y">Yes</option>
                </select>
                <select
                  v-model="vo.ldgTcnt"
                  :id="'ldgTcnt_' + idx"
                  class="inp_space50 ml3"
                  @input="checkValidationExportLicense('ldgTcnt', idx)"
                >
                  <option value="">{{ $t('msg.CSBL220.004') }}</option> <!-- 차수 -->
                  <option v-for="(cvo, i) in 30" :key="'ldgTcnt_' + i" :value="cvo">{{ cvo }}</option>
                </select>
              </td>
              <td>
                <select
                  v-model="vo.pkgtgCd"
                  :id="'pkgtgCd_' + idx"
                  class="inp_space50"
                >
                  <option value="">Code</option>
                  <option v-for="(cvo, i) in 9" :key="'pkgtgCd_' + i" :value="String.fromCharCode(cvo + 64)">{{ String.fromCharCode(cvo + 64) }}</option>
                </select>

                <e-input-number
                  v-model="vo.pkgtgPkgQty"
                  :is-comma="true"
                  :id="'pkgtgPkgQty_' + idx"
                  maxlength="3"
                  class="inp_space50 ml3"
                  @input="checkValidationExportLicense('pkgtgPkgQty', idx)"
                >
                </e-input-number>
              </td>
              <td>
                <div class="input_calendar mid">
                  <e-date-picker
                    v-model="vo.elNoValdDt"
                    :id="'elNoValdDt_' + idx"
                    :disabled="closeEl"
                    @input="checkValidationExportLicense('elNoValdDt', idx)"
                  >
                  </e-date-picker>
                </div>
              </td>
              <td>
                <button
                  v-show="!closeEl"
                  class="tbl_icon minus"
                  @click="removeExportLicense(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_col mt10" v-if="isIndonesia()">
          <!--
          <colgroup>
            <col style="width:15%">
            <col style="width:15%">
            <col style="width:15%">
            <col style="width:55%">
          </colgroup>
          <thead>
            <tr>
              <th>License No.</th>
              <th>License Date</th>
              <th>Customs Office</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in regParams.exportLicenseList" :key="'expLicense_' + idx" :id="'expLicense_' + idx">
              <td>
                {{ vo.elNo }}
              </td>
              <td>
                <e-date-picker
                  v-model="vo.elNoValdDt"
                  :id="'elNoValdDt_' + idx"
                  disabled
                  @input="checkErrorVisible('elNoValdDt_' + idx)"
                >
                </e-date-picker>
              </td>
              <td>
                {{ vo.idCstmCd }}
              </td>
              <td>Compulsory Item only for Indonesian Export B/L</td>
            </tr>
          </tbody>-->
          <colgroup>
            <col style="width:5%">
            <col style="width:19%">
            <col style="width:13%">
            <col style="width:20%">
            <col style="width:15%">
            <col style="width:21%">
            <col style="width:5%">
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>License No.</th>
              <th>License Date</th>
              <th>Customs Office</th>
              <th>Empty Check</th>
              <th>
                <input v-model="regParams.consCgoYn" id="tickYn" type="checkbox" true-value="Y" false-value="N">
                <label for="tickYn">{{ $t('msg.CSBL200.284') }}</label> <!-- Tick consolidate cargo -->
              </th>
              <th>
                <button
                  v-show="!closeEl"
                  type="button"
                  class="tbl_icon plus"
                  @click="addExportLicense"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in regParams.exportLicenseList" :key="'expLicense_' + idx" :id="'expLicense_' + idx">
              <td>{{ idx + 1 }}</td>
              <td>
                <input
                  v-model="vo.elNo"
                  :id="'elNo_' + idx"
                  type="text"
                  maxlength="20"
                  @input="checkValidationExportLicense('elNo', idx)"
                  @blur="checkElNoIdn(idx)"
                >
              </td>
              <td>
                <e-date-picker
                  v-model="vo.elNoValdDt"
                  :id="'elNoValdDt_' + idx"
                  :disabled="elNoValdDisbled"
                  @input="checkErrorVisible('elNoValdDt_' + idx)"
                >
                </e-date-picker>
              </td>
              <td>
                <input
                  v-model="vo.idCstmCd"
                  :id="'idCstmCd_' + idx"
                  type="text"
                  maxlength="6"
                  @input="checkErrorVisible('elNoValdDt_' + idx)"
                >
              </td>
              <td>
                <input
                  v-model="vo.emptyYn"
                  :id="'emptyCheck_' + idx"
                  type="checkbox"
                  true-value="Y"
                  false-value="N"
                  @click="checkIdEmptyCheck($event, idx)"
                >
                <label :for="'emptyCheck_' + idx"></label>
              </td>
              <td>Compulsory Item only for Indonesian Export B/L</td>
              <td>
                <button
                  v-show="!closeEl"
                  class="tbl_icon minus"
                  @click="removeExportLicense(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="flex_box mt20">
          <h2 class="content_title">Container</h2>
          <span class="ml_auto cal_btn">
            <a
              class="button sm"
              @click="fnCntrPkgAutoSet()"
            >
              {{ $t('msg.CSBL200.250') }} <!-- 중량/개수 자동 나누기 -->
            </a>
            <a
              v-if="excelUploadShow"
              class="button sm ml3"
              @click="excelUploadPop()"
            >Excel Upload</a>
            <a
              class="button sm ml3"
              @click="fnCgoPop()"
            >
              {{ $t('btn.CMBTK045') }} <!-- 특수화물 정보조회 -->
            </a>
          </span>
        </div>

        <table class="tbl_col">
          <colgroup v-if="lang !== 'JPN' && regParams.polCtrCd !== 'PH' && regParams.polCtrCd !== 'VN' && regParams.podCtrCd !== 'BH'">
            <col style="width:3%">
            <col style="width:9.5%">
            <col style="width:11.5%">
            <col style="width:6%">
            <col style="width:12.5%">
            <col style="width:8%">
            <col style="width:11.5%">
            <col style="width:10%">
            <col style="width:8%">
            <col style="width:8%">
            <col style="width:5%">
            <col style="width:10%">
            <col style="width:4%">
          </colgroup>
          <colgroup v-else-if="regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN' || regParams.podCtrCd !== 'BH'">
            <col style="width:3%">
            <col style="width:9%">
            <col style="width:11%">
            <col style="width:6%">
            <col style="width:12%">
            <col style="width:8%">
            <col style="width:11%">
            <col style="width:10%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:4%">
            <col style="width:5%">
            <col style="width:10%">
            <col style="width:4%">
          </colgroup>
          <colgroup v-else-if="lang === 'JPN'">
            <col style="width:2%">
            <col style="width:9%">
            <col style="width:9%">
            <col style="width:7%">
            <col style="width:12%">
            <col style="width:8%">
            <col style="width:9%">
            <col style="width:8%">
            <col style="width:6%">
            <col style="width:12%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:10%">
            <col style="width:3%">
          </colgroup>
          <thead v-if="lang !== 'JPN' && regParams.polCtrCd !== 'PH' && regParams.polCtrCd !== 'VN' && regParams.podCtrCd !== 'BH'">
            <tr>
              <th>No.</th>
              <th>{{ $t('msg.CSBL200.197') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.CSBL200.251') }}</th> <!-- Seal No. -->
              <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBL200_M1.024') }}</th> <!-- 특수화물 -->
              <th>{{ $t('art.CMATK110') }}</th> <!-- 특수화물 -->
              <th>{{ $t('msg.ONEX050P060.005') }}</th> <!-- Weight -->
              <th>{{ $t('art.CMATK100') }}</th> <!-- Package -->
              <th>{{ $t('msg.CSBL200.252') }}</th> <!-- 화주컨테이너 -->
              <th>Empty</th>
              <th>Deck</th>
              <th>
                <button
                  v-show="!closeCntr"
                  type="button"
                  class="tbl_icon plus"
                  @click="addBLContainer"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <thead v-else-if="regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN' || regParams.podCtrCd === 'BH'">
            <tr>
              <th>No.</th>
              <th>{{ $t('msg.CSBL200.197') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.CSBL200.251') }}</th> <!-- Seal No. -->
              <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBL200_M1.024') }}</th> <!-- 특수화물 -->
              <th>{{ $t('art.CMATK110') }}</th> <!-- 특수화물 -->
              <th>{{ $t('msg.ONEX050P060.005') }}</th> <!-- Weight -->
              <th>{{ $t('art.CMATK100') }}</th> <!-- Package -->
              <th>CBM</th> <!-- CBM -->
              <th>{{ $t('msg.CSBL200.252') }}</th> <!-- 화주컨테이너 -->
              <th>Empty</th>
              <th>Deck</th>
              <th>
                <button
                  v-show="!closeCntr"
                  type="button"
                  class="tbl_icon plus"
                  @click="addBLContainer"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <thead v-else-if="lang === 'JPN'">
            <tr>
              <th>No.</th>
              <th>{{ $t('msg.CSBL200.197') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.CSBL200.251') }}</th> <!-- Seal No. -->
              <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBL200_M1.024') }}</th> <!-- 특수화물 -->
              <th>Cargo Wgt</th>
              <th>Tare Wgt</th>
              <th>Pkg</th>
              <th>P'kg Type</th>
              <th>Measure</th>
              <th>{{ $t('msg.CSBL200.252') }}</th> <!-- 화주컨테이너 -->
              <th>Deck</th>
              <th>
                <button
                  class="tbl_icon plus"
                  @click="addBLContainer"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(vo, idx) in regParams.containerList" :key="'cntr_' + idx">
            <tr>
              <td>{{ idx + 1 }}</td>
              <td>
                <input
                  v-model="vo.cntrNo"
                  :id="'cntrNo_' + idx"
                  type="text"
                  maxlength="11"
                  @input="changeOnlyUpperEng($event, vo, 'cntrNo', idx)"
                  @blur="checkCntrNo(idx)"
                >
              </td>
              <td>
                <div
                  class="tbl_form div_seal"
                  v-for="(cvo, i) in vo.sealList" :key="'seal_' + i"
                >
                  <div>
                    <span class="dv col_8">
                      <input
                        v-model="cvo.sealNo"
                        :id="'sealNo_' + i"
                        :name="'sealNo_' + idx + '_' + i"
                        type="text"
                        @input="changeOnlyUpperEng($event, cvo, 'sealNo_' + idx, i)"
                      >
                    </span>
                    <span class="dv">
                      <button
                        v-if="i === 0"
                        class="tbl_icon plus"
                        @click="addSealNo(idx)"
                      >
                        plus
                      </button>
                      <button
                        v-if="i !== 0"
                        class="tbl_icon minus"
                        @click="removeSealNo(idx, i)"
                      >
                        minus
                      </button>
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <select
                  v-model="vo.cntrSzCd"
                  :id="'cntrSzCd_' + idx"
                  @click="fnNoChange($event, idx)"
                >
                  <option value=""></option>
                  <option v-for="(cvo, i) in commonCodes['01031']" :key="'cntrSzCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <select
                  v-model="vo.cntrTypCd"
                  :id="'cntrTypCd_' + idx"
                  @change="changeCntrType(vo, idx)"
                  :class="(vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH') ? 'col_85' : ''"
                  @click="fnNoChange($event, idx)"
                >
                  <option value=""></option>
                  <option v-for="(cvo, i) in commonCodes['01068']" :key="'cntrTypCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
                <a
                  v-if="(vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH') && !rfDisabled"
                  class="btn_rf_entry float_right"
                  @click="cntrTypeInfoPop(vo.cntrTypCd, idx)"
                >
                  {{ $t('msg.CSBL200.258') }}
                </a>
              </td>
              <td>
                <select
                  v-model="vo.cgoTypCd"
                  @change="cgoTypeInfoPop(vo.cgoTypCd, idx)"
                  :id="'cgoTypCd_' + idx"
                >
                  <option value="">----------------</option>
                  <option v-for="(cvo, i) in cgoTypCdList" :key="'cgoTypCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td v-show="lang !== 'JPN'">
                <select
                  :id="'lcsPolShtmCd_' + idx"
                  class="inp_space50_2"
                  v-model="vo.polShtmCd"
                  @change="fnChangeCntrTerm('polShtmCd')"
                  disabled
                >
                  <option v-for="(cvo, i) in vo.polShtmCdList" :key="'lcsPolShtmCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
                <select
                  :id="'lcsPodShtmCd_' + idx"
                  class="inp_space50_2"
                  v-model="vo.podShtmCd"
                  @change="fnChangeCntrTerm('podShtmCd')"
                  disabled
                >
                  <option v-for="(cvo, i) in commonCodes['01033']" :key="'lcsPodShtmCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-input-number
                  v-model="vo.wt"
                  :id="'wt_' + idx"
                  :point="3"
                  class="col_7"
                  :is-comma="true"
                  maxlength="14"
                  @input="checkValidationInfo('wt_' + idx)"
                >
                </e-input-number> Kgs
              </td>
              <td v-if="lang === 'JPN'">
                <div class="tbl_form">
                  <span class="dv col_7">
                    <e-input-number
                      v-model="vo.tareWt"
                      :id="'tareWt_' + idx"
                      :is-comma="true"
                      :point="3"
                      maxlength="14"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv">
                    Kgs
                  </span>
                </div>
              </td>
              <td>
                <e-input-number
                  v-model="vo.pkgQty"
                  :id="'cntrPkgQty_' + idx"
                  :is-comma="true"
                  maxlength="10"
                >
                </e-input-number>
              </td>
              <td v-if="lang === 'JPN'">
                <select
                  :id="'lcsPkgCd_' + idx"
                  v-model="vo.pkgCd"
                >
                  <option value="">{{ $t('msg.CSBL220.003') }}</option>
                  <option v-for="(cvo, i) in pkgCdList" :key="'cntrPkgCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td v-if="lang === 'JPN' || regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN' || regParams.podCtrCd === 'BH'">
                <e-input-number
                  v-model="vo.meaCbm"
                  :id="'meaCbm_' + idx"
                  :is-comma="true"
                  :point="4"
                  maxlength="8"
                >
                </e-input-number>
              </td>
              <td>
                <input
                  v-model="vo.shprVanYn"
                  @change="changeShprVanYn(vo, idx)"
                  type="checkbox"
                  :id="'shprVanYn_' + idx"
                  true-value="Y"
                  false-value="N"
                >
                <label :for="'shprVanYn_' + idx"></label>
              </td>
              <td v-if="lang !== 'JPN'">
                <input
                  v-model="vo.feUnitCd"
                  type="checkbox"
                  :id="'feUnitCd_' + idx"
                  true-value="E"
                  false-value="F"
                  :disabled="vo.shprVanYn === 'N'"
                >
                <label :for="'feUnitCd_' + idx"></label>
              </td>
              <td>
                <select class="deck_select"
                        v-model="vo.deckCd"
                        :id="'deckCd_' + idx"
                        disabled
                >
                  <option value=""></option>
                  <option v-for="(cvo, i) in deckCdList" :key="'deckCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <button
                  v-show="!closeCntr"
                  class="tbl_icon minus"
                  @click="removeBLContainer(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt10 flex_box">
          <a v-if="lang === 'JPN'" class="button blue sm ml_auto" @click="fnCommonBtnAction('JPCLP')">CLP Print</a>
          <a v-if="showSHAMFButton()" class="button blue sm  ml_auto" @click="fnCommonBtnAction('SHAMF')">SHA M/F Data</a>
        </div>

        <h2 class="content_title">B/L Body Detail</h2>
        <table class="tbl_row mt10">
          <colgroup>
            <col width="170px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>Say</th>
              <td style="display: flex;">
                <e-trans-string-check-cnee-input
                  :id="'blPrtSntcCont'"
                  v-model="regParams.blPrtSntcCont"
                  maxlength="100"
                  :is-upper-case="true"
                  class="col_8"
                  @blur="fnSayTab"
                />
                <a
                  class="button sm ml2"
                  @click="fnSaySet()"
                >Auto Say</a>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_col mt10 tbl_mark">
          <colgroup>
            <col width="41%">
            <col width="56%">
            <col width="3%">
          </colgroup>
          <thead>
            <tr>
              <th class="text_left">
                Mark & No
                <select
                  v-model="addrStr"
                  class="wid150 ml10"
                  @change="searchAgentAddr"
                >
                  <option value="">Agent address</option>
                  <option v-if="regParams.porPlcCd === regParams.polPortCd" :value="regParams.porCtrCd + ':' + regParams.porPlcCd">{{ regParams.porPlcNm }}</option>
                  <option v-if="regParams.porPlcCd !== regParams.polPortCd" :value="regParams.porCtrCd + ':' + regParams.porPlcCd">{{ regParams.porPlcNm }}</option>
                  <option v-if="regParams.porPlcCd !== regParams.polPortCd" :value="regParams.polCtrCd + ':' + regParams.polPortCd">{{ regParams.polPortNm }}</option>
                  <option v-if="regParams.podPortCd === regParams.dlyPlcCd" :value="regParams.podCtrCd + ':' +regParams.podPortCd">{{ regParams.podPortNm }}</option>
                  <option v-if="regParams.podPortCd !== regParams.dlyPlcCd" :value="regParams.podCtrCd + ':' +regParams.podPortCd">{{ regParams.podPortNm }}</option>
                  <option v-if="regParams.podPortCd !== regParams.dlyPlcCd" :value="regParams.dlyCtrCd + ':' +regParams.dlyPlcCd">{{ regParams.dlyPlcNm }}</option>
                </select>
                <button
                  class="tbl_icon search ml2"
                  @click="searchAgentAddr"
                >
                  {{ $t('msg.NEWB010P020.006') }}
                </button>
                <select
                  class="wid200 ml20 bg_white float_right"
                  @change.prevent="changeTopMarkDscr($event, 'mrk')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in topMarkList" :key="'topMark_' + idx" :value="vo.mrk">{{ vo.mrkNm }}</option>
                </select>
              </th>
              <th class="text_left">
                Description
                <button
                  class="button sm ml2"
                  @click="addRequSentenceDescForVN"
                  v-show="regParams.porCtrCd === 'VN' && regParams.podPortCd === 'DMM'"
                >
                  REQUIRED SENTENCE
                </button>
                <button
                  class="button sm ml2"
                  @click="addTempClauseDescForVN"
                  v-show="regParams.porCtrCd === 'VN' && regParams.podPortCd === 'DMM'"
                >
                  Temp Clause
                </button>
                <select
                  class="wid200 ml20 bg_white float_right"
                  @change.prevent="changeTopMarkDscr($event, 'dscr')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in topDscrList" :key="'topDscr_' + idx" :value="vo.dscr">{{ vo.mrkNm }}</option>
                </select>
              </th>
              <th>
                <button
                  class="tbl_icon plus"
                  @click="addMarkDesc"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(vo, idx) in regParams.markDescList" :key="'markDesc_' + idx">
            <tr>
              <td class="td_mrk" :style="idx > 0 ? '' : regParams.polShtmCd === '02' ? 'padding-top: 32px' : 'padding-top: 18px'">
                <ETextarea2 :id="'mrk_' + idx"
                            :max-line="18"
                            :column-length="25"
                            v-model="vo.mrk"
                            :is-upper="true"
                            :padding-right="223"
                />
              </td>
              <td class="td_desc">
                <textarea
                  v-if="idx === 0"
                  class="txt_desc1"
                  v-model="vo.dscrSub"
                  id="dscrSub"
                  readonly
                  rows="3"
                >
                </textarea>
                <ETextarea2 :id="'dscr_' + idx"
                            :max-line="idx > 0 ? 18 : regParams.polShtmCd === '02' ? 17 : 16"
                            :column-length="45"
                            class="txt_dscr"
                            :is-upper="true"
                            v-model="vo.dscr"
                            :padding-right="383"
                />
              </td>
              <td>
                <button
                  v-if="idx !== 0"
                  class="tbl_icon minus"
                  @click="removeMarkDesc(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">Attached Files</h2>
        <table class="tbl_row mt10">
          <colgroup>
            <col width="170px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CMBA100.00140') }}</th><!-- 첨부파일 -->
              <td class="text_left">
                <span>
                  <!--<input type="text" class="upload_name" id="" name=""> v-show="uploadFile" -->
                  <a v-show="uploadFile" :class="uploadFileList.length > 0 ? 'blue button sm ml2':'button sm ml2'" @click="fnAttachPop()">
                    {{ $t('msg.ONEX020P120.022') }}</a><!-- 파일첨부 -->
                </span>

                <span :class="uploadFile ? 'ml50' : ''">
                  <a :class="showUploadClass(regParams.riderYn)">Rider</a>
                  <a v-show="usrCtrCd === 'KR'" :class="showUploadClass(regParams.emanYn)">{{ $t('msg.ARAI100_M1.003') }}</a>
                  <a :class="showUploadClass(regParams.dgcertiYn)">DG-Certi</a>
                  <a v-show="usrCtrCd === 'KR'" :class="showUploadClass(regParams.vgmcertiYn)">VGM-Certi</a>
                  <a v-show="usrCtrCd === 'KR'" :class="showUploadClass(regParams.etcYn)" id="etcYn">ETC</a>
                  <!-- 첨부파일에서 Loi 제외 -->
                  <!-- <a
                    v-show="usrCtrCd === 'KR' && regParams.dlyCtrCd !== 'IR'"
                    :class="showUploadClass(regParams.blLoiUpload)"
                  >
                    LOI Upload
                  </a> -->
                  <a v-show="regParams.dlyCtrCd === 'IR'" :class="showUploadClass(regParams.loiYn)">LOI Upload</a>
                  <a v-show="regParams.dlyCtrCd === 'IR'" :class="showUploadClass(regParams.confYn)">{{ $t('msg.CSBL200.267') }}</a>
                  <a v-show="regParams.dlyCtrCd === 'IR'" :class="showUploadClass(regParams.certYn)">{{ $t('msg.CSBL200.268') }}</a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_row mt10">
          <colgroup>
            <col width="170px">
            <col width="">
            <col width="170px">
            <col width="">
          </colgroup>
          <tbody>
            <tr v-if="lang === 'JPN'">
              <th>No. of B/L</th>
              <td>
                <e-input-number
                  id="oblPrtScnt"
                  v-model="regParams.oblPrtScnt"
                  maxlength="1"
                >
                </e-input-number>
              </td>
              <th>B/L Type</th>
              <td>
                <select
                  id="blCatCd"
                  v-model="regParams.blCatCd"
                >
                  <option value="">{{ $t('msg.CSBL200.075') }}</option>
                  <option value="01">O.B/L</option>
                  <option value="02">SeaWay Bill</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>Shipment No. <span class="txt">({{ $t('msg.CSBK100.062') }})</span></th>
              <td colspan="3">
                <e-trans-string-check-input :id="'shpmNo'"
                                            v-model="regParams.shpmNo"
                                            :is-upper-case="true"
                                            class="col_8"
                                            maxlength="10"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-show="regParams.dlyCtrCd === 'IR'"
          class="txt_desc"
        >
          {{ $t('msg.CSBL200.253') }}
        </p>
        <p
          v-show="regParams.jpPodCount > 0"
          class="txt_desc"
        >
          {{ $t('msg.CSBL200.254') }}<br>
          {{ $t('msg.CSBL200.254_1') }}
        </p>

        <h2 class="content_title">Request</h2>
        <table class="tbl_col">
          <colgroup>
            <col style="width:50%">
            <col style="width:50%">
          </colgroup>
          <thead>
            <tr>
              <th>Request</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in regParams.reqList" :key="'reqInfo_' + idx">
              <td>
                <textarea
                  v-model="vo.rmk"
                  readonly
                >
                </textarea>
              </td>
              <td>
                <textarea
                  v-model="vo.rspsRmk"
                  readonly
                >
                </textarea>
              </td>
            </tr>
            <tr>
              <td>
                <textarea
                  id="rmk"
                  :value="regParams.rmk"
                  @keyup="(e) => handleChange(e)"
                >
                </textarea>
              </td>
              <td>
                <textarea
                  v-model="regParams.rspsRmk"
                  readonly
                >
                </textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="mt10 flex_box">
        <a class="button lg mr5" @click="fnCommonBtnAction('GOLIST')">{{ $t('msg.MAIN100.213') }}</a>
        <a v-show="vueMode" class="button lg mr5" @click="templateSavePop()">Template Save</a>
        <span class="ml_auto">
          <a v-if="lang === 'KOR'" class="button blue lg ml5" @click="fnCommonBtnAction('ERROR_CHECK')">B/L Error Check</a>
          <a class="button blue lg ml5" @click="fnCommonBtnAction('VGMPOP_OPEN')">Input VGM</a>
          <a class="button blue lg ml5" @click="openBlCopyPop()">Preview</a>
          <a v-if="showSHAMFButton()" class="button blue lg ml5" @click="fnCommonBtnAction('SHAMF')">SHA M/F Data</a>
          <a v-if="regParams.temcount !== '0'" class="button blue lg ml5" @click="fnImCaPending()">C/A Preview</a>
          <a v-if="regParams.temcount === '0'" class="button blue lg ml5" @click="updateBL()">{{ $t('msg.CSBK100.178') }}</a>
        </span>
      </div>
    </form>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
        @child="getChildData"
      />
    </win-layer-pop>

    <component
      v-if="commonBLComponent"
      :is="commonBLComponent"
      :parent-info="commonBLParams"
      ref="commonBL"
      @callback="commonBLCallback"
    />
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import schedule from '@/api/rest/schedule/schedule'
import BookingScheduleInfo from '@/components/trans/BookingScheduleInfo'
import EInputNumber from '@/components/common/EInputNumber'
import EDatePicker from '@/components/common/EDatePicker'
import ETransStringCheckInput from '@/components/trans/ETransStringCheckInput'
import ETransAddressInput from '@/components/trans/ETransAddressInput'
import ETransAddressTextarea from '@/components/trans/ETransAddressTextarea'
import ETransStringCheckCneeInput from '@/components/trans/ETransStringCheckCneeInput'
import ETextareaLineColumn from '@/components/common/ETextareaLineColumn'
import commonUtils from '@/plugins/commonUtils'
import blAttach from '@/api/rest/trans/blAttach'
//
import ETextarea from '@/components/common/ETextarea'
import ETextarea2 from '@/components/common/ETextarea2'

export default {
  name: 'WorkingBL',
  components: {
    ETextarea,
    ETextarea2,
    //
    BookingScheduleInfo,
    EInputNumber,
    EDatePicker,
    ETransStringCheckInput,
    ETransAddressInput,
    ETransAddressTextarea,
    ETransStringCheckCneeInput,
    ETextareaLineColumn,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'bl-agent-address-pop': () => import('@/pages/trans/popup/BLAgentAddressPop'),
    'bl-on-board-date-pop': () => import('@/pages/trans/popup/BLOnBoardDatePop'),
    'bl-container-rf-pop': () => import('@/pages/trans/popup/BLContainerRFPop'),
    'bl-container-oog-pop': () => import('@/pages/trans/popup/BLContainerOOGPop'),
    'bl-container-dg-pop': () => import('@/pages/trans/popup/BLContainerDGPop'),
    'bl-container-cgo-pop': () => import('@/pages/trans/popup/BLContainerCgoPop'),
    'hs-code-ir-pop': () => import('@/pages/trans/popup/HsCodeIRPop'),
    'hs-code-id-pop': () => import('@/pages/trans/popup/HsCodeIDPop'),
    'hs-code-in-pop': () => import('@/pages/trans/popup/HsCodeINPop'),
    'bl-call-pop': () => import('@/pages/trans/popup/BLCallPop'),
    'common-bl': () => import('@/pages/trans/CommonBL'),
    'bl-attach-pop': () => import('@/pages/trans/popup/BLAttachPop'),
    'sr-notice-pop': () => import('@/pages/trans/popup/SrNoticePop'),
    'bl-copy-pop': () => import('@/pages/pdf/popup/BlCopyPop'),
    'iran-upload-pop': () => import('@/pages/trans/popup/IranUploadPop'),
    'ca-request-pop': () => import('@/pages/trans/popup/CARequestPop'),
    'shipping-log-bl-detail-pop': () => import('@/pages/trans/popup/ShippingLogBLDetailPop'),
    'container-excel-upload-pop': () => import('@/pages/trans/popup/ContainerExcelUploadPop'),
    'template-save-pop': () => import('@/pages/trans/popup/TemplateSavePop'),
    'bl-vgm-noti-pop': () => import('@/pages/trans/popup/BLVgmNoticePop')
  },
  props: {
    detailBkgNo: {
      type: String,
      default: ''
    },
    detailBlNo: {
      type: String,
      default: ''
    },
    newBlNo: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      cstInfoList: [],
      lang: '',
      usrCtrCd: '',
      cstCatCd: '',
      blgPlcCd: '',
      saveFlag: 'BL_EDIT',
      selectBeforeVal: '',
      commonCodes: {},
      plcEnmCodes: {},
      cstEnmCodes: {},
      ctrCdList: [],
      ctrCdIccList: [],
      actCstIdList: [],
      cneCstIdList: [],
      notiCstIdList: [],
      cgoTypCdList: [],
      cntrRfStsList: [],
      issPlcList: [],
      frtPayPlcList: [],
      topMarkList: [],
      topDscrList: [],
      blEdiTypCdList: [],
      blPhbtList: [],
      deckCdList: [],
      pkgCdList: [],
      indiaCityList: [],
      blCopyOpen: 'N',
      blRequestOpen: 'N',
      vgmPopOpen: 'N',
      isCstId: false, // Company ID 입력란 생성 여부
      addrStr: '',
      isPolHsMulti: false,
      isPodHsMulti: false,
      saveCntrCnt: 1,
      customComponent: null,
      popParams: {},
      selectFunc: {},
      commonBLComponent: null,
      commonBLParams: {},
      commonBLCallback: null,
      porPlcNmType: 'select',
      polPortNmType: 'select',
      podPortNmType: 'select',
      dlyPlcNmType: 'select',
      freeTime: '',
      serverYear: '',
      serverMonth: '',
      serverDay: '',
      serverHour: '',
      serverMin: '',
      saveType: '',
      dscrStr: '',
      byStr: '',
      timeFlag: '',
      elDateFlag: '',
      blDetailDis: '',
      descrCaYn: '',
      cgoRest: '',
      closeEl: false, // E/L 정보 수정 불가 여부
      closeCntr: false, // 컨테이너 정보 수정 불가 여부
      rfDisabled: false,
      uploadFile: true,
      isBlAdd: true,
      isShipperLOI: false,
      isShipperLOIPlc: true, // Shipper LOI 첨부 가능지역여부
      excelUploadShow: true,
      saveShipperLOI: '',
      phbtAlertMsg: [],
      originParams: {},
      originCntrLen: 0, // 조회됐을때 컨테이너 Length. (originParams는 1차 가공된 정보라서 별도 변수 생성)
      pkgInfo: '',
      docPicNo: '',
      hsChkParams: {
        maxlengthPolHsCd: '6',
        maxlengthPodHsCd: '6',
        hscdPolErrorMessage: '',
        hscdPodErrorMessage: '',
        polCtrCd: '',
        podCtrCd: '',
        polSuccYn: '',
        podSuccYn: ''
      },
      regParams: {
        bkgNo: '',
        blNo: '',
        docPicNo: '',
        bizPicNm: '',
        bizPicTelPlcNo: '',
        bizPicTelOfcNo: '',
        bizPicFaxPlcNo: '',
        bizPicFaxOfcNo: '',
        bizPicMbpBsnNo: '',
        bizPicMbpOfcNo: '',
        bizPicEmlAddr: '',
        docPicNm: '',
        docPicEmlAddr: '',
        docPicTelPlcNo: '',
        docPicTelNo: '',
        docPicFaxPlcNo: '',
        docPicFaxNo: '',
        docPicMbpBsnNo: '',
        docPicMbpNo: '',
        shprCstEnm: '',
        shprCstAddr: '',
        actShprCstCtrCd: '',
        actShprStateCd: '',
        cneCstCtrCd: '',
        cneCstStateCd: '',
        cneCstEnm: '',
        cneCstAddr: '',
        cneNatlId: '', // 도착국가 : 이란
        ntifCstCtrCd: '',
        ntifCstStateCd: '',
        ntifCstEnm: '',
        ntifCstAddr: '',
        ntfyNatlId: '', // 도착국가 : 이란
        porPlcNm: '', // Place of Receipt
        polPortNm: '', // Port of Loading
        podPortNm: '', // Port of Discharge
        dlyPlcNm: '', // Place of Delivery
        lstDestPlcNm: '', // Final Destination
        obrdDt: '',
        frtPncCd: 'P',
        polShtmCd: '',
        podShtmCd: '',
        grsCbm: '',
        grsWt: '',
        actShprIdCatCd: '',
        actShprIdNo: '',
        cneIdCatCd: '',
        cneIdNo: '',
        ntifIdCatCd: '',
        ntifIdNo: '',
        acidNo: '',
        cneAcidNo: '',
        pkgCd: '',
        blDclrYn: 'N',
        issCtrCd: '',
        issPlcCd: '',
        frtPayCtrCd: '',
        frtPayPlcCd: '',
        frtAppNo: '',
        hsCd: '',
        podHsCd: '',
        podHsCdNm: '',
        machineRmk: '',
        blTypCd: '',
        orgBlTypCd: '',
        exportLicenseList: [],
        containerList: [],
        markDescList: [],
        reqList: [],
        addBkgNoList: [],
        cnCaFlag: 'Y',
        tsCgoChoYn: '',
        consCgoYn: '',
        etcYn: ''
      },
      updateBLParams: {
        blMaster: [],
        mrkMaster: [],
        cntrMaster: [],
        liceMaster: []
      },
      uploadFileList: [],
      timer: null,
      sayChk: true,
      vueMode: true, //process.env.VUE_APP_MODE !== 'PRD'
      autoSayYn: 'N',
      templateCd: 'A',
      templateNo: '',
      templateNo_2: '',
      templateList: [],
      checkCgoType: 'Y',
      blTypeVisibleContry: []
   }
  },
  computed: {
    ...rootComputed,
    isJpn: function () {
      let b = false
      if (this.auth.login) {
        b = this.auth.userCtrCd === 'JP'
      } else {
        b = this.serviceLang === 'JPN'
      }

      return b
    }
  },
  watch: {
    'regParams.dlyPlcNm' () {
      this.changeLstDestPlcNm()
    }
  },
  created () {
    this._ = require('lodash')
    this.regParams.bkgNo = this.detailBkgNo
    this.regParams.blNo = this.detailBlNo

    if (this.$ekmtcCommon.isNotEmpty(this.newBlNo)) {
      this.regParams.blNo = this.newBlNo
    }

    if (this.auth !== undefined) {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
      this.cstCatCd = this.auth.cstCatCd
    }

    if (this.memberDetail !== undefined) {
      this.bkgPlcCd = this.memberDetail.bkgPlcCd
      this.usrCtrCd = this.memberDetail.userCtrCd
    }

    if (this.$ekmtcCommon.isNotEmpty(this.detailBlNo)) {
      this.saveFlag = 'BL_EDIT'
    }

    // Shipper LOI 첨부 불가지역 체크
    if (['JP', 'TH', 'HK', 'SG', 'IN'].indexOf(this.usrCtrCd) >= 0) {
      this.isShipperLOIPlc = false
    } else if (this.usrCtrCd === 'CN' && ['CAN', 'XMN'].indexOf(this.bkgPlcCd) >= 0) {
      this.isShipperLOIPlc = false
    } else {
      this.isShipperLOIPlc = true
    }
    //일본사용자 아닌 경우, Pay 자동세팅.
    if (this.regParams.polCtrCd !== 'JP') {
      if (this.regParams.frtPncCd === 'P') {
        this.regParams.frtPayCtrCd = this.regParams.polCtrCd
         this.regParams.frtPayPlcCd = this.regParams.polPortCd
       } else {
         this.regParams.frtPayCtrCd = this.regParams.podCtrCd
        this.regParams.frtPayPlcCd = this.regParams.podPortCd
      }
    }
    this.init()
    //this.$nextTick(function () {
    //window.addEventListener('keyup', this.fnSayTab)
    //})
  },
  methods: {
    async init () {
      // 01099 : 수출신고필증
      // 01032 : OCEAN FREIGHT 종류
      // 01130 : OCEAN FREIGHT 종류
      // 01031 : 컨테이너 사이즈
      // 01068 : 컨테이너 타입
      // 01033 : POD SHIPPING TERM
      // 01038 : DG
      // 01278 : 요청사항
      // CS038 : 인도 STATE
      // CS003 : JPN CNEE_AFR
      // CS004 : JPN NTIF_AFR

      //GTM dataLayer 추가
      //window.dataLayer.push({
      //  event: 'set_popup_info',
      //  popup_booking_no: this.regParams.bkgNo,
      //  popup_bl_no: this.regParams.blNo
      //})

      this.fnCommonBtnAction('')
      const arrCdId = ['01099', '01032', '01130', '01031', '01068', '01033', '01038', '01208', '01278', 'CS038', '00003', 'CS003', 'CS004', 'EQ051', '01291', 'CS105', 'WB208']
      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        this.commonCodes = res

        const cgoTypCdList = this.commonCodes['01038'].filter(vo => vo.cd !== ' ' && vo.cd !== '04')
        this.cgoTypCdList = cgoTypCdList

        const pkgCdList = this.commonCodes['01130']
        const deckCdList = this.commonCodes['01208']
        const indiaCityList = this.commonCodes.CS038

        this.cntrRfStsList = this.commonCodes['01291']

        pkgCdList.sort((a, b) => {
          return a.cdNm < b.cdNm ? -1 : a.cdNm > b.cdNm ? 1 : 0
        })

        deckCdList.sort((a, b) => {
          return a.cd < b.cd ? -1 : a.cd > b.cd ? 1 : 0
        })

        indiaCityList.sort((a, b) => {
          return a.cdNm < b.cdNm ? -1 : a.cdNm > b.cdNm ? 1 : 0
        })

        this.pkgCdList = pkgCdList
        this.deckCdList = deckCdList
        this.indiaCityList = indiaCityList

        //bl Type display contry
        this.commonCodes.WB208.forEach((v) => {
          this.blTypeVisibleContry.push(v.cdNm)
        })
      })

      await this.initBlBkgInfo()
      const regParams = this.regParams

      this.regParams.orgBlTypCd = this.regParams.blTypCd

      let blEdiTypCdList = this.commonCodes['01099']
      if (regParams.emptyFullYn === 'Y') {
        blEdiTypCdList = blEdiTypCdList.filter(vo => vo.cd !== 'E')
      }
      this.blEdiTypCdList = blEdiTypCdList

      const arrPlcCd = [regParams.porPlcCd, regParams.polPortCd, regParams.podPortCd, regParams.dlyPlcCd]
      const arrPromise = [
        commons.getPlcStrEnmInfo(arrPlcCd).then(res => {
          this.plcEnmCodes = res

          this.setInputType()
        }),
        commons.getCommonsCodesCountry().then(res => {
          this.ctrCdList = res

          this.checkShpperLOI()
        }),
        commons.getCommonsCodesCountry('Y').then(res => {
          this.ctrCdIccList = res
        }),
        trans.fnBlCstEnmInfo().then(res => {
          this.cstEnmCodes = res
        }),
        trans.findTopMark().then(res => {
          this.topMarkList = res
        }),
        trans.findTopDscr().then(res => {
          this.topDscrList = res
        }),
        commons.getServerTime().then(res => {
          this.serverYear = res.data.substring(0, 4)
          this.serverMonth = res.data.substring(4, 6)
          this.serverDay = res.data.substring(6, 8)
          this.serverHour = res.data.substring(8, 10)
          this.serverMin = res.data.substring(10, 12)

          this.setTimeFlag(res.data)

          this.initBLPage()
        })
      ]

      if (Number(regParams.cnPodCount) > 0 || regParams.polCtrCd === 'MY' || regParams.polPortCd === 'SHA' || regParams.polShaTsYn === 'Y' || Number(regParams.shaPodTsCount) > 0) {
        this.isCstId = true
        arrPromise.push(
          this.fnChangeActCatId(regParams.actShprCstCtrCd, 'INIT')
        )

        arrPromise.push(
          this.fnChangeCneCatId(regParams.cneCstCtrCd, 'INIT')
        )

        arrPromise.push(
          this.fnChangeNotiCatId(regParams.ntifCstCtrCd, 'INIT')
        )
      }

      const restParam = {
        asBkgNo: this.regParams.bkgNo,
        asVoyNo: this.regParams.voyNo,
        asVslCd: this.regParams.vslCd,
        asPolPortCd: this.regParams.polPortCd,
        asPodPortCd: this.regParams.podPortCd
      }

      arrPromise.push(
        schedule.checkBkgRestriction(restParam).then(res => {
          this.cgoRest = res.data.cgoRest
        })
      )

      if (this.lang === 'JPN') {
        if (this.$ekmtcCommon.isNotEmpty(this.regParams.issCtrCd)) {
          arrPromise.push(
            commons.getPlcCdList(this.regParams.issCtrCd).then(res => {
              this.$set(this, 'issPlcList', res)
            })
          )
        }

        if (this.$ekmtcCommon.isNotEmpty(this.regParams.frtPayCtrCd)) {
          arrPromise.push(
            commons.getPlcCdList(this.regParams.frtPayCtrCd).then(res => {
              this.$set(this, 'frtPayPlcList', res)
            })
          )
        }
      }

      // JamSin 도착지가 ID 인 경우 숫자만 남기고 제거
      if (this.regParams.podCtrCd === 'ID') {
          this.regParams.idTaxNo = this.regParams.idTaxNo.replace(/[^0-9]/gi, '')
      }
      // JamSin 출발지가 ID 인 경우 TAX NO 숫자만 남기고 제거
      if (this.regParams.polCtrCd === 'ID') {
        this.regParams.etTaxNo = this.regParams.etTaxNo.replace(/[^0-9]/gi, '')
      }

      await Promise.all(arrPromise)
      this.showBLAddBtn()

      this.originParams = this._.cloneDeep(this.regParams)

      this.setFileInfo()

      this.regParams.tplCatCd = '02'
      let templateParam = { tplCatCd: '02' }
      await trans.getTemplateList(templateParam).then((res) => {
        this.templateList = res.data
      })
      if (this.regParams.polCtrCd === 'JP') {
        await trans.findOnlyActYn(this.regParams.blNo).then(res => {
          if (res.data.onlyActYn === 'Y') {
            this.isBlAdd = false
          }
        })
      }

      if (this.memberDetail.userCtrCd === 'CN') {
        await commons.getCstInfo().then((res) => {
          this.cstInfoList = res
        })
      }
    },
    setTimeFlag (result) {
      const regParams = this.regParams
      const documentTime = regParams.documentTime
      const serverTime = result.substring(0, 12)

      if (documentTime !== '000000000000') {
        if (Number(serverTime) > Number(documentTime)) {
          this.timeFlag = 'N'
        } else {
          this.timeFlag = 'Y'
        }
      }

      let date = ''
      if (regParams.elDt === 'N') {
        date = regParams.obrdDt + '0000'
      } else {
        date = regParams.elDt
      }

      const aftDay = this.$ekmtcCommon.getAddDay(date, 25).substring(0, 12)

      if (Number(serverTime) > Number(aftDay)) {
        this.elDateFlag = 'Y'
      } else {
        this.elDateFlag = 'N'
      }
    },
    initBLPage () {
      const regParams = this.regParams
      if (this.auth !== undefined && this.auth.userId === 'CKLINE') {
        this.regParams.containerList.forEach((c, idx) => {
          frm.querySelector('#lcsPodShtmCd_' + idx).disabled = false
        })
        return
      }

      const frm = document.querySelector('#frm')
      const disabledNm = ['polShtmCd', 'podShtmCd', 'blEdiTypCd']
      disabledNm.forEach(el => {
        frm.querySelector('#' + el).disabled = true

        if (el === 'blEdiTypCd' && this.timeFlag !== 'N') {
          frm.querySelector('#' + el).disabled = false
        }
      })

      //1451975_(DLC)2024.06 shipper can amend the "B/L type" on web
      //userCtrCd 중국일 경우 E/L Type 항상 수정 가능 하도록 처리
      if (this.memberDetail.userCtrCd === 'CN') {
        frm.querySelector('#blEdiTypCd').disabled = false
      }

      let closeYn = '0'
      let closeEl = '0'

      if (regParams.blIssYn === 'Y' || regParams.blSndYn === 'Y' || regParams.cllYn === 'Y') {
        closeYn = '3'
      } else if (regParams.ediSndYn === 'Y') {
        regParams.descrCaYn = 'N'
        closeYn = '3'
      }

/*
     중국 부킹 지역에 따라 컨테이너 정보 비활성화 로직 삭제 요청(CHOIKY) 2022.10.20
      const arrPlcCd = ['SZP', 'TAO', 'SHA', 'LYG', 'NBO', 'WUH', 'NTG', 'CKG', 'NKG', 'XGG', 'DLC', 'XMN', 'SWA', 'FQG', 'FOC', 'SHK']
      if (this.lang === 'CHN' && arrPlcCd.indexOf(this.bkgPlcCd) === -1) {
        closeYn = '3'
      }
*/
      if (regParams.elSndYn === 'Y') {
        closeYn = '3'
      }

      if (regParams.oblYn === 'Y') {
        if (regParams.polCtrCd === 'SG') {
         // closeYn = '1'
        //this.blDetailDis = 'Y'
        }

        regParams.descrCaYn = 'N'
      }

      if (this.elDateFlag === 'Y') {
        closeEl = 'Y'
      }

      if (String(regParams.recount) !== '0') {
        closeYn = '1'
        this.blDetailDis = 'Y'

        if (String(regParams.pencount) !== '0') {
          //this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL100_M1.031'))
        } else if (String(regParams.temcount) !== '0') {
          this.fnImCaPending()
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK339'))
        }
      }

      if (this.lang === 'KOR' && regParams.apvStsCd === '07') {
        closeYn = '1'
        const message = regParams.srRspsRmk + this.$t('msg.CSBL200.225')
        this.$ekmtcCommon.alertDefault(message)
      }
/*
      if (regParams.eobYn === 'Y' && (regParams.blNo.indexOf('SHA') > -1 || regParams.yzrYn === 'Y')) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK400'))
        closeYn = '1'
        this.blDetailDis = 'Y'
      }
*/
      if (regParams.caMfYn === 'Y') {
        regParams.descrCaYn = 'N'
      }

      if (closeEl === 'Y') {
        const inputEl = frm.getElementsByTagName('input')
        for (const el of inputEl) {
          const id = el.getAttribute('id')

          if (id !== null) {
            const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
            let checkNm = 'elNo|lcsPkgQty|lcsGrsWt|pkgtgPkgQty|elNoValdDt'

            if (checkNm.indexOf(idNm) > -1) {
              el.disabled = true
            }
          }
        }

        const selectEl = frm.getElementsByTagName('select')
        for (const el of selectEl) {
          const id = el.getAttribute('id')

          if (id !== null) {
            const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
            let checkNm = 'lcsPkgCd|pkgtgCd|prtlLdgYn|ldgTcnt'

            if (checkNm.indexOf(idNm) > -1) {
              el.disabled = true
            }
          }
        }

        this.closeEl = true
      }

      if (closeYn === '1') {
        this.fnBtnShowYn()
        this.fnValidation(true)
      } else if (closeYn === '3') {
        const inputEl = frm.getElementsByTagName('input')
        for (const el of inputEl) {
          const id = el.getAttribute('id')

          if (id !== null) {
            const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
            let checkNm = 'cntrNo|shprVanYn'

            if (checkNm.indexOf(idNm) > -1) {
              el.disabled = true
            }
          }
        }

        const selectEl = frm.getElementsByTagName('select')
        for (const el of selectEl) {
          const id = el.getAttribute('id')

          if (id !== null) {
            const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
            let checkNm = 'cntrSzCd|cntrTypCd|cgoTypCd'

            if (checkNm.indexOf(idNm) > -1) {
              el.disabled = true
            }
          }
        }

        this.closeCntr = true
      }

      if (closeYn === '1' || closeYn === '3') {
        this.excelUploadShow = false
      }

      if (this.lang !== 'KOR' || regParams.polCtrCd === 'JP' || regParams.podCtrCd === 'IR') {
        regParams.descrCaYn = 'N'
      }

      if (regParams.blPrtYn === 'Y') {
        regParams.descrCaYn = 'N'
      }
      if (regParams.polCtrCd !== 'SG' && regParams.polCtrCd !== 'AE' && regParams.polCtrCd !== 'TW' && regParams.polCtrCd !== 'RU' && regParams.polCtrCd !== 'KH' && regParams.polCtrCd !== 'MM' && regParams.polCtrCd !== 'LK' && regParams.polCtrCd !== 'PK' && (
        (this.$ekmtcCommon.isNotEmpty(regParams.mfClosDt) && regParams.polCtrCd === 'KR' && (regParams.mfClosDt < regParams.toDayDt && this.regParams.eobYn === 'Y')) ||
        regParams.oblYn === 'Y' ||
        regParams.surrYn === 'Y' || (regParams.polCtrCd === 'CN' && this.regParams.eobYn === 'Y'))) {
        regParams.mfClosYn = 'Y'
        regParams.descrCaYn = 'N'
      } else if (this.usrCtrCd === 'KR' && (this.regParams.blChkYn === 'Y' || this.regParams.blChkYn === 'A')) {
        this.fnCommonBtnAction('ERROR_CHECK')
      }
    },
    fnImCaPending () {
      console.log('fnImCaPending @@@@@@ ')
      let emanYn = true
      if (this.isEmanSave()) {
        emanYn = false
      }

      const regParams = this.regParams
      if (this.$ekmtcCommon.isNotEmpty(regParams.mfClosDt) && (regParams.mfClosDt < regParams.toDayDt && this.regParams.eobYn === 'Y')) {
        regParams.mfClosDtYn = 'Y'
      }

      let blIssYn = ''
      const surrYn = regParams.surrYn
      const mfClosDtYn = regParams.mfClosDtYn

      if (regParams.blIssYn === 'Y' && regParams.blCatCd === '01') {
        blIssYn = 'Y'
      }

      if (regParams.oblYn === 'Y' ||
        (regParams.surrYn === 'Y' && regParams.surrDt != null) ||
        (regParams.blCatCd === '02' && regParams.wbilCfmYn === 'Y' && regParams.wbilCfmDt != null) ||
        regParams.polCtrCd !== 'KR') {
        regParams.caCfmYn = 'N'
      } else {
        regParams.caCfmYn = 'Y'
      }

      this.popParams = {
        blNo: regParams.blNo,
        blIssYn: blIssYn,
        surrYn: surrYn,
        mfCloseDtYn: mfClosDtYn,
        tempCaNo: regParams.tempCaNo,
        path: regParams.path,
        emanYn: emanYn,
        caCfmYn: regParams.caCfmYn
      }

      this.selectFunc = this.refresh
      this.openPopup('ca-request-pop')
    },
    fnBtnShowYn () {
      this.uploadFile = false
    },
    fnValidation (flag) {
      const frm = document.querySelector('#frm')
      const inputEl = frm.getElementsByTagName('input')
      for (const el of inputEl) {
        el.disabled = flag
      }

      const selectEl = frm.getElementsByTagName('select')
      for (const el of selectEl) {
        el.disabled = flag
      }

      const textareaEl = frm.getElementsByTagName('textarea')
      for (const el of textareaEl) {
        el.disabled = flag
      }

      if (flag) {
        this.closeCntr = true
        this.closeEl = true
        this.uploadFile = true
        this.rfDisabled = true
      }
    },
    setBlBkgInfo (obj) {
      if (obj !== undefined) {
        const defaults = {
          bkgNo: '',
          bizPicNm: '',
          bizPicTelPlcNo: '',
          bizPicTelOfcNo: '',
          bizPicFaxPlcNo: '',
          bizPicFaxOfcNo: '',
          bizPicMbpBsnNo: '',
          bizPicMbpOfcNo: '',
          bizPicEmlAddr: '',
          shprCstEnm: '',
          shprCstAddr: '',
          actShprCstCtrCd: '',
          actShprStateCd: '',
          cneCstCtrCd: '',
          cneCstStateCd: '',
          cneCstEnm: '',
          cneCstAddr: '',
          cneNatlId: '', // 도착국가 : 이란
          ntifCstCtrCd: '',
          ntifCstStateCd: '',
          ntifCstEnm: '',
          ntifCstAddr: '',
          ntfyNatlId: '', // 도착국가 : 이란
          porPlcNm: '', // Place of Receipt
          polPortNm: '', // Port of Loading
          podPortNm: '', // Port of Discharge
          dlyPlcNm: '', // Place of Delivery
          lstDestPlcNm: '', // Final Destination
          obrdDt: '',
          frtPncCd: 'P',
          polShtmCd: '',
          podShtmCd: '',
          grsCbm: '',
          grsWt: '',
          actShprIdCatCd: '',
          actShprIdNo: '',
          cneIdCatCd: '',
          cneIdNo: '',
          ntifIdCatCd: '',
          ntifIdNo: '',
          pkgCd: '',
          blDclrYn: 'N',
          issCtrCd: '',
          issPlcCd: '',
          frtPayPlcCd: '',
          exportLicenseList: [],
          containerList: [],
          markDescList: [],
          cgoTypList: [],
          reqList: [],
          addBkgNoList: this.regParams.addBkgNoList
        }

        this.regParams = { ...defaults, ...obj }

        this.originCntrLen = this.regParams.containerList.length
        //console.log(`>>>>> this.originCntrLen : ${this.originCntrLen}`)

        this.regParams.obrdDtTemp = this.$ekmtcCommon.changeDatePattern(this.regParams.obrdDt, '.')
        this.regParams.cgoCont = this.regParams.cmdtDsc || '' // 값이 null인 경우 빈값으로 치환

        const regParams = this.regParams
        if (regParams.polCtrCd === 'ID' || regParams.polCtrCd === 'PK') {
          this.hsChkParams.maxlengthPolHsCd = '8'
        }

        if (regParams.dlyCtrCd === 'PK') {
          this.hsChkParams.maxlengthPodHsCd = '8'
        }

        this.hsChkParams.polCtrCd = regParams.polCtrCd

        if (regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') {
          if (regParams.dlyCtrCd === 'IN') {
            this.hsChkParams.maxlengthPodHsCd = '6'
          } else if (regParams.dlyCtrCd === 'ID' || regParams.dlyCtrCd === 'VN') {
            this.hsChkParams.maxlengthPodHsCd = '8'
          } else if (regParams.shaTsYn === 'Y') {
            this.hsChkParams.maxlengthPodHsCd = '10'
          }
        } else if (regParams.dlyCtrCd === 'IR') {
          this.hsChkParams.maxlengthPodHsCd = '10'
        }

        this.hsChkParams.podCtrCd = regParams.dlyCtrCd

        if (regParams.shaTsYn === 'Y') {
          this.hsChkParams.podCtrCd = 'SHA'
        }
      }

      if (this.regParams.exportLicenseList.length === 0) {
        this.addExportLicense()

        //this.regParams.exportLicenseList[0].pkgCd = this.regParams.pkgCd
        this.fnSumValue('grsWt')
        this.fnSumValue('pkgQty')
      } else {
        this.regParams.exportLicenseList.forEach(e => {
          if (Number(e.ldgTcnt) === 0) {
            e.ldgTcnt = ''
          }
        })
      }

      if (this.regParams.markDescList.length === 0) {
        this.addMarkDesc()
        this.fnChangeContSz()
        this.fnPackage('INIT')
      } else {
        this.markDescEnterSet()
      }

      if (this.regParams.containerList.length === 0) {
        this.addBLContainer()
      } else {
        this.regParams.containerList.forEach(c => {
          if (this.$ekmtcCommon.isEmpty(c.sealNo1) || this.$ekmtcCommon.isEmpty(c.sealNo1.trim())) {
            //c.sealList = [{ sealNo: '' }]
            this.$set(c, 'sealList', [{ sealNo: '' }])
          } else {
            const sealList = []
            for (let i = 1; i <= 5; i++) {
              if (this.$ekmtcCommon.isNotEmpty(c['sealNo' + i]) && this.$ekmtcCommon.isNotEmpty(c['sealNo' + i].trim())) {
                sealList.push({ sealNo: c['sealNo' + i] })
              }
            }

            this.$set(c, 'sealList', sealList)
          }

          //c.polShtmCd = this.regParams.polShtmCd
          c.podShtmCd = this.regParams.podShtmCd
        })
      }

      const trdFileInfo = this.regParams.trdFileInfo
      if (this.$ekmtcCommon.isNotEmpty(trdFileInfo) && this.$ekmtcCommon.isNotEmpty(trdFileInfo.reqRno)) {
        this.saveShipperLOI = 'Y'
      }

      /* if (this.$ekmtcCommon.isNotEmpty(this.regParams.invIssNo)) {
        const frm = document.querySelector('#frm')
        const blTypCd = frm.querySelector('#blTypCd')

        blTypCd.disabled = true
      } */

      this.initDesc()
      this.checkShpperLOI()

      this.isMultiPolHsCode()
      this.isMultiPodHsCode()

      this.regParams.containerList.forEach((c, idx) => {
        if (this.isPolShtmListChange()) {
          c.polShtmCdList = this.commonCodes['01033'].filter(vo => vo.cd === '01' || vo.cd === '02')
        } else {
          c.polShtmCdList = this.commonCodes['01033']
        }

        if (this.regParams.polShtmCd === '03') {
          if (c.polShtmCd !== '01' && c.polShtmCd !== '02') {
            c.polShtmCd = '01'
          }
        }
      })

      if (this.blCopyOpen === 'Y') {
        this.$ekmtcCommon.alertCallback(this.$t('art.CMATK375'), () => {
          this.openBlCopyPop()
          this.blCopyOpen = 'N'
        })
      } else if (this.blRequestOpen === 'Y') {
        this.fnImCaPending()
        this.blRequestOpen = 'N'
      } else if (this.vgmPopOpen === 'Y') {
        this.fnCommonBtnAction('VGMPOP_SAVE')
        this.vgmPopOpen = 'N'
      }

      const frm = document.querySelector('#frm')

      // 220713 #356 비활성화 처리
      // this.$nextTick(() => {
      //   this.regParams.containerList.forEach((c, idx) => {
      //   if (this.regParams.polShtmCd === '03') {
      //       frm.querySelector('#lcsPolShtmCd_' + idx).disabled = false
      //     } else {
      //       frm.querySelector('#lcsPolShtmCd_' + idx).disabled = true
      //     }
      //   })
      // })

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.addBkgNoList) && this.regParams.addBkgNoList.length > 0) {
        const arrBkgNoList = this._.cloneDeep(this.regParams.addBkgNoList)

        arrBkgNoList.push(this.detailBkgNo)
        trans.getShprVanInfoMulti(arrBkgNoList).then(res => {
          if (this.$ekmtcCommon.isNotEmpty(res)) {
            this.regParams.shprVanYn = res.data
          }
        })
      }

      let isCgoTypeO1 = false
      if (this.regParams.cgoTypList.length > 0) {
        this.regParams.cgoTypList.forEach(c => {
          if (c.cgoTypCd === '01') { isCgoTypeO1 = true }
        })

        if (isCgoTypeO1 && this.regParams.podPortCd === 'SGN' && (this.regParams.dlyPlcCd === 'TNT' || this.regParams.dlyPlcCd === 'TCP')) {
          if (this.$ekmtcCommon.isEmpty(this.regParams.lstDestPlcNm)) {
            this.regParams.lstDestPlcNm = this.regParams.dlyPlcNm
          }
          $('#lstDestPlcNm').prop('disabled', true)
        }
      }

      this.docPicNo = obj?.docPicNo ?? ''

      this.initPkgInfo()
      this.originParams = this._.cloneDeep(this.regParams)
    },
    initPkgInfo () {
      const regParams = this.regParams
      const pkgQty = this.regParams.pkgQty
      const pkgCd = this.regParams.pkgCd

      const pkgInfo = this.commonCodes['01130'].filter(vo => vo.cd === pkgCd)[0]
      let pkgNm = ''

      if (pkgInfo !== undefined) {
        pkgNm = pkgInfo.cdNm
      }

      if (this.$ekmtcCommon.isNotEmpty(pkgQty) && this.$ekmtcCommon.isNotEmpty(pkgCd)) {
        this.pkgInfo = this.$ekmtcCommon.changeNumberFormat(pkgQty, { isComma: true }) + pkgNm
      }
    },
    async initBlBkgInfo () {
      const param = {
        blNo: this.regParams.blNo,
        bkgNo: this.regParams.bkgNo
      }

      // BL 정보 조회
      await trans.fnBlBkgInfo(param).then(res => {
        const result = res.data

        if (result !== undefined) {
          this.setBlBkgInfo(result)
        }
      })
    },
    initDesc () {
      let descStatic = ''
      const regParams = this.regParams
      const markDesc = this.regParams.markDescList[0]

      if (this.$ekmtcCommon.isNotEmpty(markDesc.dscr.trim())) {
        let dscr = markDesc.dscr

        if (this.regParams.polShtmCd === '02') {
          descStatic = '"SAID TO BE:"\r\n'
        } else {
          descStatic = '"SHIPPER\'S LOAD, COUNT & SEAL"\r\n'
          descStatic += '"SAID TO CONTAIN"\r\n'
        }

        if (dscr.indexOf('"SHIPPER\'S LOAD, COUNT & SEAL"') > -1) {
          dscr = dscr.replace('"SHIPPER\'S LOAD, COUNT & SEAL"', '').replace(/^\s*/, '')
        }

        if (dscr.indexOf('"SAID TO CONTAIN"') > -1) {
          dscr = dscr.replace('"SAID TO CONTAIN"', '').replace(/^\s*/, '')
        }

        if (dscr.indexOf('"SAID TO BE:"') > -1 || dscr.indexOf('"SAID TO BE : "') > -1) {
          dscr = dscr.replace('"SAID TO BE:"', '').replace(/^\s*/, '')
          dscr = dscr.replace('"SAID TO BE : "', '').replace(/^\s*/, '')
        }

        markDesc.dscrSub = descStatic
        markDesc.dscr = dscr
      } else {
        if (this.regParams.polShtmCd === '02') {
          descStatic = '"SAID TO BE:"\r\n'
        } else {
          descStatic = '"SHIPPER\'S LOAD, COUNT & SEAL"\r\n'
          descStatic += '"SAID TO CONTAIN"\r\n'
        }

        markDesc.dscrSub = descStatic
        //markDesc.dscr = markDesc.dscr.replace(/\s+$/g, '')
      }
    },
    fnChangeActCatId (ctrCd, flag) {
      if (this.$ekmtcCommon.isEmpty(ctrCd)) {
        ctrCd = this.regParams.polCtrCd
      }

      if (flag === undefined) {
        flag = 'CHANGE'
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (flag !== 'INIT') {
            this.regParams.actMin = ''
            this.regParams.actMax = ''
            this.regParams.actShprIdNo = ''
            this.regParams.actShprIdCatCd = ''
          }
          this.actCstIdList = res.data
        })
    },
    fnChangeCneCatId (ctrCd, flag) {
      if (this.$ekmtcCommon.isEmpty(ctrCd)) {
        ctrCd = this.regParams.podCtrCd
      }

      if (flag === undefined) {
        flag = 'CHANGE'
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (flag !== 'INIT') {
            this.regParams.cneMin = ''
            this.regParams.cneMax = ''
            this.regParams.cneIdNo = ''
            this.regParams.cneIdCatCd = ''
          }
          this.cneCstIdList = res.data
        })
    },
    fnChangeNotiCatId (ctrCd, flag) {
      if (this.$ekmtcCommon.isEmpty(ctrCd)) {
        ctrCd = this.regParams.podCtrCd
      }

      if (flag === undefined) {
        flag = 'CHANGE'
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (flag !== 'INIT') {
            this.regParams.ntifMin = ''
            this.regParams.ntifMax = ''
            this.regParams.ntifIdNo = ''
            this.regParams.ntifIdCatCd = ''
          }
          this.notiCstIdList = res.data
        })
    },
    fnChangeCatId (e, flag) {
      const val = e.target.value
      const regParams = this.regParams
      const frm = document.querySelector('#frm')
      const id = e.target.id
      if (flag === 'ACT') {
        if (val !== '') {
          const vo = this.actCstIdList.find(vo => vo.cstIdCatCd === val) || {}
          regParams.actShprIdCatCd = val
          regParams.actMin = vo.minInpLen || ''
          regParams.actMax = vo.maxInpLen || ''
        } else {
          regParams.actShprIdCatCd = ''
          regParams.actMin = ''
          regParams.actMax = ''
        }
      } else if (flag === 'CNE') {
        if (val !== '') {
          const vo = this.cneCstIdList.find(vo => vo.cstIdCatCd === val) || {}
          regParams.cneIdCatCd = val
          regParams.cneMin = vo.minInpLen || ''
          regParams.cneMax = vo.maxInpLen || ''
        } else {
          regParams.cneIdCatCd = ''
          regParams.cneMin = ''
          regParams.cneMax = ''
        }
      } else if (flag === 'NTIF') {
        if (val !== '') {
          const vo = this.notiCstIdList.find(vo => vo.cstIdCatCd === val) || {}
          regParams.ntifIdCatCd = val
          regParams.ntifMin = vo.minInpLen || ''
          regParams.ntifMax = vo.maxInpLen || ''
        } else {
          regParams.ntifIdCatCd = ''
          regParams.ntifMin = ''
          regParams.ntifMax = ''
        }
      }

      this.checkErrorVisible(id)
    },
    fnAddInfoStateSet (flag) {
      if (flag === 'ACT') {
        //this.regParams.actShprStateCd = ''
        //this.regParams.actShprStateNm = ''
        this.$set(this.regParams, 'actShprStateCd', '')
        this.$set(this.regParams, 'actShprStateNm', '')
      } else if (flag === 'CNE') {
        //this.regParams.cneCstStateCd = ''
        //this.regParams.cneCstStateNm = ''
        this.$set(this.regParams, 'cneCstStateCd', '')
        this.$set(this.regParams, 'cneCstStateNm', '')
      } else if (flag === 'NTIF') {
        //this.regParams.ntifCstStateCd = ''
        //this.regParams.ntifCstStateNm = ''
        this.$set(this.regParams, 'ntifCstStateCd', '')
        this.$set(this.regParams, 'ntifCstStateNm', '')
      }
    },
    fnSumValue (type) {
      const regParams = this.regParams
      if (regParams.blEdiTypCd === 'S' && this.memberDetail.userCtrCd === 'KR') {
        regParams.exportLicenseList.forEach(e => {
          if (this.$ekmtcCommon.isEmpty(e[type]) || String(e[type]) === '0') {
            e[type] = regParams[type]
          }
        })
      }
    },
    fnPackage (actionFlag) {
      const regParams = this.regParams
      const pkgQty = regParams.pkgQty
      const pkgCd = regParams.pkgCd

      const pkgInfo = this.commonCodes['01130'].filter(vo => vo.cd === pkgCd)[0]

      let retValue = ''
      let pkgNm = ''

      if (pkgInfo !== undefined) {
        pkgNm = pkgInfo.cdNm
      }

      let flagChange = 'N'
      let cc = ''
      let dd = 0
      let jj = ''
      let kk = ''

      const dscr = this.regParams.markDescList[0].dscr
      regParams.markDescList[0].dscr = dscr.replace(this.pkgInfo, '')

      if (this.$ekmtcCommon.isNotEmpty(pkgQty) && this.$ekmtcCommon.isNotEmpty(pkgCd)) {
        retValue = this.$ekmtcCommon.changeNumberFormat(pkgQty, { isComma: true }) + pkgNm
      }

      const arrDscr = dscr.split('\n')
      arrDscr.forEach((d, i) => {
        if ((d.indexOf('X20') !== -1 || d.indexOf('X40') !== -1 || d.indexOf('X45') !== -1 ||
             d.indexOf('X 20') !== -1 || d.indexOf('X 40') !== -1 || d.indexOf('X 45') !== -1)) {
          cc += d + '\n'
          ++dd
        } else {
          if (parseInt(d.trim().substring(0, 5).charAt(0)) >= 0 && parseInt(d.trim().substring(0, 5).charAt(0)) <= 9) {
            // Package 수량 체크는 2번째 라인까지만 확인
            if (i < 3 && flagChange === 'N') {
              regParams.markDescList[0].dscr = dscr.replace(d, retValue)
              flagChange = 'Y'

              /* if (d.indexOf('X20') === -1 && d.indexOf('X40') === -1 && d.indexOf('X45') === -1 && d !== this.pkgInfo) {
                jj += d.replace(this.pkgInfo, '')
              } */
            } else {
              jj += d
            }
          } else {
            jj += d
          }
          if (i !== (arrDscr.length - 1)) {
            jj += '\n'
          }
        }
      })

      if (arrDscr.length === dd) {
        kk = cc + retValue
      } else {
        kk = cc + retValue + jj
      }

      regParams.markDescList[0].dscr = kk

      if (regParams.exportLicenseList.length > 0) {
        regParams.exportLicenseList.forEach(e => {
          e.pkgCd = regParams.pkgCd
        })
      }

      if (actionFlag === 'CHANGE') {
        this.pkgInfo = retValue
        this.checkValidationInfo('pkgCd')
      }
    },
    changeInputType (e, targetKey) {
      const elTxt = e.target.value // 보여줄 대상

      if (elTxt === 'input') {
        // selectbox 숨기면서 이전값을 셋팅
        // 다시 select로 변경시 사용하기 위한 용도
        this.selectBeforeVal = this.regParams[targetKey]
        this.regParams[targetKey] = ''
      } else {
        this.regParams[targetKey] = this.selectBeforeVal
      }
    },
    changeOnlyUpperEng (e, object, key, idx) {
      let key2 = key
      if (key === 'cntrNo') {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#cntrNo_' + idx)
        this.$ekmtcCommon.hideErrorTooltip(elem)
        if (e.target.value) {
          const regEng = /^[a-zA-Z0-9]*$/
          const matchEng = regEng.test(e.target.value)
          if (!matchEng) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
          }
        }
      } else if (key && key.startsWith('sealNo')) { // seal No
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector(`[name="${key}_${idx}"]`) // name으로 검색
        key2 = 'sealNo'
        this.$ekmtcCommon.hideErrorTooltip(elem)
        if (e.target.value) {
          const regEng = /^[a-zA-Z0-9]*$/
          const matchEng = regEng.test(e.target.value)
          if (!matchEng) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
          }
        }
      }
      this.$ekmtcCommon.onlyEngNumber(e)
      this.$set(object, key2, object[key2].toUpperCase())
    },
    changeLatestInfo (e, flag) {
      const cstNm = e.target.value
      if (this.$ekmtcCommon.isNotEmpty(cstNm)) {
        const selectedIdx = e.target.options.selectedIndex
        const cstEnmInfo = this.cstEnmCodes[flag.toUpperCase()][selectedIdx - 1]

        this.$set(this.regParams, flag + 'CstEnm', cstEnmInfo.cstNm)
        this.$set(this.regParams, flag + 'CstAddr', cstEnmInfo.cstAddr)
      }
    },
    async changeCtrCd (e, target) {
      const ctrCd = e.target.value

      const plcList = await commons.getPlcCdList(ctrCd)
      this.$set(this, target, plcList)
    },
    changeCneCstEnmToOrder (e) {
      if (e.target.checked) {
        this.regParams.cneCstEnm = 'TO ORDER'
      } else {
        this.regParams.cneCstEnm = ''
      }
    },
    copyConsignee (e) {
      if (e.target.checked) {
        this.regParams.ntifCstEnm = this.regParams.cneCstEnm
        this.regParams.ntifCstAddr = this.regParams.cneCstAddr

        //지역, 담당자++ 부분도 복사
        this.regParams.ntifCstCtrCd = this.regParams.cneCstCtrCd
        this.regParams.ntifCstCityNm = this.regParams.cneCstCityNm
        this.regParams.ntifCstStateNm = this.regParams.cneCstStateNm
        this.regParams.ntifCstPostNo = this.regParams.cneCstPostNo
        this.regParams.ntifCstPicNm = this.regParams.cneCstPicNm
        this.regParams.ntifCstTelNo = this.regParams.cneCstTelNo
        this.regParams.ntifCstFaxNo = this.regParams.cneCstFaxNo
        this.regParams.ntifCstEmlAddr = this.regParams.cneCstEmlAddr
      } else {
        this.regParams.ntifCstEnm = ''
        this.regParams.ntifCstAddr = ''
        this.regParams.ntifCstCtrCd = ''
        this.regParams.ntifCstCityNm = ''
        this.regParams.ntifCstStateNm = ''
        this.regParams.ntifCstPostNo = ''
        this.regParams.ntifCstPicNm = ''
        this.regParams.ntifCstTelNo = ''
        this.regParams.ntifCstFaxNo = ''
        this.regParams.ntifCstEmlAddr = ''
      }
    },
    showBkgNoList (e) {
      const el = e.target
      const parentEl = el.closest('.span_tooltip')
      parentEl.querySelector('.tooltip_wrap').style.display = 'inline-block'
    },
    hideBkgNoList (e) {
      const el = e.target
      el.closest('.tooltip_wrap').style.display = 'none'
    },
    addExportLicense () {
      const obj = {
        elNo: '',
        pkgQty: '',
        pkgCd: '',
        grsWt: '',
        prtlLdgYn: 'N',
        ldgTcnt: '',
        pkgtgCd: '',
        pkgtgPkgQty: '',
        elNoValdDt: ''
      }

      if (this.isIndonesia()) {
        obj.idExportYn = 'Y'
        obj.idCstmCd = ''
        obj.emptyYn = ''
      } else {
        obj.idExportYn = 'N'
      }

      this.regParams.exportLicenseList.push(obj)
    },
    removeExportLicense (idx) {
      this.regParams.exportLicenseList.splice(idx, 1)

      if (this.regParams.exportLicenseList.length === 0) {
        this.addExportLicense()
      }
    },
    addBLContainer () {
      let obj = {
        cntrNo: '',
        sealList: [
          { sealNo: '' }
        ],
        cntrSzCd: '',
        cntrTypCd: '',
        cgoTypCd: '',
        wt: '',
        pkgQty: '',
        shprVanYn: 'N',
        feUnitCd: 'F',
        cntrCheck: 'Y',
        polShtmCd: this.regParams.polShtmCd,
        podShtmCd: this.regParams.podShtmCd,
        deckCd: ''
      }

      if (this.lang === 'JPN') {
        const objJpn = {
          tareWt: '',
          pkgCd: '',
          meaCbm: ''
        }
        obj = { ...obj, ...objJpn }
      }

      if (this.regParams.polShtmCd === '03') {
        obj.polShtmCd = '01'
      }

      if (this.isPolShtmListChange()) {
        obj.polShtmCdList = this.commonCodes['01033'].filter(vo => vo.cd === '01' || vo.cd === '02')
      } else {
        obj.polShtmCdList = this.commonCodes['01033']
      }

      const cntrLen = this.regParams.containerList.length
      this.regParams.containerList.push(obj)

      this.fnChangeCntrTerm('polShtmCd')
      this.fnChangeCntrTerm('podShtmCd')

      this.$nextTick(() => {
        // 220713 #356 비활성화 처리
        // if ((cntrLen > 1 && this.regParams.polShtmCd === '03')) {
        //   const frm = document.querySelector('#frm')
        //   frm.querySelector('#lcsPolShtmCd_' + cntrLen).disabled = false
        // } else {
        //   frm.querySelector('#lcsPolShtmCd_' + cntrLen).disabled = true
        // }

        if (this.auth.userId === 'CKLINE') {
          const frm = document.querySelector('#frm')
          frm.querySelector('#lcsPolShtmCd_' + cntrLen).disabled = false
          frm.querySelector('#lcsPodShtmCd_' + cntrLen).disabled = false
        }
      })
    },
    removeBLContainer (idx) {
      this.regParams.containerList.splice(idx, 1)
      if (this.regParams.containerList.length === 0) {
        this.addBLContainer()
      }

      this.fnChangeContSz()
      this.fnPackage()
      this.fnChangeCntrTerm('polShtmCd')
      this.fnChangeCntrTerm('podShtmCd')
    },
    addSealNo (idx) {
      const containerList = this.regParams.containerList[idx]
      let sealList = containerList.sealList
      if (containerList.sealList.length === 5) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.271')) // 최대 5개까지 추가 가능합니다.
        return
      }

      if (sealList === undefined || sealList === null) {
        sealList = []
      }

      sealList.push({ sealNo: '' })

      this.$set(this.regParams.containerList[idx], 'sealList', sealList)
    },
    removeSealNo (parentIdx, idx) {
      const containerList = this.regParams.containerList[parentIdx]
      const sealList = containerList.sealList

      sealList.splice(idx, 1)
    },
    addMarkDesc () {
      const markDescList = this.regParams.markDescList
      const markSeq = markDescList.length === 0 ? 1 : Number(markDescList[markDescList.length - 1].markSeq) + 1
      const obj = {
        markSeq: markSeq,
        mrk: '',
        dscr: ''
      }

      if (markSeq === 1) {
        obj.dscrSub = ''
      }

      markDescList.push(obj)
    },
    removeMarkDesc (idx) {
      this.regParams.markDescList.splice(idx, 1)

      if (this.regParams.markDescList.length === 0) {
        this.addMarkDesc()
        this.initDesc()
      }
    },
    checkIdEmptyCheck (e, idx) {
      const tr = document.querySelector('#expLicense_' + idx)
      const inputEl = tr.getElementsByTagName('input')

      if (e.target.checked) {
        Array.from(inputEl).forEach(el => {
          if (el.type === 'text') {
            //el.disabled = true
          }
        })
      } else {
        Array.from(inputEl).forEach(el => {
          if (el.type === 'text') {
            //el.disabled = false
          }
        })
      }
    },
    async checkElNo (idx) {
      const regParams = this.regParams
      const exportLicense = regParams.exportLicenseList[idx]
      const elNo = exportLicense.elNo

      if (this.$ekmtcCommon.isNotEmpty(elNo)) {
        if (elNo.length < 10) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.194'))
          return
        }

        let duplCnt = 0
        regParams.exportLicenseList.forEach((el, i) => {
          if (i !== idx && el.elNo.trim() === elNo.trim()) {
            duplCnt += 1
          }
        })

        if (duplCnt > 0) {
          this.$ekmtcCommon.alertCallback(this.$t('art.CMATK043'), () => {
            exportLicense.elNo = ''
          })
          return
        }

        const retVal = this.elNoValidation(elNo)

        if (retVal) {
          let successFlag = true
          let result = null
          await trans.fnElNoDuplChk(regParams.blNo, elNo).then(res => {
            if (res.headers.respcode !== 'C0000') {
              successFlag = false
            } else {
              result = res.data
            }
          }).catch(() => {
            successFlag = false
          }).finally(() => {
            if (successFlag) {
              if (result === 'Y') {
                // this.$ekmtcCommon.alertCallback(this.$t('art.CMATK043'), () => {
                //   exportLicense.elNo = ''
                // })
                this.$ekmtcCommon.alertDefault(this.$t('art.CMATK043'))
              }
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
            }
          })
        } else {
          this.$ekmtcCommon.alertCallback(this.$t('art.CMATK016') + this.$t('tem.CMAT006'), () => {
            exportLicense.elNo = ''
            const frm = document.querySelector('#frm')
            frm.querySelector('#elNo_' + idx).focus()
          })
        }
        if (regParams.polCtrCd === 'KR') {
          this.checkElNoTsCgoChk()
          const arrElNo = elNo.split('')
          if (arrElNo.length > 14 && this.$ekmtcCommon.isEmpty(regParams.tsCgoChoYn)) {
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: '환적화물인지 여부 확인 바랍니다.', useConfirmBtn: true })) {
              this.checkTsCargoYes(idx)
            } else {
              this.checkTsCargonNo(idx)
            }
         }
        }
      }
    },
    checkTsCargoYes (idx) {
      this.$ekmtcCommon.alertDefault('수입적하목록 첨부 바랍니다')
      this.regParams.tsCgoChoYn = 'Y'
    },
    async checkTsCargonNo (idx) {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: '수출면장번호는 14자리까지 입력 가능합니다. 까르네(전시품) 진행건인지 확인 바랍니다.', useConfirmBtn: true })) {
        this.checkExhibitYes(idx)
      } else {
        this.checkExhibitNo(idx)
       }
    },
    checkExhibitYes () {
      this.regParams.tsCgoChoYn = 'N'
    },
    checkExhibitNo (idx) {
      this.$ekmtcCommon.alertDefault('면장번호는 14자리까지 입력 가능합니다.')
      const selector = document.querySelector('#elNo_' + idx)
      this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.194'))
      this.regParams.exportLicenseList[idx].elNo = ''
      this.checkElNoTsCgoChk()
    },
    checkElNoTsCgoChk () {
      let tsCgoYn = ''
      this.regParams.exportLicenseList.forEach((el, i) => {
        if (this.$ekmtcCommon.isNotEmpty(el.elNo) && el.elNo.length > 14) {
          tsCgoYn = 'Y'
        }
      })
      this.regParams.tsCgoChoYn = tsCgoYn === '' ? tsCgoYn : this.regParams.tsCgoChoYn
    },
    elNoValidation (elNo) {
      const arrElNo = elNo.split('')

      if (arrElNo.length !== 14 && arrElNo.length !== 15 && arrElNo.length !== 18 && arrElNo.length !== 19) {
        return false
      }

      /*
      if (arrElNo.length === 14) {
        for (let i = 0; i < arrElNo.length; i++) {
          if (isNaN(arrElNo) && i < 12 && i !== 1) {
            return false
          }
        }

        if (!isNaN(arrElNo[12])) {
          if (arrElNo[13] !== 'X' && arrElNo[13] !== 'R') {
            return false
          }
        } else {
          if ((arrElNo[12] + arrElNo[13]) !== 'PX' && (arrElNo[12] + arrElNo[13]) !== 'PR') {
            return false
          }
        }
      }
      */

      return true
    },
    checkCntrNo (idx) {
      if (this.auth !== undefined && this.auth.userId === 'CKLINE') {
        return
      }

      const regParams = this.regParams
      const containerInfo = regParams.containerList[idx]
      const cntrNo = containerInfo.cntrNo
      const frm = document.querySelector('#frm')

      if (cntrNo.length === 0) {
        return
      }

      if (cntrNo.length < 11) {
        this.$ekmtcCommon.alertCallback(this.$t('msg.CSBL200.195'), () => {
          containerInfo.cntrNo = ''
          frm.querySelector('#cntrNo_' + idx).focus()
        })
        return
      }
      if (this.cstCatCd === 'Y' || regParams.shprVanYn === 'Y') {
        const regParams = this.regParams
        const containerInfo = regParams.containerList[idx]
        const cntrNo = containerInfo.cntrNo
        const frm = document.querySelector('#frm')

        const arrCntrPrefix = this.commonCodes.EQ051
        // const arrCntrPrefix = [
        //                       'TDIU', 'HDXU', 'IRSU', 'BANU', 'FURU', 'ALXU', 'XBIU', 'SBAU', 'AGIU', 'KGNU', 'PESU', 'XANU', 'FMCU', 'LCTU', 'AKKU'
        //                     ]
        let iranPrefix = 0
        arrCntrPrefix.forEach(p => {
          if (cntrNo.indexOf(p.cd) > -1) {
            iranPrefix++
          }
        })

        if (iranPrefix > 0) {
          this.$ekmtcCommon.alertCallback(this.$t('msg.CSBL200.196') + ' ' + cntrNo, () => {
            containerInfo.cntrNo = ''
            frm.querySelector('#cntrNo_' + idx).focus()
          })
          return
        }

        this.fnCntrNoCheck(idx)
      } else {
        const dlyPlcCd = regParams.dlyPlcCd

        if ((dlyPlcCd === 'BJO' || dlyPlcCd === 'LUU' || dlyPlcCd === 'ROQ') &&
          (cntrNo.startsWith('MAXU') || cntrNo.startsWith('INNU') || cntrNo.startsWith('IRNU') || cntrNo.startsWith('RALU'))) {
          this.$ekmtcCommon.alertCallback(this.$t('msg.CSBL200.160'), () => {
            containerInfo.cntrNo = ''
            frm.querySelector('#cntrNo_' + idx).focus()
          })
        }

        let succFlag = true
        let result = null
        transCommon.findCntrInfo(cntrNo).then(res => {
          if (res.headers.respcode === 'C0000') {
            result = res.data
          } else {
            succFlag = false
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (succFlag && result !== null && result !== undefined) {
            const cntrSzCd = result.cntrSzCd
            const cntrTypCd = result.cntrTypCd

            if (this.$ekmtcCommon.isNotEmpty(cntrSzCd)) {
              containerInfo.cntrSzCd = cntrSzCd
              containerInfo.cntrTypCd = cntrTypCd

              if (cntrTypCd === 'RF' || cntrTypCd === 'RH') {
                this.rfInfoPop(idx)
              }
            } else {
              this.fnResetContainer(containerInfo, idx)
            }

            this.fnChangeContSz()
            this.fnCntrNoCheck(idx)
          } else {
            this.fnResetContainer(containerInfo, idx)
          }
        })
      }
    },
    async checkCntrNoInMy (srNo, cntrNo, blNo) {
      let result = true
      await trans.checkCntrNoInMy(srNo, cntrNo, blNo).then(res => {
        if (res.data === 'N') {
          this.$ekmtcCommon.alertDefault('The Container number not assigned to this booking. Please recheck')
          result = false
        }
      })

      return result
    },
    fnResetContainer (containerInfo, idx) {
      const frm = document.querySelector('#frm')
      this.$ekmtcCommon.alertDefault(this.$t('art.CMATK088') + this.$t('tem.CMAT006'))
      containerInfo.cntrSzCd = ''
      containerInfo.cntrTypCd = ''
      containerInfo.cntrNo = ''
      frm.querySelector('#cntrNo_' + idx).focus()
    },
    fnChangeContSz () {
      const dscr = this.regParams.markDescList[0].dscr
      let flagChange = 'N'
      let cn2 = ''
      let cn4 = ''
      let cn5 = ''

      let sz20 = 0
      let sz40 = 0
      let sz45 = 0
      let retValue = ''

      const arrDscr = dscr.split('\n')

      if (arrDscr.length > 0) {
        const szInfo = arrDscr[0]
        if (szInfo.indexOf('X20\'') > -1) {
          cn2 = szInfo.split('X20\'')[1]
          if (arrDscr[1].indexOf('X40\'') > -1) {
            cn4 = dscr.split('\n')[1].split('X40\'')[1]
          }
        } else if (szInfo.indexOf('X40\'') > -1) {
          cn4 = szInfo.split('X40\'')[1]
        } else if (szInfo.indexOf('X45\'') > -1) {
          cn5 = szInfo.split('X45\'')[1]
        }
      }

      if (!cn2) cn2 = ''
      if (!cn4) cn4 = ''
      if (!cn5) cn5 = ''

      const regParams = this.regParams
      const containerList = regParams.containerList

      containerList.forEach(c => {
        if (c.cntrSzCd === '20') {
          sz20++
        } else if (c.cntrSzCd === '40') {
          sz40++
        } else if (c.cntrSzCd === '45') {
          sz45++
        }
      })

      if (sz20 > 0) {
        retValue += sz20 + 'X20\'' + cn2 + '\n'
      }

      if (sz40 > 0) {
        retValue += sz40 + 'X40\'' + cn4 + '\n'
      }

      if (sz45 > 0) {
        retValue += sz45 + 'X45\'' + cn5 + '\n'
      }

      let dd = 0
      let kk = ''
      let cc = ''
      let jj = ''
      arrDscr.forEach((d, i) => {
        if ((d.indexOf('X20') !== -1 || d.indexOf('X40') !== -1 || d.indexOf('X45') !== -1)) {
          ++dd
        } else {
          if (parseInt(d.substring(0, 1).charAt(0)) >= 0 && parseInt(d.substring(0, 1).charAt(0)) <= 9) {
            // Package 수량 체크는 2번째 라인까지만 확인
            if (i < 2 && flagChange === 'N') {
              kk = dscr.replace(d, retValue)
              cc += d
              flagChange = 'Y'
            } else {
              jj += d
            }
          } else {
            jj += d
          }

          if (i !== (d.length - 1)) {
            jj += '\n'
          }
        }
      })

      if (arrDscr.length === dd) {
        kk = retValue + cc
      } else {
        if (this.$ekmtcCommon.isNotEmpty(retValue)) {
          kk = retValue + cc + jj
        } else {
          kk = retValue + jj
        }
      }

      regParams.markDescList[0].dscr = kk
    },
    async fnCntrNoCheck (idx) {
      const regParams = this.regParams
      const containerInfo = regParams.containerList[idx]
      const cntrNo = containerInfo.cntrNo
      const params = {
        cntrNo: cntrNo,
        bkgNo: regParams.bkgNo
      }

      let checkMyCntrNo = true
      //SR 1362717 Request Container Error Message on ICC and e-kmtc Website
      if (regParams.polCtrCd === 'MY' && 'PKG, PKW, PGU, PEN'.indexOf(regParams.polPortCd) > -1) {
        checkMyCntrNo = await this.checkCntrNoInMy('', cntrNo, regParams.blNo)
      }

      let succFlag = true
      let result = null
      await transCommon.searchCtrCount(params).then(res => {
        if (res.headers.respcode === 'C0000') {
          result = res.data
        } else {
          succFlag = false
        }
      }).catch(() => {
        succFlag = false
      }).finally(() => {
        if (succFlag && result !== null && result !== undefined) {
          const cntrOffYn = result.cntrOffYn
          const ctrCd = result.ctrCd
          const polCtrCd = regParams.polCtrCd
          if (cntrOffYn === 'Y') {
            // 컨테이너 Number를 확인하세요. <br>Container No. XXXX
            const failMsg = this.$t('msg.CSBL200.195') + '<br>' + this.$t('msg.CSBL200.197') + cntrNo
            this.$ekmtcCommon.alertCallback(failMsg, () => {
              containerInfo.cntrNo = ''
              containerInfo.cntrSzCd = ''
              containerInfo.cntrTypCd = ''
              frm.querySelector('#cntrNo_' + idx).focus()
            })
          } else {
            if (ctrCd === 'N') {
              containerInfo.cntrCheck = 'Y'
              return
            }
            if (this.$ekmtcCommon.isNotEmpty(ctrCd)) {
              if (polCtrCd === ctrCd) {
                containerInfo.cntrCheck = 'Y'
              } else {
                containerInfo.cntrCheck = 'N'
                if (ctrCd === 'HK' && regParams.bkgCn4Yn === 'Y') {
                  containerInfo.cntrCheck = 'Y'
                }
              }
            } else {
              containerInfo.cntrCheck = 'Y'
            }
          }
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
        }
      })
    },
    fnCntrPkgAutoSet () {
      const regParams = this.regParams
      const frm = document.querySelector('#frm')
      const totalPkgQty = regParams.pkgQty
      const totalWt = regParams.grsWt
      const totalCbm = regParams.grsCbm

      let cntrSz = 0
      let cntr20hop = 0
      let cntr40hop = 0
      let count = 0

      let wtSum = 0
      let cbmSum = 0

      regParams.containerList.forEach((c, idx) => {
        if (c.cntrSzCd === '20') {
          cntrSz += 1
          cntr20hop++
        } else if (c.cntrSzCd === '40' || c.cntrSzCd === '45') {
          cntrSz += 1.3
          cntr40hop++
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK088') + this.$t('tem.CMAT002'))
          frm.querySelector('#cntrSzCd_' + idx).focus()
          return
        }

        count++
      })

      let cCntrSz20Sum = Math.round(totalPkgQty * 1 / cntrSz * cntr20hop)
      let cCntrSz40Sum = Math.round(totalPkgQty * 1 / cntrSz * cntr40hop)
      let lsCntr20Cnt = 0
      let lsCntr40Cnt = 0

      if (cCntrSz20Sum + cCntrSz40Sum !== totalPkgQty) {
        if (cCntrSz20Sum > 0 && cCntrSz40Sum > 0) {
          cCntrSz40Sum = totalPkgQty - cCntrSz20Sum
        } else {
          if (cCntrSz20Sum > 0) {
            cCntrSz20Sum = totalPkgQty
          }

          if (cCntrSz40Sum > 0) {
            cCntrSz40Sum = totalPkgQty
          }
        }
      }

      if (count === 1) {
        const containerInfo = regParams.containerList[0]

        containerInfo.wt = totalWt
        containerInfo.pkgQty = totalPkgQty
        if (this.lang === 'JPN' || this.regParams.polCtrCd === 'PH' || this.regParams.polCtrCd === 'VN' || this.regParams.podCtrCd === 'BH') {
          containerInfo.meaCbm = totalCbm
        }
      } else {
        regParams.containerList.forEach(c => {
          if (c.cntrSzCd === '20') {
            //SR 1428093 콜럼보 항은 소숫점 2자리로 반올림처리
            if (this.regParams.podPortCd === 'CMB') {
              c.wt = (Math.trunc(totalWt * (1 / cntrSz) * 100)) / 100
            } else {
              c.wt = (Math.trunc(totalWt * (1 / cntrSz) * 1000)) / 1000
            }
            wtSum += Number(c.wt)

            if (this.lang === 'JPN' || this.regParams.polCtrCd === 'PH' || this.regParams.polCtrCd === 'VN' || this.regParams.podCtrCd === 'BH') {
              c.meaCbm = (Math.round(totalCbm * (1 / cntrSz) * 1000)) / 1000
              cbmSum += Number(c.meaCbm)
            }

            lsCntr20Cnt++
            if ((cCntrSz20Sum % cntr20hop) < lsCntr20Cnt) {
              c.pkgQty = Math.floor(Number(cCntrSz20Sum / cntr20hop))
            } else {
              c.pkgQty = Math.floor(Number(cCntrSz20Sum / cntr20hop) + 1)
            }
          } else {
            //SR 1428093 콜럼보 항은 소숫점 2자리로 반올림처리
            if (this.regParams.podPortCd === 'CMB') {
              c.wt = (Math.trunc(totalWt * (1.3 / cntrSz) * 100)) / 100
            } else {
              c.wt = (Math.trunc(totalWt * (1.3 / cntrSz) * 1000)) / 1000
            }
            wtSum += Number(c.wt)

            if (this.lang === 'JPN' || this.regParams.polCtrCd === 'PH' || this.regParams.polCtrCd === 'VN' || this.regParams.podCtrCd === 'BH') {
              c.meaCbm = (Math.round(totalCbm * (1.3 / cntrSz) * 1000)) / 1000
              cbmSum += Number(c.meaCbm)
            }

            lsCntr40Cnt++
            if ((cCntrSz40Sum % cntr40hop) < lsCntr40Cnt) {
              c.pkgQty = Math.floor(Number(cCntrSz40Sum / cntr40hop))
            } else {
              c.pkgQty = Math.floor(Number(cCntrSz40Sum / cntr40hop) + 1)
            }
          }
        })

        let modWt = 0

        if (this.regParams.podPortCd === 'CMB') {
          modWt = (totalWt - wtSum).toFixed(2)

          let modCnt = Math.ceil((modWt / 0.01) / regParams.containerList.length)

          for (let i = 0; i < modCnt; i++) {
            regParams.containerList.forEach(c => {
              if (modWt > 0) {
                c.wt = c.wt + 0.01
                modWt = modWt - 0.01
              }
            })
          }
        } else {
          modWt = (totalWt - wtSum).toFixed(3)

          let modCnt = Math.ceil((modWt / 0.001) / regParams.containerList.length)

          for (let i = 0; i < modCnt; i++) {
            regParams.containerList.forEach(c => {
              if (modWt > 0) {
                c.wt = c.wt + 0.001
                modWt = modWt - 0.001
              }
            })
          }
        }
      }
    },
    fnSaySet () {
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd

      let flag = ''
      let cnt = 0
      let pkgCd = ''
      let arrCntrNo = []
      if (polShtmCd === '01') {
        flag = 'CNTR'

        regParams.containerList.forEach((c, i) => {
          if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
            cnt++

            arrCntrNo.push(c.cntrNo)
          }
        })
      } else if (polShtmCd === '02') {
        flag = 'QTY'
        cnt = regParams.pkgQty

        if (this.$ekmtcCommon.isNotEmpty(regParams.pkgCd)) {
          const pkgCdList = this.commonCodes['01130']
          pkgCd = pkgCdList.filter(vo => vo.cd === regParams.pkgCd)[0].cdNm
        }

        if (this.$ekmtcCommon.isEmpty(pkgCd) || this.$ekmtcCommon.isEmpty(cnt)) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.270'))
          return
        }
      }

      const params = {
        blNo: regParams.blNo,
        flag: flag,
        cnt: cnt,
        arrCntrNo: arrCntrNo,
        pkgCd: pkgCd
      }

      trans.fnSayCheck(params).then(res => {
        const result = res.data
        // console.log('fnSayCheck res @@@@@ ', res)

        if (result !== undefined) {
          this.$set(this.regParams, 'blPrtSntcCont', result)
        }
      })

      if (cnt === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('js.working.001'))
      }
    },
    fnSaySetNew (val) {
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd

      let flag = ''
      let cnt = 0
      let pkgCd = ''
      let arrCntrNo = []
      if (val > 999) {
          return
        }
      if (polShtmCd === '01') {
        flag = 'CNTR'
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', val)
        for (let i = 0; i < val; i++) {
          cnt++
        }
      } else if (polShtmCd === '02') {
        flag = 'QTY'
        cnt = val

        if (this.$ekmtcCommon.isNotEmpty(regParams.pkgCd)) {
          const pkgCdList = this.commonCodes['01130']
          pkgCd = pkgCdList.filter(vo => vo.cd === regParams.pkgCd)[0].cdNm
        }

        if (this.$ekmtcCommon.isEmpty(pkgCd) || this.$ekmtcCommon.isEmpty(cnt)) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.270'))
          return
        }
      }

      const params = {
        blNo: regParams.blNo,
        flag: flag,
        cnt: cnt,
        arrCntrNo: arrCntrNo,
        pkgCd: pkgCd
      }

      trans.fnSayCheck(params).then(res => {
        const result = res.data
        // console.log('fnSayCheck res @@@@@ ', res)

        if (result !== undefined) {
          this.$set(this.regParams, 'blPrtSntcCont', result)
        }
      })

      if (cnt === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('js.working.001'))
      }
    },
    fnSayTab () {
      let vNormal = /[^0-9.]/g
      let val = document.querySelector('#blPrtSntcCont').value
      if (!vNormal.test(val) && !isNaN(parseInt(val))) {
        this.fnSaySetNew(val)
      }
    },
    fnSayQtyChk () {
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd
      let cntrCnt = 0
      let sayCnt = 0
      if (polShtmCd === '01') {
        regParams.containerList.forEach((c, i) => {
          if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
            cntrCnt++
          }
        })
      } else if (polShtmCd === '02') {
        cntrCnt = regParams.pkgQty
      }
      sayCnt = document.querySelector('#blPrtSntcCont').value.replaceAll(/[^0-9]/g, '')
      if (Number(cntrCnt) === Number(sayCnt) || cntrCnt === 0) {
        return true
      } else {
        return false
      }
    },
    addrStrEnter (e) {
      if (e.keyCode === 13) {
        this.searchAgentAddr()
      }
    },
    searchAgentAddr () {
      this.popParams = {}
      if (this.$ekmtcCommon.isNotEmpty(this.addrStr)) {
        const ctrCd = this.addrStr.split(':')[0]
        const plcCd = this.addrStr.split(':')[1]
        this.popParams.addrCtrCd = ctrCd
        this.popParams.addrPlcCd = plcCd
      }

      this.selectFunc = ''
      this.openPopup('bl-agent-address-pop')
    },
    selectAgentAddr (vo) {
      if (vo !== undefined) {
        const markDescList = this.regParams.markDescList

        markDescList[0].mrk = vo.sntcCont.trim()
        this.closePopup()
      }
    },
    onBoardDatePop () {
      this.popParams = {
        blNo: this.regParams.blNo,
        bkgNo: this.regParams.bkgNo,
        flag: 'BL'
      }

      this.selectFunc = this.setOnBoardDate
      this.openPopup('bl-on-board-date-pop')
    },
    setOnBoardDate (date) {
      this.regParams.obrdDt = date
      this.regParams.issDt = date
      this.regParams.obrdDtTemp = this.$ekmtcCommon.changeDatePattern(date)

      this.closePopup()
    },
    changeCntrType (vo, idx) { // 컨테이너 Type 변경에 따른 처리
      // Tank container 여부에 따라 화주컨테이너여부 체크
      if (vo.cntrTypCd === 'TK') {
        this.$set(vo, 'shprVanYn', 'Y') // 화주컨테이너여부
        //this.$set(vo, 'feUnitCd', 'E') // Empty(E:Empty) (ASIS로직은 있으나 작동이 안됨)
      } else {
        this.$set(vo, 'shprVanYn', 'N') // 화주컨테이너여부
        this.$set(vo, 'feUnitCd', 'F') // Empty(F:Full)
      }

      this.cntrTypeInfoPop(vo.cntrTypCd, idx)
    },
    cntrTypeInfoPop (cntrTypCd, idx) {
      if (cntrTypCd === 'RF' || cntrTypCd === 'RH') {
        this.rfInfoPop(idx)
      }
    },
    changeShprVanYn (vo, idx) { // 화주컨테이너 여부에 따른 처리
      // 화주컨테이너여부 체크 해제시 Empty 체크 해제
      if (vo.shprVanYn !== 'Y' && vo.feUnitCd !== 'F') {
        this.$set(vo, 'feUnitCd', 'F') // Empty(F:Full)
      }

      this.shprVanValidation(vo)
    },
    cgoTypeInfoPop (cntrTypCd, idx) {
      const containerInfo = this.regParams.containerList[idx]

      if (this.$ekmtcCommon.isEmpty(containerInfo.cntrNo) || containerInfo.cntrNo.length < 10) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK088') + ' ' + this.$t('tem.CMAT002'))
        containerInfo.cgoTypCd = ''
        return
      }

      if (cntrTypCd === '02') {
        this.oogInfoPop(idx)
      }
    },
    async rfInfoPop (idx) {
      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK359'), useConfirmBtn: true })) {
        return
      }
      const containerInfo = this.regParams.containerList[idx]

      this.popParams = {
        blNo: this.regParams.blNo,
        bkgNo: this.regParams.bkgNo,
        modifyFlag: 'U',
        idx: idx,
        rfTmprUnitCd: containerInfo.rfTmprUnitCd || '',
        rfSetupTmpr: containerInfo.rfSetupTmpr || '',
        rfSlecUseYn: containerInfo.rfSlecUseYn || '',
        cntrRfStsCd: containerInfo.cntrRfStsCd || '',
        polCtrCd: this.regParams.polCtrCd
      }

      console.log('popParams {}', this.popParams)

      this.selectFunc = this.setRFInfo
      this.openPopup('bl-container-rf-pop')
    },
    setRFInfo (vo) {
      if (vo !== undefined) {
        const containerInfo = this.regParams.containerList[vo.selectIdx]

        containerInfo.cntrRfStsCd = vo.cntrRfStsCd
        containerInfo.rfSetupTmpr = vo.rfSetupTmpr
        containerInfo.rfTmprUnitCd = vo.rfTmprUnitCd
        containerInfo.rfSlecUseYn = vo.rfSlecUseYn
      }
    },
    hzInfoPop (idx) {
      const containerList = this.regParams.containerList[idx]

      this.popParams = {
        bkgNo: this.regParams.bkgNo
      }

      this.selectFunc = ''
      this.openPopup('bl-container-dg-pop')
    },
    oogInfoPop (idx) {
      const containerList = this.regParams.containerList[idx]

      this.popParams = {
        blNo: this.regParams.srNo,
        bkgNo: this.regParams.bkgNo,
        modifyFlag: this.saveFlag === 'SR_ADD' ? 'I' : 'U',
        idx: idx
      }

      this.selectFunc = this.setOOGInfo
      this.openPopup('bl-container-oog-pop')
    },
    setOOGInfo (vo) {
      if (vo !== undefined) {
        const containerInfo = this.regParams.containerList[vo.selectIdx]

        containerInfo.ovrHght = vo.ovrHght
        containerInfo.ovrFwdLen = vo.ovrFwdLen
        containerInfo.ovrBwrdLen = vo.ovrBwrdLen
        containerInfo.ovrPortLen = vo.ovrPortLen
        containerInfo.ovrStbdLen = vo.ovrStbdLen
      }
    },
    openPopup (compNm) {
      this.customComponent = null
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.bl_area > .popup_dim')
    },
    closePopup () {
      this.customComponent = null
      this.selectFunc = null
      this.$ekmtcCommon.layerClose('.bl_area > .popup_dim')
    },
    isMultiPolHsCode () {
      let isBtnShow = false

      const regParams = this.regParams
      if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') &&
          (regParams.polCtrCd === 'ID' || regParams.polCtrCd === 'IN')) {
        isBtnShow = true
      }

      this.isPolHsMulti = isBtnShow
    },
    isMultiPodHsCode () {
      let isBtnShow = false

      const regParams = this.regParams

      if (regParams.dlyCtrCd === 'IR' ||
          (
            (regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') &&
            (regParams.podCtrCd === 'ID' || regParams.podCtrCd === 'IN')
          )
        ) {
          isBtnShow = true
      }

      this.isPodHsMulti = isBtnShow
    },
    hsCdMultiPop (catCd) {
      // if (this.originParams.containerList.length === 0) {
      //   this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.224'))
      //   return
      // }
      let cntrNoVal = true
      this.regParams.containerList.forEach(c => {
        if (this.$ekmtcCommon.isEmpty(c.cntrNo)) {
          cntrNoVal = false
        }
      })
      if (!cntrNoVal) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.224'))
        return
      }

      const regParams = this.regParams
      let popParams = {}
      let openPopName = ''
      this.selectFunc = ''
      // DELIVERY 국가 이란
      if (catCd === '02' && regParams.dlyCtrCd === 'IR') {
        popParams = {
          bkgNo: regParams.bkgNo
        }

        openPopName = 'hs-code-ir-pop'
      } else {
        if (Number(this.saveCntrCnt) === 0) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.hsCdPop.006'))
          return
        }
        // 출발지 국가 인도네시아 또는 인도
        // 도착지 국가 인도네시아 또는 인도
        const ctrCd = catCd === '01' ? regParams.polCtrCd : regParams.podCtrCd
        popParams = {
          blNo: regParams.blNo,
          ldCatCd: catCd,
          uiId: 'BL',
          ctrCd: ctrCd
        }

        if (ctrCd === 'IN') {
          popParams.pkgCd = regParams.pkgCd
          popParams.pkgQty = regParams.pkgQty
          popParams.containerList = this.regParams.containerList
          this.selectFunc = this.setHsQty
        }

        openPopName = ctrCd === 'ID' ? 'hs-code-id-pop' : 'hs-code-in-pop'
      }

      this.popParams = popParams
      this.openPopup(openPopName)
    },
    setHsQty (qty) {
      if (this.regParams.podCtrCd === 'IN') {
        this.regParams.hsQty = qty
      }
    },
    fnNoChange (e, idx) {
      const containerInfo = this.regParams.containerList[idx]

      if (this.cstCatCd === 'Y' || this.regParams.shprVanYn === 'Y') {
        return
      }

      if (this.$ekmtcCommon.isNotEmpty(containerInfo.cntrNo)) {
        e.target.blur()
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK356'))
      }
    },
    fnCgoPop () {
      this.popParams = {
        blNo: this.regParams.blNo,
        bkgNo: this.regParams.bkgNo,
        modifyFlag: this.saveFlag === 'SR_ADD' ? 'I' : 'U',
        type: 'BL'
      }

      this.selectFunc = ''
      this.openPopup('bl-container-cgo-pop')
    },
    async checkHsCd (e, ldCatCd) {
      const regParams = this.regParams
      const checkType = ldCatCd === 'POD' ? this.hsChkParams.podCtrCd : this.hsChkParams.polCtrCd

      let val = e.replace(/,/g, '')

      if (val === '') {
        if (ldCatCd === 'POL') {
          this.$nextTick(() => {
            this.hsChkParams.hscdPolErrorMessage = ''
          })
          this.hsChkParams.polSuccYn = 'Y'
        } else {
          this.$nextTick(() => {
            this.hsChkParams.hscdPodErrorMessage = ''
          })
          this.hsChkParams.podSuccYn = 'Y'
        }
        return
      }

      let isOk = true
      let message = ''
      const regex = /[^0-9]/g

      if (checkType === 'IR') {
        isOk = await this.fnIranCheck(ldCatCd)
        message = this.$t('msg.CSBK100.117') // 금지된 HS Code입니다."
      } else {
        message = this.$t('msg.CSBL200.175') // 선적 미확정 부킹에 대하여 상태값이 미승인으로 변경될 수 있으니 적극적인 협조 부탁드립니다.
        if (regex.test(val) || isNaN(parseInt(val))) {
          isOk = false
        }

        if (checkType === 'ID' || checkType === 'IR') {
          if (val.length !== 4 && val.length !== 6 && val.length !== 8) {
            isOk = false
          }
        } else if (ldCatCd === 'POD' && checkType === 'VN') {
          if (regParams.wstCgoYn === 'Y') {
            if (val.length !== 8) {
              isOk = false
            }
          } else if (val.length !== 4 && val.length !== 6 && val.length !== 8) {
            isOk = false
          }
        } else if (ldCatCd === 'POD' && checkType === 'SHA') {
          if (val.length !== 10) {
            isOk = false
          }
        } else {
          if (val.length < 6) {
            isOk = false
          }
        }

        if (isOk) {
          isOk = await this.fnHsCdValidation(ldCatCd)
        }
      }

      if (ldCatCd === 'POD') {
        this.setIndiaInfo()
      }

      if (!isOk) {
        if (ldCatCd === 'POL') {
          this.$nextTick(() => {
            this.hsChkParams.hscdPolErrorMessage = message
          })
          this.hsChkParams.polSuccYn = 'N'
        } else {
          this.$nextTick(() => {
            this.hsChkParams.hscdPodErrorMessage = message
          })
          this.hsChkParams.podSuccYn = 'N'
        }
      } else {
        if (ldCatCd === 'POL') {
          this.$nextTick(() => {
            this.hsChkParams.hscdPolErrorMessage = ''
          })
          this.hsChkParams.polSuccYn = 'Y'
        } else {
          this.$nextTick(() => {
            this.hsChkParams.hscdPodErrorMessage = ''
          })
          this.hsChkParams.podSuccYn = 'Y'
        }
      }
    },
    async fnIranCheck (ldCatCd) {
      let isOk = true
      const hsCd = ldCatCd === 'POL' ? this.regParams.hsCd : this.regParams.podHsCd
      const params = {
        kndCd: 3,
        hsCd: hsCd
      }

      await transCommon.getIranCheck(params).then(res => {
        const result = res.data

        if (res.headers.respcode === 'C0000' && result !== undefined) {
          if (result === 'Y') {
            isOk = false
          }
        }
      })

      return isOk
    },
    async fnHsCdValidation (ldCatCd) {
      let isOk = true
      let hsCd = ldCatCd === 'POL' ? this.regParams.hsCd : this.regParams.podHsCd
      let checkType = ldCatCd === 'POL' ? this.hsChkParams.polCtrCd : this.hsChkParams.podCtrCd

      let polCtrCd = ''
      let podCtrCd = ''

      if (checkType !== 'SHA' && checkType !== 'VN' && checkType !== 'IN') {
        checkType = ldCatCd // POD or POL
        polCtrCd = this.hsChkParams.polCtrCd
        podCtrCd = this.hsChkParams.podCtrCd
      }

      await transCommon.getHscdValidation({
        hsCd: hsCd,
        hsFlag: checkType,
        polCtrCd: polCtrCd,
        podCtrCd: podCtrCd,
        hsNmSearchYn: 'Y'
      }).then(res => {
        const result = res.data
        this.$set(this.regParams, 'podHsCdNm', result.hsNm)
        if (result.returnFlag === 'N') {
          isOk = false
        }
      })

      return isOk
    },
    async inputValidation () {
      const TH = this
      const type = TH.saveType
      const regParams = TH.regParams
      let isOk = true

      if (!this.checkValidationInfoAll()) {
        isOk = false
      }

      if (!this.checkValidationEmailAll()) {
        isOk = false
      }

      if (!this.checkValidationHsCd()) {
        isOk = false
      }

      if (!this.checkValidationELAll()) {
        isOk = false
        if (this.isIndonesia()) {
          const message = 'Input the Licence No.\n\n(PEB no. is compulsory item for the Indonesian Export S/R confirm. Please fill in the Blank spaces of the PEB no.'
          this.$ekmtcCommon.alertDefault(message)
        }
      }

      if (!this.checkValidationCntrAll()) {
        isOk = false
      }

      // 중국인 경우만 say 필수값 체크
      if (this.checkValidationCntrAll() && (this.regParams.blPrtSntcCont === undefined || this.regParams.blPrtSntcCont === '')) {
        this.checkValidationInfo('blPrtSntcCont')
        this.sayChk = false
        isOk = false
      }
      if (!this.checkValidationMarkDesc()) {
        isOk = false
      }

      if (regParams.dlyCtrCd === 'IR') {
        if (!await this.checkValidationIranHsCdAll()) {
          isOk = false
        }
      }

      return isOk
    },
    checkValidationMarkDesc () {
      let isOk = true
      const regParams = this.regParams
      const markDescList = regParams.markDescList
      const frm = document.querySelector('#frm')
      const markDescEl = frm.querySelector('.tbl_mark')

      this.$ekmtcCommon.hideErrorTooltipAll(markDescEl)

      if (markDescList.length > 1) {
        markDescList.forEach((vo, idx) => {
          if (this.$ekmtcCommon.isEmpty(vo.mrk) && this.$ekmtcCommon.isEmpty(vo.dscr)) {
            const mrkSelector = frm.querySelector('#mrk_' + idx)
            const dscrSelector = frm.querySelector('#dscr_' + idx)
            this.$ekmtcCommon.showErrorTooltip(mrkSelector, this.$t('ark.CMATK353'))
            this.$ekmtcCommon.showErrorTooltip(dscrSelector, this.$t('ark.CMATK353'))

            isOk = false
          }
        })
      }

      return isOk
    },
    checkValidationRFCntr () {
      let isOk = true
      const TH = this
      const regParams = TH.regParams
      const containerList = regParams.containerList
      const frm = document.querySelector('#frm')

      containerList.forEach((cvo, idx) => {
        const selector = frm.querySelector('#cntrTypCd_' + idx)
        this.$ekmtcCommon.hideErrorTooltip(selector)
        if ((cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') && this.$ekmtcCommon.isEmpty(cvo.rfTmprUnitCd)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK347'))
          isOk = false
        }
      })

      return isOk
    },
    async validation () {
      let isOk = true
      const TH = this
      const regParams = TH.regParams

      const cgoTypList = regParams.cgoTypList
      const containerList = regParams.containerList

      await commons.getServerTime().then(res => {
        this.serverYear = res.data.substring(0, 4)
        this.serverMonth = res.data.substring(4, 6)
        this.serverDay = res.data.substring(6, 8)
      })

      if (!await this.inputValidation()) {
        isOk = false
      }

      // JamSin if (this.lang !== 'CHN') {
        if (String(regParams.dgcount) !== '0' && String(regParams.srdgcount) === '0') {
          containerList.forEach(c => {
            if (this.$ekmtcCommon.isEmpty(c.cgoTypCd) && this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
              c.cgoCheckYn = 'N'
            } else {
              c.cgoCheckYn = 'Y'
            }
          })
        }
      //}

      const rfBkYn = containerList.filter(vo => this.$ekmtcCommon.isNotEmpty(vo.rfTmprUnitCd)).length > 0 ? 'Y' : 'N'
      if (rfBkYn === 'Y') {
        if (!this.checkValidationRFCntr()) {
          isOk = false
        }
      }

      return isOk
    },
    fnDescPkgCheck () {
      const TH = this
      const regParams = TH.regParams
      const pkgQty = regParams.pkgQty
      const pkgCdList = this.commonCodes['01130']
      const pkgCdInfo = pkgCdList.filter(vo => vo.cd === regParams.pkgCd)
      const dscr = regParams.markDescList[0].dscr
      const dscrLine = dscr.split('\n')

      let pkgText = ''
      let pkgHop = ''
      let pkgStrCount = ''
      let pkgName = ''
      let pkgCount = ''
      let pkgDescName = ''
      let pkgRtn = ''
      let pkgStr = ''

      if (pkgCdInfo !== undefined && pkgCdInfo.length > 0) {
        pkgText = pkgCdInfo[0].cdNm
      }

      pkgHop = pkgQty + pkgText
      pkgHop = pkgHop.split(' ').join('').trim()

      for (let i = 0; i < pkgHop.length; i++) {
        if (parseInt(pkgHop.charAt(i)) >= 0 && parseInt(pkgHop.charAt(i)) <= 9) {
          pkgStrCount += pkgHop.charAt(i)
        } else {
          pkgName += pkgHop.charAt(i)
        }
      }

      for (let i = 0; i < dscrLine.length; i++) {
        let cntrStr = ''

        for (let j = 0; j < dscrLine[i].length; j++) {
          cntrStr += dscrLine[i].charAt(j).split(' ').join('')
        }

        if (cntrStr.indexOf('X20\'') > -1 || cntrStr.indexOf('X20`') > -1 || cntrStr.indexOf('X40\'') > -1 || cntrStr.indexOf('X40`') > -1) {
          if (!dscrLine[i + 1]) {
            continue
          }

          if (pkgRtn === 'A') {
            continue
          }

          pkgStr = dscrLine[i + 1]
          pkgStr = pkgStr.split(' ').join('').trim()

          pkgCount = ''
          pkgDescName = ''

          for (let j = 0; j < pkgStr.length; j++) {
            if (parseInt(pkgStr.charAt(j)) >= 0 && parseInt(pkgStr.charAt(j)) <= 9) {
              pkgCount += pkgStr.charAt(j)
            } else {
              pkgDescName += pkgStr.charAt(j)
            }
          }

          if (pkgStrCount === pkgCount && pkgName === pkgDescName) {
            pkgRtn = 'A'
          } else if (pkgStrCount === pkgCount && pkgName !== pkgDescName) {
            pkgRtn = 'B'
          } else {
            pkgRtn = 'C'
          }
        }
      }

      return pkgRtn
    },
    fnDescPkgChgCheck () {
      const regParams = this.regParams
      const dscrLineOld = this.originParams.markDescList[0].dscr.split('\n')
      const dscrLineNew = regParams.markDescList[0].dscr.split('\n')

      const pkgQtyOld = this.originParams.pkgQty
      const pkgCdOld = this.originParams.pkgCd

      const pkgQtyNew = regParams.pkgQty
      const pkgCdNew = regParams.pkgCd

      let pkgStrOld = ''
      let pkgCountOld = ''
      let pkgDescNameOld = ''
      let pkgStrNew = ''
      let pkgCountNew = ''
      let pkgDescNameNew = ''
      let chgYn = ''

      if (pkgQtyOld === pkgQtyNew && pkgCdOld === pkgCdNew) {
        for (let i = 0; i < dscrLineOld.length; i++) {
          let cntrStr = ''

          for (let j = 0; j < dscrLineOld[i].length; j++) {
            cntrStr += dscrLineOld[i].charAt(j).split(' ').join('')
          }

          if (cntrStr.indexOf('X20\'') > -1 || cntrStr.indexOf('X20`') > -1 || cntrStr.indexOf('X40\'') > -1 || cntrStr.indexOf('X40`') > -1) {
            if (dscrLineNew[i + 1]) {
            pkgStrNew = dscrLineNew[i + 1]
            pkgStrNew = pkgStrNew.split(' ').join('').trim()
            }
            for (let j = 0; j < pkgStrOld.length; j++) {
              if (parseInt(pkgStrOld.charAt(j)) >= 0 && parseInt(pkgStrOld.charAt(j)) <= 9) {
                pkgCountOld += pkgStrOld.charAt(j)
              } else {
                pkgDescNameOld += pkgStrOld.charAt(j)
              }
            }
          }
        }

        for (let i = 0; i < dscrLineNew.length; i++) {
          let cntrStr = ''

          for (let j = 0; j < dscrLineNew[i].length; j++) {
            cntrStr += dscrLineNew[i].charAt(j).split(' ').join('')
          }

          if (cntrStr.indexOf('X20\'') > -1 || cntrStr.indexOf('X20`') > -1 || cntrStr.indexOf('X40\'') > -1 || cntrStr.indexOf('X40`') > -1) {
            if (dscrLineNew[i + 1]) {
            pkgStrNew = dscrLineNew[i + 1]
            pkgStrNew = pkgStrNew.split(' ').join('').trim()
            }
            for (let j = 0; j < pkgStrNew.length; j++) {
              if (parseInt(pkgStrNew.charAt(j)) >= 0 && parseInt(pkgStrNew.charAt(j)) <= 9) {
                pkgCountNew += pkgStrNew.charAt(j)
              } else {
                pkgDescNameNew += pkgStrNew.charAt(j)
              }
            }
          }
        }

        if (pkgCountOld === pkgCountNew && pkgDescNameOld === pkgDescNameNew) {
          chgYn = 'N'
        } else if (pkgCountOld === pkgCountNew && pkgDescNameOld !== pkgDescNameNew) {
          chgYn = 'Y'
        } else {
          chgYn = 'Y'
        }
      } else {
        chgYn = 'Y'
      }

      return chgYn
    },
    async updateBL () {
      const TH = this
      const regParams = TH.regParams
      if (String(regParams.recount) !== '0') {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK339'))
        return
      }

      if (this.blDetailDis === 'Y') {
        return
      }
      //1485328 [FY2024 Global BSC NKG] FEEDER FUNCTION OF YZR. FDR 변경하는 경우 필수 체크 X
      try {
        const schdInfo = this.$refs.schdInfo
        this.regParams.hidCS008I = schdInfo.hidCS008I
        if (this.$ekmtcCommon.isNotEmpty(this.regParams.hidCS008I) && this.$ekmtcCommon.isNotEmpty(schdInfo.fdrInfo) && schdInfo.fdrInfo.yzrYn === 'Y') {
          if (await this.$ekmtcCommon.asyncAlertMessage({ message: 'Only feeder vessel are requested to be changed. Do you want proceed?', useConfirmBtn: true })) {
          this.updateBLRequestFdr()
          return
          } else {
            return
          }
        }
      } catch (e) {
        console.log('YZR ERROR', e)
      }
      console.log('updateBl')

      let isOk = await this.validation()

      if (isOk) {
        //regParams.oldBlTypCd = this.originParams.blTypCd
        let checkBlCancel = false
        await trans.findBlCancelYn(regParams.blNo).then(res => {
          checkBlCancel = res.data
        }).catch(() => {
          TH.refresh()
        })

        if (checkBlCancel === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.294'))
          return
        }
      }

      // 일본 부킹일때, 부킹 화주 컨테이너 값 (shprVanYn) / BL 컨테이너 화주 값 같은지 확인
      if (this.memberDetail.userCtrCd === 'JP') {
        if (!this.shprVanValidation()) {
          this.$ekmtcCommon.alertDefault('BookingのShipper’s Van情報と相違があります。まずはBooking情報を確認してください')
          return
        }
      }

      if (regParams.blEdiTypCd === 'S' && this.memberDetail.userCtrCd === 'KR') {
        if (!this.checkTotPkgWt()) {
          isOk = false
        }
      }

      //SR 1362717 Request Container Error Message on ICC and e-kmtc Website
      if (regParams.polCtrCd === 'MY' && 'PKG, PKW, PGU, PEN'.indexOf(regParams.polPortCd) > -1) {
        if (regParams.containerList.length > 0) {
          for (let c of regParams.containerList) {
            if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
              let check = await TH.checkCntrNoInMy(regParams.bkgNo, c.cntrNo)
              if (!check) {
                isOk = false
                return false
              }
            }
          }
          if (!isOk) return
        }
      }

      //1416920 사우디(DMM)향 부킹 필수정보 기입
      //1.POD가 DMM인 경우
      if (regParams.podPortCd === 'DMM') {
        //2.Consignee가 To order가 아닌 경우, Consignee란에 “To order of”가 포함 안된 경우
        if (regParams.cneCstEnm.indexOf('TO ORDER') < 0) {
          //이메일 및 전화번호 필수 체크
          if (this.$ekmtcCommon.isEmpty(regParams.cneCstEmlAddr) || this.$ekmtcCommon.isEmpty(regParams.cneCstTelNo)) {
            //message : The consignee's contact information(Tel/email) is required.
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.290'))
            return
          }
        }
      }
      // VN -> DMM향일 때 REQUIRED SENTENCE 필수 체크
      if (regParams.porCtrCd === 'VN' && regParams.podPortCd === 'DMM') {
        let arr = [
          'SHIPPING LINE / SHIPPING LINE AGENTS ARE ELIG',
          'IBLE UNDER THE TERMS OF THIS B/L AS AGREED, T',
          'O COLLECT ANY CHARGES SUCH AS  DEPOSIT SAR 30',
          '00 PER DRY CNTR, SAR 7000 PER REEFER AND ANY ',
          'OTHER CHARGES OR FEES WHERE DEEMED NECESSARY ',
          'BY THE SHIPPING LINE / SHIPPING LINE AGENTS.L',
          'OLO(FASAH): SAR 50/CNTR, GATE PASS & HANDLING',
          ': SAR 120/CNTR, OCR: USD80/120 PER 20\'/40\'(NO',
          'N HAZ SHIPMENTS), USD 100/150 PER 20\'/40\'(HAZ',
          ' SHIPMENTS)'
        ]

        let chkRequiredSentence = false
        for (let desc of this.regParams.markDescList) {
          if (desc.dscr.indexOf(arr[0]) > -1 && desc.dscr.indexOf(arr[1]) > -1 &&
            desc.dscr.indexOf(arr[2]) > -1 && desc.dscr.indexOf(arr[3]) > -1 &&
            desc.dscr.indexOf(arr[4]) > -1 && desc.dscr.indexOf(arr[5]) > -1 &&
            desc.dscr.indexOf(arr[6]) > -1 && desc.dscr.indexOf(arr[7]) > -1 &&
            desc.dscr.indexOf(arr[8]) > -1 && desc.dscr.indexOf(arr[9]) > -1) {
            chkRequiredSentence = true
          }
        }

        if (!chkRequiredSentence) {
          this.$ekmtcCommon.alertDefault('REQUIRED SENTENCE is required.')
          return
        }
      }

      //
      if (this.regParams.polCtrCd === 'KR') {
        this.checkElNoTsCgoChk()
        if (this.regParams.tsCgoChoYn === 'Y' && this.regParams.emanYn !== 'Y') {
          this.$ekmtcCommon.alertDefault('수입적하목록 첨부 바랍니다.')
          return
        }
      }
      if (!isOk) {
        // 중국인 경우만 say 필수값 체크
        if (!this.sayChk && this.lang === 'CHN') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.238'))
          document.querySelector('html').scrollTop = 0
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.238'))
          document.querySelector('html').scrollTop = 0
          return
        }
      }
      if (this.memberDetail.userCtrCd === 'KR') {
        if (this.regParams.blPrtSntcCont.indexOf('ZERO') > -1) {
          this.$ekmtcCommon.alertDefault(this.$t('js.working.001'))
          return
        }
        let sayQtychk = await this.fnSayQtyChk()
        if (!sayQtychk) {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: '입력한 컨테이너 갯수와 SAY란이 불일치합니다.\n이대로 저장하시겠습니까? ', useConfirmBtn: true })) {
            return
          }
        }
      }

      if (regParams.dlyPlcCd === 'IPL') {
        const phuocLongIdx = this.regParams.dlyPlcNm.indexOf('PHUOC LONG')
        if ((phuocLongIdx >= regParams.dlyPlcNm.indexOf('1') && phuocLongIdx >= regParams.dlyPlcNm.indexOf('3')) || phuocLongIdx === -1) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: '<div style="width: 560px;">There are two ports for Place of Delivery : <b>ICD PHUOC LONG 1</b> and <b>ICD PHUOC LONG 3</b>.<br>Please clarify and choose the correct port. Do not accept the exception.</div>' })
        }
      }

      if (this.regParams.podCtrCd === 'BH') {
        //여기 validation 추가
        if (Number(this.regParams.grsCbm) <= 0) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.238') })
          this.$ekmtcCommon.showErrorTooltip('#grsCbm', this.$t('msg.CSBK100.238'))
          return
        }
      }

      // 1378414 Mandatory BL Type Selection prior online BL submission
      if (this.blTypeVisibleContry.includes(this.auth.userCtrCd) && isOk && this.regParams.orgBlTypCd !== this.regParams.blTypCd) {
        if (this.$ekmtcCommon.isEmpty(this.regParams.blTypCd)) {
          this.$ekmtcCommon.showErrorTooltip('#blTypCd0', this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          let bltypCdNm = $('[name=blTypCd]:checked').next().text()

          let cs105CdNmArr = []
          this.commonCodes.CS105.forEach(c => {
            cs105CdNmArr.push(c.cdNm)
          })

          if (this.$ekmtcCommon.isEmpty(this.regParams.rmk)) {
            TH.regParams.rmk = bltypCdNm
          } else {
            if (!TH.regParams.rmk.includes(bltypCdNm)) {
              for (var val of cs105CdNmArr) {
                if (bltypCdNm === val) continue

                if (TH.regParams.rmk.indexOf(val) > -1) {
                  TH.regParams.rmk = TH.regParams.rmk.replaceAll(val, '')
                }
              }
              if (this.$ekmtcCommon.isEmpty(this.regParams.rmk)) {
                TH.regParams.rmk = bltypCdNm
              } else {
                TH.regParams.rmk = TH.regParams.rmk + '\n' + bltypCdNm
              }

              $('#rmk').val(TH.regParams.rmk)
            }
          }
        }
      }

      if (isOk) {
        this.blPhbtList = await this.getRtnPhbtChr()
        const rsltWt = this.checkCntrGrossWeightAndPackage('wt')
        const rsltPkg = this.checkCntrGrossWeightAndPackage('pkgQty')
        let rsltCbm = 0
        if (this.lang === 'JPN' || regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN') {
          rsltCbm = this.checkCntrGrossWeightAndPackage('meaCbm')
        }

        if (rsltWt === -1) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.097'))
          return
        } else if (rsltWt === -2) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.098'))
          return
        }

        if (rsltPkg === -1) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.095'))
          return
        } else if (rsltPkg === -2) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.096'))
          return
        }

        if (this.lang === 'JPN' || regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN') {
          if (rsltCbm === -1) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.102'))
            return
          } else if (rsltCbm === -2) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.103'))
            return
          }
        }
        this.setCntrGrsWtAndPkg(rsltWt, rsltPkg, rsltCbm)

        let cntrCheckMsg = this.$t('art.CMATK364')
        let cntrCheckMsgYn = 'N'
        let cgoCheckMsgYn = 'N'
        let chkMyHz = false // 말레이시아 위험물 체크

        regParams.containerList.forEach(c => {
          if (c.shprVanYn !== 'Y' || regParams.cgoModeCd !== '04') {
            if (c.cntrCheck === 'N' && this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
              if (cntrCheckMsgYn === 'Y') {
                cntrCheckMsg += ', '
              }

              cntrCheckMsg += c.cntrNo
              cntrCheckMsgYn = 'Y'
            }

            if (c.cgoCheckYn === 'N') {
              cgoCheckMsgYn = 'Y'
            }
          }

          // 카고타입명 HZ, 코드 01은 위험물이며, 컨테이너 중 위험물 있는지 체크
          if (c.cgoTypCd === '01') {
            chkMyHz = true
          }
        })

        //1364520 면장란과 비엘의 중량이 불일치하나 S/R 제출 가능한 오류 개선 요청
        if (regParams.polCtrCd === 'KR') {
          //E/L Information 중량에 0입력 여부 확인 후 popup 오픈
          if (this.regParams.exportLicenseList.length > 0) {
            let checkZeroData = false
            this.regParams.exportLicenseList.forEach(c => {
              if (c.grsWt === '0' || c.grsWt === 0) {
                checkZeroData = true
                return false
              }
            })

            if (checkZeroData) {
              this.$ekmtcCommon.alertDefault(this.$t('art.CMATK408'))
              return
            }
          }

          //Container Information 중량에 0입력 여부 확인 후 popup 오픈
          if (regParams.containerList.length > 0) {
            let checkZeroData = false
            this.regParams.containerList.forEach(c => {
              if (c.wt === '0' || c.wt === 0) {
                checkZeroData = true
                return false
              }
            })

            if (checkZeroData) {
              this.$ekmtcCommon.alertDefault(this.$t('art.CMATK409'))
              return
            }
          }
        }

        // 카고 타입 위험물 포함, POL MY, CN인 경우 DG-Certi 필수 체크. SR 1276477
        if (chkMyHz && (regParams.polCtrCd === 'MY' || regParams.polCtrCd === 'CN') && regParams.dgcertiYn !== 'Y') {
          let msg = this.$t('msg.CSBL200.288')

          if (regParams.polCtrCd === 'CN') {
            msg = this.$t('msg.CSBL200.292')
          }

          this.$ekmtcCommon.alertDefault(msg)
          return
        }

        let saveOk = true

        if ((Number(regParams.obrdDt) < Number(regParams.etaObrdDt) || Number(regParams.obrdDt) > Number(regParams.etdObrdDt)) && this.lang !== 'JPN' && regParams.obrdFixYn !== 'Y') {
          if ([regParams.etaObrdDt, regParams.etdObrdDt].indexOf(regParams.obrdDt) === -1) { // 입항일, 출항일이 잘못나온경우 입항일, 출항일중 하나와 동일한 경우 제출
            const etaObrdDt = this.$ekmtcCommon.changeDatePattern(regParams.etaObrdDt)
            const etdObrdDt = this.$ekmtcCommon.changeDatePattern(regParams.etdObrdDt)
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.274') + '( POL ETA : ' + etaObrdDt + '/ POL ETD : ' + etdObrdDt + ' )')
            return
          }
        }

        if (String(regParams.dgcount) !== '0' && String(regParams.srdgcount) === '0' && cgoCheckMsgYn === 'Y' && this.checkCgoType === 'Y') {
          const message = this.$t('msg.CSBL200.093') + '<br>' + this.$t('msg.CSBL200.094')
          if (regParams.mixedYn === 'Y') {
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: message, useConfirmBtn: true })) {
              saveOk = false
            }
          } else {
            let obj = {
              onConfirm: this.cgoTypeConfirm,
              onClose: this.cgoTypeClose,
              customCloseBtnClass: 'button blue lg',
              customCloseBtnText: this.$t('tem.CMAT039'), // 위험물 아님(진행)
              customConfirmBtnText: this.$t('tem.CMAT040'), //위험물임(진행안됨)
              useConfirmBtn: true,
              customConfirmBtnClass: 'button blue lg',
              message: message,
              type: 'info'
            }

            this.$ekmtcCommon.alert(obj)

            // this.$ekmtcCommon.alertDefault(message)
            return
          }
        }

        const isRider = this.isRider()
        if (isRider) {
          if (regParams.riderYn === 'N') {
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: 'RIDER' + this.$t('tem.CMAT024'), useConfirmBtn: true })) {
              this.fnAttachPop()
              saveOk = false
            }
          }
        }

        this.setChangeValueCheck()
        if (regParams.cllCntnoUpdate === 'Y') {
          if (!await this.fnPopCLL()) {
            return
          }
        }

        if (this.isEmanSave()) {
          this.fnCommonBtnAction('SHAMF')
          return
        }

        if (regParams.dlyPlcCd === 'SGN') {
          if (!await this.fnDlyTrmlMsgChk()) {
            saveOk = false
          }
        }

        if (regParams.podCtrCd === 'VN' && regParams.wstCgoYn !== 'Y') {
          if (!await this.checkValidationVNWaste()) {
            saveOk = false
          }
        }

        if (this.cntrCheckMsgYn === 'Y') {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: cntrCheckMsg, useConfirmBtn: true })) {
            return
          }
        }

        if (this.lang === 'KOR' && this.$ekmtcCommon.isEmpty(regParams.grsCbm)) {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.199'), useConfirmBtn: true })) {
            return
          }
        }
        // EMPTY 일 경우 cbm == wt 해도 저장 가능.
        let notEmptyCnt = 0
        regParams.containerList.forEach(c => {
          if (c.feUnitCd !== 'E') {
            notEmptyCnt++
          }
        })

        if (this.lang === 'KOR' && Number(regParams.grsCbm) === Number(regParams.grsWt) && notEmptyCnt > 0) {
           this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.279'))
           return
        }
        if (this.lang === 'KOR') {
          if (!await this.checkValidationDscrErr()) {
            saveOk = false
          }
        }

        if (this.regParams.polCtrCd === 'SG' && this.regParams.eobYn === 'Y') {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: 'This correction may impose penalty.\n\nPlease select \'Confirm\' to proceed correction with penalty.', useConfirmBtn: true, customConfirmBtnText: 'Confirm' })) {
            return
          }
        }
        const obj = []
        obj.push(this.regParams.cgoCont)
        this.regParams.markDescList.forEach(m => {
          obj.push(m.mrk)
          obj.push(m.dscr)
        })

        let chrCheckOk = true
        for (let i = 0; i < obj.length; i++) {
          if (await this.fnRtnCHRCheck(this.blPhbtList, obj[i])) {
            chrCheckOk = false
          }
        }

         if (!chrCheckOk) {
           return
         }

        // cargo 타입 체크
        this.checkCgoType = 'Y'

        if (saveOk) {
          const pkgChk = this.fnDescPkgCheck()
          let saveMessage = this.$t('art.CMATK354')
          const originParams = this.originParams

          // gross weight(중량) 변경되면 알림메시지 변경
          if (originParams.grsWt !== regParams.grsWt) {
            if (this.lang !== 'CHN') {
              saveMessage = `<div style="color:red;"><strong>${this.$t('art.CMATK410')}</strong></div>` + '\n' + this.$t('art.CMATK354')
            } else {
              saveMessage = this.$t('art.CMATK354')
            }
          }

          if (pkgChk !== 'A') {
            saveMessage = this.lang === 'KOR' ? this.$t('art.CMATK354') : this.$t('art.CMATK355')
          }

          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: saveMessage, useConfirmBtn: true })) {
            return
          }

          if (regParams.dlyCtrCd === 'IN' && Number(regParams.pkgQty) !== Number(regParams.hsQty) && Number(regParams.hsQty) !== 0) {
            // 1462098 SCMTR -Regulation manifest error issues.
            await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.276') })
            this.hsCdMultiPop('02')
            return

            /*if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.276'), useConfirmBtn: false })) {
              return
            }*/
          }

          const sndShaYn = await this.getSndShaYn()
          if ((sndShaYn === 'Y' || sndShaYn === 'E') && this.lang === 'KOR') {
            if (regParams.afaCostYn === 'Y') {
              if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.176'), useConfirmBtn: true })) {
                regParams.afaCostEmptyYn = 'N'
                return
              }
            }
          }

          //1366353 비엘 정정 시 자동 PENDING 적용 건의 C/A 요청서 첨부 조건 및 화면 구성 개성 요청 (전자동의)
          if (this.lang === 'KOR' && regParams.mfClosYn === 'Y') {
            if (regParams.oblYn === 'Y' ||
              (regParams.surrYn === 'Y' && regParams.surrDt != null) ||
              (regParams.blCatCd === '02' && regParams.wbilCfmYn === 'Y' && regParams.wbilCfmDt != null)) {
              await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.260') })
            } else {
              regParams.caCfmYn = 'Y'
              await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.289') })
            }
          }

          if (regParams.podCtrCd === 'HK' || regParams.podCtrCd === 'PKG') {
            if (regParams.riderYn !== 'N') {
              regParams.riderYn = 'Y'
            }
            if (regParams.emanYn !== 'N') {
              regParams.emanYn = 'N'
            }
          }

          if (isRider && regParams.riderYn !== 'N') {
            regParams.emanYn = 'Y'
          }

          regParams.containerList.forEach((c, i) => {
            c.sealNo1 = ''
            c.sealNo2 = ''
            c.sealNo3 = ''
            c.sealNo4 = ''
            c.sealNo5 = ''
            c.sealList.forEach((s, idx) => {
              c['sealNo' + (idx + 1)] = s.sealNo
            })
          })

          // Mark & No와 Description에서 자동승인 제약 체크
          regParams.markDescList.forEach((vo, i) => {
            this.checkCAword(vo.mrk)
            this.checkCAword(vo.dscr)
            this.checkCAwordBL(vo.dscr)
          })

          let byStr = this.checkValidationNboErr(regParams.cgoCont, 'BYTEXT', this.byStr, this.blPhbtList)
          regParams.byStrMsg = byStr

          let dscrMsg = ''
          regParams.markDescList.forEach(vo => {
            dscrMsg += this.checkValidationNboErr(vo.dscr, 'DSCR', this.dscrStr, this.blPhbtList)
          })

          regParams.dscrStrMsg = dscrMsg

          this.dscrStr = ''
          this.byStr = ''

          if (Number(regParams.bkgRfCount) > 0) {
            regParams.descrCaYn = 'N'
          }

          let pkgChgRtn = ''
          if (pkgChk === 'B') {
            if (regParams.podCtrCd === 'ID' || regParams.podCtrCd === 'PH') {
              regParams.descrCaYn = 'N'
            }

            if (regParams.pkErrYn === 'Y') {
              pkgChgRtn = this.fnDescPkgChgCheck()

              if (pkgChgRtn === 'Y') {
                regParams.pkgErrorYn = 'Y'
              }
            } else {
              regParams.pkgErrorYn = 'Y'
            }
          //} else if (pkgChk === 'C') {
          } else if (pkgChk !== 'A') { // pkgChk값이 ''인 경우도 탈수 있게 ASIS처럼 else 처리
            regParams.descrCaYn = 'N'

            if (regParams.podCtrCd === 'ID' || regParams.podCtrCd === 'PH') {
              if (regParams.pkErrYn === 'Y') {
                pkgChgRtn = this.fnDescPkgChgCheck()

                if (pkgChgRtn === 'Y') {
                  regParams.pkgErrorYn = 'Y'
                }
              } else {
                regParams.pkgErrorYn = 'Y'
              }
            }
          }

          if (this.lang === 'KOR') {
            if ((regParams.polShtmCd === '02' && regParams.podShtmCd === '01') ||
                (regParams.polShtmCd === '02' && regParams.podShtmCd === '02')) {
              if ((Number(this.originParams.grsCbm) !== Number(regParams.grsCbm) || Number(this.originParams.grsWt) !== Number(regParams.grsWt)) &&
                regParams.frtLnkYn === 'Y') {
                regParams.descrCaYn = 'N'
              }
            }

            const rtnObj = await this.fnSrConstraintsCheck()
            if (rtnObj.isCons) {
              if (!rtnObj.isOk) {
                return
              } else {
                this.regParams.machineRmk = rtnObj.rtnVal
                this.fnNoticePop()
                return
              }
            }
          }
          //중국 VGM 중량 체크
          if (this.regParams.polCtrCd === 'CN') {
           const caYn = await this.caVgmChk(this.regParams)
           if (caYn.rtnCode === 'N') {
              if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('您的货物已超重，请核对。是否继续提交?'), useConfirmBtn: true })) {
                  this.regParams.cnCaFlag = 'N'
              } else {
                  this.regParams.cnCaFlag = 'Y'
                return
              }
           } else if (caYn.rtnCode === 'X') {
              this.regParams.cnCaFlag = 'N'
           } else if (caYn.rtnCode === 'Y') {
              this.regParams.cnCaFlag = 'Y'
           } else {
              this.regParams.cnCaFlag = 'N'
           }
          }
          //const schdInfo = this.$refs.schdInfo
          //this.regParams.hidCS008I = schdInfo.hidCS008I
          this.updateBLRequest()
        }
      }
    },
    async updateBLRequest () {
      const TH = this
      const regParams = TH.regParams
      let succFlag = true
      let vgmInput = 'N'
      let result = null
      regParams.markDescList.forEach(m => {
        let mrk = this.$ekmtcCommon.isNotEmpty(m.mrk) ? m.mrk : ''
        let dscr = this.$ekmtcCommon.isNotEmpty(m.dscr) ? m.dscr : ''

        mrk = mrk.replace(/\r/gi, '')
        dscr = dscr.replace(/\r/gi, '')

        m.mrk = mrk.split('\n').join('\r\n')
        m.dscr = dscr.split('\n').join('\r\n')
      })

      await trans.updateBLRequest(regParams).then(res => {
        if (res.headers.respcode !== 'C0000') {
          succFlag = false
        } else {
          result = res.data

          if (result === undefined || result === null || result === '') {
            succFlag = false
          }
        }
      }).catch(() => {
        succFlag = false
      }).finally(async () => {
        if (succFlag && this.$ekmtcCommon.isNotEmpty(result)) {
          if (result.rtnCode === '1') {
            if (this.$ekmtcCommon.isNotEmpty(result.verifyRmk)) {
              TH.$ekmtcCommon.alertCallback(result.verifyRmk, () => {
                TH.blCopyOpen = 'Y'
                TH.refresh()
              })
            } else {
              TH.blCopyOpen = 'Y'
              TH.refresh()
            }
          } else if (result.rtnCode === '5') {
            if (result.vgmInputYn === 'N') {
              TH.vgmPopOpen = 'Y'
            }
            TH.$ekmtcCommon.alertCallback(this.$t('art.CMATK202'), () => {
              TH.refresh()
            })
          } else if (result.rtnCode === '4' || result.rtnCode === '10' || result.rtnCode === '6') {
            let resultMessage = ''

            if (result.rtnCode === '4') {
              resultMessage = this.$t('art.CMATK339')
            } else {
              resultMessage = this.$t('art.CMATK372')
            }/* else if (result.rtnCode === '10') {
              resultMessage = this.$t('msg.CSBL100_M1.031')
            }  */

            TH.$ekmtcCommon.alertCallback(resultMessage, () => {
              //TH.$router.push({ name: 'progress' }).catch(() => {})
              TH.refresh()
            })
          } else if (result.rtnCode === '7') {
            this.blRequestOpen = 'Y'
            TH.$ekmtcCommon.alertCallback(this.$t('art.CMATK339'), () => {
            })
          } else if (result.rtnCode === '8') {
            TH.refresh()
          } else if (result.rtnCode === '0') {
            TH.$ekmtcCommon.alertCallback(this.$t('art.CMATK184') + this.$t('art.CMATK185'), () => {
              TH.refresh()
            })
          } else if (result.rtnCode === '11') {
            TH.$ekmtcCommon.alertCallback(this.$t('art.CMATK407'), () => {
            TH.refresh()
            //TH.blCopyOpen = 'Y'
            })
          } else if (result.rtnCode === 'InHsCdSanc') {
            const arrMsg = result.rtnMessage
            let rtnMessage = ''
            arrMsg.forEach(vo => {
              rtnMessage += TH.$t(vo)
            })
            TH.$ekmtcCommon.alertCallback(rtnMessage, () => {
              const catCd = TH.regParams.polCtrCd === 'IN' ? '01' : '02'
              TH.hsCdMultiPop(catCd)
              TH.markDescEnterSet()
            })
          } else if (result.rtnCode === 'InHsCdCntrSanc') {
              const arrMsg = result.rtnMessage
              let rtnMessage = ''
              arrMsg.forEach(vo => {
                rtnMessage += TH.$t(vo)
              })

              if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
                rtnMessage = TH.$t('js.common.004')
                TH.$ekmtcCommon.alertDefault(rtnMessage)
              } else {
                TH.$ekmtcCommon.alertCallback(rtnMessage, () => {
                  //TH.refresh()
                  const catCd = this.regParams.polCtrCd === 'IN' ? '01' : '02'
                  this.hsCdMultiPop(catCd)
                  this.hsCdPopLoad = 'N'
                })
              }
              TH.markDescEnterSet()
            } else if (result.rtnCode === 'cnCntrCaChk') {
              const arrMsg = result.rtnMessage
              let rtnMessage = ''
              arrMsg.forEach(vo => {
                rtnMessage += TH.$t(vo)
              })

              if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
                rtnMessage = TH.$t('js.common.004')
                TH.$ekmtcCommon.alertDefault(rtnMessage)
              } else {
                TH.$ekmtcCommon.alertCallback(rtnMessage, () => {
                  //TH.refresh()
                  const catCd = this.regParams.polCtrCd === 'IN' ? '01' : '02'
                  this.hsCdMultiPop(catCd)
                  this.hsCdPopLoad = 'N'
                })
              }
              TH.markDescEnterSet()
            } else {
            const arrMsg = result.rtnMessage
            let rtnMessage = ''
            arrMsg.forEach(vo => {
              rtnMessage += TH.$t(vo)
            })
            if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
              rtnMessage = TH.$t('js.common.004')
            }

            TH.$ekmtcCommon.alertDefault(rtnMessage)
            TH.markDescEnterSet()
          }
        } else {
          TH.$ekmtcCommon.screenToPdfUpload(TH.regParams, 'updateBLRequest')
          TH.$ekmtcCommon.alertDefault(TH.$t('js.common.004'))

          TH.markDescEnterSet()
        }
         this.regParams.cnCaFlag = 'Y'
         if (result.rtnCode === '1' || result.rtnCode === '5' || result.rtnCode === '4' || result.rtnCode === '10' || result.rtnCode === '6' || result.rtnCode === '7' || result.rtnCode === '8') {
          trans.updateEmlCont(regParams).then(res => {
          //
          }).catch()
         }
      })
    },
    async updateBLRequestFdr () {
      const TH = this
      const regParams = TH.regParams
      let succFlag = true
      let vgmInput = 'N'
      let result = null
      regParams.markDescList.forEach(m => {
        let mrk = this.$ekmtcCommon.isNotEmpty(m.mrk) ? m.mrk : ''
        let dscr = this.$ekmtcCommon.isNotEmpty(m.dscr) ? m.dscr : ''

        mrk = mrk.replace(/\r/gi, '')
        dscr = dscr.replace(/\r/gi, '')

        m.mrk = mrk.split('\n').join('\r\n')
        m.dscr = dscr.split('\n').join('\r\n')
      })

      await trans.updateBLRequestFdr(regParams).then(res => {
        if (res.headers.respcode !== 'C0000') {
          succFlag = false
        } else {
          result = res.data

          if (result === undefined || result === null || result === '') {
            succFlag = false
          }
        }
      }).catch(() => {
        succFlag = false
      }).finally(async () => {
        if (succFlag && this.$ekmtcCommon.isNotEmpty(result)) {
          if (result.rtnCode === '1') {
            if (this.$ekmtcCommon.isNotEmpty(result.verifyRmk)) {
              TH.$ekmtcCommon.alertCallback(result.verifyRmk, () => {
                TH.blCopyOpen = 'Y'
                TH.refresh()
              })
            } else {
              TH.blCopyOpen = 'Y'
              TH.refresh()
            }
          } else if (result.rtnCode === '5') {
            if (result.vgmInputYn === 'N') {
              TH.vgmPopOpen = 'Y'
            }
            TH.$ekmtcCommon.alertCallback(this.$t('art.CMATK202'), () => {
              TH.refresh()
            })
          } else if (result.rtnCode === '4' || result.rtnCode === '10' || result.rtnCode === '6') {
            let resultMessage = ''

            if (result.rtnCode === '4') {
              resultMessage = this.$t('art.CMATK339')
            } else {
              resultMessage = this.$t('art.CMATK372')
            }/* else if (result.rtnCode === '10') {
              resultMessage = this.$t('msg.CSBL100_M1.031')
            }  */

            TH.$ekmtcCommon.alertCallback(resultMessage, () => {
              //TH.$router.push({ name: 'progress' }).catch(() => {})
              TH.refresh()
            })
          } else if (result.rtnCode === '7') {
            this.blRequestOpen = 'Y'
            TH.$ekmtcCommon.alertCallback(this.$t('art.CMATK339'), () => {
            })
          } else if (result.rtnCode === '8') {
            TH.refresh()
          } else if (result.rtnCode === '0') {
            TH.$ekmtcCommon.alertCallback(this.$t('art.CMATK184') + this.$t('art.CMATK185'), () => {
              TH.refresh()
            })
          } else if (result.rtnCode === '11') {
            TH.$ekmtcCommon.alertCallback(this.$t('art.CMATK407'), () => {
            TH.refresh()
            //TH.blCopyOpen = 'Y'
            })
          } else if (result.rtnCode === 'InHsCdSanc') {
            const arrMsg = result.rtnMessage
            let rtnMessage = ''
            arrMsg.forEach(vo => {
              rtnMessage += TH.$t(vo)
            })
            TH.$ekmtcCommon.alertCallback(rtnMessage, () => {
              const catCd = TH.regParams.polCtrCd === 'IN' ? '01' : '02'
              TH.hsCdMultiPop(catCd)
              TH.markDescEnterSet()
            })
          } else if (result.rtnCode === 'InHsCdCntrSanc') {
              const arrMsg = result.rtnMessage
              let rtnMessage = ''
              arrMsg.forEach(vo => {
                rtnMessage += TH.$t(vo)
              })

              if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
                rtnMessage = TH.$t('js.common.004')
                TH.$ekmtcCommon.alertDefault(rtnMessage)
              } else {
                TH.$ekmtcCommon.alertCallback(rtnMessage, () => {
                  //TH.refresh()
                  const catCd = this.regParams.polCtrCd === 'IN' ? '01' : '02'
                  this.hsCdMultiPop(catCd)
                  this.hsCdPopLoad = 'N'
                })
              }
              TH.markDescEnterSet()
            } else if (result.rtnCode === 'cnCntrCaChk') {
              const arrMsg = result.rtnMessage
              let rtnMessage = ''
              arrMsg.forEach(vo => {
                rtnMessage += TH.$t(vo)
              })

              if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
                rtnMessage = TH.$t('js.common.004')
                TH.$ekmtcCommon.alertDefault(rtnMessage)
              } else {
                TH.$ekmtcCommon.alertCallback(rtnMessage, () => {
                  //TH.refresh()
                  const catCd = this.regParams.polCtrCd === 'IN' ? '01' : '02'
                  this.hsCdMultiPop(catCd)
                  this.hsCdPopLoad = 'N'
                })
              }
              TH.markDescEnterSet()
            } else {
            const arrMsg = result.rtnMessage
            let rtnMessage = ''
            arrMsg.forEach(vo => {
              rtnMessage += TH.$t(vo)
            })
            if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
              rtnMessage = TH.$t('js.common.004')
            }

            TH.$ekmtcCommon.alertDefault(rtnMessage)
            TH.markDescEnterSet()
          }
        } else {
          TH.$ekmtcCommon.screenToPdfUpload(TH.regParams, 'updateBLRequest')
          TH.$ekmtcCommon.alertDefault(TH.$t('js.common.004'))

          TH.markDescEnterSet()
        }
      })
    },
    popCARequest () {
      this.popParams = {
        blNo: this.regParams.blNo
      }

      this.selectFunc = ''
      this.openPopup('ca-request-pop')
    },
    async fnAttachPop () {
      await this.initAttachPopupData()

      this.popParams = {
        blNo: this.regParams.blNo,
        type: 'BL',
        podCtrCd: this.regParams.podCtrCd,
        uploadFileList: this.uploadFileList,
        btnAddYn: this.timeFlag === 'N'
      }

      this.selectFunc = this.setFileInfo
      this.openPopup('bl-attach-pop')
    },
    setFileInfo () {
      this.initAttachPopupData()
    },
    fnIranUploadPop (type) {
      this.popParams = {
        reqRno: this.regParams.bkgNo,
        count: 0,
        type: type
      }

      this.selectFunc = ''
      this.openPopup('iran-upload-pop')
    },
    async checkVanningInfo () {
      let saveOk = true
      let isSucc = true
      await trans.findVaniInfo(regParams.bkgNo).then(res => {
        if (res.headers.respcode === 'C0000') {
          const result = res.data
          if (result !== undefined) {
            if (result.vaniDtYn === 'N') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.205'))
              saveOk = false
              return
            }
            if (result.vaniCertYn === 'N') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.206'))
              saveOk = false
            }
          }
        } else {
          isSucc = false
        }
      }).catch(() => {
        isSucc = false
      }).finally(() => {
        if (!isSucc) {
          this.$ekmtcCommon.alertDefault('[Vanning Info] ' + this.$t('js.common.004'))
          saveOk = false
        }
      })

      return saveOk
    },
    async checkValidationVNWaste () {
      let isOk = true
      const regParams = this.regParams

      let messageYn = 'N'
      if (regParams.cgoCont.indexOf('WASTE') > -1 || regParams.cgoCont.indexOf('SCRAP') > -1) {
        messageYn = 'Y'
      }

      regParams.markDescList.forEach(m => {
        if (m.dscr.indexOf('WASTE') > -1 || m.dscr.indexOf('SCRAP') > -1) {
          messageYn = 'Y'
        }
      })

      if (messageYn === 'Y') {
        if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.178'), useConfirmBtn: true })) {
          isOk = false
        }
      }

      return isOk
    },
    isRider () {
      let rtn = false
      const regParams = this.regParams

      if (this.$ekmtcCommon.isNotEmpty(regParams.shprCstAddr)) {
        if (regParams.shprCstAddr.indexOf('RIDER') >= 0) {
          rtn = true
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(regParams.cneCstAddr)) {
        if (regParams.cneCstAddr.indexOf('RIDER') >= 0) {
          rtn = true
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(regParams.ntifCstAddr)) {
        if (regParams.ntifCstAddr.indexOf('RIDER') >= 0) {
          rtn = true
        }
      }

      const markDescInfo = regParams.markDescList[0]
      if (this.$ekmtcCommon.isNotEmpty(markDescInfo.mrk)) {
        if (markDescInfo.mrk.indexOf('RIDER') >= 0) {
          rtn = true
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(markDescInfo.dscr)) {
        if (markDescInfo.dscr.indexOf('RIDER') >= 0) {
          rtn = true
        }
      }

      return rtn
    },
    async checkValidationDscrErr () {
      let isOk = true
      let chkFlag = false
      const regParams = this.regParams
      const totPkgQty = regParams.pkgQty
      const totPkgCd = regParams.pkgCd
      const totGrsWt = regParams.grsWt
      const pkgCdList = this.commonCodes['01130']
      const pkgCdInfo = pkgCdList.filter(vo => vo.cd === totPkgCd)

      let totPkgNm = ''

      if (pkgCdInfo !== undefined && pkgCdInfo.length > 0) {
        totPkgNm = pkgCdInfo[0].cdNm
      }

      const dscr = regParams.markDescList[0].dscr

      const params = {
        totPkgQty: totPkgQty,
        totPkgNm: totPkgNm,
        dscr: dscr
      }

      await trans.fnPkgDscrErrCheck(params).then(async res => {
        if (res.headers.respcode === 'C0000') {
          const result = res.data

          if (result.rtnYn !== 'N') {
            const pkgErrNm = this.$ekmtcCommon.isEmpty(result.pkgErrNm) ? '' : result.pkgErrNm
            const pkgErrQty = this.$ekmtcCommon.isEmpty(result.pkgErrQty) ? '' : result.pkgErrQty

            const obj = {
              totPkgQty: totPkgQty,
              totPkgNm: totPkgNm,
              pkgErrQty: pkgErrQty,
              pkgErrNm: pkgErrNm,
              totGrsWt: totGrsWt
            }

            const message = this.makeConfirmPkgErrMessage(obj)

            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: message, useConfirmBtn: true })) {
              isOk = false
            }
          }
        }
      })

      return isOk
    },
    makeConfirmPkgErrMessage (obj) {
      let message = ''

      message += this.$t('msg.CSBL200.259') + '<br><br>'
      message += '<table class="tbl_col">'
      message += '<colgroup>'
      message += '  <col width="20%"/>'
      message += '  <col width="5%"/>'
      message += '  <col width="40%"/>'
      message += '  <col width="35%"/>'
      message += '</colgroup>'
      message += '<thead>'
      message += '  <tr>'
      message += '    <th>Kind</th>'
      message += '    <th>Q\'ty</th>'
      message += '    <th>Unit</th>'
      message += '    <th>Gross Weight</th>'
      message += '  </tr>'
      message += '</thead>'
      message += '<tbody>'
      message += '  <tr>'
      message += '  <td>Total P\'kgs</td>'
      message += '  <td>' + obj.totPkgQty + '</td>'
      message += '  <td>' + obj.totPkgNm + '</td>'
      message += '  <td>' + obj.totGrsWt + '</td>'
      message += '  </tr>'

      message += '  <td>Description P\'kgs</td>'
      if (obj.totPkgQty !== obj.pkgErrQty) {
        message += '  <td><span class="color_red">' + obj.pkgErrQty + '</span></td>'
      } else {
        message += '  <td>' + obj.pkgErrQty + '</td>'
      }

      if (obj.totPkgNm !== obj.pkgErrNm) {
        message += '  <td><span class="color_red">' + obj.pkgErrNm + '</span></td>'
      } else {
        message += '  <td>' + obj.pkgErrNm + '</td>'
      }
      message += '  <td></td>'
      message += '  </tr>'

      message += '  <td>Container P\'kgs</td>'
      message += '  <td>' + obj.totPkgQty + '</td>'
      message += '  <td></td>'
      message += '  <td>' + obj.totGrsWt + '</td>'
      message += '  </tr>'
      message += '</tbody>'
      message += '</table>'

      return message
    },
    setCntrGrsWtAndPkg (rsltWt, rsltPkg, rsltCbm) {
      if (rsltWt !== 0 && rsltPkg !== 0 && rsltCbm !== 0) {
        return
      }
      const TH = this
      const regParams = TH.regParams
      let cntr20hop = 0
      let cntr40hop = 0
      let cntr20Cnt = 0
      let cntr40Cnt = 0
      let cntrSz = 0
      let count = 0

      regParams.containerList.forEach((c, i) => {
        if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
          if (c.cntrSzCd === '20') {
            cntrSz += 1
            cntr20hop++
          } else {
            cntrSz += 1.3
            cntr40hop++
          }
          count++
        }
      })

      const totalPkg = Number(regParams.pkgQty)
      const totalWt = Number(regParams.grsWt)
      const totalCbm = Number(regParams.grsCbm)

      let cntrSz20Sum = Math.round((totalPkg * 1 / cntrSz * cntr20hop))
      let cntrSz40Sum = Math.round((totalPkg * 1 / cntrSz * cntr40hop))

      if (cntrSz20Sum + cntrSz40Sum !== totalPkg) {
        if (cntrSz20Sum > 0 && cntrSz40Sum > 0) {
          cntrSz40Sum = totalPkg - cntrSz20Sum
        } else {
          if (cntrSz20Sum > 0) {
            cntrSz20Sum = totalPkg
          } else {
            cntrSz40Sum = totalPkg
          }
        }
      }

      if (regParams.containerList.length === 1) {
        const cntrInfo = regParams.containerList[0]

        if (rsltWt === 0) {
          cntrInfo.wt = regParams.grsWt
        }

        if (rsltPkg === 0) {
          cntrInfo.pkgQty = regParams.pkgQty
        }

        if (this.lang === 'JPN' && rsltCbm === 0) {
          cntrInfo.meaCbm = regParams.grsCbm
        }
      } else {
        regParams.containerList.forEach((c, i) => {
          if (c.cntrSzCd === '20') {
            if (rsltWt === 0) {
              c.wt = (Math.round(totalWt * (1 / cntrSz) * 1000)) / 1000
            }
            cntr20Cnt++
            if (rsltPkg === 0) {
              if ((cntrSz20Sum % cntr20hop) < cntr20Cnt) {
                c.pkgQty = Math.floor(Number(cntrSz20Sum / cntr20hop))
              } else {
                c.pkgQty = Math.floor(Number(cntrSz20Sum / cntr20hop) + 1)
              }
            }

            if (this.lang === 'JPN' && rsltCbm === 0) {
              c.meaCbm = (Math.round(totalCbm * (1 / cntrSz) * 1000)) / 1000
            }
          } else {
            if (rsltWt === 0) {
              c.wt = (Math.round(totalWt * (1.3 / cntrSz) * 1000)) / 1000
            }
            cntr40Cnt++
            if (rsltPkg === 0) {
              if ((cntrSz40Sum % cntr40hop) < cntr40Cnt) {
                c.pkgQty = Math.floor(Number(cntrSz40Sum / cntr40hop))
              } else {
                c.pkgQty = Math.floor(Number(cntrSz40Sum / cntr40hop) + 1)
              }
            }

            if (this.lang === 'JPN' && rsltCbm === 0) {
              c.meaCbm = (Math.round(totalCbm * (1.3 / cntrSz) * 1000)) / 1000
            }
          }
        })
      }
    },
    checkValidationCntrNo (idx) {
      let isOk = true
      const regParams = this.regParams
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#cntrNo_' + idx)
      const cntrInfo = regParams.containerList[idx]
      const cntrTypCd = frm.querySelector('#cntrTypCd_' + idx)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (regParams.containerList.length > 1) {
        if (this.$ekmtcCommon.isEmpty(cntrInfo.cntrNo)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK363'))
          isOk = false
        }

        regParams.containerList.forEach((c, i) => {
          if (i !== idx && c.cntrNo === cntrInfo.cntrNo) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK177'))
            isOk = false
          }
        })
      }

      if (regParams.dlyPlcCd === 'BJO' || regParams.dlyPlcCd === 'LUU' || regParams.dlyPlcCd === 'ROQ') {
        if (cntrInfo.cntrNo.startsWith('MAXU') || cntrInfo.cntrNo.startsWith('INNU') || cntrInfo.cntrNo.startsWith('IRNU') || cntrInfo.cntrNo.startsWith('RALU')) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.160'))
          isOk = false
        }
      }
      if (cntrInfo.sealList.length > 0 && cntrTypCd.value !== 'TK') {
        cntrInfo.sealList.forEach((s, i) => {
          if (!this.checkValidationSealNo(i, idx)) {
            isOk = false
          }
        })
      }

      return isOk
    },
    checkValidationELAll () {
      let isOk = true
      let arrEl = []

      if (this.isIndonesia()) {
        arrEl = ['elNo', 'elNoValdDt', 'idCstmCd']
      }

      if (this.regParams.blEdiTypCd === 'S' && this.memberDetail.userCtrCd === 'KR') {
        arrEl = ['elNo', 'lcsPkgQty', 'lcsPkgCd', 'lcsGrsWt', 'ldgTcnt', 'pkgtgPkgQty', 'elNoValdDt']
      }

      arrEl.forEach(el => {
        this.regParams.exportLicenseList.forEach((c, idx) => {
          if (!this.checkValidationExportLicense(el, idx)) {
            isOk = false
          }
        })
      })

      return isOk
    },
    checkValidationExportLicense (flag, idx) {
      let isOk = true
      const regParams = this.regParams
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + flag + '_' + idx)
      const elInfo = regParams.exportLicenseList[idx]

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (flag === 'elNo') {
        let elNo = elInfo.elNo
        elNo = elNo.replace(/[^a-zA-Z0-9]/gi, '')
        this.$set(elInfo, 'elNo', elNo.toUpperCase())
      }

      if (regParams.exportLicenseList.length === 1 && this.$ekmtcCommon.isEmpty(elInfo.elNo)) {
        this.$ekmtcCommon.hideErrorTooltipAll(document.querySelector('.table_elInfo'))
      }

      if (flag === 'elNo') {
        if (regParams.exportLicenseList.length > 1 && !this.isIndonesia()) {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK362'))
            isOk = false
          }
        } else if (this.isIndonesia()) {
          if (elInfo.emptyYn !== 'Y') {
            if (this.$ekmtcCommon.isEmpty(selector.value)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
              isOk = false
            }
          }
        }
      }

      if (regParams.exportLicenseList.length > 1) {
        regParams.exportLicenseList.forEach((e, i) => {
          if (i !== idx && e.elNo === elInfo.elNo) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.194'))
            isOk = false
          }
        })
      }

      if (this.regParams.blEdiTypCd === 'S' && this.memberDetail.userCtrCd === 'KR') {
        if (regParams.exportLicenseList.length >= 1 && this.$ekmtcCommon.isNotEmpty(elInfo.elNo)) {
          if (flag === 'ldgTcnt') {
            if (elInfo.prtlLdgYn === 'Y' && (this.$ekmtcCommon.isEmpty(selector.value) || String(selector.value) === '0')) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK344'))
              isOk = false
            }
          } else if (flag === 'pkgtgPkgQty') {
            if (this.$ekmtcCommon.isNotEmpty(elInfo.pkgtgCd) && this.$ekmtcCommon.isEmpty(selector.value)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
              isOk = false
            }
          } else if (flag === 'elNoValdDt') {
            if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
              const today = this.$ekmtcCommon.getStrToDate(String(this.serverYear) + String(this.serverMonth) + String(this.serverDay))
              const elDate = this.$ekmtcCommon.getStrToDate(elInfo.elNoValdDt)

              if (elDate > today) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK345'))
                isOk = false
              }
            }
          } else if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        }
      }

      //SR 1428093 콜롬보향 중량 소수점 2자리 처리
      if (this.regParams.podPortCd === 'CMB' && flag === 'lcsGrsWt') {
        if (selector.value.indexOf('.') > -1) {
          if (selector.value.substring(selector.value.indexOf('.') + 1).length > 2) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.550'))
            isOk = false
          }
        }
      }

      return isOk
    },
    checkValidationSealNo (idx, cntrIdX) {
      let isOk = true
      const regParams = this.regParams
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector(`[name="sealNo_${cntrIdX}_${idx}"]`)
      const sealNo = selector.value
      this.$ekmtcCommon.hideErrorTooltip(selector)
      const xx = cntrIdX + '_' + idx
      regParams.containerList.forEach((v, x) => {
        regParams.containerList[x].sealList.forEach((s, i) => {
          const yy = x + '_' + i
          if (xx !== yy && s.sealNo === sealNo && this.$ekmtcCommon.isNotEmpty(s.sealNo) && v.cntrTypCd !== 'TK') {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.198'))
            isOk = false
          }
        })
      })
      return isOk
    },
    checkValidationInfoAll () {
      let isOk = true
      const regParams = this.regParams
      const arrEl = ['porPlcNm', 'polPortNm', 'podPortNm', 'dlyPlcNm', 'shprCstEnm', 'cneCstEnm', 'ntifCstEnm',
                      'grsWt', 'pkgQty', 'pkgCd', 'hsCd', 'podHsCd', 'cgoCont']

      let cneeChkYn = 'Y'
      let ntifChkYn = 'Y'

      const cneAfrList = this.commonCodes.CS003
      const ntifAftList = this.commonCodes.CS004

      if (Number(this.regParams.jpPodCount) > 0 && Number(this.regParams.cnPodCount) > 0) {
        const cneCstInfo = cneAfrList.filter(vo => vo.cdNm === this.regParams.cneCstEnm)
        if (cneCstInfo !== null && cneCstInfo !== undefined && cneCstInfo.length > 0) {
          cneeChkYn = 'N'
        }

        const ntifCstInfo = ntifAftList.filter(vo => vo.cdNm === this.regParams.ntifCstEnm)
        if (ntifCstInfo !== null && ntifCstInfo !== undefined && ntifCstInfo.length > 0) {
          ntifChkYn = 'N'
        }
      }

      if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03')) {
        if (Number(regParams.jpPodCount) > 0) {
          const addEl = ['actShprCstTelNo']
          if (ntifChkYn === 'Y') {
            addEl.push('cneCstTelNo')
            addEl.push('ntifCstTelNo')
          }

          addEl.forEach(el => {
            arrEl.push(el)
          })
        }

        if (Number(regParams.cnPodCount) > 0) {
          const addEl = ['actShprCstPicNm', 'actShprCstTelNo', 'cneCstPicNm', 'cneCstTelNo', 'ntifCstPicNm', 'ntifCstTelNo']
          addEl.forEach(el => {
            arrEl.push(el)
          })
        }

        if (Number(regParams.cnPodCount) > 0 || Number(regParams.shaPodTsCount) > 0) {
          const addEl = ['actShprIdNo', 'cneIdNo', 'ntifIdNo']
          addEl.forEach(el => {
            arrEl.push(el)
          })
        }
      }

      if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW' || regParams.polCtrCd === 'MY') {
        const addEl = ['actShprIdCatCd', 'cneIdNo', 'ntifIdNo']
        addEl.forEach(el => {
          arrEl.push(el)
        })

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          arrEl.push('actShprIdNo')
        }
      }

      if (regParams.polPortCd === 'SHA' || regParams.polShaTsYn === 'Y') {
        let addEl = []
        //if (regParams.cneCstEnm.indexOf('TO ORDER') === -1) {
          if (cneeChkYn === 'Y') {
            addEl.push('cneIdCatCd')
            addEl.push('cneIdNo')
          }
          if (this.$ekmtcCommon.isEmpty(regParams.cneIdCatCd) &&
              this.$ekmtcCommon.isNotEmpty(regParams.cneIdNo)) {
            addEl.push('cneIdCatCd')
          }

          if (this.$ekmtcCommon.isNotEmpty(regParams.cneIdCatCd) &&
              this.$ekmtcCommon.isEmpty(regParams.cneIdNo)) {
            addEl.push('cneIdNo')
          }
        //}

        addEl.forEach(el => {
          arrEl.push(el)
        })
      }

      if (regParams.polCtrCd === 'IN') {
        const addEl = ['actShprCstCtrCd', 'actShprCstCityNm', 'actShprPostNo', 'actShprCstPicNm', 'actShprCstTelNo', 'actShprCstFaxNo', 'actShprCstEmlAddr',
                        'cneCstCtrCd', 'cneCstCityNm', 'cneCstPostNo', 'cneCstPicNm', 'cneCstTelNo', 'cneCstFaxNo', 'cneCstEmlAddr',
                        'ntifCstCtrCd', 'ntifCstCityNm', 'ntifCstPostNo', 'ntifCstPicNm', 'ntifCstEmlAddr',
                        'iecNo', 'invValCurCd', 'invValExp'
                      ]
        addEl.forEach(el => {
          arrEl.push(el)
        })

        if (regParams.actShprCstCtrCd === 'IN') {
          arrEl.push('actShprStateCd')
        } else {
          arrEl.push('actShprStateNm')
        }
        if (regParams.cneCstCtrCd === 'IN') {
          arrEl.push('cneCstStateCd')
        } else {
          arrEl.push('cneCstStateNm')
        }

        if (regParams.ntifCstCtrCd === 'IN') {
          arrEl.push('ntifCstStateCd')
        } else {
          arrEl.push('ntifCstStateNm')
        }
      }

      if (regParams.polCtrCd === 'ID') {
        let addEl = ['etTaxNo']
        addEl.forEach(el => {
          arrEl.push(el)
        })
      }

      if (regParams.podCtrCd === 'ID') {
        let addEl = ['idTaxNo']
        addEl.forEach(el => {
          arrEl.push(el)
        })
      }
      if (regParams.podCtrCd === 'EG') {
        let addEl = ['acidNo', 'cneAcidNo']
        addEl.forEach(el => {
          arrEl.push(el)
        })
      }
      if (regParams.podCtrCd === 'IN') {
        let addEl = ['actShprCstCtrCd', 'actShprCstCityNm', 'actShprCstEmlAddr',
                      'cneCstCtrCd', 'cneCstCityNm', 'cneCstPostNo', 'cneCstEmlAddr',
                      'ntifCstCtrCd', 'ntifCstCityNm', 'ntifCstPostNo', 'ntifCstEmlAddr', 'iecNo', 'ntifPanNo',
                      'invValCurCd', 'invValExp'
                    ]
        addEl.forEach(el => {
          arrEl.push(el)
        })

        if (regParams.actShprCstCtrCd === 'IN') {
          arrEl.push('actShprStateCd')
        } else {
          arrEl.push('actShprStateNm')
        }

        if (regParams.cneCstCtrCd === 'IN') {
          arrEl.push('cneCstStateCd')
        } else {
          arrEl.push('cneCstStateNm')
        }

        if (regParams.ntifCstCtrCd === 'IN') {
          arrEl.push('ntifCstStateCd')
        } else {
          arrEl.push('ntifCstStateNm')
        }
      }

      if (regParams.podCtrCd === 'VN') {
        if (regParams.cneCstEnm.indexOf('TO ORDER') === -1) {
          arrEl.push('cneCstTelNo')
          arrEl.push('cneCstEmlAddr')
        } else {
          arrEl.push('ntifCstTelNo')
          arrEl.push('ntifCstEmlAddr')
        }
        //arrEl.push('cneCstFaxNo')
        arrEl.push('idTaxNo')
        if (regParams.wstCgoYn === 'Y') {
          arrEl.push('imLicNo')
          arrEl.push('imBnkGurNo')
        }
      }

      if (regParams.podPortCd === 'NSA') {
        arrEl.push('gstNo')
        arrEl.push('iecPicEmlAddr')
      }

      if (regParams.polCtrCd === 'PH' || regParams.podCtrCd === 'PH') {
        //담당자, 전화번호, 이메일
        let addEl = ['actShprCstPicNm', 'actShprCstTelNo', 'cneCstPicNm', 'cneCstTelNo', 'cneCstEmlAddr',
          'ntifCstPicNm', 'ntifCstTelNo', 'ntifCstEmlAddr'
        ]
        addEl.forEach(el => {
          arrEl.push(el)
        })
      }

      arrEl.forEach(el => {
        if (!this.checkValidationInfo(el)) {
          isOk = false
        }
      })

      return isOk
    },
    checkValidationInfo (id) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + id)
      const regParams = this.regParams

      if (id !== 'podHsCd' && id !== 'hsCd') {
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }
      /*
      if (id === 'pkgQty') {
        this.fnPackage('CHANGE')
      }
      */
      if (id === 'blPrtSntcCont') {
        if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
          if (this.regParams.blPrtSntcCont.length > 57) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.207'))
            isOk = false
          }
        } else if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      } else if (id === 'podHsCd') {
        // 카고 모드 01037 체크 (현재 무조건 01만 사용하고 나머지 사용 안한다고 함)
        if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') && this.$ekmtcCommon.isEmpty(selector.value)) {
          // if문 한개에 조건이 너무 많아 구분하여 분리함
          // blEdiTypCd : E/L TYPE - E-EMPTY, S-LINE, C-HOUSE B/L / emptyFullYn : CS002I 조회하여 컨테이너가 풀이면 Y, empty면 N으로 세팅됨
          if (regParams.blEdiTypCd !== 'E' || regParams.emptyFullYn === 'Y') {
            // POD = 'JP' OR 'MY', (POL = 'NBO', VSL_CD = 'FDR') 체크
            if (Number(regParams.jpPodCount) !== 0 || Number(regParams.nboFdrCount) !== 0 || Number(regParams.myPodCount) !== 0) {
              this.hsChkParams.hscdPodErrorMessage = ''
              this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
              isOk = false
            }
            // POD, DLY 국가별 체크
            if (regParams.podCtrCd === 'ID' || regParams.podCtrCd === 'IN' || regParams.podCtrCd === 'VN' ||
              regParams.podCtrCd === 'PK' || regParams.dlyCtrCd === 'PH') {
              this.hsChkParams.hscdPodErrorMessage = ''
              this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
              isOk = false
            }
            // POL 태국, POD 특정 포트 체크
            if (regParams.polCtrCd === 'TH' && (regParams.podPortCd === 'JEA' || regParams.podPortCd === 'SOH')) {
              this.hsChkParams.hscdPodErrorMessage = ''
              this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
              isOk = false
            }
            // 이외 복합조건 아래에 추가하면 됨
            if (regParams.shaTsYn === 'Y' || regParams.aimRteYn === 'Y') {
              this.hsChkParams.hscdPodErrorMessage = ''
              this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
              isOk = false
            }
            // SR 1353609 특정 POD 포트인 경우 POL, POD HS CODE 필수 입력 요청 건 체크
            // 1. 방콕, 라엠차방, 라크라방(태국), 자카르타(인도네시아)
            // 2. 하이퐁(베트남), 황푸, 마웨이, 샤먼(중국)
            // 3. 첸나이, 문드라, 나바셰바 (인도), 세마랑(인도네시아)
            // 4. 일본 모든 포트
            // 인도, 인도네시아, 베트남은 이미 POD 국가로 추가 되어 있어 제외. 일본은 위 POD 국가 조건에 추가. 나머지 포트 아래 추가.
            // 2024.02.02 고서팀 최기영 대리님 요청으로 원복(아래 주석처리)함.
            // if (regParams.podPortCd === 'BKK' || regParams.podPortCd === 'LCH' || regParams.podPortCd === 'LKB' ||
            //   regParams.podPortCd === 'HUA' || regParams.podPortCd === 'FOC' || regParams.podPortCd === 'XMN') {
            //   this.hsChkParams.hscdPodErrorMessage = ''
            //   this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
            //   isOk = false
            // }
          }
        }
        // if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') && this.$ekmtcCommon.isEmpty(selector.value)) {
        //   if (((regParams.blEdiTypCd !== 'E' || regParams.emptyFullYn === 'Y') && Number(regParams.jpPodCount) !== 0) ||
        //       (regParams.blEdiTypCd !== 'E' && (
        //         Number(regParams.nboFdrCount) !== 0 ||
        //         (regParams.podCtrCd === 'ID' || Number(regParams.myPodCount) !== 0 || regParams.podCtrCd === 'IN') ||
        //         regParams.podCtrCd === 'VN' ||
        //         regParams.podCtrCd === 'PK' ||
        //         regParams.shaTsYn === 'Y' ||
        //         regParams.aimRteYn === 'Y' ||
        //         regParams.dlyCtrCd === 'PH' || (regParams.polCtrCd === 'TH' && (regParams.podPortCd === 'JEA' || regParams.podPortCd === 'SOH'))
        //       ))
        //   ) {
        //     this.hsChkParams.hscdPodErrorMessage = ''
        //     this.$nextTick(() => {
        //       this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147')
        //     })
        //     isOk = false
        //   }
        // }
      } else if (id === 'hsCd') {
        if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') && this.$ekmtcCommon.isEmpty(selector.value)) {
          // POL 국가별 체크
          if (regParams.polCtrCd === 'ID' || regParams.polCtrCd === 'MY' || regParams.polCtrCd === 'IN') {
            this.hsChkParams.hscdPolErrorMessage = ''
            this.$nextTick(() => { this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.147') })
            isOk = false
          }
          // POL 태국, POD 특정 포트 체크
          if (regParams.polCtrCd === 'TH' && (regParams.podPortCd === 'JEA' || regParams.podPortCd === 'SOH')) {
            this.hsChkParams.hscdPolErrorMessage = ''
            this.$nextTick(() => { this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.147') })
            isOk = false
          }
          // SR 1353609 특정 POD 포트인 경우 POL, POD HS CODE 필수 입력 요청 건 체크
          // 1. 방콕, 라엠차방, 라크라방(태국), 자카르타(인도네시아)
          // 2. 하이퐁(베트남), 황푸, 마웨이, 샤먼(중국)
          // 3. 첸나이, 문드라, 나바셰바 (인도), 세마랑(인도네시아)
          // 4. 일본 모든 포트
          // 2024.02.02 고서팀 최기영 대리님 요청으로 원복(아래 주석처리)함.
          // if (regParams.podPortCd === 'BKK' || regParams.podPortCd === 'LCH' || regParams.podPortCd === 'LKB' ||
          //   regParams.podPortCd === 'JKT' || regParams.podPortCd === 'HPH' || regParams.podPortCd === 'HUA' ||
          //   regParams.podPortCd === 'FOC' || regParams.podPortCd === 'XMN' || regParams.podPortCd === 'MAA' ||
          //   regParams.podPortCd === 'MUN' || regParams.podPortCd === 'NSA' || regParams.podPortCd === 'SRG' ||
          //   regParams.podCtrCd === 'JP') {
          //   this.hsChkParams.hscdPolErrorMessage = ''
          //   this.$nextTick(() => { this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.147') })
          //   isOk = false
          // }
          // SR 1440595 POL HS CODE 필수 기입값으로 제약함
          // 긴급 배포 요청으로 다시 제거
          // if (this.$ekmtcCommon.isEmpty(selector.value)) {
          //   this.hsChkParams.hscdPolErrorMessage = ''
          //   this.$nextTick(() => {
          //     this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.549')
          //   })
          //   isOk = false
          // }
        }
        // if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') && (regParams.polCtrCd === 'ID' || regParams.polCtrCd === 'MY' || regParams.polCtrCd === 'IN' || (regParams.polCtrCd === 'TH' && (regParams.podPortCd === 'JEA' || regParams.podPortCd === 'SOH')))) {
        //   if (this.$ekmtcCommon.isEmpty(selector.value)) {
        //     this.hsChkParams.hscdPolErrorMessage = ''
        //     this.$nextTick(() => {
        //       this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.147')
        //     })
        //     isOk = false
        //   }
        // }
      } else if (id === 'actShprIdNo') {
        const actCstIdInfo = this.actCstIdList.filter(vo => vo.cstIdCatCd === regParams.actShprIdCatCd)

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            if (actCstIdInfo !== undefined && actCstIdInfo.length > 0) {
              if (Number(actCstIdInfo[0].minInpLen) > selector.value.length ||
                  Number(actCstIdInfo[0].maxInpLen) < selector.value.length
              ) {
                const message = this.$t('msg.CSBL200.210') + actCstIdInfo[0].minInpLen + ' ~ ' + actCstIdInfo[0].maxInpLen + ')'
                this.$ekmtcCommon.showErrorTooltip(selector, message)
                isOk = false
              }
            }
          }
        } else {
          if (actCstIdInfo !== undefined && actCstIdInfo.length > 0) {
            if (Number(actCstIdInfo[0].minInpLen) > selector.value.length ||
                Number(actCstIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.210') + actCstIdInfo[0].minInpLen + ' ~ ' + actCstIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          }
        }
      } else if (id === 'actShprIdCatCd') {
        if ((regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      } else if (id === 'cneIdNo') {
        const cneIdInfo = this.cneCstIdList.filter(vo => vo.cstIdCatCd === regParams.cneIdCatCd)
        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          if (this.$ekmtcCommon.isNotEmpty(selector.value) && cneIdInfo !== undefined && cneIdInfo.length > 0) {
            if (Number(cneIdInfo[0].minInpLen) > selector.value.length ||
                Number(cneIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.211') + cneIdInfo[0].minInpLen + ' ~ ' + cneIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          } else if (this.$ekmtcCommon.isNotEmpty(frm.querySelector('#cneIdCatCd').value) && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
          if (regParams.polPortCd === 'SHA' || regParams.polShaTsYn === 'Y') {
            if (this.$ekmtcCommon.isEmpty(selector.value)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
              isOk = false
            }
          }
        } else {
          if (regParams.polPortCd === 'SHA' || regParams.polShaTsYn === 'Y') {
            if (this.$ekmtcCommon.isEmpty(selector.value)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
              isOk = false
            }
          }
          if (this.$ekmtcCommon.isNotEmpty(selector.value) && cneIdInfo !== undefined && cneIdInfo.length > 0) {
            if (Number(cneIdInfo[0].minInpLen) > selector.value.length ||
                Number(cneIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.211') + cneIdInfo[0].minInpLen + ' ~ ' + cneIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          }
        }
      } else if (id === 'ntifIdNo') {
        const ntifIdInfo = this.notiCstIdList.filter(vo => vo.cstIdCatCd === regParams.ntifIdCatCd)

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          if (this.$ekmtcCommon.isNotEmpty(selector.value) && ntifIdInfo !== undefined && ntifIdInfo.length > 0) {
            if (Number(ntifIdInfo[0].minInpLen) > selector.value.length ||
                Number(ntifIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.212') + ntifIdInfo[0].minInpLen + ' ~ ' + ntifIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          } else if (this.$ekmtcCommon.isNotEmpty(frm.querySelector('#ntifIdCatCd').value) && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        } else {
          if (this.$ekmtcCommon.isNotEmpty(selector.value) && ntifIdInfo !== undefined && ntifIdInfo.length > 0) {
            if (Number(ntifIdInfo[0].minInpLen) > selector.value.length ||
                Number(ntifIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.212') + ntifIdInfo[0].minInpLen + ' ~ ' + ntifIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          }
        }
      } else if (id === 'iecNo') {
        if (regParams.polCtrCd === 'IN' && this.$ekmtcCommon.isEmpty(selector.value)) {
          if (this.$ekmtcCommon.isEmpty(regParams.panNo)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.213'))
            isOk = false
          }
        } else if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          if (selector.value.length < 10) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.214'))
            isOk = false
          }
        }
      } else if (id === 'ntifPanNo') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else if (selector.value.length < 10) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.214'))
          isOk = false
        }
      } else if (id === 'grsWt') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          //SR 1428093 콜롬보향 중량 소수점 2자리 처리
          if (this.regParams.podPortCd === 'CMB') {
            if (selector.value.indexOf('.') > -1) {
              if (selector.value.substring(selector.value.indexOf('.') + 1).length > 2) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.550'))
                isOk = false
              }
            }
          }
        }
      } else if (id.indexOf('wt') === 0) {
        //SR 1428093 콜롬보향 중량 소수점 2자리 처리
        if (this.regParams.podPortCd === 'CMB') {
          if (selector.value.indexOf('.') > -1) {
            if (selector.value.substring(selector.value.indexOf('.') + 1).length > 2) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.550'))
              isOk = false
            }
          }
        }
      } else if (id === 'acidNo') {
        if (this.regParams.podCtrCd === 'EG') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            if (selector.value.length !== 19) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.295'))
              isOk = false
            }
          }
        }
      } else if (id === 'cneAcidNo') {
        if (this.regParams.podCtrCd === 'EG') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            if (selector.value.length !== 9) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.296'))
              isOk = false
            }
          }
        }
      } else if (id === 'idTaxNo') {
        if (this.regParams.podCtrCd === 'ID') {
          this.$ekmtcCommon.hideErrorTooltip(selector)
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            if (this.regParams.idTaxNo.length !== 16) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200_M.038'))
              isOk = false
            }
          }
        }
      } else if (id === 'grsCbm') {
        if (this.regParams.podCtrCd === 'BH') {
          if (Number(this.regParams.grsCbm) <= 0) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.238'))
            isOk = false
          }
        }
      } else {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      return isOk
    },
    async checkValidationIranHsCdAll () {
      let isOk = true

      if (!await this.fnIranCheck('POL')) {
        this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.117')
        isOk = false
      }

      if (!await this.fnIranCheck('POD')) {
        this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.117')
        isOk = false
      }

      return isOk
    },
    checkValidationHsCd () {
      let isOk = true

      if (this.hsChkParams.polSuccYn === 'N' || this.hsChkParams.podSuccYn === 'N') {
        isOk = false
      }

      return isOk
    },
    checkCntrGrossWeightAndPackage (key) {
      const regParams = this.regParams
      let totalVal = 0

      if (key === 'meaCbm') {
        totalVal = Number(regParams.grsCbm)
      } else if (key === 'wt') {
        totalVal = Number(regParams.grsWt)
      } else {
        totalVal = Number(regParams.pkgQty)
      }

      let checkVal = 0
      let zeroIndex = -1

      let tmpWt = 0
      regParams.containerList.forEach((c, i) => {
        if (this.$ekmtcCommon.isNotEmpty(c[key])) {
          tmpWt += Number(c[key])
        }

        if (i > 0 && zeroIndex === -1 && tmpWt === 0) {
          zeroIndex = i
        }
      })

      if (totalVal !== 0) {
        totalVal = parseInt(totalVal * 1000) / 1000
      }

      if (tmpWt !== 0) {
        tmpWt = parseInt(tmpWt * 1000) / 1000
      }
      if (tmpWt === 0) {
        if (key === 'wt') {
          regParams.wtCmplsYn = 'N'
        }
        return 0
      } else if (tmpWt !== totalVal) {
        checkVal = tmpWt - totalVal

        if (checkVal >= 1 || checkVal <= -1) {
          return -1
        }
        regParams.wtCmplsYn = 'Y'
      } else {
        if (zeroIndex > -1) {
          return -2
        } else {
          if (key === 'wt') {
            regParams.wtCmplsYn = 'Y'
          }
          return 1
        }
      }
    },
    checkTotPkgWt () {
      const regParams = this.regParams
      const totalPkg = Number(regParams.pkgQty)
      const totalWt = Number(regParams.grsWt)
      const totalCbm = Number(regParams.grsCbm)

      let sumPkg = 0
      let sumWt = 0
      let pkgtgCdCheck = ''
      let elCheck = 'N'

      regParams.exportLicenseList.forEach(vo => {
        sumPkg += Number(vo.pkgQty)
        sumWt = Math.round((sumWt + Number(vo.grsWt)) * 1000) / 1000

        if (vo.pkgtgCd === 'A') {
          pkgtgCdCheck = 'Y'
        }

        if (this.$ekmtcCommon.isNotEmpty(vo.elNo)) {
          elCheck = 'Y'
        }
      })

      if (this.elDateFlag !== 'Y') {
        if (sumPkg !== totalPkg && pkgtgCdCheck !== 'Y' && elCheck === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK349'))
          regParams.exportLicenseList.forEach((vo, idx) => {
            this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#lcsPkgQty_' + idx), this.$t('art.CMATK349'))
          })
          return false
        }

        if (sumWt !== totalWt && pkgtgCdCheck !== 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK350'))
          regParams.exportLicenseList.forEach((vo, idx) => {
            this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#lcsGrsWt_' + idx), this.$t('art.CMATK350'))
          })
          return false
        }
      }

      return true
    },
    async getRtnPhbtChr () {
      const regParams = this.regParams
      const etd = this.$ekmtcCommon.isNotEmpty(regParams.etdDt) ? regParams.etdDt.substring(0, 8) : ''
      const eta = this.$ekmtcCommon.isNotEmpty(regParams.etaDt) ? regParams.etaDt.substring(0, 8) : ''
      const params = {
        bkgNo: regParams.bkgNo,
        porCtrCd: regParams.porCtrCd,
        porPortCd: regParams.porPlcCd,
        polCtrCd: regParams.polCtrCd,
        polPortCd: regParams.polPortCd,
        podCtrCd: regParams.podCtrCd,
        podPortCd: regParams.podPortCd,
        podTrmlCd: regParams.podTrmlCd,
        dlyCtrCd: regParams.dlyCtrCd,
        dlyPortCd: regParams.dlyPlcCd,
        uiFlag: 'BL',
        eta: eta,
        etd: etd,
        bkgDt: regParams.bkgDt,
        blNo: regParams.blNo
      }

      let blPhbtList = []
      await trans.getRtnPhbtChrChk(params).then(res => {
        if (res.headers.respcode === 'C0000') {
          blPhbtList = res.data.rtnPhbtList
        }
      })

      return blPhbtList
    },
    checkValidationNboErr (obj, flag, str, blPhbtList) {
      let rtnStr = str

      blPhbtList.forEach(vo => {
        if (vo.apclYn === 'N') {
          if (obj.indexOf(vo.phbtChr) > -1) {
            if (flag === 'DSCR') {
              this.regParams.descrErrorYn = 'Y'
              this.regParams.descrErrorAllYn = vo.tsYn === 'N' ? 'Y' : ''

              if (rtnStr.indexOf(vo.phbtChr) === -1) {
                rtnStr += vo.phbtChr + '\n'
              }
            } else {
              this.regParams.bytextErrorYn = 'Y'
              this.regParams.descrErrorAllYn = vo.tsYn === 'N' ? 'Y' : ''

              if (rtnStr.indexOf(vo.phbtChr) === -1) {
                rtnStr += vo.phbtChr + '\n'
              }
            }
          }

          this.regParams.descrCaYn = 'N'
        }
      })

      return rtnStr
    },
    async checkValidationPhbtChrAll (blPhbtList) {
      let isOk = true
      const regParams = this.regParams

      blPhbtList.forEach(vo => {
        if (vo.apclYn === 'Y' || vo.apclYn === 'A') {
          regParams.markDescList.forEach(async (m, idx) => {
            if (!await this.checkValidationPhbtChr('mrk', idx, vo)) {
              isOk = false
            }

            if (!await this.checkValidationPhbtChr('dscr', idx, vo)) {
              isOk = false
            }
          })
        }
      })

      return isOk
    },
    async checkValidationPhbtChr (flag, idx, phbtInfo) {
      const regParams = this.regParams
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + flag + '_' + idx)

      const cntrSzArr = []
      const cntrTypArr = []
      const cntrCgoArr = []

      regParams.containerList.forEach(c => {
        cntrSzArr.push(c.cntrSzCd)
        cntrTypArr.push(c.cntrTypCd)
        cntrCgoArr.push(c.cgoTypCd)
      })

      this.$ekmtcCommon.hideErrorTooltip(selector)

      let message = ''

      if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
        if (selector.value.indexOf(phbtInfo.phbtChr) > -1) {
          if (this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cgoTypCd)) {
            if (cntrSzArr.indexOf(phbtInfo.cntrSzCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cgoTypCd)) {
            if (cntrSzArr.indexOf(phbtInfo.cntrSzCd) > -1 && cntrTypArr.indexOf(phbtInfo.cntrTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cgoTypCd)) {
            if (cntrSzArr.indexOf(phbtInfo.cntrSzCd) > -1 && cntrTypArr.indexOf(phbtInfo.cntrTypCd) > -1 && cntrCgoArr.indexOf(phbtInfo.cgoTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cgoTypCd)) {
            if (cntrTypArr.indexOf(phbtInfo.cntrTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cgoTypCd)) {
            if (cntrTypArr.indexOf(phbtInfo.cntrTypCd) > -1 && cntrCgoArr.indexOf(phbtInfo.cgoTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cgoTypCd)) {
            if (cntrCgoArr.indexOf(phbtInfo.cgoTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cgoTypCd)) {
            if (cntrSzArr.indexOf(phbtInfo.cntrSzCd) > -1 && cntrCgoArr.indexOf(phbtInfo.cgoTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else {
            message = phbtInfo.rmk
          }
        }
      }

      if (phbtInfo.apclYn === 'Y' && message !== '') {
        this.$ekmtcCommon.alertDefault(message)
        return false
      } else if (phbtInfo.apclYn === 'A' && message !== '') {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: message, useConfirmBtn: true })) {
          return false
        } else {
          return true
        }
      }

      return true
    },
    checkCAword (str) { // CA_특수문자 제약 체크
      if (this.$ekmtcCommon.isEmpty(str)) {
        return
      }

      if (str.indexOf('ICD') > -1 || str.indexOf('NEPAL') > -1 || str.indexOf('VALUE') > -1 ||
          str.indexOf('CLEAN') > -1 || str.indexOf('ORIGIN') > -1 || str.indexOf('CERTI') > -1 || str.indexOf('UNDER') > -1 || // 220520. 누락분 추가
          str.indexOf('FREE') > -1 || str.indexOf('DET') > -1 || str.indexOf('DEM') > -1
        ) {
        this.regParams.descrCaYn = 'N'
      }

      if (this.regParams.podCtrCd === 'IN' || this.regParams.podCtrCd === 'PK' || this.regParams.podCtrCd === 'BD' || this.regParams.podCtrCd === 'RU') {
        if (str.indexOf('WASTE') > -1 || str.indexOf('SCRAP') > -1) {
          this.regParams.descrCaYn = 'N'
        }
      }
    },
    checkCAwordBL (str) {
      if (str.indexOf('FLEXI') > -1) {
        this.regParams.flexiYn = 'Y'
      }
    },
    fnCommonBtnAction (type, path) {
      this.commonBLCallback = ''
      if (type === 'PBL' || type === 'NBL') {
        this.commonBLParams = {
          action: type,
          bkgNo: this.regParams.bkgNo,
          blNo: this.regParams.blNo,
          saveFlag: this.saveFlag
        }

        this.commonBLCallback = this.setAddSrNumberInfo
      } else if (type === 'GOLIST') {
        this.commonBLParams = {
          action: type,
          workType: 'E'
        }
      } else if (type === 'VGMPOP_SAVE' || type === 'VGMPOP_OPEN') {
        const vgmFlag = type.split('_')[1]
        this.commonBLParams = {
          action: 'VGMPOP',
          vgmFlag: vgmFlag,
          bkgNo: this.regParams.bkgNo,
          blNo: this.regParams.blNo,
          methCd: this.regParams.bkgAcpnMethCd,
          originCntrList: this.originParams.containerList,
          tareModifyYn: this.cstCatCd === 'Y' || this.regParams.shprVanYn === 'Y' ? 'Y' : 'N'
        }

        if (vgmFlag === 'SAVE') {
          this.commonBLCallback = this.refresh
        }
      } else if (type === 'ERROR_CHECK') {
        this.commonBLParams = {
          action: 'ERROR_CHECK',
          blNo: this.regParams.blNo
        }
      } else if (type === 'JPCLP') {
        this.commonBLParams = {
          action: 'JPCLP',
          blNo: this.regParams.blNo
        }
      } else if (type === 'SHAMF') {
        this.commonBLParams = {
          action: 'SHAMF',
          blNo: this.regParams.blNo,
          originCntrList: this.originParams.containerList,
          grsWt: this.regParams.grsWt,
          pkgQty: this.regParams.pkgQty,
          grsCbm: this.regParams.grsCbm,
          polShtmCd: this.regParams.polShtmCd,
          path: path,
          containerList: this.regParams.containerList,
          polCtrCd: this.regParams.polCtrCd
        }

        this.commonBLCallback = this.setSHAMFReturn
      } else if (type === 'LOG') {
        this.commonBLParams = {
          action: 'LOG',
          blNo: this.regParams.blNo,
          bkgNo: this.regParams.bkgNo
        }
      } else if (type === 'TRD_SHIPPER') {
        this.commonBLParams = {
          action: 'TRD_SHIPPER',
          blNo: this.regParams.blNo,
          bkgNo: this.regParams.bkgNo
        }

        this.commonBLCallback = this.setShipperRegInfo
      } else if (type === 'TRD_SHIPPER_FILE') {
        this.commonBLParams = {
          action: 'TRD_SHIPPER_FILE',
          blNo: this.regParams.blNo,
          trdFileInfo: this.regParams.trdFileInfo
        }
      }

      if (this.commonBLComponent === null) {
        this.commonBLComponent = 'common-bl'
      } else {
        this.$nextTick(() => {
          this.$refs.commonBL.initPageFlag()
          this.$refs.commonBL.doAction()
        })
      }
    },
    async setSHAMFReturn (vo) {
      this.regParams.shaEmanYn = 'Y'
      this.regParams.emanPkgQty = Number(vo.sumQty.replace(/,/g, ''))
      this.regParams.emanNetWt = Number(vo.sumWt.replace(/,/g, ''))
      this.regParams.emanNetCbm = Number(vo.sumCbm.replace(/,/g, ''))

      await this.getSndShaYn().then(sndShaYn => {
        if (sndShaYn === 'Y') {
          this.regParams.afaCostYn = 'Y'
        }
      })

      this.regParams.path = vo.path

      if (vo.path === 'CAREQ') {
        this.fnImCaPending()
      }

      await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.293') })
    },
    setAddSrNumberInfo (nextBlNo) {
      this.$emit('callback', nextBlNo)
    },
    isIndonesia () {
      if (this.lang === 'ENG' && this.regParams.polCtrCd === 'ID' && this.usrCtrCd === 'ID') {
        return true
      } else {
        return false
      }
    },
    isExportLicense () {
      if (!this.isIndonesia() && this.regParams.blEdiTypCd !== 'C' && (this.lang === 'KOR' || this.regParams.polCtrCd === 'KR')) {
        return true
      } else {
        return false
      }
    },
    async fnDlyTrmlMsgChk () {
      let isOk = true
      const dlyPlcCd = this.regParams.dlyPlcCd
      const plcNm = this.regParams.dlyPlcNm

      const params = {
        kind: 'BL',
        blNo: this.regParams.blNo,
        dlyPlcNm: this.regParams.dlyPlcNm
      }
      await trans.findTrmlCompare(params).then(async res => {
        if (res.headers.respcode === 'C0000' && this.$ekmtcCommon.isNotEmpty(res.data)) {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: res.data, useConfirmBtn: true })) {
            isOk = false
          }
        }
      })

      return isOk
    },
    changeTopMarkDscr (e, key) {
      const val = e.target.value
      const obj = this.regParams.markDescList[0]

      if (key === 'dscr') {
        document.querySelector('#dscr_0').value = val
        obj.dscr = val
        this.initDesc()
      } else {
        document.querySelector('#mrk_0').value = val
        obj.mrk = val
      }
    },
    changeBlEdiTypCd (e) {
      const ediTypCd = e.target.value

      if (ediTypCd === 'C') {
        this.$set(this.regParams, 'exportLicenseList', [])
        this.addExportLicense()
      }
    },
    setChangeValueCheck () {
      const checkObj = [
        { checkId: 'polShtmCd', caCatCd: '01', identify: '500', element: 'TOT_POL_SHTM_CD' },
        { checkId: 'podShtmlCd', caCatCd: '01', identify: '501', element: 'TOT_POD_SHTM_CD' },
        { checkId: 'grsCbm', caCatCd: '01', identify: '190', element: 'TOT_GRS_CBM' },
        { checkId: 'grsWt', caCatCd: '01', identify: '180', element: 'TOT_GRS_WT' },
        { checkId: 'pkgCd', caCatCd: '01', identify: '920', element: 'TOT_PKG_CD' },
        { checkId: 'blEdiTypCd', caCatCd: '01', identify: '440', element: 'BL_EDI_TYP_CD' },
        { checkId: 'cmdtDsc', caCatCd: '01', identify: '850', element: 'CGO_CONT' },
        { checkId: 'mrk', caCatCd: '03', identify: '170', element: 'MRK' },
        { checkId: 'dscr', caCatCd: '03', identify: '170', element: 'DSCR' },
        { checkId: 'cntrNo', caCatCd: '05', identify: '140', element: 'CNTR_NO' },
        { checkId: 'cntrSzCd', caCatCd: '05', identify: '140', element: 'CNTR_SZ_CD' },
        { checkId: 'cntrTypCd', caCatCd: '05', identify: '140', element: 'CNTR_TYP_CD' },
        { checkId: 'cgoTypCd', caCatCd: '05', identify: '140', element: 'CGO_TYP_CD' },
        { checkId: 'shprVanYn', caCatCd: '05', identify: '140', element: 'SHPR_VAN_YN' },
        { checkId: 'sealNo', caCatCd: '05', identify: '140', element: 'SEAL_NO1' },
        { checkId: 'pkgQty', caCatCd: '07', identify: '170', element: 'epInfo' },
        { checkId: 'pkgCd', caCatCd: '07', identify: '170', element: 'epInfo' },
        { checkId: 'grsWt', caCatCd: '07', identify: '170', element: 'epInfo' },
        { checkId: 'prtlLdgYn', caCatCd: '07', identify: '170', element: 'epInfo' },
        { checkId: 'ldgTcnt', caCatCd: '07', identify: '170', element: 'epInfo' },
        { checkId: 'pkgtgCd', caCatCd: '07', identify: '170', element: 'epInfo' },
        { checkId: 'pkgtgPkgQty', caCatCd: '07', identify: '170', element: 'epInfo' },
        { checkId: 'elNoValdDt', caCatCd: '07', identify: '170', element: 'epInfo' }
      ]

      const oldParam = this.originParams
      const newParam = this.regParams
      checkObj.forEach(obj => {
        if (obj.caCatCd === '01') {
          if (String(oldParam[obj.checkId]) !== String(newParam[obj.checkId])) {
            this.fnChangeValue(obj.caCatCd, obj.identify, oldParam[obj.checkId], newParam[obj.checkId], obj.element, '-1')
          }
        } else if (obj.caCatCd === '03') {
          const oldList = oldParam.markDescList
          const newList = newParam.markDescList

          newList.forEach((vo, i) => {
            const oldInfo = oldList[i]

            if (oldInfo !== undefined && oldInfo !== null) {
              if (String(oldInfo[obj.checkId]) !== String(vo[obj.checkId])) {
                this.fnChangeValue(obj.caCatCd, obj.identify, '  #,#  ', vo.mrk + '#,#  ', 'MRK', (i + 1))
                this.fnChangeValue(obj.caCatCd, obj.identify, '  #,#' + oldInfo.dscr, '  #,#' + vo.dscr, 'DSCR', (i + 1))
              }
            } else {
              this.fnChangeValue(obj.caCatCd, obj.identify, '  #,#  ', vo.mrk + '#,#  ', 'MRK', (i + 1))
              this.fnChangeValue(obj.caCatCd, obj.identify, '  #,#  ', '  #,#' + vo.dscr, 'DSCR', (i + 1))
            }
          })
        } else if (obj.caCatCd === '05') {
          const oldList = oldParam.containerList
          const newList = newParam.containerList

          newList.forEach((vo, i) => {
            const oldInfo = oldList[i]

            if (oldInfo !== undefined && oldInfo !== null) {
              this.fnChangeValue(obj.caCatCd, obj.identify, oldInfo[obj.checkId], vo[obj.checkId], '', i)
            } else {
              this.fnChangeValue(obj.caCatCd, obj.identify, '', vo[obj.checkId], '', i)
            }
          })
        } else if (obj.caCatCd === '07') {
          const oldList = oldParam.exportLicenseList
          const newList = newParam.exportLicenseList

          newList.forEach((vo, i) => {
            const oldInfo = oldList[i]

            if (oldInfo !== undefined && oldInfo !== null) {
              this.fnChangeValue(obj.caCatCd, obj.identify, oldInfo.elNo, vo[obj.checkId], i)
            } else {
              this.fnChangeValue(obj.caCatCd, obj.identify, '', vo[obj.checkId], i)
            }
          })
        }
      })
    },
    fnChangeValue (caCatCd, identify, beforeValue, afterValue, element, index) {
      let compareValue = ''
      let indexValue = ''
      let count = 0

      if (beforeValue === undefined) {
        beforeValue = ''
      }

      if (afterValue === undefined) {
        afterValue = ''
      }

      const updateBLParam = this.updateBLParams

      if (caCatCd === '01') {
        count = 0
        beforeValue = beforeValue.split('@#$').join('"')
        beforeValue = beforeValue.split('`').join('\'')
        beforeValue = beforeValue.split('\\n').join('\r\n')
        afterValue = afterValue.split('@#$').join('"')
        afterValue = afterValue.split('`').join('\'')
        afterValue = afterValue.split('\\n').join('\r\n')

        updateBLParam.blMaster.forEach((vo, i) => {
          let splitValue = vo.split('@,@')[0]
          compareValue = compareValue + splitValue
          if (splitValue === identify) {
            count = i
          }
        })

        if (compareValue.indexOf(identify) <= 0) {
          updateBLParam.blMaster[updateBLParam.blMaster.length] = identify + '@,@' + beforeValue + '@,@' + afterValue + '@,@' + element
        } else {
          updateBLParam.blMaster[count] = identify + '@,@' + beforeValue + '@,@' + afterValue + '@,@' + element
        }
      } else if (caCatCd === '03') {
        count = 0
        beforeValue = beforeValue.split('@#$').join('"')
        beforeValue = beforeValue.split('`').join('\'')
        beforeValue = beforeValue.split('\\n').join('\r\n')
        beforeValue = this.$ekmtcCommon.isNotEmpty(beforeValue) ? beforeValue : ''
        afterValue = afterValue.split('@#$').join('"')
        afterValue = afterValue.split('`').join('\'')
        afterValue = afterValue.split('\\n').join('\r\n')
        afterValue = this.$ekmtcCommon.isNotEmpty(afterValue) ? afterValue : ''

        let replaceBeforeValue = []
        let replaceAfterValue = []

        if (afterValue.indexOf('DSCR') > -1) {
          const dscr = this.regParams.markDescList[0].dscr
          afterValue = '  #,#' + dscr
        }

        updateBLParam.mrkMaster.forEach((vo, i) => {
          if (this.$ekmtcCommon.isEmpty(vo)) {
            vo = '@,@@,@@,@@,@1'
          }

          if (vo.split('@,@')[4] === index) {
            replaceBeforeValue = vo.split('@,@')[1].split('#,#')
            replaceAfterValue = vo.split('@,@')[2].split('#,#')

            if (element === 'MRK') {
              beforeValue = beforeValue + replaceBeforeValue[1]
              afterValue = afterValue + replaceAfterValue[1]
              vo = identify + '@,@' + beforeValue + '@,@' + afterValue + '@,@' + element + '@,@' + index
            } else {
              beforeValue = replaceBeforeValue[0] + beforeValue
              afterValue = replaceAfterValue[0] + afterValue
              vo = identify + '@,@' + beforeValue + '@,@' + afterValue + '@,@' + element + '@,@' + index
            }

            count++
          }
        })

        if (count === 0) {
          if (String(index) === '1') {
            updateBLParam.mrkMaster[0] = identify + '@,@' + beforeValue + '@,@' + afterValue + '@,@' + element + '@,@' + index
          } else {
            if (this.$ekmtcCommon.isEmpty(updateBLParam.mrkMaster[0])) {
              updateBLParam.mrkMaster[0] = identify + '@,@' + beforeValue + '@,@' + afterValue + '@,@' + element + '@,@' + index
            }
          }
        }
      } else if (caCatCd === '05') {
        count = 0
        const cntrInfo = this.regParams.containerList[index]
        let cntrNo = cntrInfo.cntrNo

        if (this.$ekmtcCommon.isEmpty(afterValue)) {
          cntrNo = ' '
        }

        if (this.$ekmtcCommon.isEmpty(beforeValue)) {
          beforeValue = ' '
        }

        updateBLParam.cntrMaster.forEach((vo, idx) => {
          if (vo === cntrNo + '@,@' + beforeValue) {
            count++
          }
        })

        if (count === 0) {
          if (cntrNo === beforeValue) {
            return
          }

          if (cntrNo.length > 1 && cntrNo.length !== 11) {
            return
          }

          updateBLParam.cntrMaster[updateBLParam.cntrMaster.length] = cntrNo + '@,@' + beforeValue
        }

        if (this.regParams.cllCaYn === 'Y') {
          if (element === 'cntrNo') {
            this.regParams.descrCaYn = 'N'
            this.regParams.cllCntnoUpdate = 'Y'
          }
        }
      } else if (caCatCd === '07') {
        count = 0

        updateBLParam.liceMaster.forEach((vo, i) => {
          if (vo === '@,@' + beforeValue) {
            count++
          }
        })

        if (count === 0) {
          updateBLParam.liceMaster[updateBLParam.liceMaster.length] = '@,@' + beforeValue
        }
      }
    },
    async refresh () {
      this.commonBLParams = {}
      await this.init()
    },
    isEmanSave () {
      let emanSave = false
      const regParams = this.regParams

      const arrExistPol = ['NCH', 'AQG', 'CZH', 'TOL', 'YCH', 'JIN', 'JJG', 'JZH', 'JXN',
                            'CHZ', 'CKG', 'CSX', 'CTU', 'CGS', 'HGH', 'HSI', 'JIA', 'CZX',
                            'LUZ', 'NKG', 'NTG', 'KUS', 'SZH', 'TAZ', 'TCG', 'SHA', 'WUH',
                            'WUX', 'YZH', 'WHI', 'ZHE', 'ZJG', 'MSN', 'JIU'
      ]

      const arrExistBkgPlcCd = ['SZP', 'TAO', 'LYG', 'NBO', 'WUH', 'NTG', 'CKG', 'NKG', 'DLC', 'XMN']

      if (regParams.podShaYn === 'Y' &&
        //this.originParams.containerList.length > 0 &&
        this.originCntrLen > 0 && // this.originParams는 1차 가공된 데이터여서 데이터가 조회됐을때 값을 사용
        //regParams.polShtmCd === '01' &&
        arrExistPol.indexOf(regParams.polPortCd) === -1 &&
        (arrExistBkgPlcCd.indexOf(this.bkgPlcCd) === -1 && this.lang !== 'CHN')
      ) {
        if (Number(regParams.pkgQty) !== Number(regParams.emanPkgQty) ||
          Number(regParams.grsWt) !== Number(regParams.emanNetWt) ||
          Number(regParams.grsCbm) !== Number(regParams.emanNetCbm)
        ) {
          emanSave = true
        }
      }

      return emanSave
    },
    async fnSrConstraintsCheck () {
      let isCons = false
      let isOk = true
      let rtnVal = ''
      const regParams = this.regParams
      const chrArray = [regParams.cgoCont]

      regParams.markDescList.forEach(vo => {
        chrArray.push(vo.mrk)
        chrArray.push(vo.dscr)
      })

      if (regParams.srCmplsSbmtYn === 'N') {
        chrArray.forEach(vo => {
          if (this.$ekmtcCommon.isNotEmpty(vo)) {
            if (this.fnRtnCHRCheck_machine(vo)) {
              isCons = true
            }
          }
        })
      }

      if (isCons) {
        isOk = await this.$ekmtcCommon.asyncAlertMessage({
            message: this.$t('msg.CSBL200.262'),
            useConfirmBtn: true,
            onConfirmSelf: (resolve, $Simplert) => {
              const popMachineChk = document.querySelectorAll('.popMachineChk:checked')
              const elem = document.querySelector('#popMachineO')
              if (popMachineChk.length === 0) {
                this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBL200.263'))
              } else if (popMachineChk.length > 1) {
                this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBL200.264'))
              } else {
                this.$ekmtcCommon.hideErrorTooltip(elem)
                rtnVal = popMachineChk[0].value

                if (rtnVal === 'O') {
                  this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBL200.265'))
                } else {
                  resolve(true)
                  $Simplert.close()
                }
              }
            }
        })
      }

      return {
        isOk: isOk,
        rtnVal: rtnVal,
        isCons: isCons
      }
    },
    fnNoticePop () {
      this.selectFunc = this.callBackNotice
      this.popParams = {}
      this.openPopup('sr-notice-pop')
    },
    fnCAInquiryPop () {
      this.selectFunc = ''
      this.popParams = {
        blNo: this.regParams.blNo,
        polCtrCd: this.regParams.polCtrCd
      }

      this.openPopup('shipping-log-bl-detail-pop')
    },
    callBackNotice (flag) {
      if (flag === 'OK') {
        this.regParams.srCmplsSbmtYn = 'Y'
        this.updateBLRequest()
      } else {
        this.resetSaveParam()
        this.regParams.srCstrSbmtYn = 'N'
      }
    },
    resetSaveParam () {
      const regParams = this.regParams
      regParams.containerList.forEach((c, i) => {
          c.sealList.forEach((s, idx) => {
          c['sealNo' + (idx + 1)] = ''
        })
      })

      regParams.byStrMsg = ''
      regParams.dscrStrMsg = ''
      regParams.srCmplsSbmtYn = 'N'
    },
    async getSndShaYn () {
      let result = null

      const params = {
        flag: 'BL',
        blNo: this.regParams.blNo,
        bkgNo: this.regParams.bkgNo
      }

      await trans.findSndShaYn(params).then(res => {
        if (res.headers.respcode === 'C0000') {
          result = res.data
        }
      })

      return result
    },
    fnRtnCHRCheck_machine (chr) {
      let isReturn = false
      const blPhbtList = this.blPhbtList

      blPhbtList.forEach(vo => {
        if (vo.apclYn === 'Y' || vo.apclYn === 'A') {
          if (vo.srCmplsSbmtYn === 'Y') {
            if (chr.indexOf(vo.phbtChr) > -1) {
              isReturn = true
            }
          }
        }
      })

      return isReturn
    },
    async fnRtnCHRCheck (blPhbtList, obj) {
      let value = this.$ekmtcCommon.isEmpty(obj) ? '' : obj

      const cntrSzArr = []
      const cntrTypArr = []
      const cntrCgoArr = []

      const confirmMsg = []

      this.regParams.containerList.forEach(c => {
        cntrSzArr.push(c.cntrSzCd)
        cntrTypArr.push(c.cntrTypCd)
        cntrCgoArr.push(c.cgoTypCd)
      })

      let message = ''
      let message2 = ''
      for (let i = 0; i < blPhbtList.length; i++) {
        const chr = blPhbtList[i]
        if (chr.apclYn === 'Y' && chr.srCmplsSbmtYn === 'N') {
          if (value.indexOf(chr.phbtChr) > -1) {
            if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (value.indexOf(chr.cntrSzCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                    this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (cntrSzArr.indexOf(chr.cntrSzCd) > -1 && cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrSzArr.indexOf(chr.cntrSzCd) > -1 && cntrTypArr.indexOf(chr.cntrTypCd) > -1 && cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1 && cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else {
              message = chr.rmk
              break
            }
          }
        } else if (chr.apclYn === 'A' && chr.srCmplsSbmtYn === 'N') {
          message2 = ''
          if (value.indexOf(chr.phbtChr) > -1) {
            if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (value.indexOf(chr.cntrSzCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                    this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (cntrSzArr.indexOf(chr.cntrSzCd) > -1 && cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrSzArr.indexOf(chr.cntrSzCd) > -1 && cntrTypArr.indexOf(chr.cntrTypCd) > -1 && cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1 && cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message2 = chr.rmk
              }
            } else {
              message2 = chr.rmk
            }

            if (this.phbtAlertMsg.indexOf(message2) === -1) {
              confirmMsg.push(message2)
            }

            // 1315558 B/L Header란 S/R Cfm "V"인 경우 WEB에서 CA 요청 가능
            if (this.regParams.srCmplsSbmtYn !== 'X') {
              this.phbtAlertMsg.push(chr.rmk)
            }
          }
        }
      }

      // 1315558 B/L Header란 S/R Cfm "V"인 경우 WEB에서 CA 요청 가능
      if (this.regParams.srCmplsSbmtYn === 'Y') {
        //message = ''
        this.regParams.descrCaYn = 'N'
      }

      if (this.$ekmtcCommon.isNotEmpty(message)) {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: message })) {
          return true
        }
      } else {
        let isOk = false
        for (const msg of confirmMsg) {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: msg, useConfirmBtn: true })) {
            isOk = true
          }
        }

        return isOk
      }

      return false
    },
    async fnPopCLL () {
      let isOk = true
      const TH = this

      isOk = await this.$ekmtcCommon.asyncAlertMessage({
            message: this.$t('msg.CSBL200.261'),
            useConfirmBtn: true,
            onConfirmSelf: (resolve, $Simplert) => {
              const chk = document.querySelector('#popCLLChk')
              if (!chk.checked) {
                TH.$ekmtcCommon.showErrorTooltip(chk, TH.$t('art.CMATK335'))
              } else {
                TH.$ekmtcCommon.hideErrorTooltip(chk)
                resolve(true)
                $Simplert.close()
              }
            }
          })
      return isOk
    },
    showSHAMFButton () {
      let isVisible = false
      const regParams = this.regParams

      const arrPlcCd = ['SZP', 'TAO', 'SHA', 'LYG', 'NBO', 'WUH', 'NTG', 'CKG', 'NKG', 'XGG']
      if (this.lang === 'CHN' && arrPlcCd.indexOf(this.bkgPlcCd) === -1) {
        return false
      }

      const arrExceptPlc = ['NCH', 'AQG', 'CZH', 'TOL', 'YCH', 'JIN', 'JJG',
                             'JZH', 'JXN', 'CHZ', 'CKG', 'CSX', 'CTU', 'CGS',
                             'HGH', 'HSI', 'JIA', 'CZX', 'LUZ', 'NKG', 'NTG',
                             'KUS', 'SZH', 'TAZ', 'TCG', 'SHA', 'WUH', 'WUX',
                             'YZH', 'WHI', 'ZHE', 'ZJG', 'MSN', 'JIU']
      if (regParams.podShaYn === 'Y' && arrExceptPlc.indexOf(regParams.polPortCd) === -1) {
        isVisible = true
      }

      return isVisible
    },
    openBlCopyPop () {
      this.popParams = {
        items: [{
          blNo: this.regParams.blNo
        }]
      }

      this.selectFunc = ''
      this.openPopup('bl-copy-pop')
    },
    showUploadClass (flag) {
      return (flag === 'Y' ? 'blue ' : '') + 'button sm2'
    },
    showFileUploadClass () {
      return this.$ekmtcCommon.isEmpty(this.uploadFile) ? 'button sm gray file' : 'button sm blue file'
    },
    print () {
      this.openBlCopyPop()
    },
    async showBLAddBtnOld () {
      const cgoRest = this.cgoRest
      let serverDt = this.serverYear + this.serverMonth + this.serverDay + this.serverHour + this.serverMin
      await commons.getServerTime().then(res => {
        if (res.data !== undefined) {
          serverDt = res.data.length > 12 ? res.data.substring(0, 12) : res.data
        }
      })

      if (this.usrCtrCd === 'SG') { // 싱가폴인 경우 EOB만 체크.
        if (this.regParams.eobYn === 'Y') { // EOB까지만 PART B/L, NORMAL B/L 가능
          this.isBlAdd = false
        }
      } else if ((this.$ekmtcCommon.isNotEmpty(cgoRest) && cgoRest < serverDt) || this.usrCtrCd === 'CN') {
        this.isBlAdd = false
      }
    },
    async showBLAddBtn () {
      this.isBlAdd = false
      await trans.partSrYn(this.regParams.blNo).then(res => {
        if (res.data !== undefined) {
          if (res.data === 'Y') {
            this.isBlAdd = true
          }
        }
      })
    },
    changeBlTypCd (e) {
      let remark = this.$ekmtcCommon.isNotEmpty(this.regParams.rmk) ? this.regParams.rmk : ''
      let separator = remark !== '' ? '\n' : ''
      if (e.target.value === '02') {
        remark += separator + this.$t('msg.CSBL200.266')
      } else {
        remark = remark.replace(this.$t('msg.CSBL200.266'), '').trim()
      }

      this.regParams.rmk = remark
    },
    checkErrorVisible (id) {
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#' + id)

      const parentElem = $(elem).parents('td, div').eq(0)
      const msgBox = parentElem.find('.tooltip_essen_box').eq(0)

      if (msgBox.length > 0 && msgBox.is(':visible') && this.$ekmtcCommon.isNotEmpty(elem.value)) {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
    },
    changeWstCgoYn (e) {
      let isOk = true
      const val = this.regParams.podHsCd
      if (!e.target.checked) {
        this.regParams.imLicNo = ''
        this.regParams.imBnkGurNo = ''

        if (val.length !== 4 && val.length !== 6 && val.length !== 8) {
          isOk = false
        }
      } else {
        if (val.length !== 8) {
          isOk = false
        }
      }

      if (!isOk) {
        this.$nextTick(() => {
          this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBL200.175')
        })
        this.hsChkParams.podSuccYn = 'N'
      } else {
        this.$nextTick(() => {
          this.hsChkParams.hscdPodErrorMessage = ''
        })
        this.hsChkParams.podSuccYn = 'Y'
      }
    },
    setShipperRegInfo (vo) {
      if (this.$ekmtcCommon.isNotEmpty(vo.reqRno)) {
        this.saveShipperLOI = 'Y'
        this.regParams.trdFileInfo = {} // 조회된 파일정보 Clear (재확인 하기 위해)
      }
    },
    checkShpperLOI () {
      const regParams = this.regParams

      if (this.$ekmtcCommon.isNotEmpty(regParams.shprCstAddr)) {
        const ctrInfo = this.ctrCdList.filter(vo => vo.ctrCd === regParams.polCtrCd)[0]

        if (ctrInfo !== undefined) {
          const ctrNm = ctrInfo.ctrEnm.toUpperCase().replace(/ /g, '')
          const shprCstAddr = regParams.shprCstAddr.replace(/ /g, '')
          if (shprCstAddr.indexOf(ctrNm) === -1) {
            this.isShipperLOI = true
          } else {
            this.isShipperLOI = false
          }
        }
      } else {
        this.isShipperLOI = true
      }
    },
    setInputType () {
      if (this.$ekmtcCommon.isNotEmpty(this.regParams.porPlcCd)) {
        const porPlcList = this.plcEnmCodes[this.regParams.polPortCd] ? this.plcEnmCodes[this.regParams.porPlcCd].filter(vo => vo.plcEnm === this.regParams.porPlcNm) : ''
        if (porPlcList === undefined || porPlcList.length === 0) {
          this.porPlcNmType = 'input'
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.polPortCd)) {
        const polPortList = this.plcEnmCodes[this.regParams.polPortCd] ? this.plcEnmCodes[this.regParams.polPortCd].filter(vo => vo.plcEnm === this.regParams.polPortNm) : ''
        if (polPortList === undefined || polPortList.length === 0) {
          this.polPortNmType = 'input'
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.podPortCd)) {
        const podPortList = this.plcEnmCodes[this.regParams.podPortCd] ? this.plcEnmCodes[this.regParams.podPortCd].filter(vo => vo.plcEnm === this.regParams.podPortNm) : ''
        if (podPortList === undefined || podPortList.length === 0) {
          this.podPortNmType = 'input'
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.dlyPlcCd)) {
        const dlyPlcList = this.plcEnmCodes[this.regParams.dlyPlcCd] ? this.plcEnmCodes[this.regParams.dlyPlcCd].filter(vo => vo.plcEnm === this.regParams.dlyPlcNm) : ''
        if (dlyPlcList === undefined || dlyPlcList.length === 0) {
          this.dlyPlcNmType = 'input'
        }
      }
    },
    fnByTextMsg () {
      const originText = this.originParams.cmdtDsc
      const nowText = this.regParams.cgoCont
      if (this.usrCtrCd === 'KR') {
        if (originText.trim() !== nowText.trim()) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.269'))
        }
      }
    },
    checkValidationEmailAll () {
      let isOk = true
      const arrEl = ['actShprCstEmlAddr', 'cneCstEmlAddr', 'ntifCstEmlAddr', 'iecPicEmlAddr']

      arrEl.forEach(vo => {
        if (!this.checkValidationEmail(vo)) {
          isOk = false
        }
      })

      return isOk
    },
    checkValidationEmail (id) {
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + id)

      if (selector === null) {
        return true
      }

      let isOk = true
      if (selector !== undefined && selector !== null && this.$ekmtcCommon.isNotEmpty(selector.value)) {
        if (selector.value.indexOf(',') > -1) {
          if (!this.$ekmtcCommon.multiCheckEmail(selector.value, ',')) {
            isOk = false
          }
        } else {
          if (!this.$ekmtcCommon.checkEmail(selector.value)) {
            isOk = false
          }
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.VOSD100.043')) // 이메일 체크
      }

      return isOk
    },
    fnChangeTerm () {
      this.initDesc()
    },
    fnChangeCntrTerm (key) {
      let cyCount = 0
      let cfsCount = 0
      let cyNcfsCount = 0
      let count = 0
      let totCount = 0

      this.regParams.containerList.forEach(c => {
        if (c[key] === '01') {
          cyCount++
        } else if (c[key] === '02') {
          cfsCount++
        } else if (c[key] === '03') {
          cyNcfsCount++
        }
        count++
      })

      if (cyNcfsCount > 0) {
        this.regParams[key] = '03'
        this.initDesc()
        return
      }

      totCount = cyCount + cfsCount
      if (count === totCount) {
        if (cyCount === count) {
          this.regParams[key] = '01'
          this.initDesc()
        } else if (cfsCount === count) {
          this.regParams[key] = '02'
          this.initDesc()
        } else if (totCount === count) {
          this.regParams[key] = '03'
          this.initDesc()
        }
      }
    },
    isPolShtmListChange () {
      let change = false

      if (this.regParams.polShtmCd === '03' && this.auth.userId !== 'CKLINE') {
        change = true
      }

      return change
    },
    compareCheck () {
      let isChange = false
      const res = this.$ekmtcCommon.compareObject(this.regParams, this.originParams)

      if (res.length > 0) {
        isChange = true
      }

      return isChange
    },
    markDescEnterSet () {
      this.regParams.markDescList.forEach(m => {
        const mrk = this.$ekmtcCommon.isNotEmpty(m.mrk) ? m.mrk : ''
        const dscr = this.$ekmtcCommon.isNotEmpty(m.dscr) ? m.dscr : ''
        m.mrk = mrk.split('\r\n').join('\n')
        m.dscr = dscr.split('\r\n').join('\n')

        m.mrk = m.mrk.replace(/\r/g, '')
        m.dscr = m.dscr.replace(/\r/g, '')
      })
    },
    async initAttachPopupData () {
      this.uploadFileList = []

      const ctrCd = this.memberDetail.userCtrCd

      const arrPromise = [
        this.getBLAttachList()
      ]

      // 220719 첨부파일에서 loi 제외
      // if (ctrCd === 'KR') {
      //   arrPromise.push(this.getBLAttachLoiUploadList())
      // }
      if (this.regParams.podCtrCd === 'IR') {
        arrPromise.push(this.getIranUploadList())
      }

      await Promise.all(arrPromise)
    },
    // 파일 조회
    async getBLAttachList () {
      const params = {
        blNo: this.regParams.blNo,
        type: 'BL'
      }

      await blAttach.getBLAttachList(params).then(response => {
        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.blAttachList
          //console.log('getBLAttachList >>> fileList')
          // console.log(fileList)
          //JamSin
          this.regParams.riderYn = 'N'
          this.regParams.dgcertiYn = 'N'
          this.regParams.vgmcertiYn = 'N'
          this.regParams.emanYn = 'N'
          this.regParams.etcYn = 'N'
          if (fileList !== undefined) {
            fileList.forEach(file => {
              this.uploadFileList.push(
                {
                  guid: file.blRecvDocKndCd + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?blNo=' + this.regParams.blNo + '&blRecvDocKndCd=' + file.blRecvDocKndCd + '&fileSeq=' + file.fileSeq + '&type=BL_ATTACH',
                  category: file.blRecvDocKndCd,

                  blNo: this.regParams.blNo,
                  blRecvDocKndCd: file.blRecvDocKndCd,
                  fileSeq: file.fileSeq
                }
              )

              if (file.blRecvDocKndCd === '03') {
                this.regParams.riderYn = 'Y'
              } else if (file.blRecvDocKndCd === '06') {
                this.regParams.dgcertiYn = 'Y'
              } else if (file.blRecvDocKndCd === '20') {
                this.regParams.vgmcertiYn = 'Y'
              } else if (file.blRecvDocKndCd === '02') {
                this.regParams.emanYn = 'Y'
              } else if (file.blRecvDocKndCd === '04') {
                //code collection CS046 ETC가 04로 되어 있음
                this.regParams.etcYn = 'Y'
              }
            })
          }

          if ($('#etcYn').is(':visible')) {
            $('#etcYn').attr('class', this.showUploadClass(this.regParams.etcYn))
          }
        }
      })
    },
    // 파일 조회(LOI-UPLOAD)
    async getBLAttachLoiUploadList () {
      await blAttach.getBLAttachLoiUploadList(this.regParams.blNo).then(response => {
        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.blAttachList
          // console.log(fileList)

          if (fileList !== undefined) {
            fileList.forEach(file => {
              this.uploadFileList.push(
                {
                  guid: file.reqCatCd + '_' + file.reqSeq + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + this.regParams.blNo + '&reqCatCd=' + file.reqCatCd + '&reqSeq=' + file.reqSeq + '&fileSeq=' + file.fileSeq + '&type=BL_ATTACH_LOI_UPLOAD',
                  category: 'loi_' + file.reqCatCd,

                  reqRno: this.regParams.blNo,
                  reqCatCd: file.reqCatCd,
                  reqSeq: file.reqSeq,
                  fileSeq: file.fileSeq
                }
              )
            })
          }
        }
      })
    },
    // 이란 업로드 파일 조회
    async getIranUploadList () {
      const params = {
        reqRno: this.regParams.blNo
      }

      await trans.getIranUpload(params).then(response => {
        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.iranUploadList

          if (fileList !== undefined) {
            fileList.forEach(file => {
              this.uploadFileList.push(
                {
                  guid: file.bkgNo + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + file.bkgNo + '&atchFileSeq=' + file.fileSeq + '&fileTypCd=' + file.fileTypCd + '&type=IRAN_UPLOAD',
                  category: 'iran_' + file.fileTypCd,

                  bkgNo: file.bkgNo,
                  fileSeq: file.fileSeq,
                  fileTypCd: file.fileTypCd
                }
              )
            })
          }
        }
      })
    },
    excelUploadPop () {
      const checkCntr = this.cstCatCd === 'Y' || this.regParams.shprVanYn === 'Y' ? 'N' : 'Y'
      this.popParams = {
        blNo: this.regParams.blNo,
        bkgNo: this.regParams.bkgNo,
        checkCntr: checkCntr,
        polPortCd: this.regParams.polPortCd,
        type: 'BL'
      }

      this.selectFunc = this.setUploadContainerList
      this.openPopup('container-excel-upload-pop')
    },
    setUploadContainerList (list) {
      this.$set(this.regParams, 'containerList', list)
      this.regParams.containerList.forEach(c => {
        if (this.$ekmtcCommon.isEmpty(c.sealList) || c.sealList.length === 0) {
          this.$set(c, 'sealList', [{ sealNo: '' }])
        }

        // 기본값 설정
        if (this.$ekmtcCommon.isEmpty(c.shprVanYn)) c.shprVanYn = 'N'
        if (this.$ekmtcCommon.isEmpty(c.feUnitCd)) c.feUnitCd = 'F'
        if (this.$ekmtcCommon.isEmpty(c.polShtmCd)) c.polShtmCd = this.regParams.polShtmCd
        if (this.$ekmtcCommon.isEmpty(c.podShtmCd)) c.podShtmCd = this.regParams.podShtmCd
      })
    },
    handleChange (e) {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.regParams.rmk = e.target.value
      }, 300)
    },
    changeStateCd (id, nmField) {
      const elem = document.querySelector('#frm').querySelector('#' + id)
      let stateTxt = (elem.selectedOptions.length > 0 ? elem.selectedOptions[0].text : '')
      this.$set(this.regParams, nmField, stateTxt)
    },
    templateSavePop () {
      this.popParams = {
        tplRno: this.regParams.blNo,
        pol: this.regParams.polPortCd,
        pod: this.regParams.podPortCd,
        tplCatCd: '02'
      }
      this.selectFunc = this.goTemplateList
      this.openPopup('template-save-pop')
    },
   async caVgmChk (regParams) {
      let rtn = ''
      await trans.caVgmChk(regParams).then(res => {
        rtn = res.data
      }).catch(() => {
        return {
          data: {
            rtnCode: 'Y'
          }
        }
      })
      return rtn
    },
     async updateSgBL () {
        const rsltWt = await this.checkCntrGrossWeightAndPackage('wt')
        if (rsltWt === -1) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.097'))
          return
        } else if (rsltWt === -2) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.098'))
          return
        }
        const vgmWt = await this.getVgmGrsWtChk()
        console.log('@@@@@@@@@@@@@@@vgmWt=>', vgmWt)
        if (this.$ekmtcCommon.isNotEmpty(vgmWt)) {
          await this.fnVGNNoticePop(vgmWt)
        } else {
          this.updateBL()
        }
    },
    async getVgmGrsWtChk () {
      let result = ''
      const regParams = this.regParams
      await trans.findVgmGrsWtChk(regParams).then(res => {
        if (res.headers.respcode === 'C0000') {
          result = res.data
        }
      }).catch(() => {
        result = ''
      })

      return result
    },
    fnVGNNoticePop (vgmWt) {
      let popParams = {
        vgmWt: vgmWt
      }
      this.popParams = popParams
      this.selectFunc = this.callBackSgNotice
      this.customComponent = 'bl-vgm-noti-pop'
      this.$ekmtcCommon.layerOpen('.bl_area > .popup_dim')
    },
    callBackSgNotice (data) {
      this.closePopup()
      this.updateBL()
    },
    cgoTypeConfirm () {
      this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT041')) // 특수화물을 선택하십시오.

      const selectEl = frm.getElementsByTagName('select')
      for (const el of selectEl) {
        const id = el.getAttribute('id')

        if (id !== null) {
          const idNm = id.indexOf('_') > -1 ? id.split('_')[0] : id
          let checkNm = 'cgoTypCd'

          if (checkNm.indexOf(idNm) > -1) {
            el.disabled = false
          }
        }
      }
    },
    cgoTypeClose () {
      this.checkCgoType = 'N'
      this.updateBL()
    },
    getChildData (data) {
      if (data.type === 'SHAMF') {
        this.fnCommonBtnAction('SHAMF', 'CAREQ')
      }
    },
    templateSearch () {
      this.popParams = {
        templateNo: this.templateNo
      }
      this.selectFunc = this.getTemplateInfo
      this.openPopup('bl-call-pop')
    },
    templateEnter (e) {
      if (e.keyCode === 13) {
        this.templateSearch()
      }
    },
    setTemplateInfo () {
      const blNo = this.templateCd === 'A' ? this.templateNo : this.templateNo_2

      let succFlag = true
      let result = null
      let resCode = null

      trans.findBlTemplateInfo(blNo).then(res => {
        if (res.headers.respcode === 'C0000') {
          result = res.data
        } else if (res.headers.respcode === 'C1002') {
          resCode = res.headers.respcode
          succFlag = false
        } else {
          succFlag = false
        }
      }).catch(() => {
        succFlag = false
      }).finally(() => {
        if (succFlag) {
          this.setTemplateDetail(result)
        } else {
          //this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
          if (this.regParams.templateCd === 'A') {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.235'))
          } else {
            if (resCode === 'C1002') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONIM070G010.038'))
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
            }
          }
        }
        // result.templateCd = this.regParams.templateCd
      })
    },
    setTemplateDetail (obj) {
      const defaults = this.regParams
      const detail = { ...defaults, ...obj }

      this.regParams = {}
      this.regParams = detail

      const param = this.regParams

      const shprCstEnm = param.shprCstEnm || ''
      const shprCstAddr = param.shprCstAddr || ''
      const shprCstNo = param.shprCstNo || ''
      const cneCstEnm = param.cneCstEnm || ''
      const cneCstAddr = param.cneCstAddr || ''
      const cneCstNo = param.cneCstNo || ''
      const ntifCstEnm = param.ntifCstEnm || ''
      const ntifCstAddr = param.ntifCstAddr || ''
      const ntifCstNo = param.ntifCstNo || ''
      const hcneCstEnm = param.hcneCstEnm || ''
      const hcneCstAddr = param.hcneCstAddr || ''
      const hcneCstNo = param.hcneCstNo || ''
      const cgoCont = param.cgoCont || ''

      const regParams = this.regParams

      // Template 일 경우 input를 수정하면 대문자로 바뀌지만 그대로 저장할 경우 소문자로 들어가므로 대문자로 변경
      regParams.shprCstEnm = shprCstEnm.split('`').join('\'').split('\\n').join('\r\n').toUpperCase()
      regParams.shprCstAddr = shprCstAddr.split('`').join('\'').split('\\n').join('\r\n')
      //regParams.shprCstNo = shprCstNo.split('`').join('\'').split('\\n').join('\r\n')
      regParams.shprCstNo = '' // Template 일 경우는 cstNo 초기화
      // Template 일 경우 input를 수정하면 대문자로 바뀌지만 그대로 저장할 경우 소문자로 들어가므로 대문자로 변경
      regParams.cneCstEnm = cneCstEnm.split('`').join('\'').split('\\n').join('\r\n').toUpperCase()
      regParams.cneCstAddr = cneCstAddr.split('`').join('\'').split('\\n').join('\r\n')
      //regParams.cneCstNo = cneCstNo.split('`').join('\'').split('\\n').join('\r\n')
      regParams.cneCstNo = '' // Template 일 경우는 cstNo 초기화
      // Template 일 경우 input를 수정하면 대문자로 바뀌지만 그대로 저장할 경우 소문자로 들어가므로 대문자로 변경
      regParams.ntifCstEnm = ntifCstEnm.split('`').join('\'').split('\\n').join('\r\n').toUpperCase()
      regParams.ntifCstAddr = ntifCstAddr.split('`').join('\'').split('\\n').join('\r\n')
      //regParams.ntifCstNo = ntifCstNo.split('`').join('\'').split('\\n').join('\r\n')
      regParams.ntifCstNo = '' // Template 일 경우는 cstNo 초기화
      // Template 일 경우 input를 수정하면 대문자로 바뀌지만 그대로 저장할 경우 소문자로 들어가므로 대문자로 변경
      regParams.hcneCstEnm = hcneCstEnm.split('`').join('\'').split('\\n').join('\r\n').toUpperCase()
      regParams.hcneCstAddr = hcneCstAddr.split('`').join('\'').split('\\n').join('\r\n')
      //regParams.hcneCstNo = hcneCstNo.split('`').join('\'').split('\\n').join('\r\n')
      regParams.hcneCstNo = '' // Template 일 경우는 cstNo 초기화
      regParams.cgoCont = cgoCont.split('`').join('\'').split('\\n').join('\r\n')
      //불러오기 시, 아래 항목 초기화
      regParams.cnePicNm = ''

      regParams.markDescList.forEach(c => {
        if (c.mrk) c.mrk = c.mrk.split('`').join('\'').split('\\n').join('\r\n')
        if (c.dscr) c.dscr = c.dscr.split('`').join('\'').split('\\n').join('\r\n')
      })

      this.initDesc()
      this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT015'))
    },
    getTemplateInfo (obj) {
      const blNo = obj.blNo
      this.templateNo = blNo

      this.setTemplateInfo() // 자동 적용
    },
    deleteTemplate () {
      if (this.templateCd === 'B' && this.$ekmtcCommon.isNotEmpty(this.templateNo_2)) {
        this.$ekmtcCommon.confirmCallBack(this.$t('msg.CMBA660.013'), () => {
          this.regParams.tplRno = this.templateNo_2
          this.regParams.tplCatCd = '02'
          this.templateNo_2 = ''

          trans.deleteeTemplate(this.regParams).then(res => {
            if (res.data === 'SUCC') {
              this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT012'))
              trans.getTemplateList(this.regParams).then((res) => {
                this.templateList = res.data
              })
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
            }
          }).catch(err => {
            console.log(err)
          })
        })
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
      }
    },
    showTooltip (id) {
      // console.log('showTooltip id @@@@ ', id)
      // console.log('showTooltip originParams polPortNm@@@@ ', this.originParams.polPortNm)

      const polPortNmType = this.polPortNmType
      const porPlcNmType = this.porPlcNmType
      const podPortNmType = this.podPortNmType
      const dlyPlcNmType = this.dlyPlcNmType
      const originParams = this.originParams
      const regParams = this.regParams

      switch (id) {
        case 'porTooltip':
          if (this.$ekmtcCommon.isNotEmpty(regParams.porPlcNm) && (originParams.porPlcNm !== regParams.porPlcNm) && porPlcNmType === 'input') {
            $('#' + id).show()
          }
          break
        case 'polTooltip':
          if (this.$ekmtcCommon.isNotEmpty(regParams.polPortNm) && (originParams.polPortNm !== regParams.polPortNm) && polPortNmType === 'input') {
            $('#' + id).show()
          }
          break
        case 'podTooltip':
          if (this.$ekmtcCommon.isNotEmpty(regParams.podPortNm) && (originParams.podPortNm !== regParams.podPortNm) && podPortNmType === 'input') {
            $('#' + id).show()
          }
          break
        case 'dlyTooltip':
          if (this.$ekmtcCommon.isNotEmpty(regParams.dlyPlcNm) && (originParams.dlyPlcNm !== regParams.dlyPlcNm) && dlyPlcNmType === 'input') {
            $('#' + id).show()
          }
          break
      }
    },
    hideTooltip (id) {
      $('#' + id).hide()
    },
    checkJPplcPopMsg (value, id) {
      const originParams = this.originParams
      const regParams = this.regParams
      const msg = 'POR/POL/POD/DLYは、BOOKING 時のPORTと異なるPORTの記載をすることはできません。異なるPORTを記載する場合は必ずBOOKING 内容の変更をお願いします。貨物はBOOKING 時に登録されているPORTにのみ手配が可能です。'
      let plcList = []
      let commPortCd = ''

      if (regParams.polCtrCd === 'JP') {
        switch (id) {
          case 'porPlcNm':
            commPortCd = regParams.porPlcCd
            break
          case 'polPortNm':
            commPortCd = regParams.polPortCd
            break
          case 'podPortNm':
            commPortCd = regParams.podPortCd
            break
          case 'dlyPlcNm':
            commPortCd = regParams.dlyPlcCd
            break
        }

        plcList = this.plcEnmCodes[commPortCd].map(item => {
          return item.plcEnm
        })

        if (!plcList.includes(value)) {
          this.$ekmtcCommon.alertDefault(msg)
        }
      }
    },
    addRequSentenceDescForVN () {
      let arr = [
        'SHIPPING LINE / SHIPPING LINE AGENTS ARE ELIG',
        'IBLE UNDER THE TERMS OF THIS B/L AS AGREED, T',
        'O COLLECT ANY CHARGES SUCH AS  DEPOSIT SAR 30',
        '00 PER DRY CNTR, SAR 7000 PER REEFER AND ANY ',
        'OTHER CHARGES OR FEES WHERE DEEMED NECESSARY ',
        'BY THE SHIPPING LINE / SHIPPING LINE AGENTS.L',
        'OLO(FASAH): SAR 50/CNTR, GATE PASS & HANDLING',
        ': SAR 120/CNTR, OCR: USD80/120 PER 20\'/40\'(NO',
        'N HAZ SHIPMENTS), USD 100/150 PER 20\'/40\'(HAZ',
        ' SHIPMENTS)'
      ]

      let isOk = true
      for (let desc of this.regParams.markDescList) {
        if (isOk) {
          if (desc.dscr.indexOf(arr[0]) > -1 && desc.dscr.indexOf(arr[1]) > -1 &&
            desc.dscr.indexOf(arr[2]) > -1 && desc.dscr.indexOf(arr[3]) > -1 &&
            desc.dscr.indexOf(arr[4]) > -1 && desc.dscr.indexOf(arr[5]) > -1 &&
            desc.dscr.indexOf(arr[6]) > -1 && desc.dscr.indexOf(arr[7]) > -1 &&
            desc.dscr.indexOf(arr[8]) > -1 && desc.dscr.indexOf(arr[9]) > -1) {
            isOk = false
          }
        } else {
          break
        }
      }

      if (isOk) {
        let lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        let lstDescArr = lstDesc.dscr.split('\n')
        if (lstDescArr.length > 5) {
          this.addMarkDesc()
          lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        }

        lstDesc.dscr = lstDesc.dscr + '\n' + arr.join('\n')
      } else {
        return false
      }
    },
    addTempClauseDescForVN () {
      //SCARGO IS STOWED IN REFRIGERATED CONTAINERS S
      //ET AT THE SHIPPER'S REQUESTED CARRYING TEMPER
      //ATURE OF … DEGREES OF CELSIUS.
      let arr = [
        'CARGO IS STOWED IN REFRIGERATED CONTAINERS ',
        'SET AT THE SHIPPER\'S REQUESTED CARRYING ',
        'TEMPERATURE OF … DEGREES OF CELSIUS.'
      ]

      let isOk = true
      for (let desc of this.regParams.markDescList) {
        if (isOk) {
          if (desc.dscr.indexOf(arr[0]) > -1 && desc.dscr.indexOf(arr[1]) > -1 &&
            desc.dscr.indexOf(arr[2]) > -1) {
            isOk = false
          }
        } else {
          break
        }
      }

      if (isOk) {
        let lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        let lstDescArr = lstDesc.dscr.split('\n')
        if (lstDescArr.length > 12) {
          this.addMarkDesc()
          lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        }

        lstDesc.dscr = lstDesc.dscr + '\n' + arr.join('\n')
      } else {
        return false
      }
    },
    setIndiaInfo () {
      if (this.regParams.polCtrCd === 'VN' && this.regParams.podCtrCd === 'IN') {
        let descStatic = ''
        const regParams = this.regParams

        let indiaInfoTxt = `\n----\nIEC NO. : ${regParams.iecNo || ''}\nPAN NO. : ${regParams.ntifPanNo || ''}\nGST NO. : ${regParams.gstNo || ''}\nHS CODE : ${regParams.podHsCd || ''}\n----`

        for (let desc of this.regParams.markDescList) {
          let dscr = desc.dscr
          let start = dscr.indexOf('\n----')
          let end = dscr.lastIndexOf('----')
          if (start > -1 && end > -1) {
            let sliceTxt = dscr.slice(start, end + 4)
            dscr = dscr.replaceAll(sliceTxt, '')

            desc.dscr = dscr
            break
          }
        }

        let lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        let lstDescArr = lstDesc.dscr.split('\n')
        if (lstDescArr.length > 10) {
          this.addMarkDesc()
          lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        }

        lstDesc.dscr = lstDesc.dscr + indiaInfoTxt
      }
    },
    checkValidationCntrAll () {
      let isOk = true
      let arrEl = ['cntrNo', 'cntrSzCd', 'cntrTypCd', 'cgoTypCd']
      const regParams = this.regParams

      arrEl.forEach(el => {
        regParams.containerList.forEach((c, idx) => {
          if (!this.checkValidationCntr(el, idx)) {
            isOk = false
          }
        })
      })

      return isOk
    },
    checkValidationCntr (flag, idx) {
      console.log('checkValidationCntr flag @@@@@ ', flag)

      let isOk = true
      const regParams = this.regParams
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + flag + '_' + idx)

      const cntrInfo = regParams.containerList[idx]
      const cgoTypList = regParams.cgoTypList

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (this.auth.userId === 'CKLINE') {
        return true
      }

      if (flag === 'cntrNo') {
        isOk = this.checkValidationCntrNo(idx)
      } else {
        if (regParams.polShtmCd === '02' && this.$ekmtcCommon.isEmpty(cntrInfo.cntrNo)) {
          return true
        }

        if (this.$ekmtcCommon.isNotEmpty(cntrInfo.cntrNo)) {
          if (flag === 'cntrSzCd' && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else if (flag === 'cntrTypCd' && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }

          if (this.memberDetail.userCtrCd === 'CN' && flag === 'cgoTypCd') {
            console.log('cgoTypCd BL @@@@@ ', flag)

            cgoTypList.forEach((cgoVo, idx) => {
              if (cgoVo.cgoTypCd === '01' || cgoVo.cgoTypCd === '06') { // 부킹에서 입력한 컨테이너 cgoTypeCd 가 HZ or FB 일때
                console.log('cgoVo.cgoTypCd @@@@@ ', cgoVo.cgoTypCd)

                if (this.$ekmtcCommon.isEmpty(cntrInfo.cgoTypCd)) {
                  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
                  isOk = false
                }
              }
            })
          }
        }
      }

      return isOk
    },
    shprVanValidation (vo) {
      console.log('shprVanValidation BL @@@@@ ')

      let isOk = true
      const TH = this
      const regParams = TH.regParams

      const cgoTypList = regParams.cgoTypList
      const containerList = regParams.containerList
      let checkList = []

      if (this.$ekmtcCommon.isNotEmpty(vo)) {
        cgoTypList.forEach((cgoVo, idx) => {
          if (cgoVo.cntrSzCd + cgoVo.cntrTypCd === vo.cntrSzCd + vo.cntrTypCd) {
            if (cgoVo.shprVanYn !== vo.shprVanYn) {
              isOk = false
            }
          }
        })

        if (!isOk) {
          this.$ekmtcCommon.alertDefault('BookingのShipper’s Van情報と相違があります。まずはBooking情報を確認してください')
        }
      } else {
        containerList.forEach(cvo => {
          cgoTypList.forEach((cgoVo, idx) => {
              if (cgoVo.cntrSzCd + cgoVo.cntrTypCd === cvo.cntrSzCd + cvo.cntrTypCd) {
                if (cgoVo.shprVanYn !== cvo.shprVanYn) {
                  isOk = false
                }
              }
          })
        })
      }

      return isOk
    },
    changeLstDestPlcNm () {
      let isCgoTypeO1 = false
      if (this.regParams.cgoTypList.length > 0) {
        this.regParams.cgoTypList.forEach(c => {
          if (c.cgoTypCd === '01') { isCgoTypeO1 = true }
        })

        if (isCgoTypeO1 && this.regParams.podPortCd === 'SGN' && (this.regParams.dlyPlcCd === 'TNT' || this.regParams.dlyPlcCd === 'TCP')) {
          this.regParams.lstDestPlcNm = this.regParams.dlyPlcNm
        }
      }
    },
    checkValidationCharger (id, saveFlag) {
      const frm = document.querySelector('#frm')
      let isOk = true

      // essentialYn: 필수입력사항, telFirstYn: 지역번호 첫째자리 0체크
      const selectors = {
        bizPicNm: { essentialYn: 'Y' },
        bizPicTelPlcNo: { essentialYn: 'Y', telFirstYn: 'Y' },
        bizPicTelOfcNo: { essentialYn: 'Y' },
        bizPicFaxPlcNo: { essentialYn: 'Y', telFirstYn: 'Y' },
        bizPicFaxOfcNo: { essentialYn: 'Y' },
        bizPicMbpBsnNo: { essentialYn: 'Y', telFirstYn: 'Y' },
        bizPicMbpOfcNo: { essentialYn: 'Y' },
        bizPicEmlAddr: { essentialYn: 'Y', emailYn: 'Y' },
        docPicNm: {},
        docPicTelPlcNo: { telFirstYn: 'Y' },
        docPicTelNo: {},
        docPicFaxPlcNo: { telFirstYn: 'Y' },
        docPicFaxNo: {},
        docPicMbpBsnNo: { telFirstYn: 'Y' },
        docPicMbpNo: {},
        docPicEmlAddr: { emailYn: 'Y' }
      }

      const selector = frm.querySelector('#' + id)
      const telFirstYn = selectors[id].telFirstYn
      this.$ekmtcCommon.hideErrorTooltip(selector)

      const essentialYn = selectors[id].essentialYn
      if (
        saveFlag === 'SAVE' &&
        essentialYn === 'Y' &&
        this.$ekmtcCommon.isEmpty(selector.value)
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }

      if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
        const firstChr = selector.value.substring(0, 1)
        if (telFirstYn === 'Y' && firstChr !== '0' && this.lang === 'KOR') {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.180')
          ) // 지역번호, FAX번호, 휴대폰번호는 0으로 시작합니다.
          isOk = false
        }

        const emailYn = selectors[id].emailYn
        if (emailYn === 'Y') {
          if (!this.checkValidationEmail(id)) {
            isOk = false
          }
        }
      }

      return isOk
    },
    changeDocPicNo (e) {
      const picNo = e.target.value
      const regParams = this.regParams

      if (picNo !== '') {
        const cstInfo = this.cstInfoList.filter((vo) => vo.picNo === picNo)[0]

        if (cstInfo !== undefined) {
          regParams.docPicNo = cstInfo.picNo
          regParams.docPicNm = cstInfo.picNm
          regParams.docPicEmlAddr = cstInfo.emlAddr
          regParams.docPicTelPlcNo = cstInfo.telPlcNo
          regParams.docPicTelNo = cstInfo.telOfcNo

          if (this.lang === 'KOR') {
            if (
              this.$ekmtcCommon.isNotEmpty(regParams.docPicTelPlcNo) &&
              regParams.docPicTelPlcNo.substring(0, 1) !== '0'
            ) {
              regParams.docPicTelPlcNo = '0' + regParams.docPicTelPlcNo
            }
          }
        }
      } else {
        regParams.docPicNo = ''
        regParams.docPicNm = ''
        regParams.docPicEmlAddr = ''
        regParams.docPicTelPlcNo = ''
        regParams.docPicTelNo = ''
        regParams.docPicFaxPlcNo = ''
        regParams.docPicFaxNo = ''
        regParams.docPicMbpBsnNo = ''
        regParams.docPicMbpNo = ''
      }
    }
  }
}
</script>
<style scoped>
  .div_tooltip {width:230px !important; right:0; top:24px;}
  .tooltip_wrap {width:150px; right:0; top:24px; display:none;}
  .tooltip_wrap .cont {padding: 20px;}
  .txt_desc {background: inherit; position: relative;}
  .txt_desc:before {content:'※'; position: absolute; top: 0; left: 0;}
  .minus {background-position: -50px -50px;}
  .div_seal+.div_seal {margin-top: 5px;}
  .tbl_mark td{vertical-align: top;}
  .txt_desc1 {min-height: inherit; height: 45px; overflow: hidden; font-family: 'Courier New'; padding-top: 2px;}
  .txt_underline {font-weight: 500; text-decoration: underline; color: inherit; cursor: pointer;}
  .td_noline {border-left: none; border-bottom: none;}
  .tbl_btm_none {border-bottom: none !important;}
  .line_bottom {border-bottom: 1px solid #e1e3eb;}
  .no_cursor {cursor: default;}
  .no_cursor:hover { color:#5e75bf !important; border: solid 1px #a2aed2 !important;}
  .avoid_deco {
    padding-left: 0px !important;
  }
  .avoid_deco::before {
    content: '' !important;
  }
  .deck_select {
    border: solid 0px #cecece !important;
    color: black;
    background: white !important;
    padding: 0 0 0 0;
  }
</style>
