<template>
  <div
    class="popup_wrap"
    style="width:1300px; height:850px; margin-top: -50px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.MYIN050T040.013') }}</h1>
      <div class="content_box mt20" style="height: 800px;">
        <!-- 스케줄 알림 신청 여부 -->
        <div class="mt10">
          <p class="noti_questions"> {{ $t('msg.MYIN050T040.014') }} </p>
          <div class="mt10">
            <input
              type="radio"
              id="useYn_rdo1"
              name="useYn"
              value="Y"
              v-model="regData.useYn"
            >
            <label for="useYn_rdo1">{{ $t('msg.SETT060G010.020') }}</label>
            <input
              type="radio"
              id="useYn_rdo2"
              name="useYn"
              value="N"
              v-model="regData.useYn"
            >
            <label for="useYn_rdo2" class="ml30">{{ $t('msg.SETT060G010.021') }}</label>
          </div>
          <p class="txt_desc mt10">
            <span>{{ $t('msg.MYIN050T040.015') }}</span>
          </p>
        </div>
        <!-- 스케줄 알림 신청 여부 End -->

        <!--주기적 알림-->
        <div class="mt40" style="height: 150px;">
          <div>
            <input
              type="radio"
              id="vslDlayKndCd_rdo1"
              name="vslDlayKndCd"
              value="21"
              v-model="regData.vslDlayKndCd"
            >
            <label for="vslDlayKndCd_rdo1" style="color: blue; font-size: 18px; font-weight: bolder;">{{ $t('msg.MYIN050T040.016') }}</label>
          </div>
          <!-- 빈도 -->
          <div class="ml25 mt40" style="display: flex;">
            <p style="color: blue; font-size: 15px; font-weight: bolder; width: 100px;"> {{ $t('msg.MYIN050T040.018') }} </p>
            <div style="display: flex; flex-direction: column; width: 250px;">
              <!-- 매일 -->
              <div style="display: flex; width:1000px">
                <input
                  type="radio"
                  id="frequency_rdo1"
                  name="frequency"
                  value="1"
                  v-model="frequency"
                >
                <label for="frequency_rdo1" style="height: 25px; width: 120px;">{{ $t('msg.MYIN050T040.019') }}</label>
              </div>
              <!-- 매주 -->
              <div style="display: flex; width:1000px">
                <input
                  type="radio"
                  id="frequency_rdo2"
                  name="frequency"
                  value="2"
                  v-model="frequency"
                >
                <label for="frequency_rdo2" style="height: 25px; width: 120px;">{{ $t('msg.MYIN050T040.020') }}</label>
                <select v-model="weeklyDay" name="weeklyDay" id="weeklyDay" style="width: 110px; margin-left: 50px;" v-show="frequency === '2'">
                  <option
                    v-for="option in selectDayOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <!-- 직접입력 -->
              <div style="display: flex; width:1000px">
                <input
                  type="radio"
                  id="frequency_rdo3"
                  name="frequency"
                  value="3"
                  v-model="frequency"
                >
                <label for="frequency_rdo3" style="height: 25px; width: 150px;">{{ $t('msg.MYIN050T040.021') }}</label>
                <select v-model="enterDirectDay" name="enterDirectDay" id="enterDirectDay" style="width: 110px; margin-left: 20px;" @change="addArrEnterDirectDay($event)" v-show="frequency === '3'">
                  <option value="-"> {{ $t('msg.ONIM070P020.002') }} </option>
                  <option
                    v-for="option in selectDayOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
              <div class="mt10" style="display: flex; margin-left: 10px; width: 1000px">
                <ul>
                  <li v-for="day in arrEnterDirectDay" :key="day" style="display: inline; margin-right: 10px;">
                    <a class="button blue sh" href="#" @click.prevent="deleteArrEnterDirectDay(day)">{{ selectDayOptions.find((item) => item.value === day)?.label }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!--주기적 알림 End-->

        <!--변경시 알림-->
        <div class="mt40" style="height: 210px;">
          <input
            type="radio"
            id="vslDlayKndCd_rdo2"
            name="vslDlayKndCd"
            value="01"
            v-model="regData.vslDlayKndCd"
          >
          <label for="vslDlayKndCd_rdo2" style="color: blue; font-size: 18px; font-weight: bolder;">{{ $t('msg.MYIN050T040.017') }}</label>
          <div class="ml25 mt40" style="display: flex;">
            <p style="color: blue; font-size: 15px; font-weight: bolder; width: 100px;"> {{ $t('msg.MYIN050T040.022') }} </p>
            <select name="byChangeTime" id="byChangeTime" v-model="byChangeTime" style="width: 100px;">
              <option value="1">
                {{ $t('msg.MYIN050T040.023') }}
              </option>
              <option value="2">
                {{ $t('msg.MYIN050T040.024') }}
              </option>
            </select>
          </div>
          <div v-if="byChangeTime === '1'" class="mt10" style="display: flex; flex-direction: column; margin-left: 115px;">
            <input type="radio" id="dlayDrtm1" name="dlayDrtm" value="24" v-model="regData.dlayDrtm">
            <label for="dlayDrtm1" class="ml10"><span></span>{{ $t('msg.MYIN050T040.025') }}</label>
            <input type="radio" id="dlayDrtm2" name="dlayDrtm" value="48" v-model="regData.dlayDrtm">
            <label for="dlayDrtm2"><span></span>{{ $t('msg.MYIN050T040.026') }}</label>
            <input type="radio" id="dlayDrtm3" name="dlayDrtm" value="72" v-model="regData.dlayDrtm">
            <label for="dlayDrtm3"><span></span>{{ $t('msg.MYIN050T040.027') }}</label>
          </div>
          <div v-else class="mt10" style="display: flex; flex-direction: column; margin-left: 115px;">
            <input type="radio" id="dlayDrtm4" name="dlayDrtm" value="6" v-model="regData.dlayDrtm">
            <label for="dlayDrtm4" class="ml10"><span></span>{{ $t('msg.MYIN050T040.028') }}</label>
            <input type="radio" id="dlayDrtm5" name="dlayDrtm" value="12" v-model="regData.dlayDrtm">
            <label for="dlayDrtm5"><span></span>{{ $t('msg.MYIN050T040.029') }}</label>
            <input type="radio" id="dlayDrtm6" name="dlayDrtm" value="24" v-model="regData.dlayDrtm">
            <label for="dlayDrtm6"><span></span>{{ $t('msg.MYIN050T040.030') }}</label>
            <input type="radio" id="dlayDrtm7" name="dlayDrtm" value="48" v-model="regData.dlayDrtm">
            <label for="dlayDrtm7"><span></span>{{ $t('msg.MYIN050T040.031') }}</label>
            <input type="radio" id="dlayDrtm8" name="dlayDrtm" value="72" v-model="regData.dlayDrtm">
            <label for="dlayDrtm8"><span></span>{{ $t('msg.MYIN050T040.032') }}</label>
          </div>
        </div>
        <p class="txt_desc mt10 multiline-editor">
          <span>{{ $t('msg.MYIN050T040.040') }}</span>
        </p>
        <!-- 알림 매체 -->
        <div class="mt40" style="height: 80px;">
          <div>
            <input type="checkbox" id="notifyAll" name="notifyAll" true-value="Y" false-value="N" v-model="notifyAll">
            <label for="notifyAll"><span></span>{{ $t('msg.MYIN050T040.045') }}</label>
          </div>
          <div class="mt10">
            <input type="checkbox" id="smsYn" name="smsYn" true-value="Y" false-value="N" v-model="regData.smsYn" @change="allCheck('notiMedium')">
            <label for="smsYn"><span></span>{{ $t('msg.MYIN050T040.041') }}</label>
            <input type="checkbox" id="emlYn" name="emlYn" true-value="Y" false-value="N" v-model="regData.emlYn" @change="allCheck('notiMedium')">
            <label for="emlYn"><span></span>{{ $t('msg.MYIN050T040.042') }}</label>
            <input type="checkbox" id="mobPushYn" name="mobPushYn" true-value="Y" false-value="N" v-model="regData.mobPushYn" disabled>
            <label for="mobPushYn"><span></span>{{ $t('msg.MYIN050T040.043') }}</label>
            <input type="checkbox" id="kakaoNotiYn" name="kakaoNotiYn" true-value="Y" false-value="N" v-model="regData.kakaoNotiYn" disabled>
            <label for="kakaoNotiYn"><span></span>{{ $t('msg.MYIN050T040.044') }}</label>
          </div>
          <!-- 알림 매체 End-->
        </div>
        <!--변경시 알림 End-->
        <!-- Button Area-->
        <div style="margin-top: 10px; margin-left: 45%;">
          <span>
            <a class="button blue lg" href="#" @click.prevent="fnSave()">{{ $t('msg.ONIM050G020.012') }}</a>
            <a class="button gray lg ml5" href="#" @click.prevent="$emit('close')">{{ $t('msg.ONIM050G020.013') }}</a>
          </span>
        </div>
      </div>
      <!-- Button Area End-->
    </div>
  </div><!-- popup_cont -->
</template>
<script>
import schedule from '../../../api/rest/schedule/schedule'
import SelectBox from '../../../components/SelectBox.vue'

export default {
  name: 'SettingScheduleNotiPop',
  components: {
    SelectBox
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  data: function () {
    return {
      mode: 'I',
      profile: this.$store.getters['auth/selectedProfile'],
      regData: {
        vslDlayKndCd: '21', //알림 코드  { 21:주기적 알림, 01:변경 시 알림 }
        useYn: 'Y', //스케줄 알림 사용 여부
        dlayDrtm: '24',
        emlYn: 'Y', //이메일 알림 여부
        smsYn: 'Y', //SMS 알림 여부
        mobPushYn: 'N', //모바일 푸시 알림 여부 - 추후 추가 예정
        kakaoNotiYn: 'N', //카카오 알림톡 여부 - 추후 추가 예정
        wdayCd: '1234567' //요일 코드
      },
      frequency: '1', //주기적 알림 - 빈도
      weeklyDay: '1', //빈도 - 매주선택 요일
      enterDirectDay: '-', //빈도 - 직접입력 선택 요일 (다중 선택 가능)
      arrEnterDirectDay: [],
      selectDayOptions: [
        { label: this.$t('msg.MYIN050T040.033'), value: '1' },
        { label: this.$t('msg.MYIN050T040.034'), value: '2' },
        { label: this.$t('msg.MYIN050T040.035'), value: '3' },
        { label: this.$t('msg.MYIN050T040.036'), value: '4' },
        { label: this.$t('msg.MYIN050T040.037'), value: '5' },
        { label: this.$t('msg.MYIN050T040.038'), value: '6' },
        { label: this.$t('msg.MYIN050T040.039'), value: '7' }
      ],
      byChangeTime: '1', //변경 시간 기준{ 1: 날짜 기준, 2: 시간 기준 }
      notifyAll: 'Y' //알림 매체 전체 선택
    }
  },
  computed: {
  },
  watch: {
    notifyAll (val) {
      if (val === 'Y') {
        this.regData.emlYn = 'Y'
        this.regData.smsYn = 'Y'
      } else {
        if (this.regData.emlYn === 'Y' && this.regData.smsYn === 'Y') {
          this.regData.smsYn = 'N'
          this.regData.emlYn = 'N'
        }
      }
    },
    frequency (val) {
      //주weeklyDay,직접입력enterDirectDay
      // if (val === '1') {
      //   //
      //   document.getElementById('weeklyDay').setAttribute('disabled', 'disabled')
      //   document.getElementById('enterDirectDay').setAttribute('disabled', 'disabled')
      // } else if (val === '2') {
      //   document.getElementById('weeklyDay').removeAttribute('disabled')
      //   document.getElementById('enterDirectDay').setAttribute('disabled', 'disabled')
      // } else {
      //   document.getElementById('weeklyDay').removeAttribute('disabled')
      //   document.getElementById('enterDirectDay').setAttribute('disabled', 'disabled')
      // }

      if (val !== '3') {
        this.arrEnterDirectDay = []
      }
    },
    'regData.emlYn' (val) {
      if (val === 'Y') {
        this.$ekmtcCommon.hideErrorTooltip('#emlYn')
        this.$ekmtcCommon.hideErrorTooltip('#smsYn')
      }
    },
    'regData.smsYn' (val) {
      if (val === 'Y') {
        this.$ekmtcCommon.hideErrorTooltip('#emlYn')
        this.$ekmtcCommon.hideErrorTooltip('#smsYn')
      }
    }
  },
  created () {
  },
  mounted () {
    //기존 정보 있는지 조회
    schedule.getScheduleNotiInfo(this.userId).then((res) => {
      this.arrEnterDirectDay = []
      //기존 정보 없을 때
      if (res.data === '') {
        this.mode = 'I'
      //기존 정보 있을 때
      } else {
        this.mode = 'U'
        this.regData = { ...res.data }
        //주기적 알림
        if (res.data.vslDlayKndCd === '21') {
          this.regData.dlayDrtm = 24

          const selectedDays = this.regData.wdayCd

          //매일
          if (selectedDays.length === 7) {
            this.frequency = '1'
          //매주 n요일
          } else if (selectedDays.length === 1) {
            this.frequency = '2'
            this.weeklyDay = this.regData.wdayCd
          //직접 입력
          } else {
            this.frequency = '3'
            this.arrEnterDirectDay = [...selectedDays]
          }
        //변경 시 알림
        } else {
          this.byChangeTime = ['24', '36', '72'].includes(this.regData.dlayDrtm) ? '1' : '2'
        }
        this.notifyAll = this.regData.emlYn === 'Y' && this.regData.smsYn === 'Y' ? 'Y' : 'N'
      }
    })
  },
  methods: {
    closePopup () {
      this.$emit('close')
    },
    allCheck (type) {
      if (type === 'notiMedium') {
        this.notifyAll = this.regData.emlYn === 'Y' && this.regData.smsYn === 'Y' ? 'Y' : 'N'
      }
    },
    async validate () {
      let result = true

      let params = { ...this.regData }

      const emlAddr = this.profile.emlAddr
      const telNo = this.profile.mbpOfcNo

      if (!['Y', 'N'].includes(params.useYn)) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN050T040.048') })
        result = false
      }

      //주기적 알림일 때 '직접 선택' 시 아무 날짜로 선택 하지 않았을 때
      if (this.regData.vslDlayKndCd === '21' && this.frequency === '3' && this.arrEnterDirectDay?.length === 0) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN050T040.049') })
          this.showErrorTooltip('enterDirectDay')
          result = false
      //이메일 선택 시 프로필 이메일 정보가 없을 시
      } else if (params.emlYn === 'Y' && (!emlAddr || emlAddr === '')) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN050T040.046') }) //프로필 이메일이 존재하지 않습니다. 등록 후 이용해 주세요.
        this.showErrorTooltip('emlYn', this.$t('msg.MYIN050T040.046'))
        result = false
      //SMS 선택 시 프로필 전화번호 등록 되어있지 않을 시
      } else if (params.smsYn === 'Y' && (!telNo || telNo === '')) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN050T040.047') }) //프로필 전화번호가 존재하지 않습니다. 등록 후 이용해 주세요.
        this.showErrorTooltip('smsYn', this.$t('msg.MYIN050T040.047'))
        result = false
      //알림 매체 중 아무것도 선택 하지 않았을 때
      } else if (params.emlYn === 'N' && params.smsYn === 'N') {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN050T040.050') }) //알림을 받으실 방법을 선택해주세요.
        this.showErrorTooltip('smsYn', this.$t('msg.MYIN050T040.050'))
        result = false
      }

      return result
    },
    async fnSave () {
      if (!await this.validate()) {
        return false
      }

      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX070G050.009'), useConfirmBtn: true })) {
        return false
      }

      let params = { ...this.regData }

      //주기적 알림일 때
      if (params.vslDlayKndCd === '21') {
        params.dlayDrtm = ''
        //매일
        if (this.frequency === '1') {
          params.wdayCd = '1234567'
        //매주 0요일
        } else if (this.frequency === '2') {
          params.wdayCd = this.weeklyDay
        //직접입력
        } else {
          params.wdayCd = ''
          this.arrEnterDirectDay.filter((item) => item !== '-') // 1.'선택' 항목 선택한 경우 제거
                                .sort((a, b) => a - b) // 2.순서대로 정렬
                                .forEach((item) => { params.wdayCd += item }) // 3.배열 > 문자열로 변환 후 파라미터에 저장
        }
      //변경 시 알림일 때
      } else {
        params.wdayCd = ''
      }

      await schedule.saveScheduleNotiInfo(params, this.mode).then((res) => {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX050P170.011') })
        this.$emit('close')
      }).catch(error => {
        console.log(error)
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX050P170.012') })
      })
    },
    addArrEnterDirectDay (e) {
      const checkedVal = e.target.value

      //선택 값이 '-'이거나, 선택한 값이 이미 목록에 있으면
      if (!this.arrEnterDirectDay.find(item => item === checkedVal) && checkedVal !== '-') {
        this.arrEnterDirectDay.push(e.target.value)
        this.$ekmtcCommon.hideErrorTooltip('#enterDirectDay')
      }
    },
    deleteArrEnterDirectDay (val) {
      this.arrEnterDirectDay = this.arrEnterDirectDay.filter((item) => item !== val)
    },
    showErrorTooltip (id, msg) {
      const selector = document.querySelector('#' + id)
      const errMsg = !msg || msg === '' ? this.$t('msg.CSBK100.147') : msg
      this.$ekmtcCommon.showErrorTooltip(
        selector,
        errMsg
      )
    }
  }
}
</script>

<style scoped>
.noti_questions {
  font-size: 18px;
  font-weight: bolder;
}

.multiline-editor {
    white-space: pre !important;
}
</style>
