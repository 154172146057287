<template>
  <div class="select_opt_area" :id="'dg_area_' + cntrSeq">
    <div class="select_opt_area mt10">
      <table
        v-for="(vo, idx) in dgInfo.containerDGList"
        :key="'tbl_dg' + idx + forceUpdate"
        class="tbl_col t2"
      >
        <colgroup>
          <col style="width: 5%" />
          <col style="width: 8%" />
          <col style="width: 12%" />
          <col style="width: 11%" />
          <col style="width: 10%" />
          <col style="width: 8%" />
          <col style="width: 8%" />
          <col style="width: 8%" />
          <col style="width: 10%" />
          <col style="width: 20%" />
        </colgroup>
        <thead>
          <tr>
            <th v-if="idx === 0" scope="col">
              <button
                v-if="canAddDg()"
                v-show="saveFlag !== 'PICKUP'"
                type="button"
                class="tbl_icon plus"
                @click="addContainerDG()"
              >
                plus
              </button>
            </th>
            <td v-if="idx !== 0" scope="col" />
            <th scope="col">{{ $t("msg.CMBA100.00116") }}</th>
            <!-- No. -->
            <th scope="col">{{ $t("msg.CSBK100.349") }}</th>
            <!-- UN No. -->
            <th scope="col">{{ $t("msg.CSBK100.351") }}</th>
            <!-- Class -->
            <th scope="col">{{ $t("msg.CSBK100.350") }}</th>
            <!-- Packing Group -->
            <th colspan="3" scope="col">{{ $t("msg.CSBK100.352") }}</th>
            <!-- Proper Shipping Name -->
            <th colspan="2" scope="col">{{ $t("msg.CSBK100.353") }}</th>
            <!-- Segregation  Group -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="tac" rowspan="11">
              <button
                v-if="canAddDg()"
                v-show="saveFlag !== 'PICKUP'"
                type="button"
                class="tbl_icon minus"
                @click="removeContainerDG(idx)"
              >
                minus
              </button>
            </td>
            <td class="tac">
              {{ vo.dgSeq }}
            </td>
            <td>
              <div class="search_btn">
                <input
                  :value="vo.imdunNo"
                  :id="'imdunNo_' + cntrSeq + '_' + idx"
                  class="inp_responsive_space3"
                  type="text"
                  readonly
                  @input="checkValidationDG('imdunNo', idx)"
                  @click.prevent="getUnnoInfo(idx)"
                />
                <span>
                  <button
                    class="search_btn01"
                    @click.prevent="getUnnoInfo(idx)"
                  />
                </span>
              </div>
            </td>
            <td>
              <input
                :value="vo.imoClasCd"
                :id="'imoClasCd_' + cntrSeq + '_' + idx"
                type="text"
                readonly
              />
            </td>
            <td>
              <select
                :id="'dgPkgGrpCd_' + cntrSeq + '_' + idx"
                v-model="vo.dgPkgGrpCd"
                disabled
                @change="checkValidationDG('dgPkgGrpCd', idx)"
              >
                <option value=" ">-----</option>
                <option value="01">III</option>
                <option value="02">II</option>
                <option value="03">I</option>
                <option value="04">-</option>
              </select>
            </td>
            <td colspan="3">
              {{ vo.dsc }}
            </td>
            <td colspan="2">
              <select
                class="imdgGrpCd"
                :id="'imdgGrpCd_' + cntrSeq + '_' + idx"
                v-model="vo.imdgGrpCd"
                :data-value="vo.imdgGrpCd"
                disabled
                @change="checkValidationDG('imdgGrpCd', idx, '', $event.target.value)"
              >
                <option
                  v-for="cvo in commonCodes['VS044']"
                  :key="cvo.cd"
                  :value="cvo.cd"
                >
                  {{ cvo.cdNm }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <!-- Full/Residue -->
            <th class="bor-left" scope="col">
              {{ $t("msg.CSBK100.354") }}<br />{{ $t("msg.CSBK100.355") }}
            </th>
            <th scope="col">{{ $t("msg.CSBK100.356") }}</th>
            <!-- F/P -->
            <th scope="col">{{ $t("msg.CSBK100.268") }}</th>
            <!-- GRS Weight -->
            <th scope="col">{{ $t("msg.CSBK100.358") }}</th>
            <!-- Net Weight -->
            <th scope="col">{{ $t("msg.CSBK100.359") }}</th>
            <!-- Marine Pollutant -->
            <th scope="col">{{ $t("msg.CSBK100.360") }}</th>
            <!-- Ems F -->
            <th scope="col">{{ $t("msg.CSBK100.361") }}</th>
            <!-- Ems S -->
            <th colspan="2" scope="col">{{ $t("msg.CSBK100.362") }}</th>
            <!-- Outer P'KG Q'TY /TYPE -->
          </tr>
          <tr>
            <td class="bor-left">
              <select
                :id="'dgRsdCd_' + cntrSeq + '_' + idx"
                :value="vo.dgRsdCd"
                @change="
                  checkValidationDG('dgRsdCd', idx, '', $event.target.value)
                "
              >
                <option value="">----------</option>
                <option value="01">Full</option>
                <option value="02">Residue</option>
              </select>
            </td>
            <td class="tal">
              <e-input-number
                :id="'flsTmpr_' + cntrSeq + '_' + idx"
                :is-minus="true"
                :point="2"
                input-class="col_5"
                :value="vo.flsTmpr"
                @input="checkValidationDG('flsTmpr', idx, '', ...arguments)"
              >
              </e-input-number>
              <select
                :id="'tmprUnitCd_' + cntrSeq + '_' + idx"
                :value="vo.tmprUnitCd"
                class="col_4"
                @change="
                  handleSelectChange('tmprUnitCd', idx, $event.target.value)
                "
              >
                <option value="C">℃</option>
                <option value="F">℉</option>
              </select>
            </td>
            <td class="tal">
              <e-input-number
                :key="grswtKey"
                :id="'grsWt_' + cntrSeq + '_' + idx"
                :is-comma="true"
                :point="3"
                input-class="col_8"
                :value="vo.grsWt"
                :readonly="cntrTypCd === 'TK'"
                @input="checkValidationDG('grsWt', idx, '', ...arguments)"
              >
              </e-input-number>
              kg
            </td>
            <td class="tal">
              <e-input-number
                :id="'netWt_' + cntrSeq + '_' + idx"
                :is-comma="true"
                :point="3"
                input-class="col_8"
                :value="vo.netWt"
                @input="checkValidationDG('netWt', idx, '', ...arguments)"
              >
              </e-input-number>
              kg
            </td>
            <td>
              <select
                :value="vo.mpltCd"
                :id="'mpltCd_' + cntrSeq + '_' + idx"
                @change="
                  checkValidationDG('mpltCd', idx, '', $event.target.value)
                "
                @input="changeMpltCd($event, idx)"
                disabled
              >
                <option value="">{{ $t("msg.CSBK100.062") }}</option>
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select>
            </td>
            <td>
              <select
                :id="'emsFeCd_' + cntrSeq + '_' + idx"
                v-model="vo.emsFeCd"
              >
                <option
                  v-for="cvo in emsFeCdList"
                  :key="'emsFeCd_' + cvo"
                  :value="cvo"
                >
                  {{ cvo }}
                </option>
              </select>
            </td>
            <td>
              <select
                :id="'emsSeCd_' + cntrSeq + '_' + idx"
                v-model="vo.emsSeCd"
              >
                <option
                  v-for="cvo in emsSeCdList"
                  :key="'emsSeCd_' + cvo"
                  :value="cvo"
                >
                  {{ cvo }}
                </option>
              </select>
            </td>
            <td>
              <e-input-number
                :is-comma="true"
                :id="'dgPkgQty_' + cntrSeq + '_' + idx"
                input-class="dgpkg"
                :value="vo.dgPkgQty"
                @input="checkValidationDG('dgPkgQty', idx, '', ...arguments)"
              >
              </e-input-number>
            </td>
            <td>
              <select
                :id="'dgMatlCd_' + cntrSeq + '_' + idx"
                :value="vo.dgMatlCd"
                @change="changeDgOuter(vo, idx, $event.target.value)"
              >
                <option value="">
                  ---------------------------------------------------
                </option>
                <option
                  v-for="cvo in vo.outerPkgList"
                  :key="cvo.cd"
                  :value="cvo.cd"
                >
                  {{ cvo.cdNm }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <th class="bor-left" colspan="4" scope="col">
              {{ $t("msg.CSBK100.363") }}
            </th>
            <!-- Technical Name -->
            <th scope="col">{{ $t("msg.CSBK100.364") }}</th>
            <!-- Limited Quantity -->
            <th colspan="2" scope="col">{{ $t("msg.CSBK100.365") }}</th>
            <!-- SAPT -->
            <th colspan="2" scope="col">{{ $t("msg.CSBK100.366") }}</th>
            <!-- Inner P'KG Q'TY /TYPE -->
          </tr>
          <tr>
            <td class="bor-left tac" colspan="4">
              <input
                :id="'tchlNm_' + cntrSeq + '_' + idx"
                v-model="vo.tchlNm"
                type="text"
                @input="checkValidationDG('tchlNm', idx)"
                v-show="false"
                disabled
              />
              <span :id="'tchlNmSpan_' + cntrSeq + '_' + idx"> - </span>
            </td>
            <td class="tac">
              <input
                :id="'lqYn_' + cntrSeq + '_' + idx"
                :value="vo.lqYn"
                v-model="vo.lqYn"
                type="checkbox"
                true-value="Y"
                false-value="N"
                @change="
                  handeCheckboxChange('lqYn', idx, $event.target.checked)
                "
              />
              <label :for="'lqYn_' + cntrSeq + '_' + idx"><span class="offscreen">L/Q YN</span></label>
            </td>
            <td>
              <e-input-number
                :id="'saptTmpr_' + cntrSeq + '_' + idx"
                :value="vo.saptTmpr"
                :is-comma="true"
                input-class="saptTmpr"
                @input="handleDgChange(idx, 'saptTmpr', ...arguments)"
              >
              </e-input-number>
            </td>
            <td>
              <select
                :id="'saptTmprUnitCd_' + cntrSeq + '_' + idx"
                :value="vo.saptTmprUnitCd"
                disabled
                @change="handleDgChange(idx, 'saptTmprUnitCd', $event.target.value)"
              >
                <option value="C">℃</option>
                <option value="F">℉</option>
              </select>
            </td>
            <td>
              <e-input-number
                :is-comma="true"
                :id="'isdDgPkgQty_' + cntrSeq + '_' + idx"
                input-class="isdDgpkg"
                :value="vo.isdDgPkgQty"
                @input="checkValidationDG('isdDgPkgQty', idx, '', ...arguments)"
              >
              </e-input-number>
            </td>
            <td>
              <select
                :value="vo.dgCatCd"
                :id="'dgCatCd_' + cntrSeq + '_' + idx"
                @change="
                  checkValidationDG('dgCatCd', idx, '', $event.target.value)
                "
              >
                <option
                  v-for="cvo in vo.innerPkgList"
                  :key="cvo.cd"
                  :value="cvo.cd"
                >
                  {{ cvo.cdNm }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <th class="bor-left" scope="col" colspan="2">
              {{ $t("msg.CSBK100.367") }}
            </th>
            <!-- Name of Emergency PIC -->
            <th scope="col">{{ $t("msg.CSBK100.368") }}</th>
            <!-- Country Code -->
            <th scope="col">{{ $t("msg.CSBK100.369") }}</th>
            <!-- TEL No. -->
            <th class="tal" scope="col" colspan="5">
              <span v-if="vo.addInfoMsg !== undefined && vo.addInfoMsg !== ''">Q. {{ vo.addInfoMsg }}</span>
            </th>
          </tr>
          <tr>
            <td class="bor-left" colspan="2">
              <input
                :id="'emrPicNm_' + cntrSeq + '_' + idx"
                :value="vo.emrPicNm"
                type="text"
                @keyup="
                  checkValidationDG('emrPicNm', idx, '', $event.target.value)
                "
                @input="changeEmrPic($event, idx)"
              />
            </td>
            <td>
              <e-input-number
                :value="vo.emrTelCtrNo"
                :id="'emrTelCtrNo_' + cntrSeq + '_' + idx"
                :is-phone="true"
                @input="checkValidationDG('emrTelCtrNo', idx, '', ...arguments)"
              >
              </e-input-number>
            </td>
            <td>
              <e-input-number
                :value="vo.emrTelNo"
                :id="'emrTelNo_' + cntrSeq + '_' + idx"
                @input="checkValidationDG('emrTelNo', idx, '', ...arguments)"
              >
              </e-input-number>
            </td>
            <td class="bor-left bor-r0" colspan="5">
              <select
                v-if="
                  vo.addInfoMsgList !== undefined &&
                    vo.addInfoMsgList !== null &&
                    vo.addInfoMsgList.length > 0
                "
                :id="'repsCont_' + cntrSeq + '_' + idx"
                :value="vo.repsCont"
                @change="
                  checkValidationDG('repsCont', idx, '', $event.target.value)
                "
              >
                <option value="">{{ $t("msg.CSBK100.371") }}</option>
                <!-- === Select the answer === -->
                <option
                  v-for="(cvo, i) in vo.addInfoMsgList"
                  :key="i"
                  :value="cvo.rspsCont"
                >
                  {{ cvo.rspsCont }}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <th class="bor-left" scope="col" colspan="4">
              {{ $t("msg.CSBK100.372") }}
            </th>
            <!-- CAS No. Information -->
            <th scope="col" colspan="3">RATIO(%)</th>
            <th scope="col" colspan="2"></th>
          </tr>
          <tr>
            <td class="bor-left bor-r0" colspan="4">
              <div>
                <div
                  v-for="(cvo, i) in vo.casNoList"
                  :key="i"
                  class="tbl_form cas_area"
                >
                  <span class="dv wid20">
                    <button
                      v-if="i === 0"
                      type="button"
                      class="tbl_icon plus"
                      @click="addCasNo(idx)"
                    >
                      plus
                    </button>
                    <button
                      v-if="i !== 0"
                      type="button"
                      class="tbl_icon minus"
                      @click="removeCasNo(idx, i)"
                    >
                      minus
                    </button>
                  </span>
                  <span class="dv position_relative">
                    <e-input-number
                      :value="cvo.casNo"
                      :id="'casNo_' + idx + '_' + i"
                      maxlength="10"
                      @input="checkValidationCasNo(cvo, idx, i, ...arguments)"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv"></span>
                </div>
              </div>
            </td>
            <td class="bor-left bor-r0" colspan="3">
              <template>
                <div
                  v-for="(cvo, i) in vo.casNoList"
                  :key="i"
                  class="tbl_form cas_area"
                >
                  <e-input-number
                    :value="cvo.rto"
                    :id="'rto_' + idx + '_' + i"
                    :point="2"
                    :digit-max="3"
                    maxlength="6"
                    @input="checkValidationRto(cvo, idx, i, ...arguments)"
                  >
                  </e-input-number>
                </div>
              </template>
            </td>
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>
      <table class="tbl_col t2 mt10">
        <colgroup>
          <col style="width: 15%" />
          <col style="width: 35%" />
          <col style="width: 15%" />
          <col style="width: auto" />
        </colgroup>
        <tbody>
          <tr v-if="lang === 'ENG' && vanningDtYn === 'Y'">
            <th scope="row">{{ $t("msg.CSBK100.373") }}</th>
            <!-- Vanning Date -->
            <td colspan="3">
              <div class="input_calendar">
                <e-date-picker
                  :value="dgInfo.vaniDt"
                  :id="'vaniDt_' + cntrSeq"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  @input="handleEDatePickerChange($event)"
                >
                </e-date-picker>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="saveFlag !== 'PICKUP'">
          <tr>
            <th scope="row">
              File Upload<br />
              <a style="font-size: 9px;text-decoration-line: underline; color: red" href="/pdf/DG_PLACARD_GUIDANCE_(KMTC).pdf" download>DG PLACARD GUIDANCE</a><br />
              <a v-show="memberDetail.userCtrCd === 'VN'" style="font-size: 9px;text-decoration-line: underline; color: red" href="/pdf/DG_DECLARATION_FORM.docx" download>Download DG Declaration Template</a>
            </th>
            <td colspan="3">
              <div class="filebox">
                <a
                  @click="uploadPopup()"
                  :class="
                    $ekmtcCommon.isEmpty(dgInfo.reqRno)
                      ? 'button sm gray file'
                      : 'button sm blue'
                  "
                >
                  {{ $t("msg.CSBK100.205") }}
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t("msg.CSBK100.374") }}</th>
            <!-- Request Remark -->
            <td colspan="3">
              <textarea
                :value="dgInfo.reqConts"
                @input="setContainer('reqConts', $event.target.value)"
              />
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t("msg.CSBK100.375") }}</th>
            <!-- Status -->
            <td>
              <span class="span_scg">{{ dgInfo.apvStsNm }}</span>
            </td>
            <th scope="row">{{ $t("msg.CSBK100.376") }}</th>
            <!-- Ref No. -->
            <td>
              <span class="span_scg">{{ dgInfo.scgAppNo }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">{{ $t("msg.CSBK100.377") }}</th>
            <!-- KMTC Remark -->
            <td colspan="3">
              <textarea :value="dgInfo.apvCont" readonly />
            </td>
          </tr>
        </tbody>
      </table>
      <!-- File Upload e -->
    </div>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePoup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import unno from '@/api/rest/trans/unno'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import EInputNumber from '@/components/common/EInputNumber'
import EDatePicker from '@/components/common/EDatePicker'
import { rootComputed } from '@/store/helpers'
export default {
  name: 'BookingTableDg',
  computed: {
    ...rootComputed
  },
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'unno-pop': () => import('@/pages/trans/popup/UnnoPop'),
    'dg-oog-certi-pop': () => import('@/pages/trans/popup/DgOogCertiPop'),
    EInputNumber,
    EDatePicker
  },
  props: {
    parentInfo: {
      type: Object,
      default: null
    },
    parentDgList: {
      type: Array,
      default: () => {
        return []
      }
    },
    casNoPortYn: {
      type: String,
      default: 'N'
    },
    telCtrNo: {
      type: String,
      default: ''
    },
    disabledYn: {
      type: String,
      default: 'N'
    },
    unnoDisabledYn: {
      type: String,
      default: 'N'
    },
    lang: {
      type: String,
      default: ''
    },
    saveFlag: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: null
    },
    bkgStsCd: {
      type: String,
      default: ''
    },
    bkgVslDocYn: {
      type: String,
      default: ''
    },
    statusClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      grswtKey: 0,
      forceUpdate: 0,
      toggleOuterPage: false,
      dgErrors: [],
      popParams: {},
      selectFunc: null,
      customComponent: null,
      emsFeCdList: [],
      emsSeCdList: [],
      commonCodes: {},
      vanningDtYn: 'N',
      cntrTypCd: '',
      cntrSzCd: '',
      cntrSeq: '',
      cgoTypCd: '',
      alertShow: 'Y',
      dgInfo: {
        vaniDt: '',
        reqConts: '',
        apvCont: '',
        scgAppNo: '',
        apvStsCd: '',
        apvStsNm: '',
        reqRno: '',
        containerDGList: [
          {
            dgSeq: '1',
            imdunNo: '',
            imdgSeq: '',
            imoClasCd: '',
            sbRiskVal1: '',
            sbRiskVal2: '',
            sbRiskVal3: '',
            flsTmpr: '',
            tmprUnitCd: 'C',
            txtPackage: '',
            grsWt: '',
            netWt: '',
            dgPkgGrpCd: '',
            emrTelCtrNo: this.telCtrNo,
            emrPicTelNo: '',
            emrTelNo: '',
            emrPicNm: '',
            tchlNm: '',
            dgPkgQty: '',
            dgMatlCd: '',
            isdDgPkgQty: '',
            dgCatCd: '',
            mpltYn: '',
            emsFeCd: '',
            emsSeCd: '',
            lqYn: '',
            imdgGrpCd: '',
            imdgGrpYn: '',
            dgRsdCd: '',
            saptTmprUnitCd: '' /* SAPT 단위 */,
            sp386Yn: '',
            saptTmpr: '',
            casNoList: [
              {
                casNo: '',
                rto: ''
              }
            ],
            addInfoMsg: '',
            addInfoMsgList: [],
            repsCont: ''
          }
        ]
      },
      disabledInputList: [],
      enabledInputList: [],
      disabledSelectList: [],
      enabledSelectList: []
    }
  },
  watch: {
    toggleOuterPage () {
      console.log('@@@@ update toggleOuterPage')
      console.log(this.toggleOuterPage)
      this.$nextTick(function () {
        if (this.toggleOuterPage) {
          this.afterCheckValidationDg()
        }
      })
    },
    forceUpdate () {
      console.log('@@@@ forceUpdate')
      this.initParams()
    },
    parentInfo () {
      console.log('@@@@ change')
      this.initParams()
    },
    parentDgList: {
      deep: true,
      handler () {
        console.log('parentDgList change')
        this.initParams()
      }
    }
  },
  created () {
    console.log('@@@@ created')
    this.initSelect()
    this.initParams()
    this.initCommCodes()
  },
  methods: {
    getRandomKey () {
      return Math.random().toString(36).substr(2, 11)
    },
    handleChange (idx) {
      console.log('@@@@ idx: ', idx)
    },
    async initCommCodes () {
      const arrCdId = ['VS044']
      this.commonCodes = await commons.getCommonsCodesCommon(arrCdId)
    },
    initSelect () {
      for (let i = 65; i <= 74; i++) {
        // 대문자 A-J
        this.emsFeCdList.push(String.fromCharCode(i))
      }

      for (let i = 65; i <= 90; i++) {
        // 대문자 A-Z
        this.emsSeCdList.push(String.fromCharCode(i))
      }
    },
    initParams () {
      console.log('@@@@ initParams')
      const parentInfo = this.parentInfo

      const dgInfo = this.dgInfo

      dgInfo.reqConts = this.$ekmtcCommon.isNotEmpty(parentInfo.reqConts)
        ? parentInfo.reqConts
        : ''
      dgInfo.vaniDt = this.$ekmtcCommon.isNotEmpty(parentInfo.vaniDt)
        ? parentInfo.vaniDt
        : ''
      dgInfo.scgAppNo = this.$ekmtcCommon.isNotEmpty(parentInfo.scgAppNo)
        ? parentInfo.scgAppNo
        : ''
      dgInfo.apvStsCd = this.$ekmtcCommon.isNotEmpty(parentInfo.apvStsCd)
        ? parentInfo.apvStsCd
        : ''
      dgInfo.apvStsNm = this.$ekmtcCommon.isNotEmpty(parentInfo.apvStsNm)
        ? parentInfo.apvStsNm
        : ''
      dgInfo.reqRno = this.$ekmtcCommon.isNotEmpty(parentInfo.reqRno)
        ? parentInfo.reqRno
        : ''
      dgInfo.apvCont = this.$ekmtcCommon.isNotEmpty(parentInfo.apvCont)
        ? parentInfo.apvCont
        : ''
      this.cntrTypCd = parentInfo.cntrTypCd
      this.cntrSzCd = parentInfo.cntrSzCd
      this.cntrSeq = this.index + 1
      this.cgoTypCd = parentInfo.cgoTypCd

      const containerDGList = this.parentDgList
      const TH = this
      if (
        containerDGList !== undefined &&
        containerDGList !== null &&
        containerDGList.length > 0
      ) {
        dgInfo.containerDGList = containerDGList

        dgInfo.containerDGList.forEach((obj) => {
          const casNoList = obj.casNoList

          if (
            this.$ekmtcCommon.isNotEmpty(obj.addInfoMsg) &&
            this.$ekmtcCommon.isNotEmpty(obj.addInfoMsg.reqCont)
          ) {
            obj.addInfoMsg = obj.addInfoMsg.reqCont
          }

          if (casNoList !== undefined && casNoList.length > 0) {
            TH.$set(obj, 'casNoList', casNoList)
          } else {
            // 새로운부킹 들어갔을때, containerDGList.casNoList 가 undefined 일때, 빈값 추가해줌
            if (this.saveFlag === 'ADD') {
              const casNoListArr = []
              casNoListArr.push({
                casNo: '',
                rto: ''
              })

              TH.$set(obj, 'casNoList', casNoListArr)
            }
          }
        })

        this.$nextTick(() => {
          TH.initDGInfo()

          this.disabledInputList.forEach((item) => {
            const node = document.querySelector('#' + item)
            node && (node.disabled = true)
          })
          this.enabledInputList.forEach((item) => {
            const node = document.querySelector('#' + item)
            node && (node.disabled = false)
          })
          this.disabledSelectList.forEach((item) => {
            const node = document.querySelector('#' + item)
            node && (node.disabled = true)
          })
          this.enabledSelectList.forEach((item) => {
            const node = document.querySelector('#' + item)
            node && (node.disabled = false)
          })
        })
      }
    },
    initDGInfo () {
      console.log('@@@@ enter initDGInfo')
      const dgInfo = { ...this.dgInfo }
      let containerDGList = [...dgInfo.containerDGList]
      containerDGList = containerDGList.map((b, idx) =>
        Object.assign({ index: idx }, b)
      )
      const cntrSeq = this.cntrSeq

      let imdunNo1361Cnt = 0

      containerDGList.forEach((obj, idx) => {
        if (obj.imdunNo === '1361') {
          imdunNo1361Cnt++
        }
        const input = document.querySelector('#tchlNm_' + cntrSeq + '_' + idx)
        const span = document.querySelector(
          '#tchlNmSpan_' + cntrSeq + '_' + idx
        )
        if (obj.tchlNmMdtyYn === 'Y' || obj.mpltCd === 'Y') {
          document.querySelector(
            '#emsFeCd_' + cntrSeq + '_' + idx
          ).disabled = false
          document.querySelector(
            '#emsSeCd_' + cntrSeq + '_' + idx
          ).disabled = false

          input.style.display = ''
          span.style.display = 'none'
        } else {
          obj.tchlNm = ''
          document.querySelector(
            '#emsFeCd_' + cntrSeq + '_' + idx
          ).disabled = true
          document.querySelector(
            '#emsSeCd_' + cntrSeq + '_' + idx
          ).disabled = true

          input.style.display = 'none'
          span.style.display = ''
        }

        if (this.$ekmtcCommon.isEmpty(obj.imdgGrpCd)) {
          if (obj.tchlNmMdtyYn === 'Y') {
            console.log('@@@@ imdgGrpCd disabled false')
            document.querySelector(
              '#imdgGrpCd_' + cntrSeq + '_' + idx
            ).disabled = false
            obj.imdgGrpYn = 'Y'
          } else {
            console.log('@@@@ imdgGrpCd disabled true')
            document.querySelector(
              '#imdgGrpCd_' + cntrSeq + '_' + idx
            ).disabled = true
            obj.imdgGrpYn = 'N'
          }
        } else {
          console.log('@@@@ imdgGrpCd else')
          if (obj.tchlNmMdtyYn === 'Y') {
            console.log('@@@@ imdgGrpCd else 1')
            document.querySelector(
              '#imdgGrpCd_' + cntrSeq + '_' + idx
            ).disabled = false
            obj.imdgGrpYn = 'Y'
          } else {
            console.log('@@@@ imdgGrpCd else 2')
            document.querySelector(
              '#imdgGrpCd_' + cntrSeq + '_' + idx
            ).disabled = true
            obj.imdgGrpYn = 'N'
          }
        }

        if (obj.sp386Yn === 'Y') {
          document.querySelector(
            '#saptTmpr_' + cntrSeq + '_' + idx
          ).disabled = false
          document.querySelector(
            '#saptTmprUnitCd_' + cntrSeq + '_' + idx
          ).disabled = false
        } else {
          document.querySelector(
            '#saptTmpr_' + cntrSeq + '_' + idx
          ).disabled = true
          document.querySelector(
            '#saptTmprUnitCd_' + cntrSeq + '_' + idx
          ).disabled = true
        }

        if (this.cntrTypCd === 'TK') {
          document.querySelector(
            '#lqYn_' + cntrSeq + '_' + idx
          ).disabled = true
        } else if (obj.lmtVal === '0') {
          document.querySelector(
            '#lqYn_' + cntrSeq + '_' + idx
          ).disabled = true
        } else {
          document.querySelector(
            '#lqYn_' + cntrSeq + '_' + idx
          ).disabled = false
        }

        let node = document.querySelector(
            '#lqYn_' + cntrSeq + '_' + idx
          )
        console.log('@@@@ node lqYn: ', node.value)
        console.log('@@@@ node lqYn: ', node.checked)
        if (node.checked === false && node.value === 'Y') {
          node.checked = true
        }
        console.log('@@@@ obj: ', obj)
      })

      if (imdunNo1361Cnt > 0) {
        this.vanningDtYn = 'Y'
      }
    },
    async afterCheckValidationDg () {
      console.log('@@@@ enter afterCheckValidationDg')
      console.log('@@@@ this.dgErrors: ', this.dgErrors)
      if (this.dgErrors.length === 0) return
      const prefix = [
        'dgRsdCd',
        'flsTmpr',
        'grsWt',
        'netWt',
        'emrPicNm',
        'emrTelNo',
        'casNo',
        'dgPkgQty'
      ]

      const dgList = this.dgInfo.containerDGList

      let idx = 0
      for (const d of dgList) {
        console.log('@@@@ this.dgErrors[idx]: ', this.dgErrors[idx])
        if (!Array.isArray(this.dgErrors[idx]) || this.dgErrors[idx].length === 0) return
        for (const el of this.dgErrors[idx]) {
          console.log('@@@@ el: ', el)
          console.log('@@@@ d[el]: ', d[el])
          await this.preCheckValidationDG(el, idx, 'SAVE', d)
        }

        idx++
      }
    },
    async sleep (ms) {
      const wakeUpTime = Date.now() + ms
      while (Date.now() < wakeUpTime) {
        //
      }
    },
    async preCheckValidationDG (_prefix, _idx, flag, payload) {
      console.log('@@@@ enter preCheckValidationDG')
      const prefix = [
        'dgRsdCd',
        'flsTmpr',
        'grsWt',
        'netWt',
        'emrPicNm',
        'emrTelNo',
        'casNo',
        'dgPkgQty'
      ]

      await this.checkValidationDG(_prefix, _idx, 'SAVE', payload)

      // const dgList = this.dgInfo.containerDGList

      // let idx = 0
      // for (const d of dgList) {
      //   for (const el of prefix) {
      //     await this.checkValidationDG(el, idx, 'SAVE', d)
      //   }

      //   idx++
      // }
    },
    async checkValidationDGAll () {
      this.dgErrors = []
      const _dgList = this.dgInfo.containerDGList
      const dgPrefix = [
        'dgRsdCd',
        'flsTmpr',
        'grsWt',
        'netWt',
        'emrPicNm',
        'emrTelNo',
        'casNo',
        'dgPkgQty'
      ]

      let _idx = 0
      for (const d of _dgList) {
        let tmp = []
        for (const el of dgPrefix) {
          const frm = document.querySelector('#frm')
          const idxInfo = this.cntrSeq + '_' + _idx
          const id = el + '_' + _idx
          const selector = frm.querySelector('#' + id)
          if (this.$ekmtcCommon.hasErrorTooltip(selector)) {
            tmp.push(el)
          }
        }
        console.log('@@@@ tmp: ', tmp)
        this.dgErrors.push(tmp)
        _idx++
      }

      console.log('@@@@ call checkValidationDGAll')
      const prefix = [
        'imdunNo',
        'dgPkgGrpCd',
        'imdgGrpCd',
        'dgMatlCd',
        'dgRsdCd',
        'lqYn',
        'repsCont',
        'emrPicNm',
        'emrTelCtrNo',
        'emrTelNo',
        'flsTmpr',
        'saptTmpr',
        'grsWt',
        'netWt',
        'dgPkgQty',
        'isdDgPkgQty',
        'dgCatCd',
        'mpltCd',
        'casNo',
        'tchlNm'
      ]

      const dgList = this.dgInfo.containerDGList

      let isOk = true

      let idx = 0
      this.alertShow = 'Y'
      for (const d of dgList) {
        for (const el of prefix) {
          if (!(await this.checkValidationDG(el, idx, 'SAVE', d))) {
            console.log('@@@@ el error: ', el)
            isOk = false
          }
        }

        idx++
      }

      return isOk
    },
    async checkValidationDG (prefix, idx, flag, payload) {
      console.log('@@@@ enter checkValidationDG')
      console.log('@@@@ prefix: ', prefix)
      console.log('@@@@ payload: ', payload)
      console.log('@@@@ payload: ', typeof payload)
      let isOk = true
      const TH = this
      const cntrSeq = this.cntrSeq
      const frm = document.querySelector('#frm')
      const idxInfo = cntrSeq + '_' + idx
      const id = prefix + '_' + idxInfo
      const selector = frm.querySelector('#' + id)
      const containerDGInfo = { ...this.dgInfo.containerDGList[idx] }

      if (typeof payload === 'object') {
        // nothing
      } else {
        containerDGInfo[prefix] = payload
      }

      console.log('@@@@ containerDGInfo: ', containerDGInfo)

      if (selector !== null) {
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }

      if (
        prefix === 'imdunNo' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim())
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }

      if (
        prefix === 'dgPkgGrpCd' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim())
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        selector.disabled = false
        isOk = false
      }
      if (
        prefix === 'imdgGrpCd' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim()) &&
        (containerDGInfo.tchlNmMdtyYn === 'Y' ||
          containerDGInfo.mpltYn === 'Y') &&
        !selector.disabled
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          'Please kindly select Segregation Group of UNNO ' +
            containerDGInfo.imdunNo +
            ' according to 3.1.4.2 and 5.4.1.4.11.1 of IMDG'
        )
        isOk = false
      }
      if (
        prefix === 'dgMatlCd' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim())
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }
      if (
        prefix === 'dgRsdCd' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim())
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }
      const addInfoMsgList = containerDGInfo.addInfoMsgList
      if (
        prefix === 'repsCont' &&
        selector !== null &&
        addInfoMsgList !== undefined &&
        addInfoMsgList.length > 0
      ) {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        } else {
          const selectMsgInfo = addInfoMsgList.filter(
            (vo) => vo.rspsCont === selector.value
          )
          if (
            selectMsgInfo !== undefined &&
            selectMsgInfo.length > 0 &&
            selectMsgInfo[0].rspsUseYn !== 'Y'
          ) {
            const msg =
              'Cargo Seq No. ' +
              containerDGInfo.dgSeq +
              ' / UN No. ' +
              containerDGInfo.imdunNo +
              'cannot be saved due to KMTC prohibited or illegal information by IMDG Code.\n' +
              selectMsgInfo[0].rspsCont
            containerDGInfo.repsCont = ''
            selector.value = ''
            this.$ekmtcCommon.alertDefault(msg)
          }
        }
      }
      if (
        prefix === 'emrPicNm' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim())
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }

      if (
        prefix === 'emrTelCtrNo' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim())
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }
      if (
        prefix === 'emrTelNo' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim())
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      } else if (prefix === 'emrTelNo' && selector.value.trim() === '0') {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }

      if (
        prefix === 'mpltCd' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim())
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      } else if (
        prefix === 'mpltCd' &&
        containerDGInfo.mpltCd === 'N' &&
        containerDGInfo.tchlNmMdtyYn !== 'Y'
      ) {
        const tchlArea = frm.querySelector('#tchlNm_' + idxInfo)
        this.$ekmtcCommon.hideErrorTooltip(tchlArea)
        containerDGInfo.tchlNm = ''
      }

      if (
        prefix === 'tchlNm' &&
        (containerDGInfo.mpltCd === 'Y' ||
          containerDGInfo.tchlNmMdtyYn === 'Y') &&
        this.$ekmtcCommon.isEmpty(containerDGInfo.tchlNm)
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }

      if (
        prefix === 'flsTmpr' &&
        this.$ekmtcCommon.isEmpty(selector.value.trim()) &&
        (containerDGInfo.imoClasCd === '3' ||
          containerDGInfo.sbRiskVal1 === '3' ||
          containerDGInfo.sbRiskVal2 === '3')
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      } else if (
        prefix === 'flsTmpr' &&
        this.$ekmtcCommon.isNotEmpty(selector.value.trim())
      ) {
        const isFlsTmprOk = this.checkValidationFlsTmpr(idx, containerDGInfo)

        if (!isFlsTmprOk) {
          isOk = false
        }
      }

      // Net Weight
      if (prefix === 'grsWt' || prefix === 'netWt') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.147')
          )
          isOk = false
        } else {
          if (this.cntrTypCd === 'TK') {
            containerDGInfo.grsWt = containerDGInfo.netWt
          }
          console.log('@@@@ containerDGInfo.grsWt: ', containerDGInfo.grsWt)
          let grsWt = Number(
            containerDGInfo.grsWt === '' ? 0 : containerDGInfo.grsWt
          )
          let netWt = Number(containerDGInfo.netWt)

          if (
            (prefix === 'grsWt' && grsWt > 40000) ||
            (prefix === 'netWt' && netWt > 40000) ||
            grsWt < netWt
          ) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              'Please kindly check GWT and NET.\nAnd, GWT and NET are must be filled out as per one container.'
            )
            isOk = false
          } else {
            this.$ekmtcCommon.hideErrorTooltip(
              frm.querySelector('#grsWt_' + idxInfo)
            )
            if (containerDGInfo.netWt !== '') {
              this.$ekmtcCommon.hideErrorTooltip(
                frm.querySelector('#netWt_' + idxInfo)
              )
            }
          }
        }
      }

      if (prefix === 'dgPkgQty') {
        // this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#isdDgPkgQty_' + idxInfo))
      } else if (prefix === 'isdDgPkgQty') {
        // this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#dgPkgQty_' + idxInfo))
      }

      if (
        prefix === 'dgPkgQty' &&
        (this.$ekmtcCommon.isEmpty(selector.value.trim()) ||
          selector.value.trim() === '0')
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      } else if (
        prefix === 'dgPkgQty' &&
        Number(containerDGInfo.dgPkgQty) > Number(containerDGInfo.isdDgPkgQty)
      ) {
        if (Number(containerDGInfo.isdDgPkgQty) !== 0) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            'Please check again package type, quantity corresponding cargo weight.'
          )
          isOk = false
        }
      }

      if (
        prefix === 'isdDgPkgQty' &&
        (this.$ekmtcCommon.isEmpty(selector.value.trim()) ||
          selector.value.trim() === '0')
      ) {
        if (containerDGInfo.dgCatCd !== '-') {
          let msg =
            selector.value.trim() === '0'
              ? 'Please input correct inner package quantity not zero.'
              : this.$t('msg.CSBK100.147')
          this.$ekmtcCommon.showErrorTooltip(selector, msg)
          isOk = false
        }
      } else if (
        prefix === 'isdDgPkgQty' &&
        Number(containerDGInfo.dgPkgQty) > Number(containerDGInfo.isdDgPkgQty)
      ) {
        this.$ekmtcCommon.showErrorTooltip(
          selector,
          'Please check again package type, quantity corresponding cargo weight.'
        )
        isOk = false
      }

      if (
        prefix === 'dgCatCd' &&
        (this.$ekmtcCommon.isEmpty(selector.value.trim()) ||
          selector.value.trim() === '-')
      ) {
        if (
          this.$ekmtcCommon.isNotEmpty(containerDGInfo.isdDgPkgQty) &&
          String(containerDGInfo.isdDgPkgQty) !== '0'
        ) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.380')
          )
          isOk = false
        } else {
          if (prefix === 'dgCatCd' && containerDGInfo.dgCatCd === '') {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('msg.CSBK100.380')
            )
            isOk = false
          }
        }
      } else if (
        prefix === 'dgCatCd' &&
        this.$ekmtcCommon.isNotEmpty(selector.value.trim()) &&
        selector.value.trim() !== '-'
      ) {
        if (
          this.$ekmtcCommon.isEmpty(containerDGInfo.isdDgPkgQty) ||
          String(containerDGInfo.isdDgPkgQty) === '0'
        ) {
          let msg =
            containerDGInfo.isdDgPkgQty === '0'
              ? 'Please input correct inner package quantity not zero.'
              : this.$t('msg.CSBK100.147')
          this.$ekmtcCommon.showErrorTooltip(
            frm.querySelector('#isdDgPkgQty_' + idxInfo),
            msg
          )
          isOk = false
        }
      }

      if (prefix === 'casNo' && this.casNoPortYn === 'Y') {
        containerDGInfo.casNoList.forEach((el, i) => {
          if (!this.checkValidationCasNo(el, idx, i)) {
            isOk = false
          }
        })
      }

      if (prefix === 'lqYn' && containerDGInfo.lqYn !== 'Y') {
        let strImdgGrpCd = ''
        // this.dgInfo.containerDGList.forEach((dg) => {
        //   strImdgGrpCd += dg.imdgGrpCd + ','
        // })
        if (containerDGInfo.imdgGrpCd) {
          strImdgGrpCd = containerDGInfo.imdgGrpCd
        }

        if (
          strImdgGrpCd.indexOf('01') > -1 &&
          strImdgGrpCd.indexOf('18') > -1
        ) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            'Segmentation Group ACIDS and ALKALS are not intermixed.'
          )
          isOk = false
        }
      }
      let msg = ''
      if (prefix === 'saptTmpr') {
        if (this.$ekmtcCommon.isEmpty(selector.value.trim())) {
          if (containerDGInfo.sp386Yn === 'Y') {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              this.$t('msg.CSBK100.147')
            )
            isOk = false
          }
        } else {
          const confirmMsg =
            'UN No. ' +
            containerDGInfo.imdunNo +
            '(SAPT ' +
            selector.value +
            this.$t('msg.CSBK100.196')
            console.log('@@@@ selector.value: ', selector.value)
          if (Number(selector.value) <= 45) {
            console.log('@@@@ 1')
            if (this.cntrTypCd === 'GP' || this.cntrTypCd === 'HC') {
              console.log('@@@@ 2')
              msg =
                'UN No. ' +
                containerDGInfo.imdunNo +
                '(SAPT ' +
                selector.value +
                "'C)" +
                this.$t('msg.CSBK100.197_1') +
                this.$t('msg.CSBK100.197')
            } else if (this.cntrTypCd === 'TK') {
              console.log('@@@@ 3')
              msg = this.$t('msg.CSBK100.197')
            } else {
              console.log('@@@@ 4')
              msg = this.$t('msg.CSBK100.198')
            }

            this.$ekmtcCommon.showErrorTooltip(selector, msg)
            isOk = false
          } else if (
            Number(selector.value) > 45 &&
            Number(selector.value) <= 50
          ) {
            if (this.cntrTypCd === 'GP' || this.cntrTypCd === 'HC') {
              msg = this.$t('msg.CSBK100.197')
              isOk = false
              this.$ekmtcCommon.showErrorTooltip(selector, msg)
            } else if (this.cntrTypCd === 'TK') {
              console.log('@@@@ TK confirmMsg: ', confirmMsg)
              if (
                !(await this.$ekmtcCommon.asyncAlertMessage({
                  message: confirmMsg,
                  useConfirmBtn: true
                }))
              ) {
                console.log('confirm in')
                TH.alertShow = 'N'
                return false
              }
            } else {
              console.log('@@@@ error tooltip')
              msg = this.$t('msg.CSBK100.198')

              isOk = false
              this.$ekmtcCommon.showErrorTooltip(selector, msg)
            }
          } else if (
            Number(selector.value) > 50 &&
            Number(selector.value) <= 75
          ) {
            if (
              this.cntrTypCd === 'GP' ||
              this.cntrTypCd === 'HC' ||
              this.cntrTypCd === 'TK'
            ) {
              if (
                !(await this.$ekmtcCommon.asyncAlertMessage({
                  message: confirmMsg,
                  useConfirmBtn: true
                }))
              ) {
                TH.alertShow = 'N'
                return false
              }
            } else {
              msg = this.$t('msg.CSBK100.198')
              this.$ekmtcCommon.showErrorTooltip(selector, msg)
              isOk = false
            }
          } else if (Number(selector.value) > 75) {
            if (
              this.cntrTypCd !== 'GP' &&
              this.cntrTypCd !== 'HC' &&
              this.cntrTypCd !== 'TK'
            ) {
              msg = this.$t('msg.CSBK100.198')
              this.$ekmtcCommon.showErrorTooltip(selector, msg)
              isOk = false
            }
          }
        }
      }

      // dispatch
      if (prefix === 'netWt' && this.cntrTypCd === 'TK') {
        console.log('@@@@ dispatch payload: ', payload)
        if (typeof payload === 'string') {
          containerDGInfo.grsWt = payload
        }
        this.grswtKey++
      }
      this.setContainerDgList(this.index, idx, containerDGInfo)

      return isOk
    },
    checkValidationCasNo (el, idx, i, payload) {
      console.log('@@@@ enter checkValidationCasNo')
      console.log('@@@@ el: ', el)
      console.log('@@@@ idx: ', idx)
      console.log('@@@@ i: ', i)
      console.log('@@@@ payload: ', payload)
      let containerDGList = [...this.dgInfo.containerDGList]
      containerDGList = containerDGList.map((b, idx) =>
        Object.assign({ index: idx }, b)
      )
      const containerDGInfo = { ...containerDGList[idx] }
      let _casNo = { ...containerDGInfo.casNoList[i] }
      if (payload) {
        console.log('@@@@ in payload')
        _casNo.casNo = payload
      } else {
        if (payload === '') {
          _casNo.casNo = payload
        }
      }

      containerDGInfo.casNoList[i] = _casNo
      let isOk = true
      const casSelector = frm.querySelector('#casNo_' + idx + '_' + i)
      this.$ekmtcCommon.hideErrorTooltip(casSelector)
      if (this.$ekmtcCommon.isEmpty(_casNo.casNo) && this.casNoPortYn === 'Y') {
        this.$ekmtcCommon.showErrorTooltip(
          casSelector,
          this.$t('msg.CSBK100.147')
        )
        isOk = false
      }

      this.setContainerDgList(this.index, idx, containerDGInfo)

      return isOk
    },
    checkValidationRto (el, idx, i, payload) {
      console.log('@@@@ enter checkValidationRto')
      console.log('@@@@ this.dgInfo: ', this.dgInfo.containerDGList)
      const containerDGInfo = { ...this.dgInfo.containerDGList[idx] }
      containerDGInfo.casNoList[i].rto = payload
      let isOk = true
      // const casSelector = frm.querySelector('#casNo_' + idx + '_' + i)
      // this.$ekmtcCommon.hideErrorTooltip(casSelector)
      // if (this.$ekmtcCommon.isEmpty(el.casNo)) {
      //   this.$ekmtcCommon.showErrorTooltip(casSelector, this.$t('msg.CSBK100.147'))
      //   isOk = false
      // }

      this.setContainerDgList(this.index, idx, containerDGInfo)

      return isOk
    },
    checkValidationFlsTmpr (idx, _containerDGInfo = null) {
      console.log('@@@@ enter checkValidationFlsTmpr')
      console.log('@@@@ _containerDGInfo: ', _containerDGInfo)
      const frm = document.querySelector('#frm')
      let dg = this.dgInfo.containerDGList[idx]

      if (_containerDGInfo !== null) {
        dg = _containerDGInfo
      }
      console.log('@@@@ dg: ', dg)
      const cntrSeq = this.cntrSeq
      let isOk = true
      const selector = frm.querySelector('#flsTmpr_' + cntrSeq + '_' + idx)

      if (this.$ekmtcCommon.isNotEmpty(dg.imdunNo)) {
        if (this.$ekmtcCommon.isNotEmpty(dg.strFlsTmpr)) {
          let strFlsTmpr = Number(dg.strFlsTmpr)
          let endFlsTmpr = Number(dg.endFlsTmpr)
          const flsTmpr = Number(dg.flsTmpr)

          if (flsTmpr !== 0 && (strFlsTmpr > flsTmpr || endFlsTmpr < flsTmpr)) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              'Please check again Flash Point.' +
                strFlsTmpr +
                '℃~' +
                endFlsTmpr +
                '℃'
            )
            isOk = false
          }
        }
        console.log('@@@@ dg.strFlsTmpr: ', dg.strFlsTmpr)
        console.log('@@@@ dg.flsTmpr: ', dg.flsTmpr)

        if (
          this.$ekmtcCommon.isEmpty(dg.strFlsTmpr) &&
          this.$ekmtcCommon.isNotEmpty(dg.flsTmpr)
        ) {
          console.log('@@@@ if 1')
          const clasCd = this.$ekmtcCommon.isNotEmpty(dg.imoClasCd)
            ? dg.imoClasCd.substring(0, 1)
            : ''
          const sbRiskVal1 = this.$ekmtcCommon.isNotEmpty(dg.sbRiskVal1)
            ? dg.sbRiskVal1.substring(0, 1)
            : ''
          const sbRiskVal2 = this.$ekmtcCommon.isNotEmpty(dg.sbRiskVal2)
            ? dg.sbRiskVal2.substring(0, 1)
            : ''
          const sbRiskVal3 = this.$ekmtcCommon.isNotEmpty(dg.sbRiskVal3)
            ? dg.sbRiskVal3.substring(0, 1)
            : ''

          if (
            clasCd === '3' ||
            sbRiskVal1 === '3' ||
            sbRiskVal2 === '3' ||
            sbRiskVal3 === '3'
          ) {
            if (
              (dg.dgPkgGrpCd === '02' || dg.dgPkgGrpCd === '03') &&
              Number(dg.flsTmpr) >= 23
            ) {
              console.log('flash point 1')
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                'Please kindly check Flash Point according Packing Group.\nIn case of Class 3 (Flammable Liquid), please refer as below.\nPacking Group I or II : Flash Point <23 °C'
              )
              isOk = false
            }

            if (
              dg.dgPkgGrpCd === '01' &&
              (Number(dg.flsTmpr) < 23 || Number(dg.flsTmpr) > 60)
            ) {
              console.log('flash point 2')
              console.log('@@@@ 2 Number(dg.flsTmpr): ', Number(dg.flsTmpr))
              this.$ekmtcCommon.showErrorTooltip(
                selector,
                'Please kindly check Flash Point according Packing Group.\nIn case of Class 3 (Flammable Liquid), please refer as below.\nPacking Group III :  Flash Point  ≥23 to ≤60 °C'
              )
              isOk = false
            }
          } else if (
            clasCd !== '2' &&
            clasCd !== '4' &&
            sbRiskVal1 !== '2' &&
            sbRiskVal1 !== '4' &&
            sbRiskVal2 !== '2' &&
            sbRiskVal2 !== '4' &&
            sbRiskVal3 !== '2' &&
            sbRiskVal3 !== '4' &&
            Number(dg.flsTmpr) < 60
          ) {
            this.$ekmtcCommon.showErrorTooltip(
              selector,
              'Please check again F.P as Celcius(℃).\nIf F.P is less than 60℃, it should be classfied Class 3 DG Cargo.'
            )
            isOk = false
          }
        }
      }

      return isOk
    },
    async checkValidationDGMax () {
      const frm = document.querySelector('#frm')
      const containerDGList = this.dgInfo.containerDGList
      const cntrSzCd = this.cntrSzCd
      const cntrTypCd = this.cntrTypCd
      const cntrSeq = this.cntrSeq

      for (const dg of containerDGList) {
        const params = {
          cntrSzCd: cntrSzCd,
          cntrTypCd: cntrTypCd,
          flag: 'maxWt',
          imdunNo: dg.imdunNo,
          imdgSeq: dg.imdgSeq,
          dgMatlCd: dg.dgMatlCd
        }

        const grsWt = Number(dg.grsWt)
        const netWt = Number(dg.netWt)
        const dgRsdCd = dg.dgRsdCd

        let netCnt = []
        let pkgCnt = []

        await trans.getDgMaxCheck(params).then(async (res) => {
          const result = res.data
          if (result !== undefined) {
            if (result.sumGrsWt !== 'N' && grsWt > Number(result.sumGrsWt)) {
              this.$ekmtcCommon.alertDefault(
                'Please check again Gross weight. It should be modified as one container information.'
              )
              return false
            }

            if (
              dgRsdCd !== '01' &&
              result.maxNetWt !== 'N' &&
              netWt > Number(result.maxNetWt)
            ) {
              const message1 =
                'Please input cargo residue weight only in Gross and Net weight.\nTare weight shall be excluded from Gross and Net weight in case ISO Tank.'
              if (
                !(await this.$ekmtcCommon.asyncAlertMessage({
                  message: message1,
                  useConfirmBtn: true
                }))
              ) {
                return false
              }
            }

            const inPkgQty = Number(dg.isdDgPkgQty)
            const outPkgQty = Number(dg.dgPkgQty)

            if (
              result.maxOutWt !== 'N' &&
              netWt > Number(result.maxOutWt) * outPkgQty
            ) {
              netCnt.push(dg.dgSeq)
            }

            const pkg = inPkgQty !== 0 ? inPkgQty : outPkgQty
            if (
              dgRsdCd === '02' &&
              result.maxInWt !== 'N' &&
              netWt / pkg > Number(result.maxInWt)
            ) {
              pkgCnt.push(dg.dgSeq)
            }
          }
        })

        if (
          (this.$ekmtcCommon.isNotEmpty(netCnt) && netCnt.length > 0) ||
          (this.$ekmtcCommon.isNotEmpty(pkgCnt) && pkgCnt.length > 0)
        ) {
          let errMsg = ''
          if (netCnt.length > 0) {
            if (netCnt.length === 1) {
              errMsg +=
                'Please check again package type, quantity corresponding cargo weight.\n'
            } else {
              errMsg +=
                '[Cargo Seq No. ' +
                netCnt.join(', ') +
                ']\nPlease check again package type, quantity corresponding cargo weight.\n'
            }
          }

          if (errMsg !== '') errMsg += '\n'

          if (pkgCnt.length > 0) {
            if (pkgCnt.length === 1) {
              errMsg +=
                'Please input cargo residue weight only in Net weight.\nCurrent weight information is too much considering cargo residue booking.\n'
            } else {
              errMsg +=
                '[Cargo Seq No. ' +
                pkgCnt.join(', ') +
                ']\nPlease input cargo residue weight only in Net weight.\nCurrent weight information is too much considering cargo residue booking.\n'
            }
          }

          if (
            !(await this.$ekmtcCommon.asyncAlertMessage({
              message: errMsg,
              useConfirmBtn: true
            }))
          ) {
            return false
          }
        }
      }

      return true
    },
    addContainerDG () {
      if (this.statusClass === 'past') return false
      if (this.disabledYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.035'))
        return
      }

      const containerDGList = [...this.dgInfo.containerDGList]
      const dgSeq =
        containerDGList.length === 0
          ? 1
          : Number(containerDGList[containerDGList.length - 1].dgSeq) + 1
      const obj = {
        dgSeq: dgSeq,
        imdunNo: '',
        imdgSeq: '',
        imoClasCd: '',
        sbRiskVal1: '',
        sbRiskVal2: '',
        sbRiskVal3: '',
        flsTmpr: '',
        tmprUnitCd: 'C',
        txtPackage: '',
        grsWt: '',
        netWt: '',
        dgPkgGrpCd: '',
        emrPicTelNo: '',
        emrTelCtrNo: '',
        tchlNm: '',
        tchlNmMdtyYn: '',
        dgPkgQty: '',
        dgMatlCd: '',
        isdDgPkgQty: '',
        dgCatCd: '',
        mpltCd: '',
        emsFeCd: '',
        emsSeCd: '',
        lqYn: '',
        imdgGrpCd: '',
        imdgGrpYn: '',
        dgRsdCd: '',
        saptTmprUnitCd: '' /* SAPT 단위 */,
        sp386Yn: '',
        saptTmpr: '',
        casNoList: [{ casNo: '', rto: '' }],
        addInfoMsg: '',
        addInfoMsgList: [],
        repsCont: ''
      }

      containerDGList.push(obj)

      this.$store.dispatch('schedule/addContainerDgList', {
        parentIndex: this.index,
        dg: obj
      })
    },
    removeContainerDG (idx) {
      if (this.statusClass === 'past') return false
      if (this.disabledYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.002'))
        return
      }

      // const containerDGList = [...this.dgInfo.containerDGList] || []
      // const containerDGList = this.dgInfo.containerDGList.slice()
      // const containerDGList = this.deepCopy(this.dgInfo.containerDGList)
      let containerDGList = [...this.dgInfo.containerDGList]
      containerDGList = containerDGList.map((b, idx) =>
        Object.assign({ index: idx }, b)
      )
      console.log('@@@@ containerDGList: ', containerDGList)

      if (containerDGList.length > 1) {
        containerDGList.splice(idx, 1)
        const len = containerDGList.length
        console.log('@@@@ containerDGList: ', containerDGList)
        console.log('@@@@ len: ', len)

        // if (len === 0) {
        //   this.addContainerDG()
        // }

        containerDGList.forEach((c, idx) => {
          c.dgSeq = idx + 1
        })

        this.$store.dispatch('schedule/removeContainerDgList', {
          parentIndex: this.index,
          dgList: containerDGList
        })
      }
    },
    getUnnoInfo (idx) {
      if (this.unnoDisabledYn === 'Y') {
        // unno 수정 불가할 경우
        return
      }

      const dgInfo = { ...this.dgInfo.containerDGList[idx] }
      if (dgInfo !== undefined && dgInfo !== null) {
        this.popParams = {
          unno: dgInfo.imdunNo,
          cntrTypCd: this.cntrTypCd,
          selectIdx: idx
        }

        this.selectFunc = this.setUnnoInfo

        this.openPopup('unno-pop')
      }
    },
    async setUnnoInfo (obj) {
      console.log('@@@@ enter setUnnoInfo: ', obj)
      const idx = obj.selectIdx
      const dgInfo = { ...this.dgInfo.containerDGList[idx] }

      const cntrSeq = this.cntrSeq

      dgInfo.imdunNo = obj.imdunNo
      dgInfo.imoClasCd = obj.imoClasCd
      dgInfo.imdgSeq = obj.imdgSeq
      dgInfo.dgPkgGrpCd = obj.pakgGrpCd
      dgInfo.dsc = obj.dsc
      dgInfo.emsFeCd = obj.emsFeCd
      dgInfo.emsSeCd = obj.emsSeCd
      dgInfo.mpltCd = obj.mpltCd
      dgInfo.tchlNm = obj.tchlNm
      dgInfo.sbRiskVal1 = obj.sbRiskVal1
      dgInfo.sbRiskVal2 = obj.sbRiskVal2
      dgInfo.sbRiskVal3 = obj.sbRiskVal3
      dgInfo.tchlNmMdtyYn = obj.tchlNmMdtyYn
      dgInfo.flsTmpr = ''
      dgInfo.saptTmpr = ''
      dgInfo.saptTmprUnitCd = ''
      dgInfo.lmtVal = obj.lmtVal

      if (obj.sp386Yn === 'Y') {
        dgInfo.saptTmprUnitCd = 'C'
      }

      const elImdunNo = document.querySelector(
        '#imdunNo_' + cntrSeq + '_' + idx
      )
      const elDgPkgGrp = document.querySelector(
        '#dgPkgGrpCd_' + cntrSeq + '_' + idx
      )
      this.$ekmtcCommon.hideErrorTooltip(elImdunNo)
      this.$ekmtcCommon.hideErrorTooltip(elDgPkgGrp)

      const input = document.querySelector('#tchlNm_' + cntrSeq + '_' + idx)
      const span = document.querySelector('#tchlNmSpan_' + cntrSeq + '_' + idx)
      if (obj.tchlNmMdtyYn === 'Y' || obj.mpltCd === 'Y') {
        document.querySelector(
          '#emsFeCd_' + cntrSeq + '_' + idx
        ).disabled = false
        document.querySelector(
          '#emsSeCd_' + cntrSeq + '_' + idx
        ).disabled = false

        input.style.display = ''
        span.style.display = 'none'
      } else {
        dgInfo.tchlNm = ''
        document.querySelector(
          '#emsFeCd_' + cntrSeq + '_' + idx
        ).disabled = true
        document.querySelector(
          '#emsSeCd_' + cntrSeq + '_' + idx
        ).disabled = true

        input.style.display = 'none'
        span.style.display = ''
      }

      if (obj.imdgGrpCd === 'N') {
        if (obj.tchlNmMdtyYn === 'Y') {
          document.querySelector(
            '#imdgGrpCd_' + cntrSeq + '_' + idx
          ).disabled = false
          dgInfo.imdgGrpYn = 'Y'
        } else {
          document.querySelector(
            '#imdgGrpCd_' + cntrSeq + '_' + idx
          ).disabled = true
          dgInfo.imdgGrpYn = 'N'
        }
        dgInfo.imdgGrpCd = ''
      } else {
        dgInfo.imdgGrpCd = obj.imdgGrpCd
        document.querySelector(
          '#imdgGrpCd_' + cntrSeq + '_' + idx
        ).disabled = true
        dgInfo.imdgGrpYn = 'N'
      }

      if (obj.sp386Yn === 'Y') {
        document.querySelector(
          '#saptTmpr_' + cntrSeq + '_' + idx
        ).disabled = false
        document.querySelector(
          '#saptTmprUnitCd_' + cntrSeq + '_' + idx
        ).disabled = false
        dgInfo.sp386Yn = 'Y'
      } else {
        document.querySelector(
          '#saptTmpr_' + cntrSeq + '_' + idx
        ).disabled = true
        document.querySelector(
          '#saptTmprUnitCd_' + cntrSeq + '_' + idx
        ).disabled = true
        dgInfo.sp386Yn = ''
        dgInfo.saptTmpr = ''
      }

      dgInfo.lqYn = 'N'
      if (this.cntrTypCd === 'TK') {
        document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = true
      } else if (obj.lmtVal === '0') {
        document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = true
      } else {
        document.querySelector('#lqYn_' + cntrSeq + '_' + idx).disabled = false
      }

      dgInfo.outerPkgList = obj.dgOuterList

      const question = obj.addInfoMsg
      let forceUpdate = false
      if (question !== undefined && question !== null) {
        dgInfo.addInfoMsg = question.reqCont
        const seq = question.reqSeq

        if (this.$ekmtcCommon.isNotEmpty(seq)) {
          await unno.getDgAddInfoList(seq).then((res) => {
            dgInfo.addInfoMsgList = res.data
            forceUpdate = true
          })
        } else {
          dgInfo.addInfoMsgList = []
          forceUpdate = true
        }
      }

      await transCommon.dgFPointCheck(dgInfo.imdunNo).then((res) => {
        const result = res.data
        if (
          result !== undefined &&
          result !== 'N' &&
          result.indexOf('@') > -1
        ) {
          dgInfo.strFlsTmpr = Number(result.split('@')[0])
          dgInfo.endFlsTmpr = Number(result.split('@')[1])
        }
      })

      if (this.lang === 'ENG') {
        this.$emit('vanningChk')

        if (dgInfo.imdunNo === '1361') {
          this.vanningDtYn = 'Y'
        } else {
          this.vanningDtYn = 'N'
        }
      }
      console.log('@@@@ dgInfo: ', dgInfo)

      this.setContainerDgList(this.index, idx, dgInfo, forceUpdate)
    },
    async changeDgOuter (vo, idx, payload) {
      let rootId = 'dg_area_' + this.cntrSeq
      console.log('@@@@ rootId: ', rootId)
      const frm = document.querySelector('#' + rootId)

      const inputEl = frm.getElementsByTagName('input')
      for (const el of inputEl) {
        let id = el.getAttribute('id') || ''

        if (el.disabled) {
          this.disabledInputList.push(id)
        } else {
          this.enabledInputList.push(id)
        }
      }

      const selectEl = frm.getElementsByTagName('select')
      for (const el of selectEl) {
        let id = el.getAttribute('id') || ''

        if (el.disabled) {
          //
          this.disabledSelectList.push(id)
        } else {
          this.enabledSelectList.push(id)
        }
      }

      // error list setting
      this.dgErrors = []
      const dgList = this.dgInfo.containerDGList
      // let dgList = [...this.dgInfo.containerDGList]
      // dgList = dgList.map((b, idx) =>
      //   Object.assign({ index: idx }, b)
      // )
      const prefix = [
        'dgRsdCd',
        'flsTmpr',
        'grsWt',
        'netWt',
        'emrPicNm',
        'emrTelNo',
        'casNo',
        'dgPkgQty'
      ]

      let _idx = 0
      for (const d of dgList) {
        let tmp = []
        for (const el of prefix) {
          const frm = document.querySelector('#frm')
          const idxInfo = this.cntrSeq + '_' + _idx
          const id = el + '_' + idxInfo
          const selector = frm.querySelector('#' + id)
          if (this.$ekmtcCommon.hasErrorTooltip(selector)) {
            tmp.push(el)
          }
        }
        console.log('@@@@ tmp: ', tmp)
        this.dgErrors.push(tmp)
        _idx++
      }

      this.toggleOuterPage = false
      console.log('@@@@ enter changeDgOuter')
      console.log('@@@@ payload: ', payload)
      // const outer = vo.dgMatlCd
      // const containerDGInfo = { ...this.dgInfo.containerDGList[idx] }
      let containerDGInfo = { ...this.dgInfo.containerDGList[idx] }
      containerDGInfo = Object.assign({}, containerDGInfo)
      containerDGInfo = JSON.parse(JSON.stringify(containerDGInfo))

      containerDGInfo.dgMatlCd = payload
      // vo.dgMatlCd = payload
      const outer = payload
      // await this.checkValidationDG('dgMatlCd', idx, '', payload)
      this.$delete(containerDGInfo, 'innerPkgList')
      if (this.$ekmtcCommon.isNotEmpty(outer)) {
        const params = {
          cntrTypeCd: this.cntrTypCd,
          imdgSeq: containerDGInfo.imdgSeq,
          imdunNo: containerDGInfo.imdunNo,
          outer: outer
        }

        const def = {
          cd: '',
          cdNm: ''
        }

        const newList = []
        await unno.getDgInnerList(params).then((data) => {
          const innerPkgList = data.data
          innerPkgList.forEach((c) => {
            newList.push({ ...def, ...c })
          })

          this.$set(containerDGInfo, 'innerPkgList', newList)
          containerDGInfo.dgCatCd = '-'
          containerDGInfo.isdDgPkgQty = '0'
        })
      }

      console.log('@@@@ vo: ', vo)
      await this.setContainerDgList(this.index, idx, containerDGInfo, true)
      // this.$nextTick(function () {
      //   this.toggleOuterPage = true
      // })

      // await this.afterCehckValidationDg()
    },
    setDisabledList () {

    },
    addCasNo (idx) {
      const obj = {
        casNo: '',
        rto: ''
      }
      let rootId = 'dg_area_' + this.cntrSeq
      console.log('@@@@ rootId: ', rootId)
      const frm = document.querySelector('#' + rootId)

      const inputEl = frm.getElementsByTagName('input')
      for (const el of inputEl) {
        let id = el.getAttribute('id') || ''

        if (el.disabled) {
          //
          this.disabledInputList.push(id)
        } else {
          this.enabledInputList.push(id)
        }

        console.log(el.disabled)
      }

      const selectEl = frm.getElementsByTagName('select')
      for (const el of selectEl) {
        let id = el.getAttribute('id') || ''

        if (el.disabled) {
          //
          this.disabledSelectList.push(id)
        } else {
          this.enabledSelectList.push(id)
        }

        console.log(el.disabled)
      }

      let dg = { ...this.dgInfo.containerDGList[idx] }
      dg = Object.assign({}, dg)
      dg = JSON.parse(JSON.stringify(dg))
      dg.casNoList.push(obj)

      console.log('@@@@ dg: ', dg)

      this.setContainerDgList(this.index, idx, dg, true)
    },
    removeCasNo (idx, i) {
      const dg = { ...this.dgInfo.containerDGList[idx] }
      dg.casNoList.splice(i, 1)

      this.setContainerDgList(this.index, idx, dg, true)
    },
    canAddDg () {
      if (this.cntrTypCd === 'TK' && this.cgoTypCd === '01') {
        return false
      } else {
        return true
      }
    },
    changeEmrPic (e, idx) {
      this.$ekmtcCommon.onlyEngValue(e)
      const dg = this.dgInfo.containerDGList[idx]

      dg.emrPicNm = dg.emrPicNm.toUpperCase()
    },
    uploadPopup () {
      this.popParams = {
        taskCatCd: 'B',
        type: 'D',
        bkgNo: this.parentInfo.bkgNo !== undefined ? this.parentInfo.bkgNo : '',
        scgAppNo:
          this.parentInfo.scgAppNo !== undefined
            ? this.parentInfo.scgAppNo
            : '',
        reqRno: this.dgInfo.reqRno !== undefined ? this.dgInfo.reqRno : '',
        dgUploadFlag: this.saveFlag,
        count: this.cntrSeq
      }

      this.selectFunc = this.uploadComplete
      this.openPopup('dg-oog-certi-pop')
    },
    uploadComplete (returnVo) {
      if (returnVo.deleteAllYn === 'Y') {
        this.dgInfo.reqRno = '' // 전체 삭제했을 경우 reqRno 초기화
      } else {
        this.dgInfo.reqRno = returnVo.reqRno
      }

      this.setContainer('reqRno', this.dgInfo.reqRno)

      this.$ekmtcCommon.layerClose(
        '#dg_area_' + this.cntrSeq + ' > .popup_dim'
      )
    },
    openPopup (compNm) {
      this.customComponent = null
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('#dg_area_' + this.cntrSeq + ' > .popup_dim')
    },
    closePoup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose(
        '#dg_area_' + this.cntrSeq + ' > .popup_dim'
      )
    },
    changeMpltCd (e, idx) {
      const value = e.target.value
      const cntrSeq = this.cntrSeq
      const input = document.querySelector('#tchlNm_' + cntrSeq + '_' + idx)
      const span = document.querySelector('#tchlNmSpan_' + cntrSeq + '_' + idx)
      if (value === 'Y') {
        input.style.display = ''
        span.style.display = 'none'
      } else {
        input.style.display = 'none'
        span.style.display = ''
      }
    },
    setContainer (key, payload) {
      console.log('@@@@ payload:', payload)
      //
      let _containerList = [...this.$store.state.schedule.containerList]

      this.$store.commit('schedule/defaultAssign', {
        key: 'containerList',
        value: [
          ..._containerList.slice(0, this.index),
          {
            ..._containerList[this.index],
            [key]: payload
          },
          ..._containerList.slice(this.index + 1)
        ]
      })
    },
    async setContainerDgList (parentIndex, index, dg, isForceUpdate = false) {
      const that = this

      await this.$store
        .dispatch('schedule/editContainerDgList', {
          parentIndex,
          index,
          dg
        })
        .then(async () => {
          console.log('@@@@ dispatch done')
          if (isForceUpdate) {
            await this.sleep(100)
            that.forceUpdate++

            this.toggleOuterPage = true
            // await this.afterCheckValidationDg()
          }
        })
    },
    handleDgChange (index, key, payload) {
      let dg = {
        ...this.dgInfo.containerDGList[index],
        [key]: payload
      }

      this.setContainerDgList(this.index, index, dg)
    },
    handeCheckboxChange (key, index, payload) {
      let dg = {
        ...this.dgInfo.containerDGList[index],
        [key]: key === 'lqYn' ? (payload ? 'Y' : 'N') : payload
      }

      this.setContainerDgList(this.index, index, dg)
    },
    handleSelectChange (key, index, payload) {
      let dg = {
        ...this.dgInfo.containerDGList[index],
        [key]: payload
      }

      this.setContainerDgList(this.index, index, dg)
    },
    handleEDatePickerChange (e) {
      console.log('@@@@ e:', e)
      this.setContainer('vaniDt', e)
    }
  }
}
</script>
<style lang="scss" scoped>
.tbl_col th {
  text-align: center;
}
.desc_list ul li {
  text-align: left;
}

.cas_area:not(:first-child) {
  margin-top: 5px;
}
.span_scg {
  color: #075bb9;
}
</style>
