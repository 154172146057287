<template>
  <!-- 운임 >> 나의 운임 -->
  <div>
    <form id="frm2">
      <div class="content_box beforetab" style="height: 100px;">
        <div class="fak_title">{{ title }}</div>
        <div class="fak_title_sub">
          <div>- {{ $t('msg.ONEX010T010.170') }} </div>
          <div>- {{ $t('msg.ONEX010T010.171') }} </div>
          <!-- <div>- {{ fakToday }}</div> -->
          <div>
            -
            <select
              class="col_4"
              id="fakToday"
              v-model="fakToday"
            >
              <option v-for="bascDt in bascDtList" :key="bascDt" :value="bascDt">{{ formatDate(bascDt,lang) }}</option>
            </select>
            {{ $t('msg.ONEX010T010.169') }}
          </div>
        </div>
      </div>
      <div class="content_box mt10">
        <div class="flex_box">
          <span class="ml_auto cal_btn"><a class="button sm" href="#" @click.prevent="exlDownload()">{{ $t('msg.MYIN020T010.016') }}</a></span> <!-- 엑셀 다운로드 -->
        </div>
        <div id="realgrid" class="mt30" :style="`width: 100%; height: ${gridHeight}px;`" />
      </div>
    </form>
  </div>
</template>
<style scoped>
.fak_title {
  width: 65%;
  float: left;
  text-align: center;
  padding-left: 410px;
  padding-top: 10px;
  font-size: 30px;
  font-weight: bold;
}
.fak_title_sub {
  width: 35%;
  float: right;
  padding-left: 80px;
  font-size: 15px;
}
</style>
<script>

import schedule from '@/api/rest/schedule/schedule'

import { rootComputed } from '@/store/helpers'

import { GridView, LocalDataProvider } from 'realgrid'
import JSZip from 'jszip'
import moment from 'moment'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'seq', dataType: 'text' }, //순번
  { fieldName: 'dlyPlcCd', dataType: 'text' }, //지역 코드
  { fieldName: 'fakToday', dataType: 'text' }, //날짜
  { fieldName: 'areaNm', dataType: 'text' }, // 지역 구분
  { fieldName: 'portNm', dataType: 'text' }, // 대표 포트
  { fieldName: 'usd20', dataType: 'text' }, // 20' 운임
  { fieldName: 'usd40', dataType: 'text' }, // 40' 운임
  { fieldName: 'usd20Dg', dataType: 'text' }, // 20' 운임
  { fieldName: 'usd40Dg', dataType: 'text' } // 40' 운임
]

export const columns = [
  {
    name: 'areaNm',
    fieldName: 'areaNm',
    type: 'text',
    width: '100',
    header: {
        text: app.$t('msg.ONEX010T010.165')
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `${cell.value}`
      }
    }
  },
  {
    name: 'portNm',
    fieldName: 'portNm',
    type: 'text',
    width: '200',
    header: {
        text: app.$t('msg.ONEX010T010.166')
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `${cell.value}`
      }
    },
    styleName: 'multi-line-css realgrid-pre-wrap'
  },
  {
    name: 'usd20',
    fieldName: 'usd20',
    type: 'text',
    width: '100',
    header: {
        text: '20\' O/F'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `${app.$ekmtcCommon.changeNumberFormat(cell.value || '0', { isComma: true })}`
      }
    }
  },
  {
    name: 'usd40',
    fieldName: 'usd40',
    type: 'text',
    width: '100',
    header: {
        text: '40\' O/F'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `${app.$ekmtcCommon.changeNumberFormat(cell.value || '0', { isComma: true })}`
      }
    }
  },
  {
    name: 'usd20Dg',
    fieldName: 'usd20Dg',
    type: 'text',
    width: '100',
    header: {
        text: '20\' DG Surcharge'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `${app.$ekmtcCommon.changeNumberFormat(cell.value || '0', { isComma: true })}`
      }
    }
  },
  {
    name: 'usd40Dg',
    fieldName: 'usd40Dg',
    type: 'text',
    width: '100',
    header: {
        text: '40\' DG Surcharge'
    },
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        return `${app.$ekmtcCommon.changeNumberFormat(cell.value || '0', { isComma: true })}`
      }
    }
  }
]

export default {
  name: 'ShippingCostFak',
  components: {
  },
  data () {
    return {
      gridHeight: 200,
      format: 'YYYY.MM.DD',
      title: '',
      fakToday: '',
      fakFreightList: [],
      serviceCtrCd: '',
      lang: '',
      areaNm: '',
      portNm: '',
      bascDtList: []
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    fakToday (newVal) {
      //FAK 운임 조회 파라미터
      const searchParam = {
        lang: this.serviceLang,
        bascDt: newVal
      }

      //FAK 운임 조회
      this.searchFakFreight(searchParam)
    }
  },
  created () {
    this.init()
  },
  destroyed () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  mounted () {
    //엑셀 다운로드용 JSZip 셋팅
    window.JSZip = window.JSZip || JSZip
    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)

    gridView.displayOptions.fitStyle = 'evenFill'

    // [s] 그리드 크기 자동조절을 위한 설정
    gridView.header.height = 50
    gridView.displayOptions.rowHeight = -1
    // [e] 그리드 크기 자동조절을 위한 설정

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })
    gridView.setFooter({
      visible: false
    })

    gridView.columnByName('areaNm').mergeRule = { criteria: 'value' }
  },
  methods: {
    init () {
      this.gridHeight = $('body').height() - 400
      if (this.gridHeight < 300) {
        this.gridHeight = 300
      }

      this.serviceCtrCd = this.$ekmtcCommon.isNotEmpty(localStorage.getItem('service_ctrcd')) ? localStorage.getItem('service_ctrcd') : localStorage.getItem('service_lang')
      this.lang = this.serviceLang
      // KOR -> KR
      if (this.serviceCtrCd === 'KOR') {
        this.serviceCtrCd = 'KR'
      }

      //날짜 목록 조회 파라미터
      const searchParam = {
        userCtrCd: this.memberDetail?.userCtrCd
      }

      this.searchBascDtList(searchParam)
    },
    //기준 날짜 조회
    async searchBascDtList (searchParam) {
      await schedule.fetchBascDtList(searchParam).then((response) => {
        //조회 성공 시
        if (response.status === 200) {
          const result = response.data.bascDtList
          //검색 결과 없을때
          if (!result || result.length === 0) {
            this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
          //검색 결과 존재 시
          } else {
            this.bascDtList = result
            this.fakToday = result.at(0)
          }
        }
      })
    },
    //FAK 운임 조회
    async searchFakFreight (searchParam) {
        this.fakFreightList = []

        await schedule.fakOceanFreight(searchParam).then((response) => {
          //조회 성공 시
          if (response.status === 200) {
            const respFakFreightList = response.data.fakFreightList
            //검색 결과 없을때
            if (!respFakFreightList || respFakFreightList.length === 0) {
              this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
            //검색 결과 존재 시
            } else {
              this.fakFreightList = respFakFreightList
              const today = respFakFreightList.at(0).fakToday
              this.title = this.formatDate(today, this.serviceLang, 'title')
            }

            //그리드 데이터 셋팅
            provider.setRows(this.fakFreightList)
          }
        })
    },
    exlDownload () {
      const subTitleMsg = `-${this.$t('msg.ONEX010T010.170')}\n-${this.$t('msg.ONEX010T010.171')}\n-${this.formatDate(this.fakToday, this.lang)} ${this.$t('msg.ONEX010T010.169')}`
      const curDt = moment().format('YYYYMMDD')
      gridView.exportGrid({
        type: 'excel',
        target: 'local',
        documentTitle: { //제목
          message: this.title,
          visible: true,
          height: 60,
          styleName: 'excel-export-title-style',
          spaceBottom: 1
        },
        documentSubtitle: {
          message: subTitleMsg,
          visible: true,
          height: 60,
          styleName: 'excel-sub-title'
        },
        applyDynamicStyles: true,
        exportTemplate: true,
        //allColumns: true,
        pagingAllItems: true,
        fileName: `${this.$t('menu.MENU05.000') + '_' + this.$t('menu.MENU05.030')}_${curDt}.xlsx`,
        showProgress: false,
        progressMessage: this.$t('msg.ADD20220219.012'), // 엑셀 Export중입니다.
        indicator: 'default',
        header: 'default',
        footer: 'default',
        done: function () {
          console.log('엑셀 다운로드 완료')
        }
      })
    },
    //날짜 다국어처리 리턴 함수
    formatDate (strDate, lang, type) {
      if (!strDate) {
        return
      } else {
        if (strDate.trim() === '') {
          return
        }
      }

      const splitDate = moment(strDate).format('YYYY-MM-DD').split('-')
      const year = splitDate.at(0)
      const month = splitDate.at(1)
      const day = splitDate.at(2)

      let rtnStrDate = ''

      const enMonthList = [
        { no: '01', en: 'Jan' },
        { no: '02', en: 'Feb' },
        { no: '03', en: 'Mar' },
        { no: '04', en: 'Apr' },
        { no: '05', en: 'May' },
        { no: '06', en: 'Jun' },
        { no: '07', en: 'Jul' },
        { no: '08', en: 'Aug' },
        { no: '09', en: 'Sep' },
        { no: '10', en: 'Oct' },
        { no: '11', en: 'Nov' },
        { no: '12', en: 'Dec' }
      ]
      const engMonth = enMonthList.find((item) => item.no === month)?.en

      if (type === 'title') {
        rtnStrDate = lang === 'KOR' ? `${year}${this.$t('msg.CSBK100.321')} ${month}${this.$t('msg.CSBK100.322')} ${this.$t('msg.ONEX010T010.167')}`
                                    : `${year}.${engMonth} ${this.$t('msg.ONEX010T010.167')}`
      } else {
        rtnStrDate = lang === 'KOR' ? `${year}${this.$t('msg.CSBK100.321')} ${month}${this.$t('msg.CSBK100.322')} ${day}${this.$t('msg.NEWB010P110.016')}`
                                    : `${year}.${engMonth}.${day}`
      }

      return rtnStrDate
    }
  }
}
</script>
<style>
.multi-line-css{
  white-space: pre;
}

.realgrid-pre-wrap{
  white-space: pre-wrap;
}

.excel-sub-title{
  white-space: pre;
  text-align: right;
}
</style>
