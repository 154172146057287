var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mb10" }, [
    _c("h2", { staticClass: "content_title" }, [
      _vm._v(_vm._s(_vm.$t("msg.ONEX070G040.070"))),
    ]),
    _c("table", { staticClass: "tbl_row" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", { staticClass: "border_left_none" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.method.payType,
                  expression: "method.payType",
                },
              ],
              attrs: {
                type: "radio",
                id: "paytype-V",
                value: "V",
                disabled:
                  _vm.btnType === "RETURN" &&
                  _vm.inputDefaultData.existTaxInfo === "Y",
              },
              domProps: { checked: _vm._q(_vm.method.payType, "V") },
              on: {
                change: function ($event) {
                  return _vm.$set(_vm.method, "payType", "V")
                },
              },
            }),
            _c("label", { staticClass: "mr20", attrs: { for: "paytype-V" } }, [
              _c("span"),
              _vm._v(_vm._s(_vm.$t("menu.MENU06.060"))),
            ]),
            _c(
              "span",
              { staticStyle: { color: "red", "font-weight": "bold" } },
              [_vm._v("※ 선수금 사용시 직접입금으로 선택 하시기 바랍니다.")]
            ),
          ]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { attrs: { id: "vtl_pop" } },
      [
        _c(
          "win-layer-pop",
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: {
                    "parent-info": _vm.parentInfo,
                    "freight-bill-info": _vm.parentInfo,
                  },
                  on: { close: _vm.closePopup, child: _vm.getChildData },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowDext5Pop,
            expression: "isShowDext5Pop",
          },
        ],
        attrs: { id: "fb_dext5_pop2" },
      },
      [
        _c("div", { ref: "popup", staticClass: "popup_dim" }, [
          _c(
            "div",
            {
              staticClass: "popup_wrap",
              staticStyle: { width: "800px", height: "520px" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "layer_close",
                  on: {
                    click: function ($event) {
                      return _vm.closeFileUploadr()
                    },
                  },
                },
                [_vm._v("close")]
              ),
              _c("div", { staticClass: "popup_cont" }, [
                _c("h1", { staticClass: "page_title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT010T010.181"))),
                ]),
                _c(
                  "div",
                  { staticClass: "content_box" },
                  [
                    _vm.isShowDext5
                      ? _c("DEXT5Upload", {
                          ref: "dextupload",
                          attrs: {
                            id: "dext5-uploader-fb2-" + _vm.dext5Idx,
                            single: false,
                            category: false,
                            width: "100%",
                            height: "200px",
                            "file-info": _vm.uploadFileInfo,
                            "category-info": [],
                          },
                          on: { completed: _vm.uploadFile },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "mt10 text_center" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    on: {
                      click: function ($event) {
                        return _vm.closeFileUploadr()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.MYIN050T010.057")))]
                ),
              ]),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }