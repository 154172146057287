<template>
  <div class="content full">
    <e-breadcrumbs />
    <h1 class="page_title">{{ $t('cp.CTRL020G060.001') }}</h1>
    <div class="content_box mt10">
      <table class="tbl_search">
        <colgroup>
          <col width="100px">
          <col>
          <col width="100px">
          <col>
          <col width="100px">
          <col>
          <col width="100px">
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('cp.CTRL020G060.002') }}</th>
            <td>
              <input type="text" v-model="param.scenarioNm" @keydown.enter="searchData">
            </td>
            <th>{{ $t('cp.CTRL020G060.003') }}</th>
            <td>
              <input type="text" v-model="param.createUser" @keydown.enter="searchData" readonly>
            </td>
            <th>{{ $t('cp.CTRL020G060.004') }}</th>
            <td>
              <select v-model="param.effectYn">
                <option value="">{{ $t('cp.CTRL020G060.005') }}</option>
                <option v-for="(item, idx) in comm.effectYnList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <td class="text_right">
            </td>
          </tr>
          <tr>
            <th>{{ $t('cp.CTRL020G060.006') }}</th>
            <td>
              <div class="form_wrap t2">
                <e-date-range-picker-simple
                  :sdate="param.effectFr"
                  :edate="param.effectTo"
                  @change="changeDateRange"
                />
              </div>
            </td>
            <th>{{ $t('cp.CTRL020G060.007') }}</th>
            <td>
              <select v-model="param.category">
                <option value="">{{ $t('cp.COMMON.002') }}</option>
                <option v-for="(item, idx) in comm.actCateList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
              </select>
            </td>
            <th></th>
            <td></td>
            <td class="text_right">
              <a class="button blue sh" href="#" @click.prevent="searchData">{{ $t('cp.COMMON.001') }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="content_box mt10 clearfix">
      <div class="col_6 float_left pr20">
        <div id="realgrid" style="width: 100%; height: 511px" />
      </div>
      <div class="col_4 float_right">
        <table class="tbl_row">
          <colgroup>
            <col width="150"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('cp.CTRL020G060.008') }}</th>
              <td>
                <input type="text" style="width: calc(100% - 98px)" v-model="send.scenarioCd" :placeholder="$t('cp.CTRL020G060.009')" readonly>
                <a class="button blue sh lg ml5" href="#" @click.prevent="new_init">{{ $t('cp.COMMON.003') }}</a>
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.002') }} <span>*</span></th>
              <td><input type="text" v-model="send.scenarioNm" ref="scenarioNmInput"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.003') }}</th>
              <td><input type="text" v-model="send.createUser" readonly></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.010') }}</th>
              <td><input type="text" v-model="send.createDept"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.011') }} <span>*</span></th>
              <td>
                <div class="input_calendar short">
                  <e-date-picker v-model="send.effectFr" />
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.012') }} <span>*</span></th>
              <td>
                <div class="input_calendar short">
                  <e-date-picker v-model="send.effectTo" />
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.004') }} <span>*</span></th>
              <td>
                <select v-model="send.effectYn">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in comm.effectYnList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.013') }}</th>
              <td><input type="text" v-model="send.chngReason"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.014') }}</th>
              <td><input type="text" v-model="send.chngUser"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.015') }}</th>
              <td><input type="text" v-model="send.effectDateL"></td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.007') }} <span>*</span></th>
              <td>
                <select v-model="send.category">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in comm.actCateList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.016') }}</th>
              <td>
                <textarea v-model="send.remark"></textarea>
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL020G060.026') }}</th>
              <td>
                <select v-model="send.mcsScenarioCd">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in comm.mcsScenarioCdList" :key="idx" :value="item.detailCd">{{ item.detailNm }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="flex_box mt10">
      <a style="text-decoration: underline;" :href="manualLink" target="_blank">※ CP User Manual.pptx</a>
      <a class="button blue lg mr5 ml_auto" :class="{'disabled': isInitial}" href="#" @click.prevent="save">{{ $t('cp.COMMON.004') }}</a>
      <a class="button blue lg mr5" :class="{'disabled': !send.scenarioCd}" href="#" @click.prevent="target">{{ $t('cp.CTRL020G060.017') }}</a>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import { GridView, LocalDataProvider } from 'realgrid'
import cpScenarioList from '@/api/rest/cp/cpScenarioList'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
import EBreadcrumbs from '@/components/common/EBreadcrumbs'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'scenarioCd', dataType: 'text' },
  { fieldName: 'scenarioNm', dataType: 'text' },
  { fieldName: 'createUser', dataType: 'text' },
  { fieldName: 'effectFr', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
  { fieldName: 'effectTo', dataType: 'datetime', datetimeFormat: 'yyyyMMdd' },
  { fieldName: 'remark', dataType: 'text' },
  { fieldName: 'effectYn', dataType: 'text' },
  { fieldName: 'chngReason', dataType: 'text' },
  { fieldName: 'chngUser', dataType: 'text' },
  { fieldName: 'effectDateL', dataType: 'text' },
  { fieldName: 'category', dataType: 'text' },
  { fieldName: 'createDate', dataType: 'text' },
  { fieldName: 'updateUser', dataType: 'text' },
  { fieldName: 'updateDate', dataType: 'text' },
  { fieldName: 'createDept', dataType: 'text' },
  { fieldName: 'effectYnCd', dataType: 'text' },
  { fieldName: 'categoryCd', dataType: 'text' },
  { fieldName: 'mcsScenarioCd', dataType: 'text' },
  { fieldName: 'mcsScenarioNm', dataType: 'text' }
]

export const columns = [
  { name: 'scenarioCd', fieldName: 'scenarioCd', type: 'text', header: { text: app.$t('cp.CTRL020G060.008') }, editable: false, width: '35' },
  { name: 'scenarioNm', fieldName: 'scenarioNm', type: 'text', header: { text: app.$t('cp.CTRL020G060.002') }, editable: false, width: '40', styleName: 'text_left' },
  { name: 'createUser', fieldName: 'createUser', type: 'text', header: { text: app.$t('cp.CTRL020G060.003') }, editable: false, width: '15' },
  { name: 'effectFr', fieldName: 'effectFr', type: 'text', header: { text: app.$t('cp.CTRL020G060.011') }, editable: false, datetimeFormat: 'yyyy.MM.dd', width: '20' },
  { name: 'effectTo', fieldName: 'effectTo', type: 'text', header: { text: app.$t('cp.CTRL020G060.012') }, editable: false, datetimeFormat: 'yyyy.MM.dd', width: '20' },
  { name: 'remark', fieldName: 'remark', type: 'text', header: { text: app.$t('cp.CTRL020G060.016') }, editable: false, width: '70', styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'mcsScenarioCd', fieldName: 'mcsScenarioCd', type: 'text', visible: false },
  { name: 'mcsScenarioNm', fieldName: 'mcsScenarioNm', type: 'text', header: { text: app.$t('cp.CTRL020G060.026') }, editable: false, width: '50', styleName: 'text_left', renderer: { showTooltip: true } }
]

export default {
  name: 'CpScenarioList',
  components: {
    EBreadcrumbs,
    EDatePicker: () => import('@/components/common/EDatePicker'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple')
  },
  data: function () {
    return {
      manualLink: this.$t('cp.CTRL020G060.027'),
      customComponent: null,
      gridList: [],
      param: {
        scenarioNm: '',
        createUser: '',
        effectFr: '',
        effectTo: '',
        category: '',
        effectYn: 'EX1'
      },
      send: {
        scenarioCd: '',
        scenarioNm: '',
        createUser: '',
        createDept: '',
        effectFr: '',
        effectTo: '',
        effectYn: '',
        chngReason: '',
        chngUser: '',
        effectDateL: '',
        category: '',
        remark: '',
        mcsScenarioCd: '',
        mcsScenarioNm: ''
      },
      comm: {
        effectYnList: [],
        actCateList: [],
        mcsScenarioCdList: []
      },
      isInitial: true
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.param.effectFr = this.$ekmtcCommon.getToday().replaceAll('-', '')
    this.param.effectTo = this.$ekmtcCommon.getToday().replaceAll('-', '')

    //검색 작성자 로그인 유저로 고정
    this.param.createUser = this.auth.userName
  },
  mounted () {
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
      visible: false,
      exclusive: false
    })
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true, // 포커스 행 유지
      style: 'inclusive', // 멀티 컬럼 정렬
      showSortOrder: true // 정렬 순서 표기
    })

    gridView.onShowTooltip = (_grid, idx, value) => {
      if (idx.column === 'remark') {
        let breakLine = ''
        let oneLine = ''
        let oneLineByteLength = 0

        for (const token of value) {
          oneLine += token

          // 한글과 한글 외 텍스트의 너비 계산
          const unicode = token.charCodeAt(0)
          if (unicode > 127 || (unicode > 64 && unicode < 91)) {
            oneLineByteLength += 2
          } else {
            oneLineByteLength += 1
          }

          // 조건에 따른 개행
          if (token === '\n' || (token === ' ' && oneLineByteLength > 70)) {
            breakLine += oneLine + (token === '\n' ? '' : ' \n')
            oneLine = ''
            oneLineByteLength = 0
          }
        }
        breakLine += oneLine

        return breakLine
      } else {
        return value
      }
    }

    const $vm = this

    $vm.effectYnList()
    $vm.actCateList()
    $vm.getMcsScenarioCdList()
    $vm.gridEvents()
  },
  methods: {
    effectYnList () {
      cpCommonInfo.cpCommonCodeList('CP06').then(response => {
        this.comm.effectYnList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    actCateList () {
      cpCommonInfo.cpCommonCodeList('CP00').then(response => {
        this.comm.actCateList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getMcsScenarioCdList () {
      cpCommonInfo.cpCommonCodeList('CPMC').then(response => {
        this.comm.mcsScenarioCdList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    searchData () {
      cpScenarioList.cpScenarioList(this.param).then(response => {
        if (response.data.length > 0) {
          this.isInitial = false
          this.gridList = response.data.map(row => {
            row.mcsScenarioCd = row.mcsScenarioCd !== undefined ? row.mcsScenarioCd : ''
            row.mcsScenarioNm = row.mcsScenarioNm !== undefined ? row.mcsScenarioNm : ''
            return row
          })
          provider.setRows(this.gridList)
          gridView.setCurrent({ dataRow: 0 })
          this.getGridInfo(0)
        } else {
          this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL020G060.018'), () => {
            return false
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async save () {
      // 시나리오명 중복 체크
      let orgScenarioNm = ''
      try {
        orgScenarioNm = provider.getValue(gridView.getCurrent().dataRow, 'scenarioNm')
      } catch {
        // pass
      }
      if (this.send.scenarioCd === '' || this.send.scenarioNm !== orgScenarioNm) {
        const responseValiRes = await cpScenarioList.scenarioNmVali(this.send.scenarioNm).catch(err => { console.log(err) })
        const responseVali = responseValiRes.data
        if (responseVali >= 1) {
          this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL020G060.019'), () => {
            this.send.scenarioNm = ''
            this.$refs.scenarioNmInput.focus()
          })
          return
        }
      }

      if (this.send.scenarioNm === '' || this.send.scenarioNm.trim().length === 0) {
        this.openAlert(this.$t('cp.CTRL020G060.020'))
        return
      }

      if (this.send.effectFr === '' || this.send.effectTo === '') {
        this.openAlert(this.$t('cp.CTRL020G060.021'))
        return
      }
      if (this.send.effectFr > this.send.effectTo) {
        this.openAlert(this.$t('cp.CTRL020G060.022'))
        return
      }

      if (this.send.effectYn === '') {
        this.openAlert(this.$t('cp.CTRL020G060.023'))
        return
      }

      if (this.send.category === '') {
        this.openAlert(this.$t('cp.CTRL020G060.024'))
        return
      }

      cpScenarioList.saveCpScenario(this.send).then(() => {
        this.searchData()
        this.openAlert(this.$t('cp.CTRL020G060.025'))
      }).catch(err => {
        console.log(err)
      })
    },
    new_init () {
      this.isInitial = false
      this.$refs.scenarioNmInput.focus()
      this.send.scenarioCd = ''
      this.send.scenarioNm = ''
      this.send.createUser = this.auth.userName
      this.send.createDept = ''
      this.send.effectFr = ''
      this.send.effectTo = ''
      this.send.effectYn = ''
      this.send.chngReason = ''
      this.send.chngUser = ''
      this.send.effectDateL = ''
      this.send.category = ''
      this.send.remark = ''
      this.send.mcsScenarioCd = ''
      this.send.mcsScenarioNm = ''
    },
    changeDateRange (s, d) {
      this.param.effectFr = s
      this.param.effectTo = d
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.006'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    getGridInfo (idx) {
      this.send.scenarioCd = this.gridList[idx].scenarioCd
      this.send.scenarioNm = this.gridList[idx].scenarioNm
      this.send.createUser = this.gridList[idx].createUser
      this.send.createDept = this.gridList[idx].createDept
      this.send.effectFr = this.gridList[idx].effectFr
      this.send.effectTo = this.gridList[idx].effectTo
      this.send.effectYn = this.gridList[idx].effectYnCd
      this.send.chngReason = this.gridList[idx].chngReason
      this.send.chngUser = this.gridList[idx].chngUser
      this.send.effectDateL = this.gridList[idx].effectDateL
      this.send.category = this.gridList[idx].categoryCd
      this.send.remark = this.gridList[idx].remark
      this.send.mcsScenarioCd = this.gridList[idx].mcsScenarioCd
      this.send.mcsScenarioNm = this.gridList[idx].mcsScenarioNm
    },
    gridEvents () {
      const TH = this
      gridView.onCellClicked = function (_grid, clickData) {
        if (clickData.cellType === 'data') {
          const selectedData = provider.getValues(clickData.dataRow)
          TH.getGridInfo(clickData.dataRow)
        }
      }
    },
    target () {
      this.$router.push({ path: './scenario-target-set/' + this.send.scenarioCd })
    }
  }
}
</script>
