import Send from '../../trans.client.js'

export default {
  //부킹취소사유 관련 리스트
  getBookingCancelList () {
    return Send({
      url: '/trans/bookings/booking-cancel-reason-popup',
      method: 'get'
    })
  },

  //부킹취소
  bookingCancel (formData) {
      return Send({
        method: 'put',
        url: '/trans/bookings/' + formData.bkgNo + '/cancel',
        data: {
          cboTerm: formData.cboTerm,
          lclCancelYn: formData.lclCancelYn,
          cancelChargeYn: formData.cancelChargeYn,
          overUi: formData.overUi,
          cnclRsnCd: formData.cnclRsnCd,
          cnclRsnDsc: formData.cnclRsnDsc,
          cnclRsnRmk: formData.cnclRsnRmk,
          cnclYn: formData.cnclYn
        }
      }).catch((err) => {
        console.log(err)
      })
  },
  //불만수집
  getvoc () {
    return Send({
      method: 'get',
      url: '/trans/survey/booking/voc-score'
    }).catch((err) => {
      console.log(err)
    })
  },

  postvoc (formData) {
    return Send({
      method: 'post',
      url: '/trans/survey/booking/voc',
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },

  getBookingCall (formData) {
    return Send({
      url: '/trans/bookings/template',
      method: 'get',
      params: formData
    })
  },
  validationBooking (formData) {
    return Send({
      method: 'post',
      url: '/trans/bookings/check/validation',
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  validationBookingDG (formData) {
    return Send({
      method: 'post',
      url: '/trans/bookings/check/validation-dg',
      data: formData
    })
  },
  validationBookingRF (hidCS008I) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/joint-vsl-check',
      params: {
        hidCS008I: hidCS008I
      }
    })
  },
  bkgCstMaxControlChk (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/check/bkg-cst-max-control-chk',
      params: {
        vslCd: params.vslCd,
        voyNo: params.voyNo,
        porCtrCd: params.porCtrCd,
        dlyCtrCd: params.dlyCtrCd,
        porPlcCd: params.porPlcCd,
        dlyPlcCd: params.dlyPlcCd,
        hidCS008I: params.hidCS008I,
        bkgNo: params.bkgNo,
        scheduleEditYn: params.scheduleEditYn,
        sumQty20: params.sumQty20,
        sumQty40: params.sumQty40,
        sumTeu: params.sumTeu,
        uiFlag: params.uiFlag,
        fnlActShprCstCd: params.fnlActShprCstCd,
        mcOpenYn: params.mcOpenYn
      }
    })
  },
  validationBookingOOG (formData) {
    return Send({
      method: 'post',
      url: '/trans/bookings/check/validation-oog',
      data: formData
    })
  },
  postBooking (formData) {
    return Send({
      method: 'post',
      url: '/trans/bookings',
      data: formData
    })
  },
  putBooking (formData) {
    return Send({
      method: 'put',
      url: '/trans/bookings/' + formData.bkgNo,
      data: formData
    })
  },
  getBooking (bkgNo) {
    return Send({
      method: 'get',
      url: '/trans/bookings/' + bkgNo
    })
  },
  getVgm (formData) {
    return Send({
      method: 'get',
      url: '/trans/vgms/' + formData.blNo,
      params: {
        methCd: formData.methCd,
        type: formData.type,
        uiFlag: formData.uiFlag
      }
    })
  },
  vgmUpdate (formData) {
    return Send({
      method: 'put',
      url: '/trans/vgms/' + formData.blNo,
      data: formData
    })
  },
  //진행중인 업무 > 진행중인 업무 > PickUp 정보 변경
  editPickupInfo (formData) {
    return Send({
      method: 'put',
      url: '/trans/bookings/edit-pickup/' + formData.bkgNo,
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
  //컨테이너 로그 가져오기
  getContainerLog (formData) {
    return Send({
      method: 'get',
      url: '/trans/bookings/' + formData.bkgNo + '/bkg-container-log',
      params: {
        cntrStsCd: formData.cntrStsCd
      }
    }).catch((err) => {
      console.log(err)
    })
  },
  //해당컨테이너 VGM 삭제
  vgmDelete (vgmSeq) {
    return Send({
      method: 'delete',
      url: '/trans/vgms/' + vgmSeq
    }).catch((err) => {
      console.log(err)
    })
  },
  //엑셀 템플릿 다운로드
  excelDownload (fileName) {
    return Send({
      method: 'get',
      url: '/trans/vgms/static-download/' + fileName,
      responseType: 'blob'
    }).catch((err) => {
      console.log(err)
    })
  },
  //엑셀 템플릿 업로드
  excelUpload (formData) {
    return Send({
      method: 'post',
      url: '/trans/vgms/excel-upload/' + formData.fileId,
      data: formData
    }).catch((err) => {
      console.log(err)
    })
  },
   //VGM Certi 업로드
   vgmCertiUpload (formData) {
    return Send({
      url: '/trans/vgms/certi-upload/' + formData.blNo,
      method: 'post',
      data: formData
    })
  },
  //VGM Certi 삭제
  vgmCertiDelete (blNo) {
    return Send({
      url: '/trans/vgms/certi-upload/' + blNo,
      method: 'delete'
    })
  },
  //VGM Container No. 체크
  cntrNocheck (cntrNo) {
    return Send({
      url: '/trans/vgms/cntr-check/' + cntrNo,
      spinner: false,
      method: 'get'
    })
  },
  setMCBookingLog (formData) {
    return Send({
      method: 'get',
      url: '/trans/bookings/mc-booking-log',
      params: formData
    })
  },
  getSgBookingDgCheck (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/sg-bookingDg-check',
      params: params
    })
  },
  bkgIotYn (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/bkg-iot-yn',
      params: {
        polCtrCd: params.polCtrCd,
        podCtrCd: params.podCtrCd,
        polPortCd: params.polPortCd,
        podPortCd: params.podPortCd,
        cntrTypCd: params.cntrTypCd,
        cntrSzCd: params.cntrSzCd,
        cgoTypCd: params.cgoTypCd,
        frtPncCd: params.frtPncCd,
        frtAppNo: params.frtAppNo
      }
    })
  },
  getIotUseYn (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/iot-use-yn',
      params: {
        polCtrCd: params.polCtrCd,
        podCtrCd: params.podCtrCd,
        polPortCd: params.polPortCd,
        podPortCd: params.podPortCd,
        cntrTypCd: params.cntrTypCd,
        cntrSzCd: params.cntrSzCd,
        cgoTypCd: params.cgoTypCd,
        frtPncCd: params.frtPncCd,
        frtAppNo: params.frtAppNo
      }
    })
  },
  checkMyHsCd (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/my-hs-code',
      params: {
        hsCd: params.hsCd
      }
    })
  },
  getflexibagLoi (bkgNo, cntrSeq) {
    return Send({
      method: 'get',
      responseType: 'blob',
      url: '/trans/bookings/' + bkgNo + '/flexibag/',
      params: {
        cntrSeq
      }
    })
  },
  checkLoiReuseYn (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/loi-reuse-yn',
      params: {
        bkgShprCstNo: params.bkgShprCstNo
      }
    })
  },
  checkLoiDownEnable (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/loi-down-enable',
      params: {
        polPortCd: params.polPortCd,
        podPortCd: params.podPortCd,
        vslCd: params.vslCd,
        voyNo: params.voyNo
      }
    })
  },
  //RF 픽업일 체크
  rfPickupDateYn (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/rf-pickup-date-yn/',
      params: {
        docCls: params.docCls,
        pcupReqDt: params.pcupReqDt
      }
    })
  },
  getBookingCancelBtnYn (params) {
    return Send({
      method: 'get',
      url: '/trans/bookings/cancel-btn-yn',
      params: {
        bkgNo: params.bkgNo
      }
    })
  }
}
