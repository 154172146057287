var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("e-breadcrumbs"),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("menu.MENU05.000"))),
      ]),
      _c(
        "e-tab",
        { ref: "tab" },
        [
          _vm.memberDetail.userCtrCd === "KR"
            ? _c(
                "e-tab-item",
                {
                  attrs: {
                    title: _vm.$t("menu.MENU05.030"),
                    name: "fak",
                    path: "/shipping-cost",
                  },
                },
                [
                  (_vm.memberDetail.userCtrCd === "KR" &&
                    _vm.routeName === "") ||
                  _vm.routeName === "fak"
                    ? _c("shipping-cost-fak")
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: _vm.$t("menu.MENU05.010"),
                name: "inquiry",
                path: "/shipping-cost",
              },
            },
            [
              (_vm.memberDetail.userCtrCd !== "KR" && _vm.routeName === "") ||
              _vm.routeName === "inquiry"
                ? _c("shipping-cost-inquiry")
                : _vm._e(),
            ],
            1
          ),
          _c(
            "e-tab-item",
            {
              attrs: {
                title: _vm.$t("menu.MENU05.020"),
                name: "my",
                path: "/shipping-cost",
              },
            },
            [_vm.routeName === "my" ? _c("shipping-cost-my") : _vm._e()],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }