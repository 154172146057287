var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bl_area capture_area" },
    [
      _c(
        "form",
        {
          attrs: { id: "frm" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "content_box beforetab" }, [
            _c("table", { staticClass: "tbl_search" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v("B/L No.")]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.blNo,
                          expression: "regParams.blNo",
                        },
                      ],
                      staticClass: "wid200",
                      attrs: { type: "text", readonly: "" },
                      domProps: { value: _vm.regParams.blNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.regParams, "blNo", $event.target.value)
                        },
                      },
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "button sm ml2",
                        on: {
                          click: function ($event) {
                            return _vm.fnCommonBtnAction("LOG")
                          },
                        },
                      },
                      [_vm._v("Log " + _vm._s(_vm.$t("btn.CMBTK011")))]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "button sm ml2",
                        on: {
                          click: function ($event) {
                            return _vm.fnCAInquiryPop()
                          },
                        },
                      },
                      [_vm._v("C/A " + _vm._s(_vm.$t("btn.CMBTK011")))]
                    ),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isBlAdd,
                            expression: "isBlAdd",
                          },
                        ],
                        staticClass: "button sm ml2",
                        on: {
                          click: function ($event) {
                            return _vm.fnCommonBtnAction("PBL")
                          },
                        },
                      },
                      [_vm._v("Part S/R+")]
                    ),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isBlAdd,
                            expression: "isBlAdd",
                          },
                        ],
                        staticClass: "button sm ml2",
                        on: {
                          click: function ($event) {
                            return _vm.fnCommonBtnAction("NBL")
                          },
                        },
                      },
                      [_vm._v("Normal S/R+")]
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.005")))]),
                  _c("td", [
                    _vm.regParams.addBkgNoList.length === 0
                      ? _c("span", { staticClass: "span_bkg" }, [
                          _vm._v(_vm._s(_vm.detailBkgNo)),
                        ])
                      : _vm._e(),
                    _vm.regParams.addBkgNoList.length > 0
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "position_relative span_tooltip span_bkg",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "txt_underline",
                                on: {
                                  click: function ($event) {
                                    return _vm.$ekmtcCommon.showTooltip($event)
                                  },
                                },
                              },
                              [
                                _vm._v(_vm._s(_vm.detailBkgNo) + " "),
                                _vm.regParams.addBkgNoList.length > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "외 " +
                                          _vm._s(
                                            _vm.regParams.addBkgNoList.length
                                          ) +
                                          "건"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tooltip_wrap short position_absolute",
                              },
                              [
                                _c("div", { staticClass: "cont" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "close",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$ekmtcCommon.hideTooltip(
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("close")]
                                  ),
                                  _c("ul", { staticClass: "bul_list_sm t2" }, [
                                    _c(
                                      "li",
                                      _vm._l(
                                        _vm.regParams.addBkgNoList,
                                        function (vo, idx) {
                                          return _c(
                                            "p",
                                            { key: "bkgNoList_" + idx },
                                            [_vm._v(_vm._s(vo))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "content_box mt10" }, [
            _c("div", { staticClass: "booking_call_wrap" }, [
              _c("div", { staticClass: "call_search" }, [
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.vueMode,
                      expression: "vueMode",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateCd,
                      expression: "templateCd",
                    },
                  ],
                  attrs: { type: "radio", id: "templateCd_1", value: "A" },
                  domProps: { checked: _vm._q(_vm.templateCd, "A") },
                  on: {
                    change: function ($event) {
                      _vm.templateCd = "A"
                    },
                  },
                }),
                _c("label", { attrs: { for: "templateCd_1" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.505"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateNo,
                      expression: "templateNo",
                    },
                  ],
                  attrs: { type: "text", placeholder: "B/L No." },
                  domProps: { value: _vm.templateNo },
                  on: {
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.templateEnter.apply(null, arguments)
                    },
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.templateNo = $event.target.value
                      },
                      function ($event) {
                        _vm.templateNo = _vm.templateNo.toUpperCase()
                      },
                    ],
                    click: function ($event) {
                      _vm.templateCd = "A"
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "search_btn01",
                    attrs: { type: "button" },
                    on: {
                      click: [
                        function ($event) {
                          $event.preventDefault()
                          return _vm.templateSearch()
                        },
                        function ($event) {
                          _vm.templateCd = "A"
                        },
                      ],
                    },
                  },
                  [
                    _c("span", { staticClass: "offscreen" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBK100.121"))),
                    ]),
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.vueMode,
                      expression: "vueMode",
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.templateCd,
                      expression: "templateCd",
                    },
                  ],
                  attrs: { type: "radio", id: "templateCd_2", value: "B" },
                  domProps: { checked: _vm._q(_vm.templateCd, "B") },
                  on: {
                    change: function ($event) {
                      _vm.templateCd = "B"
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.vueMode,
                        expression: "vueMode",
                      },
                    ],
                    staticStyle: { "margin-left": "20px" },
                    attrs: { for: "templateCd_2" },
                  },
                  [_vm._v("Template")]
                ),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.vueMode,
                        expression: "vueMode",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.templateNo_2,
                        expression: "templateNo_2",
                      },
                    ],
                    staticStyle: { width: "200px", "margin-left": "20px" },
                    attrs: { id: "templateList" },
                    on: {
                      click: function ($event) {
                        _vm.templateCd = "B"
                      },
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.templateNo_2 = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "" } }, [
                      _vm._v(" " + _vm._s(this.$t("msg.CSBL200.078")) + " "),
                    ]),
                    _vm._l(_vm.templateList, function (vo) {
                      return _c(
                        "option",
                        { key: vo.tplRno, domProps: { value: vo.tplRno } },
                        [_vm._v(" " + _vm._s(vo.tplNm) + " ")]
                      )
                    }),
                  ],
                  2
                ),
                _c(
                  "a",
                  {
                    staticClass: "button blue sh ml15",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setTemplateInfo()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("btn.CMBTK010")))]
                ),
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.vueMode,
                        expression: "vueMode",
                      },
                    ],
                    staticClass: "button blue sh",
                    staticStyle: { "margin-left": "5px" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteTemplate()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("art.CMBA660.007")))]
                ),
              ]),
            ]),
          ]),
          _vm.memberDetail.userCtrCd === "CN"
            ? _c("div", { staticClass: "content_box mt10" }, [
                _vm._m(1),
                _c("table", { staticClass: "tbl_row mt5" }, [
                  _vm._m(2),
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(this.$t("msg.CSBK100.126")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.148")))]),
                      _c("th", [_vm._v("email")]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "position_relative" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.docPicNo,
                                expression: "docPicNo",
                              },
                            ],
                            staticClass: "col_3",
                            attrs: { id: "docPicNo" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.docPicNo = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.changeDocPicNo,
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(
                                " " + _vm._s(this.$t("msg.CSBK100.050")) + " "
                              ),
                            ]),
                            _vm._l(_vm.cstInfoList, function (vo) {
                              return _c(
                                "option",
                                {
                                  key: vo.picNo,
                                  domProps: { value: vo.picNo },
                                },
                                [_vm._v(" " + _vm._s(vo.picNm) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.docPicNm,
                              expression: "regParams.docPicNm",
                            },
                          ],
                          staticClass: "inp_space70",
                          attrs: {
                            id: "docPicNm",
                            type: "text",
                            maxlength: "30",
                          },
                          domProps: { value: _vm.regParams.docPicNm },
                          on: {
                            keyup: function ($event) {
                              return _vm.checkValidationCharger(
                                "docPicNm",
                                "KEYUP"
                              )
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "docPicNm",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c(
                        "td",
                        { staticClass: "position_relative" },
                        [
                          _c("e-input-number", {
                            staticClass: "col_3",
                            attrs: {
                              id: "docPicTelPlcNo",
                              "is-phone": true,
                              maxlength: "4",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkValidationCharger(
                                  "docPicTelPlcNo",
                                  "KEYUP"
                                )
                              },
                            },
                            model: {
                              value: _vm.regParams.docPicTelPlcNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.regParams, "docPicTelPlcNo", $$v)
                              },
                              expression: "regParams.docPicTelPlcNo",
                            },
                          }),
                          _c("e-input-number", {
                            staticClass: "inp_space70",
                            attrs: {
                              id: "docPicTelNo",
                              "is-phone": true,
                              maxlength: "12",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.checkValidationCharger(
                                  "docPicTelNo",
                                  "KEYUP"
                                )
                              },
                            },
                            model: {
                              value: _vm.regParams.docPicTelNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.regParams, "docPicTelNo", $$v)
                              },
                              expression: "regParams.docPicTelNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.docPicEmlAddr,
                              expression: "regParams.docPicEmlAddr",
                            },
                          ],
                          staticClass: "inp_98",
                          attrs: {
                            id: "docPicEmlAddr",
                            type: "text",
                            placeholder: _vm.$t("msg.CSBK100.152"),
                            maxlength: "100",
                          },
                          domProps: { value: _vm.regParams.docPicEmlAddr },
                          on: {
                            blur: function ($event) {
                              return _vm.checkValidationCharger(
                                "docPicEmlAddr",
                                "KEYUP"
                              )
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "docPicEmlAddr",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("p", { staticClass: "txt_desc" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.150"))),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "content_box mt10" },
            [
              _c("div", { staticClass: "flex_box mt20" }, [
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBL200.182"))),
                ]),
              ]),
              _c("booking-schedule-info", {
                ref: "schdInfo",
                attrs: {
                  "bkg-no": _vm.regParams.bkgNo,
                  "bl-no": _vm.regParams.blNo,
                  "save-flag": "PICKUP",
                },
              }),
              _c("table", { staticClass: "tbl_row mt20 tbl_btm_none" }, [
                _vm._m(3),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.183")))]),
                    _c("td", { staticClass: "position_relative" }, [
                      _c("div", { staticClass: "tbl_form div_plc" }, [
                        _c("span", { staticClass: "dv col_3" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.porPlcNmType,
                                  expression: "porPlcNmType",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.porPlcNmType = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.changeInputType(
                                      $event,
                                      "porPlcNm"
                                    )
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "select" } }, [
                                _vm._v("Select"),
                              ]),
                              _c("option", { attrs: { value: "input" } }, [
                                _vm._v("Input"),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "dv col_7 span_plc" },
                          [
                            _vm.porPlcNmType === "select"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.porPlcNm,
                                        expression: "regParams.porPlcNm",
                                      },
                                    ],
                                    attrs: { id: "porPlcNm" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.regParams,
                                            "porPlcNm",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.checkValidationInfo(
                                            "porPlcNm"
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.plcEnmCodes[_vm.regParams.porPlcCd],
                                    function (vo, idx) {
                                      return _c(
                                        "option",
                                        {
                                          key: "porPlcNm_" + idx,
                                          domProps: { value: vo.plcEnm },
                                        },
                                        [_vm._v(_vm._s(vo.plcEnm))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.porPlcNmType === "input"
                              ? _c("e-trans-string-check-cnee-input", {
                                  attrs: {
                                    id: "porPlcNm",
                                    "is-upper-case": true,
                                    "is-required": true,
                                    "message-required":
                                      _vm.$t("msg.CSBK100.147"),
                                    maxlength: "50",
                                    "is-jp-pop-msg": true,
                                  },
                                  on: { blur: _vm.checkJPplcPopMsg },
                                  model: {
                                    value: _vm.regParams.porPlcNm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.regParams, "porPlcNm", $$v)
                                    },
                                    expression: "regParams.porPlcNm",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.184")))]),
                    _vm._v(" "),
                    _c("td", { staticClass: "position_relative" }, [
                      _c("div", { staticClass: "tbl_form div_plc" }, [
                        _c("span", { staticClass: "dv col_3" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.polPortNmType,
                                  expression: "polPortNmType",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.polPortNmType = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.changeInputType(
                                      $event,
                                      "polPortNm"
                                    )
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "select" } }, [
                                _vm._v("Select"),
                              ]),
                              _c("option", { attrs: { value: "input" } }, [
                                _vm._v("Input"),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "dv col_7 span_plc" },
                          [
                            _vm.polPortNmType === "select"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.polPortNm,
                                        expression: "regParams.polPortNm",
                                      },
                                    ],
                                    attrs: { id: "polPortNm" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.regParams,
                                            "polPortNm",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.checkValidationInfo(
                                            "polPortNm"
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.plcEnmCodes[_vm.regParams.polPortCd],
                                    function (vo, idx) {
                                      return _c(
                                        "option",
                                        {
                                          key: "polPortNm_" + idx,
                                          domProps: { value: vo.plcEnm },
                                        },
                                        [_vm._v(_vm._s(vo.plcEnm))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.polPortNmType === "input"
                              ? _c("e-trans-string-check-cnee-input", {
                                  attrs: {
                                    id: "polPortNm",
                                    "is-upper-case": true,
                                    "is-required": true,
                                    "message-required":
                                      _vm.$t("msg.CSBK100.147"),
                                    maxlength: "50",
                                    "is-jp-pop-msg": true,
                                  },
                                  on: { blur: _vm.checkJPplcPopMsg },
                                  model: {
                                    value: _vm.regParams.polPortNm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.regParams, "polPortNm", $$v)
                                    },
                                    expression: "regParams.polPortNm",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.185")))]),
                    _vm._v(" "),
                    _c("td", { staticClass: "position_relative" }, [
                      _c("div", { staticClass: "tbl_form div_plc" }, [
                        _c("span", { staticClass: "dv col_3" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.podPortNmType,
                                  expression: "podPortNmType",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.podPortNmType = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.changeInputType(
                                      $event,
                                      "podPortNm"
                                    )
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "select" } }, [
                                _vm._v("Select"),
                              ]),
                              _c("option", { attrs: { value: "input" } }, [
                                _vm._v("Input"),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "dv col_7 span_plc" },
                          [
                            _vm.podPortNmType === "select"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.podPortNm,
                                        expression: "regParams.podPortNm",
                                      },
                                    ],
                                    attrs: { id: "podPortNm" },
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.regParams,
                                            "podPortNm",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.checkValidationInfo(
                                            "podPortNm"
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.plcEnmCodes[_vm.regParams.podPortCd],
                                    function (vo, idx) {
                                      return _c(
                                        "option",
                                        {
                                          key: "podPortNm_" + idx,
                                          domProps: { value: vo.plcEnm },
                                        },
                                        [_vm._v(_vm._s(vo.plcEnm))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.podPortNmType === "input"
                              ? _c("e-trans-string-check-cnee-input", {
                                  attrs: {
                                    id: "podPortNm",
                                    "is-upper-case": true,
                                    "is-required": true,
                                    "message-required":
                                      _vm.$t("msg.CSBK100.147"),
                                    maxlength: "50",
                                    "is-jp-pop-msg": true,
                                  },
                                  on: { blur: _vm.checkJPplcPopMsg },
                                  model: {
                                    value: _vm.regParams.podPortNm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.regParams, "podPortNm", $$v)
                                    },
                                    expression: "regParams.podPortNm",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.186")))]),
                    _vm._v(" "),
                    _c("td", { staticClass: "position_relative" }, [
                      _c("div", { staticClass: "tbl_form div_plc" }, [
                        _c("span", { staticClass: "dv col_3" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.dlyPlcNmType,
                                  expression: "dlyPlcNmType",
                                },
                              ],
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.dlyPlcNmType = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.changeInputType(
                                      $event,
                                      "dlyPlcNm"
                                    )
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "select" } }, [
                                _vm._v("Select"),
                              ]),
                              _c("option", { attrs: { value: "input" } }, [
                                _vm._v("Input"),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "span",
                          { staticClass: "dv col_7 span_plc" },
                          [
                            _vm.dlyPlcNmType === "select"
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.dlyPlcNm,
                                        expression: "regParams.dlyPlcNm",
                                      },
                                    ],
                                    attrs: { id: "dlyPlcNm" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.regParams,
                                          "dlyPlcNm",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.plcEnmCodes[_vm.regParams.dlyPlcCd],
                                    function (vo, idx) {
                                      return _c(
                                        "option",
                                        {
                                          key: "dlyPlcNm_" + idx,
                                          domProps: { value: vo.plcEnm },
                                        },
                                        [_vm._v(_vm._s(vo.plcEnm))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.dlyPlcNmType === "input"
                              ? _c("e-trans-string-check-cnee-input", {
                                  attrs: {
                                    id: "dlyPlcNm",
                                    maxlength: "50",
                                    "is-upper-case": true,
                                    "is-required": true,
                                    "message-required":
                                      _vm.$t("msg.CSBK100.147"),
                                    "is-jp-pop-msg": true,
                                  },
                                  on: { blur: _vm.checkJPplcPopMsg },
                                  model: {
                                    value: _vm.regParams.dlyPlcNm,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.regParams, "dlyPlcNm", $$v)
                                    },
                                    expression: "regParams.dlyPlcNm",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.006")))]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.obrdDtTemp,
                            expression: "regParams.obrdDtTemp",
                          },
                        ],
                        staticClass: "col_5",
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: _vm.regParams.obrdDtTemp },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.regParams,
                              "obrdDtTemp",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "button sm ml2",
                          on: {
                            click: function ($event) {
                              return _vm.onBoardDatePop()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("btn.CMBTK004")))]
                      ),
                    ]),
                    _c("th", [_vm._v("Final destination")]),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: {
                            id: "lstDestPlcNm",
                            "is-upper-case": true,
                            maxlength: "50",
                          },
                          model: {
                            value: _vm.regParams.lstDestPlcNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "lstDestPlcNm", $$v)
                            },
                            expression: "regParams.lstDestPlcNm",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.228")))]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.polShtmCd,
                              expression: "regParams.polShtmCd",
                            },
                          ],
                          staticClass: "col_3",
                          attrs: { id: "polShtmCd" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "polShtmCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.fnChangeTerm()
                              },
                            ],
                          },
                        },
                        _vm._l(_vm.commonCodes["01033"], function (vo, idx) {
                          return _c(
                            "option",
                            {
                              key: "polShtmCd_" + idx,
                              domProps: { value: vo.cd },
                            },
                            [_vm._v(_vm._s(vo.cdNm))]
                          )
                        }),
                        0
                      ),
                      _c("span", { staticClass: "slash" }, [_vm._v("/")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.podShtmCd,
                              expression: "regParams.podShtmCd",
                            },
                          ],
                          staticClass: "col_3",
                          attrs: { id: "podShtmCd" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "podShtmCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.commonCodes["01033"], function (vo, idx) {
                          return _c(
                            "option",
                            {
                              key: "podShtmCd_" + idx,
                              domProps: { value: vo.cd },
                            },
                            [_vm._v(_vm._s(vo.cdNm))]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.227")))]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.frtPncCd,
                              expression: "regParams.frtPncCd",
                            },
                          ],
                          staticClass: "col_5",
                          attrs: { disabled: true },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.regParams,
                                "frtPncCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.commonCodes["01032"], function (vo, idx) {
                          return _c(
                            "option",
                            {
                              key: "frtPncCd_" + idx,
                              domProps: { value: vo.cd },
                            },
                            [_vm._v(_vm._s(vo.cdNm))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm.lang === "JPN"
                    ? _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.229")))]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c("span", { staticClass: "dv col_5" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.issCtrCd,
                                      expression: "regParams.issCtrCd",
                                    },
                                  ],
                                  attrs: { id: "issCtrCd" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.regParams,
                                          "issCtrCd",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.changeCtrCd(
                                          $event,
                                          "issPlcList"
                                        )
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                                  ]),
                                  _vm._l(_vm.ctrCdList, function (vo, idx) {
                                    return _c(
                                      "option",
                                      {
                                        key: "issCtrCd_" + idx,
                                        domProps: { value: vo.ctrCd },
                                      },
                                      [_vm._v(_vm._s(vo.ctrEnm))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _c("span", { staticClass: "dv col_5" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.issPlcCd,
                                      expression: "regParams.issPlcCd",
                                    },
                                  ],
                                  attrs: { id: "issPlcCd" },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.regParams,
                                        "issPlcCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                                  ]),
                                  _vm._l(_vm.issPlcList, function (vo, idx) {
                                    return _c(
                                      "option",
                                      {
                                        key: "issPlcCd_" + idx,
                                        domProps: { value: vo.plcCd },
                                      },
                                      [_vm._v(_vm._s(vo.plcEnm))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.230")))]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c("span", { staticClass: "dv col_5" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.frtPayCtrCd,
                                      expression: "regParams.frtPayCtrCd",
                                    },
                                  ],
                                  attrs: { id: "frtPayCtrCd" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.regParams,
                                          "frtPayCtrCd",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                      function ($event) {
                                        return _vm.changeCtrCd(
                                          $event,
                                          "frtPayPlcList"
                                        )
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                                  ]),
                                  _vm._l(_vm.ctrCdList, function (vo, idx) {
                                    return _c(
                                      "option",
                                      {
                                        key: "frtPayCtrCd_" + idx,
                                        domProps: { value: vo.ctrCd },
                                      },
                                      [_vm._v(_vm._s(vo.ctrEnm))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _c("span", { staticClass: "dv col_5" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.frtPayPlcCd,
                                      expression: "regParams.frtPayPlcCd",
                                    },
                                  ],
                                  attrs: { id: "frtPayPlcCd" },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.regParams,
                                        "frtPayPlcCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                                  ]),
                                  _vm._l(_vm.frtPayPlcList, function (vo, idx) {
                                    return _c(
                                      "option",
                                      {
                                        key: "frtPayPlcCd_" + idx,
                                        domProps: { value: vo.plcCd },
                                      },
                                      [_vm._v(_vm._s(vo.plcEnm))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.blTypeVisibleContry.includes(
                            _vm.auth.userCtrCd
                          ),
                          expression:
                            "!blTypeVisibleContry.includes(auth.userCtrCd)",
                        },
                      ],
                      staticClass: "td_noline",
                      attrs: { colspan: "2" },
                    }),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.blTypeVisibleContry.includes(
                              _vm.auth.userCtrCd
                            ),
                            expression:
                              "blTypeVisibleContry.includes(auth.userCtrCd)",
                          },
                        ],
                        staticClass: "line_bottom",
                      },
                      [_vm._v("B/L type")]
                    ),
                    _c(
                      "td",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.blTypeVisibleContry.includes(
                              _vm.auth.userCtrCd
                            ),
                            expression:
                              "blTypeVisibleContry.includes(auth.userCtrCd)",
                          },
                        ],
                        staticClass: "line_bottom",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tbl_form" },
                          _vm._l(
                            _vm.commonCodes["CS105"],
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticStyle: { float: "left" } },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.regParams.blTypCd,
                                        expression: "regParams.blTypCd",
                                      },
                                    ],
                                    attrs: {
                                      type: "radio",
                                      id: "blTypCd" + index,
                                      name: "blTypCd",
                                      disabled: item.cd === "03",
                                    },
                                    domProps: {
                                      value: item.cd,
                                      checked: _vm._q(
                                        _vm.regParams.blTypCd,
                                        item.cd
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.$set(
                                          _vm.regParams,
                                          "blTypCd",
                                          item.cd
                                        )
                                      },
                                    },
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr20",
                                      attrs: { for: "blTypCd" + index },
                                    },
                                    [_vm._v(_vm._s(item.cdNm))]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                    _c("th", { staticClass: "line_bottom" }, [
                      _vm._v("S/C No."),
                    ]),
                    _c("td", { staticClass: "line_bottom" }, [
                      _vm._v(_vm._s(_vm.regParams.frtAppNo)),
                    ]),
                  ]),
                ]),
              ]),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBL200.231"))),
              ]),
              _c("table", { staticClass: "tbl_col" }, [
                _vm._m(4),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "text_left" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.NEWB020G010.043")) + " "),
                      _c(
                        "select",
                        {
                          staticClass: "wid150 ml20 bg_white",
                          on: {
                            change: function ($event) {
                              return _vm.changeLatestInfo($event, "shpr")
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200_M1.019"))),
                          ]),
                          _vm._l(_vm.cstEnmCodes["SHPR"], function (vo, idx) {
                            return _c(
                              "option",
                              {
                                key: "shpr_" + idx,
                                domProps: { value: vo.cstNm },
                              },
                              [_vm._v(_vm._s(vo.cstNm))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.188")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.189")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.190")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.191")))]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("e-trans-address-input", {
                          ref: "shprCstEnm",
                          attrs: {
                            id: "shprCstEnm",
                            "is-required": true,
                            "is-char": true,
                            "max-length": 50,
                            "message-required": _vm.$t("msg.CSBK100.147"),
                          },
                          model: {
                            value: _vm.regParams.shprCstEnm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "shprCstEnm", $$v)
                            },
                            expression: "regParams.shprCstEnm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.actShprCstCtrCd,
                              expression: "regParams.actShprCstCtrCd",
                            },
                          ],
                          attrs: { id: "actShprCstCtrCd" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "actShprCstCtrCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                _vm.fnChangeActCatId(
                                  _vm.regParams.actShprCstCtrCd
                                )
                                _vm.fnAddInfoStateSet("ACT")
                              },
                            ],
                            input: function ($event) {
                              return _vm.checkErrorVisible("actShprCstCtrCd")
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                          ]),
                          _vm._l(_vm.ctrCdIccList, function (vo, idx) {
                            return _c(
                              "option",
                              {
                                key: "actCst_" + idx,
                                domProps: { value: vo.ctrCd },
                              },
                              [_vm._v(_vm._s(vo.ctrEnm))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "actShprCstCityNm", maxlength: "100" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("actShprCstCityNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.actShprCstCityNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "actShprCstCityNm", $$v)
                            },
                            expression: "regParams.actShprCstCityNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.regParams.actShprCstCtrCd !== "IN",
                              expression: "regParams.actShprCstCtrCd !== 'IN'",
                            },
                          ],
                          attrs: { id: "actShprStateNm", maxlength: "35" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("actShprStateNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.actShprStateNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "actShprStateNm", $$v)
                            },
                            expression: "regParams.actShprStateNm",
                          },
                        }),
                        _vm.regParams.actShprCstCtrCd === "IN"
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.actShprStateCd,
                                    expression: "regParams.actShprStateCd",
                                  },
                                ],
                                attrs: { id: "actShprStateCd" },
                                on: {
                                  input: function ($event) {
                                    return _vm.checkErrorVisible(
                                      "actShprStateCd"
                                    )
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.regParams,
                                      "actShprStateCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                                ]),
                                _vm._l(_vm.indiaCityList, function (vo, idx) {
                                  return _c(
                                    "option",
                                    {
                                      key: "inAct_" + idx,
                                      domProps: { value: vo.cd },
                                    },
                                    [_vm._v(_vm._s(vo.cdNm))]
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "actShprPostNo", maxlength: "6" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("actShprPostNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.actShprPostNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "actShprPostNo", $$v)
                            },
                            expression: "regParams.actShprPostNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass: "text_left td_shpr",
                        attrs: { rowspan: "2" },
                      },
                      [
                        _c("e-trans-address-textarea", {
                          ref: "shprCstAddr",
                          attrs: {
                            id: "shprCstAddr",
                            "max-line": 5,
                            "column-length": 50,
                            "is-char": true,
                          },
                          on: { input: _vm.checkShpperLOI },
                          model: {
                            value: _vm.regParams.shprCstAddr,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "shprCstAddr", $$v)
                            },
                            expression: "regParams.shprCstAddr",
                          },
                        }),
                        _vm.isShipperLOIPlc && _vm.isShipperLOI
                          ? _c("div", { staticClass: "shp_msg" }, [
                              _vm.$ekmtcCommon.isEmpty(_vm.saveShipperLOI)
                                ? _c("span", { staticClass: "shp_msg_txt" }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBL200.222"))),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "d_inline_flex" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button sm mt5",
                                    on: {
                                      click: function ($event) {
                                        return _vm.fnCommonBtnAction(
                                          "TRD_SHIPPER"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("msg.CSBL200.272")))]
                                ),
                                _vm.$ekmtcCommon.isNotEmpty(_vm.saveShipperLOI)
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "btn_doc mt5",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.fnCommonBtnAction(
                                              "TRD_SHIPPER_FILE"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("Shipper LOI 첨부 확인")]
                                    )
                                  : _vm._e(),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBK100_M1.023_1")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.KMTC118.003")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.187")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.003")))]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "border_left" },
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "actShprCstPicNm", maxlength: "100" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("actShprCstPicNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.actShprCstPicNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "actShprCstPicNm", $$v)
                            },
                            expression: "regParams.actShprCstPicNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: {
                            id: "actShprCstTelNo",
                            maxlength: "30",
                            "number-only":
                              _vm.regParams.polCtrCd === "KR" &&
                              _vm.regParams.podCtrCd === "JP",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("actShprCstTelNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.actShprCstTelNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "actShprCstTelNo", $$v)
                            },
                            expression: "regParams.actShprCstTelNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: {
                            id: "actShprCstFaxNo",
                            maxlength: "30",
                            "number-only":
                              _vm.regParams.polCtrCd === "KR" &&
                              _vm.regParams.podCtrCd === "JP",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("actShprCstFaxNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.actShprCstFaxNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "actShprCstFaxNo", $$v)
                            },
                            expression: "regParams.actShprCstFaxNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.actShprCstEmlAddr,
                            expression: "regParams.actShprCstEmlAddr",
                          },
                        ],
                        attrs: { id: "actShprCstEmlAddr", type: "text" },
                        domProps: { value: _vm.regParams.actShprCstEmlAddr },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "actShprCstEmlAddr",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkErrorVisible("actShprCstEmlAddr")
                            },
                          ],
                          blur: function ($event) {
                            return _vm.checkValidationEmail("actShprCstEmlAddr")
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "text_left" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.NEWB020G010.044")) + " "),
                      _c(
                        "select",
                        {
                          staticClass: "wid150 ml20 bg_white",
                          on: {
                            change: function ($event) {
                              return _vm.changeLatestInfo($event, "cne")
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200_M1.019"))),
                          ]),
                          _vm._l(_vm.cstEnmCodes["CNE"], function (vo, idx) {
                            return _c(
                              "option",
                              {
                                key: "cne_" + idx,
                                domProps: { value: vo.cstNm },
                              },
                              [_vm._v(_vm._s(vo.cstNm))]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "span",
                        { staticClass: "ml10 color_black font_light" },
                        [
                          _c("input", {
                            attrs: { type: "checkbox", id: "order" },
                            on: { click: _vm.changeCneCstEnmToOrder },
                          }),
                          _vm._m(5),
                        ]
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.188")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.189")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.190")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.191")))]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("e-trans-address-input", {
                          ref: "cneCstEnm",
                          attrs: {
                            id: "cneCstEnm",
                            "is-required": true,
                            "is-char": true,
                            "max-length": 50,
                            "message-required": _vm.$t("msg.CSBK100.147"),
                          },
                          model: {
                            value: _vm.regParams.cneCstEnm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cneCstEnm", $$v)
                            },
                            expression: "regParams.cneCstEnm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.cneCstCtrCd,
                              expression: "regParams.cneCstCtrCd",
                            },
                          ],
                          attrs: { id: "cneCstCtrCd" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "cneCstCtrCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                _vm.fnChangeCneCatId(_vm.regParams.cneCstCtrCd)
                                _vm.fnAddInfoStateSet("CNE")
                              },
                            ],
                            input: function ($event) {
                              return _vm.checkErrorVisible("cneCstCtrCd")
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                          ]),
                          _vm._l(_vm.ctrCdList, function (vo, idx) {
                            return _c(
                              "option",
                              {
                                key: "cneCst_" + idx,
                                domProps: { value: vo.ctrCd },
                              },
                              [_vm._v(_vm._s(vo.ctrEnm))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "cneCstCityNm", maxlength: "100" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("cneCstCityNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.cneCstCityNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cneCstCityNm", $$v)
                            },
                            expression: "regParams.cneCstCityNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.regParams.cneCstCtrCd !== "IN",
                              expression: "regParams.cneCstCtrCd !== 'IN'",
                            },
                          ],
                          attrs: { id: "cneCstStateNm", maxlength: "35" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("cneCstStateNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.cneCstStateNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cneCstStateNm", $$v)
                            },
                            expression: "regParams.cneCstStateNm",
                          },
                        }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.regParams.cneCstCtrCd === "IN",
                                expression: "regParams.cneCstCtrCd === 'IN'",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.cneCstStateCd,
                                expression: "regParams.cneCstStateCd",
                              },
                            ],
                            attrs: { id: "cneCstStateCd" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.regParams,
                                    "cneCstStateCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.changeStateCd(
                                    "cneCstStateCd",
                                    "cneCstStateNm"
                                  )
                                },
                              ],
                              input: function ($event) {
                                return _vm.checkErrorVisible("cneCstStateCd")
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                            ]),
                            _vm._l(_vm.indiaCityList, function (vo, idx) {
                              return _c(
                                "option",
                                {
                                  key: "inCne_" + idx,
                                  domProps: { value: vo.cd },
                                },
                                [_vm._v(_vm._s(vo.cdNm))]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "cneCstPostNo", maxlength: "6" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("cneCstPostNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.cneCstPostNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cneCstPostNo", $$v)
                            },
                            expression: "regParams.cneCstPostNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { attrs: { rowspan: "2" } },
                      [
                        _c("e-trans-address-textarea", {
                          ref: "cneCstAddr",
                          attrs: {
                            id: "cneCstAddr",
                            "max-line": 5,
                            "column-length": 50,
                            "is-char": true,
                          },
                          model: {
                            value: _vm.regParams.cneCstAddr,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cneCstAddr", $$v)
                            },
                            expression: "regParams.cneCstAddr",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBK100_M1.023_1")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.KMTC118.003")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.187")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.003")))]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "border_left" },
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "cneCstPicNm", maxlength: "100" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("cneCstPicNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.cneCstPicNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cneCstPicNm", $$v)
                            },
                            expression: "regParams.cneCstPicNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: {
                            id: "cneCstTelNo",
                            maxlength: "30",
                            "number-only":
                              _vm.regParams.polCtrCd === "KR" &&
                              _vm.regParams.podCtrCd === "JP",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("cneCstTelNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.cneCstTelNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cneCstTelNo", $$v)
                            },
                            expression: "regParams.cneCstTelNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: {
                            id: "cneCstFaxNo",
                            maxlength: "30",
                            "number-only":
                              _vm.regParams.polCtrCd === "KR" &&
                              _vm.regParams.podCtrCd === "JP",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("cneCstFaxNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.cneCstFaxNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cneCstFaxNo", $$v)
                            },
                            expression: "regParams.cneCstFaxNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.cneCstEmlAddr,
                            expression: "regParams.cneCstEmlAddr",
                          },
                        ],
                        staticClass: "col_9",
                        attrs: {
                          id: "cneCstEmlAddr",
                          type: "text",
                          maxlength: "300",
                        },
                        domProps: { value: _vm.regParams.cneCstEmlAddr },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "cneCstEmlAddr",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkErrorVisible("cneCstEmlAddr")
                            },
                          ],
                          blur: function ($event) {
                            return _vm.checkValidationEmail("cneCstEmlAddr")
                          },
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "position_relative d_inline_block ml5" },
                        [
                          _c(
                            "button",
                            { staticClass: "tbl_icon help btn_tooltip" },
                            [_vm._v("도움말")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tooltip_wrap short position_absolute text_left div_tooltip",
                            },
                            [
                              _c("div", { staticClass: "cont" }, [
                                _c("ul", { staticClass: "bul_list_sm t2" }, [
                                  _c("li", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBL200.232")) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", { staticClass: "text_left" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.NEWB020G010.045")) + " "),
                      _c(
                        "select",
                        {
                          staticClass: "wid150 ml20 bg_white",
                          on: {
                            change: function ($event) {
                              return _vm.changeLatestInfo($event, "ntif")
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200_M1.019"))),
                          ]),
                          _vm._l(_vm.cstEnmCodes["NTIF"], function (vo, idx) {
                            return _c(
                              "option",
                              {
                                key: "ntif_" + idx,
                                domProps: { value: vo.cstNm },
                              },
                              [_vm._v(_vm._s(vo.cstNm))]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "span",
                        { staticClass: "ml10 color_black font_light" },
                        [
                          _c("input", {
                            attrs: { type: "checkbox", id: "consi" },
                            on: { click: _vm.copyConsignee },
                          }),
                          _c("label", { attrs: { for: "consi" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("msg.NEWB020G010.018"))),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.188")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.189")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.190")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.191")))]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      [
                        _c("e-trans-address-input", {
                          ref: "ntifCstEnm",
                          attrs: {
                            id: "ntifCstEnm",
                            "is-required": true,
                            "is-char": true,
                            "max-length": 50,
                            "message-required": _vm.$t("msg.CSBK100.147"),
                          },
                          model: {
                            value: _vm.regParams.ntifCstEnm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstEnm", $$v)
                            },
                            expression: "regParams.ntifCstEnm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.ntifCstCtrCd,
                              expression: "regParams.ntifCstCtrCd",
                            },
                          ],
                          attrs: { id: "ntifCstCtrCd" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "ntifCstCtrCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                _vm.fnChangeNotiCatId(
                                  _vm.regParams.ntifCstCtrCd
                                )
                                _vm.fnAddInfoStateSet("NTIF")
                              },
                            ],
                            input: function ($event) {
                              return _vm.checkErrorVisible("ntifCstCtrCd")
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                          ]),
                          _vm._l(_vm.ctrCdList, function (vo, idx) {
                            return _c(
                              "option",
                              {
                                key: "ntifCst_" + idx,
                                domProps: { value: vo.ctrCd },
                              },
                              [_vm._v(_vm._s(vo.ctrEnm))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "ntifCstCityNm", maxlength: "100" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("ntifCstCityNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.ntifCstCityNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstCityNm", $$v)
                            },
                            expression: "regParams.ntifCstCityNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.regParams.ntifCstCtrCd !== "IN",
                              expression: "regParams.ntifCstCtrCd !== 'IN'",
                            },
                          ],
                          attrs: { id: "ntifCstStateNm", maxlength: "35" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("ntifCstStateNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.ntifCstStateNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstStateNm", $$v)
                            },
                            expression: "regParams.ntifCstStateNm",
                          },
                        }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.regParams.ntifCstCtrCd === "IN",
                                expression: "regParams.ntifCstCtrCd === 'IN'",
                              },
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.ntifCstStateCd,
                                expression: "regParams.ntifCstStateCd",
                              },
                            ],
                            attrs: { id: "ntifCstStateCd" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.regParams,
                                    "ntifCstStateCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.changeStateCd(
                                    "ntifCstStateCd",
                                    "ntifCstStateNm"
                                  )
                                },
                              ],
                              input: function ($event) {
                                return _vm.checkErrorVisible("ntifCstStateCd")
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.062"))),
                            ]),
                            _vm._l(_vm.indiaCityList, function (vo, idx) {
                              return _c(
                                "option",
                                {
                                  key: "inNtif_" + idx,
                                  domProps: { value: vo.cd },
                                },
                                [_vm._v(_vm._s(vo.cdNm))]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "ntifCstPostNo", maxlength: "6" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("ntifCstPostNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.ntifCstPostNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstPostNo", $$v)
                            },
                            expression: "regParams.ntifCstPostNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { attrs: { rowspan: "2" } },
                      [
                        _c("e-trans-address-textarea", {
                          ref: "ntifCstAddr",
                          attrs: {
                            id: "ntifCstAddr",
                            "max-line": 5,
                            "column-length": 50,
                            "is-char": true,
                          },
                          model: {
                            value: _vm.regParams.ntifCstAddr,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstAddr", $$v)
                            },
                            expression: "regParams.ntifCstAddr",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBK100_M1.023_1")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.KMTC118.003")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.187")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.NEWB020G010.003")))]),
                  ]),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "border_left" },
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: { id: "ntifCstPicNm", maxlength: "100" },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("ntifCstPicNm")
                            },
                          },
                          model: {
                            value: _vm.regParams.ntifCstPicNm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstPicNm", $$v)
                            },
                            expression: "regParams.ntifCstPicNm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: {
                            id: "ntifCstTelNo",
                            maxlength: "30",
                            "number-only":
                              _vm.regParams.polCtrCd === "KR" &&
                              _vm.regParams.podCtrCd === "JP",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("ntifCstTelNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.ntifCstTelNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstTelNo", $$v)
                            },
                            expression: "regParams.ntifCstTelNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: {
                            id: "ntifCstFaxNo",
                            maxlength: "30",
                            "number-only":
                              _vm.regParams.polCtrCd === "KR" &&
                              _vm.regParams.podCtrCd === "JP",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("ntifCstFaxNo")
                            },
                          },
                          model: {
                            value: _vm.regParams.ntifCstFaxNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "ntifCstFaxNo", $$v)
                            },
                            expression: "regParams.ntifCstFaxNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.regParams.ntifCstEmlAddr,
                            expression: "regParams.ntifCstEmlAddr",
                          },
                        ],
                        staticClass: "col_9",
                        attrs: { id: "ntifCstEmlAddr", type: "text" },
                        domProps: { value: _vm.regParams.ntifCstEmlAddr },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "ntifCstEmlAddr",
                                $event.target.value
                              )
                            },
                            function ($event) {
                              return _vm.checkErrorVisible("ntifCstEmlAddr")
                            },
                          ],
                          blur: function ($event) {
                            return _vm.checkValidationEmail("cneCstEmlAddr")
                          },
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "position_relative d_inline_block ml5" },
                        [
                          _c(
                            "button",
                            { staticClass: "tbl_icon help btn_tooltip" },
                            [_vm._v("도움말")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "tooltip_wrap short position_absolute text_left div_tooltip",
                            },
                            [
                              _c("div", { staticClass: "cont" }, [
                                _c("ul", { staticClass: "bul_list_sm t2" }, [
                                  _c("li", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("msg.CSBL200.232")) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm.isCstId
                ? _c("table", { staticClass: "tbl_row mt10" }, [
                    _vm._m(6),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.233")))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.actShprIdCatCd,
                                    expression: "regParams.actShprIdCatCd",
                                  },
                                ],
                                staticClass: "col_4",
                                attrs: { id: "actShprIdCatCd" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.regParams,
                                        "actShprIdCatCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.fnChangeCatId($event, "ACT")
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("select"),
                                ]),
                                _vm._l(_vm.actCstIdList, function (vo, idx) {
                                  return _c(
                                    "option",
                                    {
                                      key: "actShprCatCd_" + idx,
                                      domProps: { value: vo.cstIdCatCd },
                                    },
                                    [_vm._v(_vm._s(vo.cstIdCatNm))]
                                  )
                                }),
                              ],
                              2
                            ),
                            _c("e-trans-string-check-cnee-input", {
                              staticClass: "inp_space60 ml3 float_right",
                              attrs: { id: "actShprIdNo", maxlength: "200" },
                              on: {
                                input: function ($event) {
                                  return _vm.checkErrorVisible("actShprIdNo")
                                },
                              },
                              model: {
                                value: _vm.regParams.actShprIdNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.regParams, "actShprIdNo", $$v)
                                },
                                expression: "regParams.actShprIdNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.234")))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.cneIdCatCd,
                                    expression: "regParams.cneIdCatCd",
                                  },
                                ],
                                staticClass: "col_4",
                                attrs: { id: "cneIdCatCd" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.regParams,
                                        "cneIdCatCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.fnChangeCatId($event, "CNE")
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("select"),
                                ]),
                                _vm._l(_vm.cneCstIdList, function (vo, idx) {
                                  return _c(
                                    "option",
                                    {
                                      key: "cneCatCd_" + idx,
                                      domProps: { value: vo.cstIdCatCd },
                                    },
                                    [_vm._v(_vm._s(vo.cstIdCatNm))]
                                  )
                                }),
                              ],
                              2
                            ),
                            _c("e-trans-string-check-cnee-input", {
                              staticClass: "inp_space60 ml3 float_right",
                              attrs: { id: "cneIdNo", maxlength: "200" },
                              on: {
                                input: function ($event) {
                                  return _vm.checkErrorVisible("cneIdNo")
                                },
                              },
                              model: {
                                value: _vm.regParams.cneIdNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.regParams, "cneIdNo", $$v)
                                },
                                expression: "regParams.cneIdNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.235")))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.regParams.ntifIdCatCd,
                                    expression: "regParams.ntifIdCatCd",
                                  },
                                ],
                                staticClass: "col_4",
                                attrs: { id: "ntifIdCatCd" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.regParams,
                                        "ntifIdCatCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.fnChangeCatId($event, "NTIF")
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v("select"),
                                ]),
                                _vm._l(_vm.notiCstIdList, function (vo, idx) {
                                  return _c(
                                    "option",
                                    {
                                      key: "ntifCatCd_" + idx,
                                      domProps: { value: vo.cstIdCatCd },
                                    },
                                    [_vm._v(_vm._s(vo.cstIdCatNm))]
                                  )
                                }),
                              ],
                              2
                            ),
                            _c("e-trans-string-check-cnee-input", {
                              staticClass: "inp_space60 ml3 float_right",
                              attrs: { id: "ntifIdNo", maxlength: "200" },
                              on: {
                                input: function ($event) {
                                  return _vm.checkErrorVisible("ntifIdNo")
                                },
                              },
                              model: {
                                value: _vm.regParams.ntifIdNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.regParams, "ntifIdNo", $$v)
                                },
                                expression: "regParams.ntifIdNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.polCtrCd === "ID"
                ? _c("table", { staticClass: "tbl_row mt10" }, [
                    _vm._m(7),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.236")))]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "col_4" },
                            [
                              _c("e-input-number", {
                                attrs: {
                                  id: "etTaxNo",
                                  "is-comma": false,
                                  "is-phone": true,
                                  maxlength: "16",
                                },
                                model: {
                                  value: _vm.regParams.etTaxNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "etTaxNo", $$v)
                                  },
                                  expression: "regParams.etTaxNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.podCtrCd === "ID"
                ? _c("table", { staticClass: "tbl_row mt10" }, [
                    _vm._m(8),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.237")))]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "col_4" },
                            [
                              _c("e-input-number", {
                                attrs: {
                                  id: "idTaxNo",
                                  "is-comma": false,
                                  "is-phone": true,
                                  maxlength: "16",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.checkValidationInfo("idTaxNo")
                                  },
                                },
                                model: {
                                  value: _vm.regParams.idTaxNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "idTaxNo", $$v)
                                  },
                                  expression: "regParams.idTaxNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.podCtrCd === "VN"
                ? _c("div", { staticClass: "mt10" }, [
                    _c("table", { staticClass: "tbl_row mt10" }, [
                      _vm._m(9),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.237")))]),
                          _vm._v(" "),
                          _c("td", { attrs: { colspan: "3" } }, [
                            _c(
                              "div",
                              { staticClass: "d_flex" },
                              [
                                _c("e-trans-string-check-cnee-input", {
                                  staticClass: "col_5",
                                  attrs: { id: "idTaxNo", maxlength: "30" },
                                  model: {
                                    value: _vm.regParams.idTaxNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.regParams, "idTaxNo", $$v)
                                    },
                                    expression: "regParams.idTaxNo",
                                  },
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.wstCgoYn,
                                      expression: "regParams.wstCgoYn",
                                    },
                                  ],
                                  attrs: {
                                    id: "wstCgoYn",
                                    type: "checkbox",
                                    "true-value": "Y",
                                    "false-value": "N",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.regParams.wstCgoYn
                                    )
                                      ? _vm._i(_vm.regParams.wstCgoYn, null) >
                                        -1
                                      : _vm._q(_vm.regParams.wstCgoYn, "Y"),
                                  },
                                  on: {
                                    click: _vm.changeWstCgoYn,
                                    change: function ($event) {
                                      var $$a = _vm.regParams.wstCgoYn,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "Y" : "N"
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.regParams,
                                              "wstCgoYn",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.regParams,
                                              "wstCgoYn",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.regParams, "wstCgoYn", $$c)
                                      }
                                    },
                                  },
                                }),
                                _c("label", { attrs: { for: "wstCgoYn" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBL200.238"))),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm.regParams.wstCgoYn === "Y"
                          ? _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.CSBL200.239"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("e-trans-string-check-cnee-input", {
                                    attrs: { id: "imLicNo", maxlength: "20" },
                                    model: {
                                      value: _vm.regParams.imLicNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.regParams, "imLicNo", $$v)
                                      },
                                      expression: "regParams.imLicNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.CSBL200.240"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("e-trans-string-check-cnee-input", {
                                    attrs: {
                                      id: "imBnkGurNo",
                                      maxlength: "20",
                                    },
                                    model: {
                                      value: _vm.regParams.imBnkGurNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.regParams,
                                          "imBnkGurNo",
                                          $$v
                                        )
                                      },
                                      expression: "regParams.imBnkGurNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.polCtrCd === "IN" || _vm.regParams.podCtrCd === "IN"
                ? _c("div", { staticClass: "mt10" }, [
                    _c("h2", { staticClass: "content_title" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200.241"))),
                    ]),
                    _vm._v(" "),
                    _c("table", { staticClass: "tbl_row mt10" }, [
                      _vm._m(10),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.regParams.polCtrCd === "IN"
                                  ? _vm.$t("msg.CSBL200.192")
                                  : _vm.$t("msg.CSBL200.193")
                              )
                            ),
                          ]),
                          _c(
                            "td",
                            [
                              _c("e-trans-string-check-cnee-input", {
                                attrs: { id: "iecNo", maxlength: "10" },
                                on: {
                                  input: function ($event) {
                                    return _vm.setIndiaInfo()
                                  },
                                },
                                model: {
                                  value: _vm.regParams.iecNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "iecNo", $$v)
                                  },
                                  expression: "regParams.iecNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200.242")) + " "),
                            _c("span", { staticClass: "txt" }, [
                              _vm._v(
                                "(" + _vm._s(_vm.$t("msg.CSBK100.062")) + ")"
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c("e-trans-string-check-cnee-input", {
                                attrs: { id: "panNo", maxlength: "10" },
                                model: {
                                  value: _vm.regParams.panNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "panNo", $$v)
                                  },
                                  expression: "regParams.panNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm.regParams.polCtrCd === "IN"
                          ? _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.ONEX050P190.015"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                { attrs: { colspan: "3" } },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.invValCurCd,
                                          expression: "regParams.invValCurCd",
                                        },
                                      ],
                                      staticClass: "col_5",
                                      attrs: { id: "invValCurCd" },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.regParams,
                                              "invValCurCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            return _vm.checkErrorVisible(
                                              "invValCurCd"
                                            )
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("select"),
                                      ]),
                                      _vm._l(
                                        _vm.commonCodes["00003"],
                                        function (vo, idx) {
                                          return _c(
                                            "option",
                                            {
                                              key: "invValCurCd_" + idx,
                                              domProps: { value: vo.cd },
                                            },
                                            [_vm._v(_vm._s(vo.cd))]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c("e-input-number", {
                                    staticClass: "inp_space50_2 ml3",
                                    attrs: {
                                      id: "invValExp",
                                      "is-comma": true,
                                      point: 3,
                                      maxlength: "18",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkErrorVisible(
                                          "invValExp"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.regParams.invValExp,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.regParams,
                                          "invValExp",
                                          $$v
                                        )
                                      },
                                      expression: "regParams.invValExp",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.regParams.podCtrCd === "IN"
                          ? _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.CSBL200.243"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("e-trans-string-check-cnee-input", {
                                    attrs: { id: "ntifPanNo", maxlength: "10" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.setIndiaInfo()
                                      },
                                    },
                                    model: {
                                      value: _vm.regParams.ntifPanNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.regParams,
                                          "ntifPanNo",
                                          $$v
                                        )
                                      },
                                      expression: "regParams.ntifPanNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.ONEX050P190.015"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.regParams.invValCurCd,
                                          expression: "regParams.invValCurCd",
                                        },
                                      ],
                                      staticClass: "col_5",
                                      attrs: { id: "invValCurCd" },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.regParams,
                                              "invValCurCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            return _vm.checkErrorVisible(
                                              "invValCurCd"
                                            )
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("select"),
                                      ]),
                                      _vm._l(
                                        _vm.commonCodes["00003"],
                                        function (vo, idx) {
                                          return _c(
                                            "option",
                                            {
                                              key: "invValCurCd_" + idx,
                                              domProps: { value: vo.cd },
                                            },
                                            [_vm._v(_vm._s(vo.cd))]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _c("e-input-number", {
                                    staticClass: "inp_space50_2 ml3",
                                    attrs: {
                                      id: "invValExp",
                                      "is-comma": true,
                                      point: 3,
                                      maxlength: "18",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkErrorVisible(
                                          "invValExp"
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.regParams.invValExp,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.regParams,
                                          "invValExp",
                                          $$v
                                        )
                                      },
                                      expression: "regParams.invValExp",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.regParams.podCtrCd === "IN" &&
                        _vm.regParams.podPortCd === "NSA"
                          ? _c("tr", [
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.CSBL200.244"))),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("e-trans-string-check-cnee-input", {
                                    attrs: { id: "gstNo", maxlength: "20" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.setIndiaInfo()
                                      },
                                    },
                                    model: {
                                      value: _vm.regParams.gstNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.regParams, "gstNo", $$v)
                                      },
                                      expression: "regParams.gstNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("th", [
                                _vm._v(_vm._s(_vm.$t("msg.MAIN100.202"))),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.iecPicEmlAddr,
                                      expression: "regParams.iecPicEmlAddr",
                                    },
                                  ],
                                  attrs: {
                                    id: "iecPicEmlAddr",
                                    type: "text",
                                    maxlength: "100",
                                  },
                                  domProps: {
                                    value: _vm.regParams.iecPicEmlAddr,
                                  },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.regParams,
                                          "iecPicEmlAddr",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkErrorVisible(
                                          "iecPicEmlAddr"
                                        )
                                      },
                                    ],
                                    blur: function ($event) {
                                      return _vm.checkValidationEmail(
                                        "iecPicEmlAddr"
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.podCtrCd === "EG"
                ? _c("div", { staticClass: "mt10" }, [
                    _c("table", { staticClass: "tbl_row mt10" }, [
                      _vm._m(11),
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("ACID No")]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [
                              _c("e-trans-string-check-cnee-input", {
                                attrs: {
                                  id: "acidNo",
                                  maxlength: "19",
                                  "is-upper-case": true,
                                },
                                model: {
                                  value: _vm.regParams.acidNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "acidNo", $$v)
                                  },
                                  expression: "regParams.acidNo",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.237")))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [
                              _c("e-trans-string-check-cnee-input", {
                                attrs: {
                                  id: "cneAcidNo",
                                  maxlength: "9",
                                  "is-upper-case": true,
                                },
                                model: {
                                  value: _vm.regParams.cneAcidNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.regParams, "cneAcidNo", $$v)
                                  },
                                  expression: "regParams.cneAcidNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.podCtrCd === "EG"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.040"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.041"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.042"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.jpPodCount > 0
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.001"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.cnPodfCount > 0 && _vm.regParams.shaPodTsCount === 0
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.002"))),
                    ]),
                  ])
                : _vm._e(),
              (_vm.regParams.polPortCd === "PKG" ||
                _vm.regParams.polPortCd === "PKW") &&
              _vm.regParams.cnPodCount < 1
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.003"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.polCtrCd === "ID"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.004"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.polCtrCd === "JP" && _vm.regParams.podCtrCd === "VN"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.027"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.028"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.029"))),
                      _c("br"),
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.030"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.031"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.032"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.033"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.034"))),
                    ]),
                    _c("br"),
                  ])
                : _vm.regParams.podCtrCd === "VN"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.005"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.006"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.polCtrCd === "JP" && _vm.regParams.podCtrCd === "ID"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc_normal" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.027"))),
                    ]),
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.007"))),
                    ]),
                  ])
                : _vm.regParams.podCtrCd === "ID"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.007"))),
                    ]),
                  ])
                : _vm.regParams.podPortCd === "DMM"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.039"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.polCtrCd === "IN"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.008"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.009"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.010")) + " "),
                    ]),
                  ])
                : _vm._e(),
              _vm.regParams.polCtrCd === "JP" && _vm.regParams.podCtrCd === "IN"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc_normal" }, [
                      _vm._v(" " + _vm._s(`<${_vm.$t("msg.CSBL200_M.017")}>`)),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.018"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.019"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.020"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.021"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.022"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.023"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.024"))),
                      _c("br"),
                    ]),
                  ])
                : _vm.regParams.podCtrCd === "IN"
                ? _c("div", [
                    _c("p", { staticClass: "txt_desc" }, [
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.011"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.012"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.013"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.014"))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200_M.015")) + " "),
                    ]),
                  ])
                : _vm._e(),
              [
                _vm.regParams.polCtrCd === "JP" &&
                _vm.regParams.podCtrCd === "ID"
                  ? void 0
                  : [
                      _c("p", { staticClass: "txt_desc" }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBL200_M.016"))),
                      ]),
                    ],
              ],
              _vm.regParams.polCtrCd === "JP" && _vm.regParams.podCtrCd === "PK"
                ? [
                    _c(
                      "p",
                      {
                        staticClass: "txt_desc_normal",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [_vm._v(_vm._s(`<${_vm.$t("msg.CSBL200_M.035")}>`))]
                    ),
                    _c("p", { staticClass: "txt_desc_normal" }, [
                      _vm._v(_vm._s(`1. ${_vm.$t("msg.CSBL200_M.036")}`)),
                    ]),
                    _c("p", { staticClass: "txt_desc_normal" }, [
                      _vm._v(_vm._s(`2. ${_vm.$t("msg.CSBL200_M.037")}`)),
                    ]),
                  ]
                : _vm._e(),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBL200.245"))),
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(12),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.246")))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { attrs: { colspan: "5" } },
                      [
                        _c("e-trans-string-check-cnee-input", {
                          attrs: {
                            id: "cgoCont",
                            maxlength: "200",
                            "is-upper-case": true,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.fnByTextMsg()
                            },
                          },
                          model: {
                            value: _vm.regParams.cgoCont,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "cgoCont", $$v)
                            },
                            expression: "regParams.cgoCont",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("Cargo Weight")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          staticClass: "col_6",
                          attrs: {
                            "is-comma": true,
                            point: 3,
                            id: "grsWt",
                            maxlength: "14",
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.fnSumValue("grsWt")
                            },
                            input: function ($event) {
                              return _vm.checkValidationInfo("grsWt")
                            },
                          },
                          model: {
                            value: _vm.regParams.grsWt,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "grsWt", $$v)
                            },
                            expression: "regParams.grsWt",
                          },
                        }),
                        _vm._v(" Kgs "),
                      ],
                      1
                    ),
                    _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK100")))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          staticClass: "col_3",
                          attrs: {
                            "is-comma": true,
                            id: "pkgQty",
                            maxlength: "10",
                          },
                          on: {
                            blur: function ($event) {
                              _vm.fnSumValue("pkgQty")
                              _vm.fnPackage("CHANGE")
                            },
                            input: function ($event) {
                              return _vm.checkValidationInfo("pkgQty")
                            },
                          },
                          model: {
                            value: _vm.regParams.pkgQty,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "pkgQty", $$v)
                            },
                            expression: "regParams.pkgQty",
                          },
                        }),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.pkgCd,
                                expression: "regParams.pkgCd",
                              },
                            ],
                            staticClass: "inp_space70",
                            attrs: { id: "pkgCd" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.regParams,
                                    "pkgCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  _vm.checkValidationInfo("pkgCd")
                                  _vm.fnPackage("CHANGE")
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBL220.003"))),
                            ]),
                            _vm._l(_vm.pkgCdList, function (vo, idx) {
                              return _c(
                                "option",
                                {
                                  key: "pkgCd_" + idx,
                                  domProps: { value: vo.cd },
                                },
                                [_vm._v(_vm._s(vo.cdNm))]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK098")))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          staticClass: "dv col_5",
                          attrs: {
                            id: "grsCbm",
                            "is-comma": true,
                            point: 4,
                            maxlength: "13",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.checkErrorVisible("grsCbm")
                            },
                          },
                          model: {
                            value: _vm.regParams.grsCbm,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "grsCbm", $$v)
                            },
                            expression: "regParams.grsCbm",
                          },
                        }),
                        _vm._v(" CBM "),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v("POL HS Code")]),
                    _c("td", [
                      _c("div", { staticClass: "tbl_form" }, [
                        _c(
                          "span",
                          { class: _vm.isPolHsMulti ? "dv col_8" : "dv" },
                          [
                            _c("e-trans-string-check-input", {
                              attrs: {
                                id: "hsCd",
                                "is-upper-case": true,
                                maxlength: _vm.hsChkParams.maxlengthPolHsCd,
                                "error-message":
                                  _vm.hsChkParams.hscdPolErrorMessage,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkHsCd($event, "POL")
                                },
                              },
                              model: {
                                value: _vm.regParams.hsCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.regParams, "hsCd", $$v)
                                },
                                expression: "regParams.hsCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.isPolHsMulti
                          ? _c("span", { staticClass: "dv" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon plus",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.hsCdMultiPop("01")
                                    },
                                  },
                                },
                                [_vm._v(" plus ")]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("th", [_vm._v("POD HS Code")]),
                    _c("td", [
                      _c("div", { staticClass: "tbl_form" }, [
                        _c(
                          "span",
                          { class: _vm.isPodHsMulti ? "dv col_8" : "dv" },
                          [
                            _c("e-trans-string-check-input", {
                              attrs: {
                                id: "podHsCd",
                                "is-upper-case": true,
                                maxlength: _vm.hsChkParams.maxlengthPodHsCd,
                                "error-message":
                                  _vm.hsChkParams.hscdPodErrorMessage,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkHsCd($event, "POD")
                                },
                              },
                              model: {
                                value: _vm.regParams.podHsCd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.regParams, "podHsCd", $$v)
                                },
                                expression: "regParams.podHsCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.isPodHsMulti
                          ? _c("span", { staticClass: "dv" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon plus",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.hsCdMultiPop("02")
                                    },
                                  },
                                },
                                [_vm._v(" plus ")]
                              ),
                            ])
                          : _vm._e(),
                        !_vm.isPodHsMulti
                          ? _c("span", { staticClass: "dv" }, [
                              _c("div", { staticClass: "position_relative" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.regParams.podHsCdNm,
                                      expression: "regParams.podHsCdNm",
                                    },
                                  ],
                                  attrs: { type: "text", readonly: true },
                                  domProps: { value: _vm.regParams.podHsCdNm },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.regParams,
                                        "podHsCdNm",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _c("td", {
                      staticClass: "border_left_none",
                      attrs: { colspan: "2" },
                    }),
                  ]),
                ]),
              ]),
              _vm.lang === "KOR"
                ? _c("div", { staticClass: "desc_list" }, [
                    _c("ul", [
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.247")))]),
                    ]),
                    _vm.regParams.podCtrCd === "MY"
                      ? _c("ul", [
                          _c("li", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "말레이시아향은 품명(세관신고 품목)란과 디스크립션의 품명이 수입신고 품명으로 일치해야 합니다."
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _c("h2", { staticClass: "content_title mt20" }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBL200.248"))),
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(13),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.249")))]),
                    _vm._v(" "),
                    _c("td", { attrs: { colspan: "5" } }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.blEdiTypCd,
                              expression: "regParams.blEdiTypCd",
                            },
                          ],
                          staticClass: "wid200",
                          attrs: { id: "blEdiTypCd" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.regParams,
                                  "blEdiTypCd",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              _vm.changeBlEdiTypCd,
                            ],
                          },
                        },
                        _vm._l(_vm.blEdiTypCdList, function (vo, idx) {
                          return _c(
                            "option",
                            {
                              key: "blEdiTypCd_" + idx,
                              domProps: { value: vo.cd },
                            },
                            [_vm._v(_vm._s(vo.dsc))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm.isExportLicense()
                ? _c(
                    "table",
                    { staticClass: "tbl_col mt10 table_elInfo" },
                    [
                      _vm._m(14),
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("No.")]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL220.002")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK100")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ARAI100_M1.005"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ARAI100_M1.006"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.082")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.083")))]),
                          _vm._v(" "),
                          _c("th", [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.closeEl,
                                    expression: "!closeEl",
                                  },
                                ],
                                staticClass: "tbl_icon plus",
                                attrs: { type: "button" },
                                on: { click: _vm.addExportLicense },
                              },
                              [_vm._v(" plus ")]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._l(
                        _vm.regParams.exportLicenseList,
                        function (vo, idx) {
                          return _c("tbody", { key: "expLicense_" + idx }, [
                            _c("tr", [
                              _c("td", [_vm._v(" " + _vm._s(idx + 1) + " ")]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.elNo,
                                      expression: "vo.elNo",
                                    },
                                  ],
                                  attrs: { id: "elNo_" + idx, type: "text" },
                                  domProps: { value: vo.elNo },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          vo,
                                          "elNo",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkValidationExportLicense(
                                          "elNo",
                                          idx
                                        )
                                      },
                                    ],
                                    blur: function ($event) {
                                      return _vm.checkElNo(idx)
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "td",
                                [
                                  _c("e-input-number", {
                                    staticClass: "inp_space30",
                                    attrs: {
                                      "is-comma": true,
                                      id: "lcsPkgQty_" + idx,
                                      maxlength: "10",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkValidationExportLicense(
                                          "lcsPkgQty",
                                          idx
                                        )
                                      },
                                    },
                                    model: {
                                      value: vo.pkgQty,
                                      callback: function ($$v) {
                                        _vm.$set(vo, "pkgQty", $$v)
                                      },
                                      expression: "vo.pkgQty",
                                    },
                                  }),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.pkgCd,
                                          expression: "vo.pkgCd",
                                        },
                                      ],
                                      staticClass: "inp_responsive_space7 ml3",
                                      attrs: { id: "lcsPkgCd_" + idx },
                                      on: {
                                        input: function ($event) {
                                          return _vm.checkValidationExportLicense(
                                            "lcsPkgCd",
                                            idx
                                          )
                                        },
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            vo,
                                            "pkgCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBL220.003"))
                                        ),
                                      ]),
                                      _vm._l(_vm.pkgCdList, function (cvo, i) {
                                        return _c(
                                          "option",
                                          {
                                            key: "lcsPkg_" + i,
                                            domProps: { value: cvo.cd },
                                          },
                                          [_vm._v(_vm._s(cvo.cdNm))]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "td",
                                [
                                  _c("e-input-number", {
                                    attrs: {
                                      "is-comma": true,
                                      id: "lcsGrsWt_" + idx,
                                      point: 3,
                                      maxlength: "13",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkValidationExportLicense(
                                          "lcsGrsWt",
                                          idx
                                        )
                                      },
                                    },
                                    model: {
                                      value: vo.grsWt,
                                      callback: function ($$v) {
                                        _vm.$set(vo, "grsWt", $$v)
                                      },
                                      expression: "vo.grsWt",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: vo.prtlLdgYn,
                                        expression: "vo.prtlLdgYn",
                                      },
                                    ],
                                    staticClass: "inp_space50",
                                    attrs: { id: "prtlLdgYn_" + idx },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          vo,
                                          "prtlLdgYn",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "N" } }, [
                                      _vm._v("No"),
                                    ]),
                                    _c("option", { attrs: { value: "Y" } }, [
                                      _vm._v("Yes"),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: vo.ldgTcnt,
                                        expression: "vo.ldgTcnt",
                                      },
                                    ],
                                    staticClass: "inp_space50 ml3",
                                    attrs: { id: "ldgTcnt_" + idx },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkValidationExportLicense(
                                          "ldgTcnt",
                                          idx
                                        )
                                      },
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          vo,
                                          "ldgTcnt",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "" } }, [
                                      _vm._v(_vm._s(_vm.$t("msg.CSBL220.004"))),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(30, function (cvo, i) {
                                      return _c(
                                        "option",
                                        {
                                          key: "ldgTcnt_" + i,
                                          domProps: { value: cvo },
                                        },
                                        [_vm._v(_vm._s(cvo))]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: vo.pkgtgCd,
                                          expression: "vo.pkgtgCd",
                                        },
                                      ],
                                      staticClass: "inp_space50",
                                      attrs: { id: "pkgtgCd_" + idx },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            vo,
                                            "pkgtgCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v("Code"),
                                      ]),
                                      _vm._l(9, function (cvo, i) {
                                        return _c(
                                          "option",
                                          {
                                            key: "pkgtgCd_" + i,
                                            domProps: {
                                              value: String.fromCharCode(
                                                cvo + 64
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                String.fromCharCode(cvo + 64)
                                              )
                                            ),
                                          ]
                                        )
                                      }),
                                    ],
                                    2
                                  ),
                                  _c("e-input-number", {
                                    staticClass: "inp_space50 ml3",
                                    attrs: {
                                      "is-comma": true,
                                      id: "pkgtgPkgQty_" + idx,
                                      maxlength: "3",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkValidationExportLicense(
                                          "pkgtgPkgQty",
                                          idx
                                        )
                                      },
                                    },
                                    model: {
                                      value: vo.pkgtgPkgQty,
                                      callback: function ($$v) {
                                        _vm.$set(vo, "pkgtgPkgQty", $$v)
                                      },
                                      expression: "vo.pkgtgPkgQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "input_calendar mid" },
                                  [
                                    _c("e-date-picker", {
                                      attrs: {
                                        id: "elNoValdDt_" + idx,
                                        disabled: _vm.closeEl,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.checkValidationExportLicense(
                                            "elNoValdDt",
                                            idx
                                          )
                                        },
                                      },
                                      model: {
                                        value: vo.elNoValdDt,
                                        callback: function ($$v) {
                                          _vm.$set(vo, "elNoValdDt", $$v)
                                        },
                                        expression: "vo.elNoValdDt",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.closeEl,
                                        expression: "!closeEl",
                                      },
                                    ],
                                    staticClass: "tbl_icon minus",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeExportLicense(idx)
                                      },
                                    },
                                  },
                                  [_vm._v(" minus ")]
                                ),
                              ]),
                            ]),
                          ])
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.isIndonesia()
                ? _c("table", { staticClass: "tbl_col mt10" }, [
                    _vm._m(15),
                    _c("thead", [
                      _c("tr", [
                        _c("th", [_vm._v("No.")]),
                        _c("th", [_vm._v("License No.")]),
                        _c("th", [_vm._v("License Date")]),
                        _c("th", [_vm._v("Customs Office")]),
                        _c("th", [_vm._v("Empty Check")]),
                        _c("th", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.regParams.consCgoYn,
                                expression: "regParams.consCgoYn",
                              },
                            ],
                            attrs: {
                              id: "tickYn",
                              type: "checkbox",
                              "true-value": "Y",
                              "false-value": "N",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.regParams.consCgoYn)
                                ? _vm._i(_vm.regParams.consCgoYn, null) > -1
                                : _vm._q(_vm.regParams.consCgoYn, "Y"),
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.regParams.consCgoYn,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.regParams,
                                        "consCgoYn",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.regParams,
                                        "consCgoYn",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.regParams, "consCgoYn", $$c)
                                }
                              },
                            },
                          }),
                          _c("label", { attrs: { for: "tickYn" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200.284"))),
                          ]),
                        ]),
                        _c("th", [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.closeEl,
                                  expression: "!closeEl",
                                },
                              ],
                              staticClass: "tbl_icon plus",
                              attrs: { type: "button" },
                              on: { click: _vm.addExportLicense },
                            },
                            [_vm._v(" plus ")]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(
                        _vm.regParams.exportLicenseList,
                        function (vo, idx) {
                          return _c(
                            "tr",
                            {
                              key: "expLicense_" + idx,
                              attrs: { id: "expLicense_" + idx },
                            },
                            [
                              _c("td", [_vm._v(_vm._s(idx + 1))]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.elNo,
                                      expression: "vo.elNo",
                                    },
                                  ],
                                  attrs: {
                                    id: "elNo_" + idx,
                                    type: "text",
                                    maxlength: "20",
                                  },
                                  domProps: { value: vo.elNo },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          vo,
                                          "elNo",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkValidationExportLicense(
                                          "elNo",
                                          idx
                                        )
                                      },
                                    ],
                                    blur: function ($event) {
                                      return _vm.checkElNoIdn(idx)
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "td",
                                [
                                  _c("e-date-picker", {
                                    attrs: {
                                      id: "elNoValdDt_" + idx,
                                      disabled: _vm.elNoValdDisbled,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.checkErrorVisible(
                                          "elNoValdDt_" + idx
                                        )
                                      },
                                    },
                                    model: {
                                      value: vo.elNoValdDt,
                                      callback: function ($$v) {
                                        _vm.$set(vo, "elNoValdDt", $$v)
                                      },
                                      expression: "vo.elNoValdDt",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.idCstmCd,
                                      expression: "vo.idCstmCd",
                                    },
                                  ],
                                  attrs: {
                                    id: "idCstmCd_" + idx,
                                    type: "text",
                                    maxlength: "6",
                                  },
                                  domProps: { value: vo.idCstmCd },
                                  on: {
                                    input: [
                                      function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          vo,
                                          "idCstmCd",
                                          $event.target.value
                                        )
                                      },
                                      function ($event) {
                                        return _vm.checkErrorVisible(
                                          "elNoValdDt_" + idx
                                        )
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.emptyYn,
                                      expression: "vo.emptyYn",
                                    },
                                  ],
                                  attrs: {
                                    id: "emptyCheck_" + idx,
                                    type: "checkbox",
                                    "true-value": "Y",
                                    "false-value": "N",
                                  },
                                  domProps: {
                                    checked: Array.isArray(vo.emptyYn)
                                      ? _vm._i(vo.emptyYn, null) > -1
                                      : _vm._q(vo.emptyYn, "Y"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkIdEmptyCheck($event, idx)
                                    },
                                    change: function ($event) {
                                      var $$a = vo.emptyYn,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "Y" : "N"
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              vo,
                                              "emptyYn",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              vo,
                                              "emptyYn",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(vo, "emptyYn", $$c)
                                      }
                                    },
                                  },
                                }),
                                _c("label", {
                                  attrs: { for: "emptyCheck_" + idx },
                                }),
                              ]),
                              _c("td", [
                                _vm._v(
                                  "Compulsory Item only for Indonesian Export B/L"
                                ),
                              ]),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.closeEl,
                                        expression: "!closeEl",
                                      },
                                    ],
                                    staticClass: "tbl_icon minus",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeExportLicense(idx)
                                      },
                                    },
                                  },
                                  [_vm._v(" minus ")]
                                ),
                              ]),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "flex_box mt20" }, [
                _c("h2", { staticClass: "content_title" }, [
                  _vm._v("Container"),
                ]),
                _c("span", { staticClass: "ml_auto cal_btn" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button sm",
                      on: {
                        click: function ($event) {
                          return _vm.fnCntrPkgAutoSet()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("msg.CSBL200.250")) + " ")]
                  ),
                  _vm.excelUploadShow
                    ? _c(
                        "a",
                        {
                          staticClass: "button sm ml3",
                          on: {
                            click: function ($event) {
                              return _vm.excelUploadPop()
                            },
                          },
                        },
                        [_vm._v("Excel Upload")]
                      )
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      staticClass: "button sm ml3",
                      on: {
                        click: function ($event) {
                          return _vm.fnCgoPop()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("btn.CMBTK045")) + " ")]
                  ),
                ]),
              ]),
              _c(
                "table",
                { staticClass: "tbl_col" },
                [
                  _vm.lang !== "JPN" &&
                  _vm.regParams.polCtrCd !== "PH" &&
                  _vm.regParams.polCtrCd !== "VN" &&
                  _vm.regParams.podCtrCd !== "BH"
                    ? _c("colgroup", [
                        _c("col", { staticStyle: { width: "3%" } }),
                        _c("col", { staticStyle: { width: "9.5%" } }),
                        _c("col", { staticStyle: { width: "11.5%" } }),
                        _c("col", { staticStyle: { width: "6%" } }),
                        _c("col", { staticStyle: { width: "12.5%" } }),
                        _c("col", { staticStyle: { width: "8%" } }),
                        _c("col", { staticStyle: { width: "11.5%" } }),
                        _c("col", { staticStyle: { width: "10%" } }),
                        _c("col", { staticStyle: { width: "8%" } }),
                        _c("col", { staticStyle: { width: "8%" } }),
                        _c("col", { staticStyle: { width: "5%" } }),
                        _c("col", { staticStyle: { width: "10%" } }),
                        _c("col", { staticStyle: { width: "4%" } }),
                      ])
                    : _vm.regParams.polCtrCd === "PH" ||
                      _vm.regParams.polCtrCd === "VN" ||
                      _vm.regParams.podCtrCd !== "BH"
                    ? _c("colgroup", [
                        _c("col", { staticStyle: { width: "3%" } }),
                        _c("col", { staticStyle: { width: "9%" } }),
                        _c("col", { staticStyle: { width: "11%" } }),
                        _c("col", { staticStyle: { width: "6%" } }),
                        _c("col", { staticStyle: { width: "12%" } }),
                        _c("col", { staticStyle: { width: "8%" } }),
                        _c("col", { staticStyle: { width: "11%" } }),
                        _c("col", { staticStyle: { width: "10%" } }),
                        _c("col", { staticStyle: { width: "7%" } }),
                        _c("col", { staticStyle: { width: "7%" } }),
                        _c("col", { staticStyle: { width: "4%" } }),
                        _c("col", { staticStyle: { width: "5%" } }),
                        _c("col", { staticStyle: { width: "10%" } }),
                        _c("col", { staticStyle: { width: "4%" } }),
                      ])
                    : _vm.lang === "JPN"
                    ? _c("colgroup", [
                        _c("col", { staticStyle: { width: "2%" } }),
                        _c("col", { staticStyle: { width: "9%" } }),
                        _c("col", { staticStyle: { width: "9%" } }),
                        _c("col", { staticStyle: { width: "7%" } }),
                        _c("col", { staticStyle: { width: "12%" } }),
                        _c("col", { staticStyle: { width: "8%" } }),
                        _c("col", { staticStyle: { width: "9%" } }),
                        _c("col", { staticStyle: { width: "8%" } }),
                        _c("col", { staticStyle: { width: "6%" } }),
                        _c("col", { staticStyle: { width: "12%" } }),
                        _c("col", { staticStyle: { width: "7%" } }),
                        _c("col", { staticStyle: { width: "7%" } }),
                        _c("col", { staticStyle: { width: "10%" } }),
                        _c("col", { staticStyle: { width: "3%" } }),
                      ])
                    : _vm._e(),
                  _vm.lang !== "JPN" &&
                  _vm.regParams.polCtrCd !== "PH" &&
                  _vm.regParams.polCtrCd !== "VN" &&
                  _vm.regParams.podCtrCd !== "BH"
                    ? _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("No.")]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.197")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.251")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200_M1.024"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK110")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX050P060.005"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK100")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.252")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Empty")]),
                          _c("th", [_vm._v("Deck")]),
                          _c("th", [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.closeCntr,
                                    expression: "!closeCntr",
                                  },
                                ],
                                staticClass: "tbl_icon plus",
                                attrs: { type: "button" },
                                on: { click: _vm.addBLContainer },
                              },
                              [_vm._v(" plus ")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm.regParams.polCtrCd === "PH" ||
                      _vm.regParams.polCtrCd === "VN" ||
                      _vm.regParams.podCtrCd === "BH"
                    ? _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("No.")]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.197")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.251")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200_M1.024"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK110")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX050P060.005"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK100")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v("CBM")]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.252")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Empty")]),
                          _c("th", [_vm._v("Deck")]),
                          _c("th", [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.closeCntr,
                                    expression: "!closeCntr",
                                  },
                                ],
                                staticClass: "tbl_icon plus",
                                attrs: { type: "button" },
                                on: { click: _vm.addBLContainer },
                              },
                              [_vm._v(" plus ")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm.lang === "JPN"
                    ? _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("No.")]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.197")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.251")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200_M1.024"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Cargo Wgt")]),
                          _c("th", [_vm._v("Tare Wgt")]),
                          _c("th", [_vm._v("Pkg")]),
                          _c("th", [_vm._v("P'kg Type")]),
                          _c("th", [_vm._v("Measure")]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.252")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Deck")]),
                          _c("th", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon plus",
                                on: { click: _vm.addBLContainer },
                              },
                              [_vm._v(" plus ")]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.regParams.containerList, function (vo, idx) {
                    return _c("tbody", { key: "cntr_" + idx }, [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(idx + 1))]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.cntrNo,
                                expression: "vo.cntrNo",
                              },
                            ],
                            attrs: {
                              id: "cntrNo_" + idx,
                              type: "text",
                              maxlength: "11",
                            },
                            domProps: { value: vo.cntrNo },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "cntrNo", $event.target.value)
                                },
                                function ($event) {
                                  return _vm.changeOnlyUpperEng(
                                    $event,
                                    vo,
                                    "cntrNo",
                                    idx
                                  )
                                },
                              ],
                              blur: function ($event) {
                                return _vm.checkCntrNo(idx)
                              },
                            },
                          }),
                        ]),
                        _c(
                          "td",
                          _vm._l(vo.sealList, function (cvo, i) {
                            return _c(
                              "div",
                              {
                                key: "seal_" + i,
                                staticClass: "tbl_form div_seal",
                              },
                              [
                                _c("div", [
                                  _c("span", { staticClass: "dv col_8" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: cvo.sealNo,
                                          expression: "cvo.sealNo",
                                        },
                                      ],
                                      attrs: {
                                        id: "sealNo_" + i,
                                        name: "sealNo_" + idx + "_" + i,
                                        type: "text",
                                      },
                                      domProps: { value: cvo.sealNo },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              cvo,
                                              "sealNo",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.changeOnlyUpperEng(
                                              $event,
                                              cvo,
                                              "sealNo_" + idx,
                                              i
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                  ]),
                                  _c("span", { staticClass: "dv" }, [
                                    i === 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "tbl_icon plus",
                                            on: {
                                              click: function ($event) {
                                                return _vm.addSealNo(idx)
                                              },
                                            },
                                          },
                                          [_vm._v(" plus ")]
                                        )
                                      : _vm._e(),
                                    i !== 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "tbl_icon minus",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeSealNo(idx, i)
                                              },
                                            },
                                          },
                                          [_vm._v(" minus ")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.cntrSzCd,
                                  expression: "vo.cntrSzCd",
                                },
                              ],
                              attrs: { id: "cntrSzCd_" + idx },
                              on: {
                                click: function ($event) {
                                  return _vm.fnNoChange($event, idx)
                                },
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    vo,
                                    "cntrSzCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }),
                              _vm._l(
                                _vm.commonCodes["01031"],
                                function (cvo, i) {
                                  return _c(
                                    "option",
                                    {
                                      key: "cntrSzCd_" + i,
                                      domProps: { value: cvo.cd },
                                    },
                                    [_vm._v(_vm._s(cvo.cdNm))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.cntrTypCd,
                                  expression: "vo.cntrTypCd",
                                },
                              ],
                              class:
                                vo.cntrTypCd === "RF" || vo.cntrTypCd === "RH"
                                  ? "col_85"
                                  : "",
                              attrs: { id: "cntrTypCd_" + idx },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      vo,
                                      "cntrTypCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.changeCntrType(vo, idx)
                                  },
                                ],
                                click: function ($event) {
                                  return _vm.fnNoChange($event, idx)
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }),
                              _vm._l(
                                _vm.commonCodes["01068"],
                                function (cvo, i) {
                                  return _c(
                                    "option",
                                    {
                                      key: "cntrTypCd_" + i,
                                      domProps: { value: cvo.cd },
                                    },
                                    [_vm._v(_vm._s(cvo.cdNm))]
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                          (vo.cntrTypCd === "RF" || vo.cntrTypCd === "RH") &&
                          !_vm.rfDisabled
                            ? _c(
                                "a",
                                {
                                  staticClass: "btn_rf_entry float_right",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cntrTypeInfoPop(
                                        vo.cntrTypCd,
                                        idx
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("msg.CSBL200.258")) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.cgoTypCd,
                                  expression: "vo.cgoTypCd",
                                },
                              ],
                              attrs: { id: "cgoTypCd_" + idx },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      vo,
                                      "cgoTypCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.cgoTypeInfoPop(vo.cgoTypCd, idx)
                                  },
                                ],
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("----------------"),
                              ]),
                              _vm._l(_vm.cgoTypCdList, function (cvo, i) {
                                return _c(
                                  "option",
                                  {
                                    key: "cgoTypCd_" + i,
                                    domProps: { value: cvo.cd },
                                  },
                                  [_vm._v(_vm._s(cvo.cdNm))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.lang !== "JPN",
                                expression: "lang !== 'JPN'",
                              },
                            ],
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.polShtmCd,
                                    expression: "vo.polShtmCd",
                                  },
                                ],
                                staticClass: "inp_space50_2",
                                attrs: {
                                  id: "lcsPolShtmCd_" + idx,
                                  disabled: "",
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        vo,
                                        "polShtmCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.fnChangeCntrTerm("polShtmCd")
                                    },
                                  ],
                                },
                              },
                              _vm._l(vo.polShtmCdList, function (cvo, i) {
                                return _c(
                                  "option",
                                  {
                                    key: "lcsPolShtmCd_" + i,
                                    domProps: { value: cvo.cd },
                                  },
                                  [_vm._v(_vm._s(cvo.cdNm))]
                                )
                              }),
                              0
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.podShtmCd,
                                    expression: "vo.podShtmCd",
                                  },
                                ],
                                staticClass: "inp_space50_2",
                                attrs: {
                                  id: "lcsPodShtmCd_" + idx,
                                  disabled: "",
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        vo,
                                        "podShtmCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                    function ($event) {
                                      return _vm.fnChangeCntrTerm("podShtmCd")
                                    },
                                  ],
                                },
                              },
                              _vm._l(
                                _vm.commonCodes["01033"],
                                function (cvo, i) {
                                  return _c(
                                    "option",
                                    {
                                      key: "lcsPodShtmCd_" + i,
                                      domProps: { value: cvo.cd },
                                    },
                                    [_vm._v(_vm._s(cvo.cdNm))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              staticClass: "col_7",
                              attrs: {
                                id: "wt_" + idx,
                                point: 3,
                                "is-comma": true,
                                maxlength: "14",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkValidationInfo("wt_" + idx)
                                },
                              },
                              model: {
                                value: vo.wt,
                                callback: function ($$v) {
                                  _vm.$set(vo, "wt", $$v)
                                },
                                expression: "vo.wt",
                              },
                            }),
                            _vm._v(" Kgs "),
                          ],
                          1
                        ),
                        _vm.lang === "JPN"
                          ? _c("td", [
                              _c("div", { staticClass: "tbl_form" }, [
                                _c(
                                  "span",
                                  { staticClass: "dv col_7" },
                                  [
                                    _c("e-input-number", {
                                      attrs: {
                                        id: "tareWt_" + idx,
                                        "is-comma": true,
                                        point: 3,
                                        maxlength: "14",
                                      },
                                      model: {
                                        value: vo.tareWt,
                                        callback: function ($$v) {
                                          _vm.$set(vo, "tareWt", $$v)
                                        },
                                        expression: "vo.tareWt",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "dv" }, [
                                  _vm._v(" Kgs "),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "cntrPkgQty_" + idx,
                                "is-comma": true,
                                maxlength: "10",
                              },
                              model: {
                                value: vo.pkgQty,
                                callback: function ($$v) {
                                  _vm.$set(vo, "pkgQty", $$v)
                                },
                                expression: "vo.pkgQty",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.lang === "JPN"
                          ? _c("td", [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.pkgCd,
                                      expression: "vo.pkgCd",
                                    },
                                  ],
                                  attrs: { id: "lcsPkgCd_" + idx },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        vo,
                                        "pkgCd",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(_vm._s(_vm.$t("msg.CSBL220.003"))),
                                  ]),
                                  _vm._l(_vm.pkgCdList, function (cvo, i) {
                                    return _c(
                                      "option",
                                      {
                                        key: "cntrPkgCd_" + i,
                                        domProps: { value: cvo.cd },
                                      },
                                      [_vm._v(_vm._s(cvo.cdNm))]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                        _vm.lang === "JPN" ||
                        _vm.regParams.polCtrCd === "PH" ||
                        _vm.regParams.polCtrCd === "VN" ||
                        _vm.regParams.podCtrCd === "BH"
                          ? _c(
                              "td",
                              [
                                _c("e-input-number", {
                                  attrs: {
                                    id: "meaCbm_" + idx,
                                    "is-comma": true,
                                    point: 4,
                                    maxlength: "8",
                                  },
                                  model: {
                                    value: vo.meaCbm,
                                    callback: function ($$v) {
                                      _vm.$set(vo, "meaCbm", $$v)
                                    },
                                    expression: "vo.meaCbm",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.shprVanYn,
                                expression: "vo.shprVanYn",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "shprVanYn_" + idx,
                              "true-value": "Y",
                              "false-value": "N",
                            },
                            domProps: {
                              checked: Array.isArray(vo.shprVanYn)
                                ? _vm._i(vo.shprVanYn, null) > -1
                                : _vm._q(vo.shprVanYn, "Y"),
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = vo.shprVanYn,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? "Y" : "N"
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          vo,
                                          "shprVanYn",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          vo,
                                          "shprVanYn",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(vo, "shprVanYn", $$c)
                                  }
                                },
                                function ($event) {
                                  return _vm.changeShprVanYn(vo, idx)
                                },
                              ],
                            },
                          }),
                          _c("label", { attrs: { for: "shprVanYn_" + idx } }),
                        ]),
                        _vm.lang !== "JPN"
                          ? _c("td", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: vo.feUnitCd,
                                    expression: "vo.feUnitCd",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  id: "feUnitCd_" + idx,
                                  "true-value": "E",
                                  "false-value": "F",
                                  disabled: vo.shprVanYn === "N",
                                },
                                domProps: {
                                  checked: Array.isArray(vo.feUnitCd)
                                    ? _vm._i(vo.feUnitCd, null) > -1
                                    : _vm._q(vo.feUnitCd, "E"),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = vo.feUnitCd,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "E" : "F"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            vo,
                                            "feUnitCd",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            vo,
                                            "feUnitCd",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(vo, "feUnitCd", $$c)
                                    }
                                  },
                                },
                              }),
                              _c("label", {
                                attrs: { for: "feUnitCd_" + idx },
                              }),
                            ])
                          : _vm._e(),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.deckCd,
                                  expression: "vo.deckCd",
                                },
                              ],
                              staticClass: "deck_select",
                              attrs: { id: "deckCd_" + idx, disabled: "" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    vo,
                                    "deckCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }),
                              _vm._l(_vm.deckCdList, function (cvo, i) {
                                return _c(
                                  "option",
                                  {
                                    key: "deckCd_" + i,
                                    domProps: { value: cvo.cd },
                                  },
                                  [_vm._v(_vm._s(cvo.cdNm))]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.closeCntr,
                                  expression: "!closeCntr",
                                },
                              ],
                              staticClass: "tbl_icon minus",
                              on: {
                                click: function ($event) {
                                  return _vm.removeBLContainer(idx)
                                },
                              },
                            },
                            [_vm._v(" minus ")]
                          ),
                        ]),
                      ]),
                    ])
                  }),
                ],
                2
              ),
              _c("div", { staticClass: "mt10 flex_box" }, [
                _vm.lang === "JPN"
                  ? _c(
                      "a",
                      {
                        staticClass: "button blue sm ml_auto",
                        on: {
                          click: function ($event) {
                            return _vm.fnCommonBtnAction("JPCLP")
                          },
                        },
                      },
                      [_vm._v("CLP Print")]
                    )
                  : _vm._e(),
                _vm.showSHAMFButton()
                  ? _c(
                      "a",
                      {
                        staticClass: "button blue sm ml_auto",
                        on: {
                          click: function ($event) {
                            return _vm.fnCommonBtnAction("SHAMF")
                          },
                        },
                      },
                      [_vm._v("SHA M/F Data")]
                    )
                  : _vm._e(),
              ]),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v("B/L Body Detail"),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(16),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Say")]),
                    _c(
                      "td",
                      { staticStyle: { display: "flex" } },
                      [
                        _c("e-trans-string-check-cnee-input", {
                          staticClass: "col_8",
                          attrs: {
                            id: "blPrtSntcCont",
                            maxlength: "100",
                            "is-upper-case": true,
                          },
                          on: { blur: _vm.fnSayTab },
                          model: {
                            value: _vm.regParams.blPrtSntcCont,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "blPrtSntcCont", $$v)
                            },
                            expression: "regParams.blPrtSntcCont",
                          },
                        }),
                        _c(
                          "a",
                          {
                            staticClass: "button sm ml2",
                            on: {
                              click: function ($event) {
                                return _vm.fnSaySet()
                              },
                            },
                          },
                          [_vm._v("Auto Say")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "table",
                { staticClass: "tbl_col mt10 tbl_mark" },
                [
                  _vm._m(17),
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "text_left" }, [
                        _vm._v(" Mark & No "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.addrStr,
                                expression: "addrStr",
                              },
                            ],
                            staticClass: "wid150 ml10",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.addrStr = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.searchAgentAddr,
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("Agent address"),
                            ]),
                            _vm.regParams.porPlcCd === _vm.regParams.polPortCd
                              ? _c(
                                  "option",
                                  {
                                    domProps: {
                                      value:
                                        _vm.regParams.porCtrCd +
                                        ":" +
                                        _vm.regParams.porPlcCd,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.regParams.porPlcNm))]
                                )
                              : _vm._e(),
                            _vm.regParams.porPlcCd !== _vm.regParams.polPortCd
                              ? _c(
                                  "option",
                                  {
                                    domProps: {
                                      value:
                                        _vm.regParams.porCtrCd +
                                        ":" +
                                        _vm.regParams.porPlcCd,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.regParams.porPlcNm))]
                                )
                              : _vm._e(),
                            _vm.regParams.porPlcCd !== _vm.regParams.polPortCd
                              ? _c(
                                  "option",
                                  {
                                    domProps: {
                                      value:
                                        _vm.regParams.polCtrCd +
                                        ":" +
                                        _vm.regParams.polPortCd,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.regParams.polPortNm))]
                                )
                              : _vm._e(),
                            _vm.regParams.podPortCd === _vm.regParams.dlyPlcCd
                              ? _c(
                                  "option",
                                  {
                                    domProps: {
                                      value:
                                        _vm.regParams.podCtrCd +
                                        ":" +
                                        _vm.regParams.podPortCd,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.regParams.podPortNm))]
                                )
                              : _vm._e(),
                            _vm.regParams.podPortCd !== _vm.regParams.dlyPlcCd
                              ? _c(
                                  "option",
                                  {
                                    domProps: {
                                      value:
                                        _vm.regParams.podCtrCd +
                                        ":" +
                                        _vm.regParams.podPortCd,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.regParams.podPortNm))]
                                )
                              : _vm._e(),
                            _vm.regParams.podPortCd !== _vm.regParams.dlyPlcCd
                              ? _c(
                                  "option",
                                  {
                                    domProps: {
                                      value:
                                        _vm.regParams.dlyCtrCd +
                                        ":" +
                                        _vm.regParams.dlyPlcCd,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.regParams.dlyPlcNm))]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon search ml2",
                            on: { click: _vm.searchAgentAddr },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.NEWB010P020.006")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "select",
                          {
                            staticClass: "wid200 ml20 bg_white float_right",
                            on: {
                              change: function ($event) {
                                $event.preventDefault()
                                return _vm.changeTopMarkDscr($event, "mrk")
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBL200_M1.019"))),
                            ]),
                            _vm._l(_vm.topMarkList, function (vo, idx) {
                              return _c(
                                "option",
                                {
                                  key: "topMark_" + idx,
                                  domProps: { value: vo.mrk },
                                },
                                [_vm._v(_vm._s(vo.mrkNm))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("th", { staticClass: "text_left" }, [
                        _vm._v(" Description "),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.regParams.porCtrCd === "VN" &&
                                  _vm.regParams.podPortCd === "DMM",
                                expression:
                                  "regParams.porCtrCd === 'VN' && regParams.podPortCd === 'DMM'",
                              },
                            ],
                            staticClass: "button sm ml2",
                            on: { click: _vm.addRequSentenceDescForVN },
                          },
                          [_vm._v(" REQUIRED SENTENCE ")]
                        ),
                        _c(
                          "button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.regParams.porCtrCd === "VN" &&
                                  _vm.regParams.podPortCd === "DMM",
                                expression:
                                  "regParams.porCtrCd === 'VN' && regParams.podPortCd === 'DMM'",
                              },
                            ],
                            staticClass: "button sm ml2",
                            on: { click: _vm.addTempClauseDescForVN },
                          },
                          [_vm._v(" Temp Clause ")]
                        ),
                        _c(
                          "select",
                          {
                            staticClass: "wid200 ml20 bg_white float_right",
                            on: {
                              change: function ($event) {
                                $event.preventDefault()
                                return _vm.changeTopMarkDscr($event, "dscr")
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBL200_M1.019"))),
                            ]),
                            _vm._l(_vm.topDscrList, function (vo, idx) {
                              return _c(
                                "option",
                                {
                                  key: "topDscr_" + idx,
                                  domProps: { value: vo.dscr },
                                },
                                [_vm._v(_vm._s(vo.mrkNm))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            on: { click: _vm.addMarkDesc },
                          },
                          [_vm._v(" plus ")]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._l(_vm.regParams.markDescList, function (vo, idx) {
                    return _c("tbody", { key: "markDesc_" + idx }, [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "td_mrk",
                            style:
                              idx > 0
                                ? ""
                                : _vm.regParams.polShtmCd === "02"
                                ? "padding-top: 32px"
                                : "padding-top: 18px",
                          },
                          [
                            _c("ETextarea2", {
                              attrs: {
                                id: "mrk_" + idx,
                                "max-line": 18,
                                "column-length": 25,
                                "is-upper": true,
                                "padding-right": 223,
                              },
                              model: {
                                value: vo.mrk,
                                callback: function ($$v) {
                                  _vm.$set(vo, "mrk", $$v)
                                },
                                expression: "vo.mrk",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "td_desc" },
                          [
                            idx === 0
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: vo.dscrSub,
                                      expression: "vo.dscrSub",
                                    },
                                  ],
                                  staticClass: "txt_desc1",
                                  attrs: {
                                    id: "dscrSub",
                                    readonly: "",
                                    rows: "3",
                                  },
                                  domProps: { value: vo.dscrSub },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        vo,
                                        "dscrSub",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("ETextarea2", {
                              staticClass: "txt_dscr",
                              attrs: {
                                id: "dscr_" + idx,
                                "max-line":
                                  idx > 0
                                    ? 18
                                    : _vm.regParams.polShtmCd === "02"
                                    ? 17
                                    : 16,
                                "column-length": 45,
                                "is-upper": true,
                                "padding-right": 383,
                              },
                              model: {
                                value: vo.dscr,
                                callback: function ($$v) {
                                  _vm.$set(vo, "dscr", $$v)
                                },
                                expression: "vo.dscr",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("td", [
                          idx !== 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeMarkDesc(idx)
                                    },
                                  },
                                },
                                [_vm._v(" minus ")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ])
                  }),
                ],
                2
              ),
              _c("h2", { staticClass: "content_title" }, [
                _vm._v("Attached Files"),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(18),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CMBA100.00140")))]),
                    _c("td", { staticClass: "text_left" }, [
                      _c("span", [
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.uploadFile,
                                expression: "uploadFile",
                              },
                            ],
                            class:
                              _vm.uploadFileList.length > 0
                                ? "blue button sm ml2"
                                : "button sm ml2",
                            on: {
                              click: function ($event) {
                                return _vm.fnAttachPop()
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.022")))]
                        ),
                      ]),
                      _c("span", { class: _vm.uploadFile ? "ml50" : "" }, [
                        _c(
                          "a",
                          { class: _vm.showUploadClass(_vm.regParams.riderYn) },
                          [_vm._v("Rider")]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.usrCtrCd === "KR",
                                expression: "usrCtrCd === 'KR'",
                              },
                            ],
                            class: _vm.showUploadClass(_vm.regParams.emanYn),
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ARAI100_M1.003")))]
                        ),
                        _c(
                          "a",
                          {
                            class: _vm.showUploadClass(_vm.regParams.dgcertiYn),
                          },
                          [_vm._v("DG-Certi")]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.usrCtrCd === "KR",
                                expression: "usrCtrCd === 'KR'",
                              },
                            ],
                            class: _vm.showUploadClass(
                              _vm.regParams.vgmcertiYn
                            ),
                          },
                          [_vm._v("VGM-Certi")]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.usrCtrCd === "KR",
                                expression: "usrCtrCd === 'KR'",
                              },
                            ],
                            class: _vm.showUploadClass(_vm.regParams.etcYn),
                            attrs: { id: "etcYn" },
                          },
                          [_vm._v("ETC")]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.regParams.dlyCtrCd === "IR",
                                expression: "regParams.dlyCtrCd === 'IR'",
                              },
                            ],
                            class: _vm.showUploadClass(_vm.regParams.loiYn),
                          },
                          [_vm._v("LOI Upload")]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.regParams.dlyCtrCd === "IR",
                                expression: "regParams.dlyCtrCd === 'IR'",
                              },
                            ],
                            class: _vm.showUploadClass(_vm.regParams.confYn),
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.CSBL200.267")))]
                        ),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.regParams.dlyCtrCd === "IR",
                                expression: "regParams.dlyCtrCd === 'IR'",
                              },
                            ],
                            class: _vm.showUploadClass(_vm.regParams.certYn),
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.CSBL200.268")))]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("table", { staticClass: "tbl_row mt10" }, [
                _vm._m(19),
                _c("tbody", [
                  _vm.lang === "JPN"
                    ? _c("tr", [
                        _c("th", [_vm._v("No. of B/L")]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: { id: "oblPrtScnt", maxlength: "1" },
                              model: {
                                value: _vm.regParams.oblPrtScnt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.regParams, "oblPrtScnt", $$v)
                                },
                                expression: "regParams.oblPrtScnt",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("th", [_vm._v("B/L Type")]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.regParams.blCatCd,
                                  expression: "regParams.blCatCd",
                                },
                              ],
                              attrs: { id: "blCatCd" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.regParams,
                                    "blCatCd",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.$t("msg.CSBL200.075"))),
                              ]),
                              _c("option", { attrs: { value: "01" } }, [
                                _vm._v("O.B/L"),
                              ]),
                              _c("option", { attrs: { value: "02" } }, [
                                _vm._v("SeaWay Bill"),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("th", [
                      _vm._v("Shipment No. "),
                      _c("span", { staticClass: "txt" }, [
                        _vm._v("(" + _vm._s(_vm.$t("msg.CSBK100.062")) + ")"),
                      ]),
                    ]),
                    _c(
                      "td",
                      { attrs: { colspan: "3" } },
                      [
                        _c("e-trans-string-check-input", {
                          staticClass: "col_8",
                          attrs: {
                            id: "shpmNo",
                            "is-upper-case": true,
                            maxlength: "10",
                          },
                          model: {
                            value: _vm.regParams.shpmNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.regParams, "shpmNo", $$v)
                            },
                            expression: "regParams.shpmNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.regParams.dlyCtrCd === "IR",
                      expression: "regParams.dlyCtrCd === 'IR'",
                    },
                  ],
                  staticClass: "txt_desc",
                },
                [_vm._v(" " + _vm._s(_vm.$t("msg.CSBL200.253")) + " ")]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.regParams.jpPodCount > 0,
                      expression: "regParams.jpPodCount > 0",
                    },
                  ],
                  staticClass: "txt_desc",
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200.254"))),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.$t("msg.CSBL200.254_1")) + " "),
                ]
              ),
              _c("h2", { staticClass: "content_title" }, [_vm._v("Request")]),
              _c("table", { staticClass: "tbl_col" }, [
                _vm._m(20),
                _vm._m(21),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.regParams.reqList, function (vo, idx) {
                      return _c("tr", { key: "reqInfo_" + idx }, [
                        _c("td", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.rmk,
                                expression: "vo.rmk",
                              },
                            ],
                            attrs: { readonly: "" },
                            domProps: { value: vo.rmk },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "rmk", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.rspsRmk,
                                expression: "vo.rspsRmk",
                              },
                            ],
                            attrs: { readonly: "" },
                            domProps: { value: vo.rspsRmk },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "rspsRmk", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ])
                    }),
                    _c("tr", [
                      _c("td", [
                        _c("textarea", {
                          attrs: { id: "rmk" },
                          domProps: { value: _vm.regParams.rmk },
                          on: { keyup: (e) => _vm.handleChange(e) },
                        }),
                      ]),
                      _c("td", [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.rspsRmk,
                              expression: "regParams.rspsRmk",
                            },
                          ],
                          attrs: { readonly: "" },
                          domProps: { value: _vm.regParams.rspsRmk },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.regParams,
                                "rspsRmk",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]),
            ],
            2
          ),
          _c("div", { staticClass: "mt10 flex_box" }, [
            _c(
              "a",
              {
                staticClass: "button lg mr5",
                on: {
                  click: function ($event) {
                    return _vm.fnCommonBtnAction("GOLIST")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MAIN100.213")))]
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.vueMode,
                    expression: "vueMode",
                  },
                ],
                staticClass: "button lg mr5",
                on: {
                  click: function ($event) {
                    return _vm.templateSavePop()
                  },
                },
              },
              [_vm._v("Template Save")]
            ),
            _c("span", { staticClass: "ml_auto" }, [
              _vm.lang === "KOR"
                ? _c(
                    "a",
                    {
                      staticClass: "button blue lg ml5",
                      on: {
                        click: function ($event) {
                          return _vm.fnCommonBtnAction("ERROR_CHECK")
                        },
                      },
                    },
                    [_vm._v("B/L Error Check")]
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "button blue lg ml5",
                  on: {
                    click: function ($event) {
                      return _vm.fnCommonBtnAction("VGMPOP_OPEN")
                    },
                  },
                },
                [_vm._v("Input VGM")]
              ),
              _c(
                "a",
                {
                  staticClass: "button blue lg ml5",
                  on: {
                    click: function ($event) {
                      return _vm.openBlCopyPop()
                    },
                  },
                },
                [_vm._v("Preview")]
              ),
              _vm.showSHAMFButton()
                ? _c(
                    "a",
                    {
                      staticClass: "button blue lg ml5",
                      on: {
                        click: function ($event) {
                          return _vm.fnCommonBtnAction("SHAMF")
                        },
                      },
                    },
                    [_vm._v("SHA M/F Data")]
                  )
                : _vm._e(),
              _vm.regParams.temcount !== "0"
                ? _c(
                    "a",
                    {
                      staticClass: "button blue lg ml5",
                      on: {
                        click: function ($event) {
                          return _vm.fnImCaPending()
                        },
                      },
                    },
                    [_vm._v("C/A Preview")]
                  )
                : _vm._e(),
              _vm.regParams.temcount === "0"
                ? _c(
                    "a",
                    {
                      staticClass: "button blue lg ml5",
                      on: {
                        click: function ($event) {
                          return _vm.updateBL()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.CSBK100.178")))]
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: {
                  close: _vm.closePopup,
                  selectFunc: _vm.selectFunc,
                  child: _vm.getChildData,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.commonBLComponent
        ? _c(_vm.commonBLComponent, {
            ref: "commonBL",
            tag: "component",
            attrs: { "parent-info": _vm.commonBLParams },
            on: { callback: _vm.commonBLCallback },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "120px" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "120px" } }),
      _c("col", { staticStyle: { width: "180px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box mt5" }, [
      _c("h2", { staticClass: "content_title" }, [_vm._v("Job(document) Pic")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "50%" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "170px" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "170px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "36%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "22%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "order" } }, [
      _c("span", [_vm._v("To Order")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "170px" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "180px" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "150px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px;" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px;" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px;" } }),
      _c("col"),
      _c("col", { attrs: { width: "170px;" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col"),
      _c("col", { attrs: { width: "210px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px;" } }),
      _c("col"),
      _c("col", { attrs: { width: "170px;" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "170px" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "180px" } }),
      _c("col"),
      _c("col", { staticStyle: { width: "150px" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { staticStyle: { width: "170px" } })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "18%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "18%" } }),
      _c("col", { staticStyle: { width: "18%" } }),
      _c("col", { staticStyle: { width: "14%" } }),
      _c("col", { staticStyle: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "5%" } }),
      _c("col", { staticStyle: { width: "19%" } }),
      _c("col", { staticStyle: { width: "13%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "170px" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "41%" } }),
      _c("col", { attrs: { width: "56%" } }),
      _c("col", { attrs: { width: "3%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "170px" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
      _c("col", { attrs: { width: "170px" } }),
      _c("col", { attrs: { width: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "50%" } }),
      _c("col", { staticStyle: { width: "50%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("Request")]), _c("th", [_vm._v("Answer")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }