var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1300px", height: "850px", "margin-top": "-50px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.013"))),
        ]),
        _c(
          "div",
          { staticClass: "content_box mt20", staticStyle: { height: "800px" } },
          [
            _c("div", { staticClass: "mt10" }, [
              _c("p", { staticClass: "noti_questions" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.MYIN050T040.014")) + " "),
              ]),
              _c("div", { staticClass: "mt10" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.regData.useYn,
                      expression: "regData.useYn",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    id: "useYn_rdo1",
                    name: "useYn",
                    value: "Y",
                  },
                  domProps: { checked: _vm._q(_vm.regData.useYn, "Y") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.regData, "useYn", "Y")
                    },
                  },
                }),
                _c("label", { attrs: { for: "useYn_rdo1" } }, [
                  _vm._v(_vm._s(_vm.$t("msg.SETT060G010.020"))),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.regData.useYn,
                      expression: "regData.useYn",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    id: "useYn_rdo2",
                    name: "useYn",
                    value: "N",
                  },
                  domProps: { checked: _vm._q(_vm.regData.useYn, "N") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.regData, "useYn", "N")
                    },
                  },
                }),
                _c(
                  "label",
                  { staticClass: "ml30", attrs: { for: "useYn_rdo2" } },
                  [_vm._v(_vm._s(_vm.$t("msg.SETT060G010.021")))]
                ),
              ]),
              _c("p", { staticClass: "txt_desc mt10" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("msg.MYIN050T040.015")))]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "mt40", staticStyle: { height: "150px" } },
              [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regData.vslDlayKndCd,
                        expression: "regData.vslDlayKndCd",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      id: "vslDlayKndCd_rdo1",
                      name: "vslDlayKndCd",
                      value: "21",
                    },
                    domProps: {
                      checked: _vm._q(_vm.regData.vslDlayKndCd, "21"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.regData, "vslDlayKndCd", "21")
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticStyle: {
                        color: "blue",
                        "font-size": "18px",
                        "font-weight": "bolder",
                      },
                      attrs: { for: "vslDlayKndCd_rdo1" },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.MYIN050T040.016")))]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "ml25 mt40",
                    staticStyle: { display: "flex" },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          color: "blue",
                          "font-size": "15px",
                          "font-weight": "bolder",
                          width: "100px",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.MYIN050T040.018")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          width: "250px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex", width: "1000px" } },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.frequency,
                                  expression: "frequency",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                id: "frequency_rdo1",
                                name: "frequency",
                                value: "1",
                              },
                              domProps: { checked: _vm._q(_vm.frequency, "1") },
                              on: {
                                change: function ($event) {
                                  _vm.frequency = "1"
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticStyle: { height: "25px", width: "120px" },
                                attrs: { for: "frequency_rdo1" },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.MYIN050T040.019")))]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "flex", width: "1000px" } },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.frequency,
                                  expression: "frequency",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                id: "frequency_rdo2",
                                name: "frequency",
                                value: "2",
                              },
                              domProps: { checked: _vm._q(_vm.frequency, "2") },
                              on: {
                                change: function ($event) {
                                  _vm.frequency = "2"
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticStyle: { height: "25px", width: "120px" },
                                attrs: { for: "frequency_rdo2" },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.MYIN050T040.020")))]
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.weeklyDay,
                                    expression: "weeklyDay",
                                  },
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.frequency === "2",
                                    expression: "frequency === '2'",
                                  },
                                ],
                                staticStyle: {
                                  width: "110px",
                                  "margin-left": "50px",
                                },
                                attrs: { name: "weeklyDay", id: "weeklyDay" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.weeklyDay = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(_vm.selectDayOptions, function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.value,
                                    domProps: { value: option.value },
                                  },
                                  [_vm._v(" " + _vm._s(option.label) + " ")]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          { staticStyle: { display: "flex", width: "1000px" } },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.frequency,
                                  expression: "frequency",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                id: "frequency_rdo3",
                                name: "frequency",
                                value: "3",
                              },
                              domProps: { checked: _vm._q(_vm.frequency, "3") },
                              on: {
                                change: function ($event) {
                                  _vm.frequency = "3"
                                },
                              },
                            }),
                            _c(
                              "label",
                              {
                                staticStyle: { height: "25px", width: "150px" },
                                attrs: { for: "frequency_rdo3" },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.MYIN050T040.021")))]
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.enterDirectDay,
                                    expression: "enterDirectDay",
                                  },
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.frequency === "3",
                                    expression: "frequency === '3'",
                                  },
                                ],
                                staticStyle: {
                                  width: "110px",
                                  "margin-left": "20px",
                                },
                                attrs: {
                                  name: "enterDirectDay",
                                  id: "enterDirectDay",
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.enterDirectDay = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.addArrEnterDirectDay($event)
                                    },
                                  ],
                                },
                              },
                              [
                                _c("option", { attrs: { value: "-" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("msg.ONIM070P020.002")) +
                                      " "
                                  ),
                                ]),
                                _vm._l(_vm.selectDayOptions, function (option) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.value,
                                      domProps: { value: option.value },
                                    },
                                    [_vm._v(" " + _vm._s(option.label) + " ")]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mt10",
                            staticStyle: {
                              display: "flex",
                              "margin-left": "10px",
                              width: "1000px",
                            },
                          },
                          [
                            _c(
                              "ul",
                              _vm._l(_vm.arrEnterDirectDay, function (day) {
                                return _c(
                                  "li",
                                  {
                                    key: day,
                                    staticStyle: {
                                      display: "inline",
                                      "margin-right": "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button blue sh",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.deleteArrEnterDirectDay(
                                              day
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectDayOptions.find(
                                              (item) => item.value === day
                                            )?.label
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "mt40", staticStyle: { height: "210px" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.regData.vslDlayKndCd,
                      expression: "regData.vslDlayKndCd",
                    },
                  ],
                  attrs: {
                    type: "radio",
                    id: "vslDlayKndCd_rdo2",
                    name: "vslDlayKndCd",
                    value: "01",
                  },
                  domProps: { checked: _vm._q(_vm.regData.vslDlayKndCd, "01") },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.regData, "vslDlayKndCd", "01")
                    },
                  },
                }),
                _c(
                  "label",
                  {
                    staticStyle: {
                      color: "blue",
                      "font-size": "18px",
                      "font-weight": "bolder",
                    },
                    attrs: { for: "vslDlayKndCd_rdo2" },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.MYIN050T040.017")))]
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml25 mt40",
                    staticStyle: { display: "flex" },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          color: "blue",
                          "font-size": "15px",
                          "font-weight": "bolder",
                          width: "100px",
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.MYIN050T040.022")) + " "
                        ),
                      ]
                    ),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.byChangeTime,
                            expression: "byChangeTime",
                          },
                        ],
                        staticStyle: { width: "100px" },
                        attrs: { name: "byChangeTime", id: "byChangeTime" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.byChangeTime = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.MYIN050T040.023")) + " "
                          ),
                        ]),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.MYIN050T040.024")) + " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm.byChangeTime === "1"
                  ? _c(
                      "div",
                      {
                        staticClass: "mt10",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "margin-left": "115px",
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regData.dlayDrtm,
                              expression: "regData.dlayDrtm",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "dlayDrtm1",
                            name: "dlayDrtm",
                            value: "24",
                          },
                          domProps: {
                            checked: _vm._q(_vm.regData.dlayDrtm, "24"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.regData, "dlayDrtm", "24")
                            },
                          },
                        }),
                        _c(
                          "label",
                          { staticClass: "ml10", attrs: { for: "dlayDrtm1" } },
                          [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.025"))),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regData.dlayDrtm,
                              expression: "regData.dlayDrtm",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "dlayDrtm2",
                            name: "dlayDrtm",
                            value: "48",
                          },
                          domProps: {
                            checked: _vm._q(_vm.regData.dlayDrtm, "48"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.regData, "dlayDrtm", "48")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dlayDrtm2" } }, [
                          _c("span"),
                          _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.026"))),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regData.dlayDrtm,
                              expression: "regData.dlayDrtm",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "dlayDrtm3",
                            name: "dlayDrtm",
                            value: "72",
                          },
                          domProps: {
                            checked: _vm._q(_vm.regData.dlayDrtm, "72"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.regData, "dlayDrtm", "72")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dlayDrtm3" } }, [
                          _c("span"),
                          _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.027"))),
                        ]),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "mt10",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "margin-left": "115px",
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regData.dlayDrtm,
                              expression: "regData.dlayDrtm",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "dlayDrtm4",
                            name: "dlayDrtm",
                            value: "6",
                          },
                          domProps: {
                            checked: _vm._q(_vm.regData.dlayDrtm, "6"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.regData, "dlayDrtm", "6")
                            },
                          },
                        }),
                        _c(
                          "label",
                          { staticClass: "ml10", attrs: { for: "dlayDrtm4" } },
                          [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.028"))),
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regData.dlayDrtm,
                              expression: "regData.dlayDrtm",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "dlayDrtm5",
                            name: "dlayDrtm",
                            value: "12",
                          },
                          domProps: {
                            checked: _vm._q(_vm.regData.dlayDrtm, "12"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.regData, "dlayDrtm", "12")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dlayDrtm5" } }, [
                          _c("span"),
                          _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.029"))),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regData.dlayDrtm,
                              expression: "regData.dlayDrtm",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "dlayDrtm6",
                            name: "dlayDrtm",
                            value: "24",
                          },
                          domProps: {
                            checked: _vm._q(_vm.regData.dlayDrtm, "24"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.regData, "dlayDrtm", "24")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dlayDrtm6" } }, [
                          _c("span"),
                          _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.030"))),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regData.dlayDrtm,
                              expression: "regData.dlayDrtm",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "dlayDrtm7",
                            name: "dlayDrtm",
                            value: "48",
                          },
                          domProps: {
                            checked: _vm._q(_vm.regData.dlayDrtm, "48"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.regData, "dlayDrtm", "48")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dlayDrtm7" } }, [
                          _c("span"),
                          _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.031"))),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regData.dlayDrtm,
                              expression: "regData.dlayDrtm",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            id: "dlayDrtm8",
                            name: "dlayDrtm",
                            value: "72",
                          },
                          domProps: {
                            checked: _vm._q(_vm.regData.dlayDrtm, "72"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.regData, "dlayDrtm", "72")
                            },
                          },
                        }),
                        _c("label", { attrs: { for: "dlayDrtm8" } }, [
                          _c("span"),
                          _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.032"))),
                        ]),
                      ]
                    ),
              ]
            ),
            _c("p", { staticClass: "txt_desc mt10 multiline-editor" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("msg.MYIN050T040.040")))]),
            ]),
            _c(
              "div",
              { staticClass: "mt40", staticStyle: { height: "80px" } },
              [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.notifyAll,
                        expression: "notifyAll",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "notifyAll",
                      name: "notifyAll",
                      "true-value": "Y",
                      "false-value": "N",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.notifyAll)
                        ? _vm._i(_vm.notifyAll, null) > -1
                        : _vm._q(_vm.notifyAll, "Y"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.notifyAll,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.notifyAll = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.notifyAll = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.notifyAll = $$c
                        }
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "notifyAll" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.045"))),
                  ]),
                ]),
                _c("div", { staticClass: "mt10" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regData.smsYn,
                        expression: "regData.smsYn",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "smsYn",
                      name: "smsYn",
                      "true-value": "Y",
                      "false-value": "N",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.regData.smsYn)
                        ? _vm._i(_vm.regData.smsYn, null) > -1
                        : _vm._q(_vm.regData.smsYn, "Y"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.regData.smsYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? "Y" : "N"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.regData,
                                  "smsYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.regData,
                                  "smsYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.regData, "smsYn", $$c)
                          }
                        },
                        function ($event) {
                          return _vm.allCheck("notiMedium")
                        },
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "smsYn" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.041"))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regData.emlYn,
                        expression: "regData.emlYn",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "emlYn",
                      name: "emlYn",
                      "true-value": "Y",
                      "false-value": "N",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.regData.emlYn)
                        ? _vm._i(_vm.regData.emlYn, null) > -1
                        : _vm._q(_vm.regData.emlYn, "Y"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          var $$a = _vm.regData.emlYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? "Y" : "N"
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.regData,
                                  "emlYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.regData,
                                  "emlYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.regData, "emlYn", $$c)
                          }
                        },
                        function ($event) {
                          return _vm.allCheck("notiMedium")
                        },
                      ],
                    },
                  }),
                  _c("label", { attrs: { for: "emlYn" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.042"))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regData.mobPushYn,
                        expression: "regData.mobPushYn",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "mobPushYn",
                      name: "mobPushYn",
                      "true-value": "Y",
                      "false-value": "N",
                      disabled: "",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.regData.mobPushYn)
                        ? _vm._i(_vm.regData.mobPushYn, null) > -1
                        : _vm._q(_vm.regData.mobPushYn, "Y"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.regData.mobPushYn,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.regData,
                                "mobPushYn",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.regData,
                                "mobPushYn",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.regData, "mobPushYn", $$c)
                        }
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "mobPushYn" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.043"))),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regData.kakaoNotiYn,
                        expression: "regData.kakaoNotiYn",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "kakaoNotiYn",
                      name: "kakaoNotiYn",
                      "true-value": "Y",
                      "false-value": "N",
                      disabled: "",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.regData.kakaoNotiYn)
                        ? _vm._i(_vm.regData.kakaoNotiYn, null) > -1
                        : _vm._q(_vm.regData.kakaoNotiYn, "Y"),
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.regData.kakaoNotiYn,
                          $$el = $event.target,
                          $$c = $$el.checked ? "Y" : "N"
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.regData,
                                "kakaoNotiYn",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.regData,
                                "kakaoNotiYn",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.regData, "kakaoNotiYn", $$c)
                        }
                      },
                    },
                  }),
                  _c("label", { attrs: { for: "kakaoNotiYn" } }, [
                    _c("span"),
                    _vm._v(_vm._s(_vm.$t("msg.MYIN050T040.044"))),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "10px", "margin-left": "45%" } },
              [
                _c("span", [
                  _c(
                    "a",
                    {
                      staticClass: "button blue lg",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnSave()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONIM050G020.012")))]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button gray lg ml5",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONIM050G020.013")))]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }