import Send from '../../settle.client.js'

export default {
  getInvoiceList (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice',
      url: '/settle/invoice',
      method: 'get',
      params: param
    })
  },
  getBlstatusList (param) {
    return Send({
      url: '/settle/invoice/bl-status',
      method: 'get',
      params: {
        blNo: param
      }
    })
  },
  getRemarkList (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice/rmk',
      url: '/settle/invoice/rmk',
      method: 'get',
      params: param
    })
  },
  getPickupInfo (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice/pickup',
      url: '/settle/invoice/pickup',
      method: 'get',
      params: param
    })
  },
  getFreightInfo (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice/reqpop',
      url: '/settle/invoice/reqpop',
      method: 'get',
      params: param
    })
  },
  insertReq (data) {
    return new Promise((resolve, reject) => {
      Send({
        //url: 'http://localhost:9008/settle/invoice/reqsave',
        url: 'settle/invoice/reqsave',
        method: 'POST',
        data: data
      }).then(res => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getExrtInfo (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice/exrt',
      url: '/settle/invoice/exrt',
      method: 'get',
      params: param
    })
  },
  getLimitInfo (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice-limit',
      url: '/settle/invoice-limit',
      method: 'get',
      params: param
    })
  },
  getCreditLimitInfo (blNo) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice-limit',
      url: `/settle/invoice-limit/${blNo}/credit`,
      method: 'get'
    })
  },
  getPaymentList (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice-inquiry/payment',
      url: '/settle/invoice-inquiry/payment',
      method: 'get',
      params: param
    })
  },
  getPaymentList2 (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice-inquiry/payment',
      url: '/settle/invoice-inquiry/payment2',
      method: 'get',
      params: param
    })
  },
  getEmailList () {
    return Send({
      //url: 'http://localhost:9008/settle/invoice-inquiry/email',
      url: '/settle/invoice-inquiry/email',
      method: 'get'
    })
  },
  sendEmail (data) {
    return new Promise((resolve, reject) => {
      Send({
        //url: 'http://localhost:9008/settle/invoice-inquiry/send',
        url: 'settle/invoice-inquiry/send',
        method: 'POST',
        data: data
      }).then(res => {
        resolve(res)
      }).catch((err) => {
        reject(err)
      })
    })
  },
  getDetailList (param) {
    return Send({
      //url: 'http://localhost:9008/settle/invoice-inquiry/detail',
      url: '/settle/invoice-inquiry/detail',
      method: 'get',
      params: param
    })
  },
  getTaxTemplateList () {
    return Send({
      url: '/settle/invoice/tax/template',
      method: 'get',
      params: {}
    })
  },
  insertTaxTemplate (data) {
    return Send({
      url: '/settle/invoice/tax/template',
      method: 'post',
      data: data
    })
  },
  updateTaxTemplate (data) {
    return Send({
      url: '/settle/invoice/tax/template',
      method: 'put',
      data: data
    })
  },
  deleteTaxTemplate (data) {
    return Send({
      url: '/settle/invoice/tax/template',
      method: 'delete',
      data: data
    })
  },
  getVrAccount () {
    return Send({
      url: '/settle/invoice/vr-account',
      method: 'get',
      params: {}
    })
  },
  getBankInfo () {
    return Send({
      url: '/settle/invoice/bank-info',
      method: 'get',
      params: {}
    })
  },
  getPayCur () {
    return Send({
      url: '/settle/invoice/paycur',
      method: 'get',
      params: {}
    })
  },
  getBank (params) {
    return Send({
      url: '/settle/invoice/bank',
      method: 'get',
      params: params
    })
  },
  getAccNo (params) {
    return Send({
      url: '/settle/invoice/accno',
      method: 'get',
      params: params
    })
  },
  pay (data) {
    return Send({
      url: '/settle/invoice/pay',
      method: 'post',
      data: data
    })
  },
  accPay (data) {
    return Send({
      url: '/settle/invoice/acc-pay',
      method: 'post',
      data: data
    })
  },
  erpPay (data) {
    return Send({
      url: '/settle/invoice/erp-pay',
      method: 'post',
      data: data
    })
  },
  bankingInfoAndDoRequest (data) {
    return Send({
      url: '/settle/invoice/banking-info-and-do',
      method: 'post',
      data: data
    })
  },
  bankingInfoAndBlRequest (data) {
    return Send({
      url: '/settle/invoice/banking-info-and-bl',
      method: 'post',
      data: data
    })
  },
  bankingInfoAndTax (data) {
    return Send({
      url: '/settle/invoice/banking-info-and-tax',
      method: 'post',
      data: data
    })
  },
  tenUnderTransfer (data) {
    return Send({
      url: '/settle/invoice/ten-under-transfer',
      method: 'post',
      data: data
    })
  },
  getBankLoginInfo () {
    return Send({
      url: '/settle/invoice/bank-login-info',
      method: 'get',
      params: {}
    })
  },
  cancelPay (params) {
    return Send({
      url: '/settle/invoice/cancel-pay',
      method: 'put',
      params: params
    })
  },
  tenUnderSel (data) {
    return Send({
      url: '/settle/invoice/ten-under-sel',
      method: 'post',
      data: data
    })
  },
  createDemDetInvoice (data) {
    return Send({
      url: '/settle/create-invoice',
      method: 'post',
      data: data
    })
  },
  getVirtaulAccountInfo (invIssNo) {
    return Send({
      url: '/settle/create-invoice/virtualAccountInfo/' + invIssNo,
      method: 'get'
    })
  },
  setVirtualAccount (invIssNo) {
    return Send({
      url: '/settle/create-invoice/setVirtualAccount/' + invIssNo,
      method: 'get'
    })
  },
  invoicePublish (data) {
    //Invoice 발행
    return Send({
      url: '/settle/invoice/bl',
      method: 'post',
      data: data
    })
  },
  getUserDmd (params) {
    //화주구분
    return Send({
      url: '/settle/invoice/dmd',
      method: 'get',
      params: params
    })
  },
  getRejectCont (blNo) {
    return Send({
      url: '/settle/invoice/reject-cont',
      method: 'get',
      params: { blNo: blNo }
    })
  },
  getRejectReason (blNo) {
    return Send({
      url: '/settle/invoice/reject-reason',
      method: 'get',
      params: { blNo: blNo }
    })
  },
  checkBl (data) {
    // BL 체크
    return Send({
      url: '/settle/invoice/bl/check',
      method: 'post',
      data: data
    })
  },
  invoiceCancel (params) {
    //Invoice Cancel
    return Send({
      url: '/settle/invoice/cancel',
      method: 'GET',
      params: params
    })
  },
  getBlFreight (params) {
    // B/L 운임 수납 금액 정보 가져오기
    return Send({
      url: '/settle/invoice/bl/freight',
      method: 'get',
      params: params,
      paramsSerializer: paramsObj => {
        const params = new URLSearchParams()
        for (const key in paramsObj) {
          if (Array.isArray(paramsObj[key]) && paramsObj[key].length === 0) {
            continue
          }
          params.append(key, paramsObj[key])
        }
        return params.toString()
      }
    })
  },
  getBlFreightKrw (params) {
    // B/L 운임 수납 금액 정보 가져오기(KRW)
    return Send({
      url: '/settle/invoice/bl/freight-krw',
      method: 'get',
      params: params,
      paramsSerializer: paramsObj => {
        const params = new URLSearchParams()
        for (const key in paramsObj) {
          if (Array.isArray(paramsObj[key]) && paramsObj[key].length === 0) {
            continue
          }
          params.append(key, paramsObj[key])
        }
        return params.toString()
      }
    })
  },
  getBlInvoiceData (params) {
    // B/L Invoice 발행 데이터
    return Send({
      url: '/settle/invoice/bl/invoice-data',
      method: 'get',
      params: params,
      paramsSerializer: paramsObj => {
        const params = new URLSearchParams()
        for (const key in paramsObj) {
          if (Array.isArray(paramsObj[key]) && paramsObj[key].length === 0) {
            continue
          }
          params.append(key, paramsObj[key])
        }
        return params.toString()
      }
    })
  },
  reqChk (params) {
    //운임정정요청 사전 체크
    return Send({
      url: '/settle/invoice/req-chk',
      method: 'GET',
      params: { ...params }
    })
  },
  detailCreditList (params) {
    // 월말화주 정산 내역 목록 조회
    return Send({
      url: '/settle/invoice-inquiry/detail/credit',
      method: 'POST',
      data: { ...params },
      params: {}
    })
  },
  detailBlList (params) {
    // 청구, 영수화주 정산 내역 목록 조회
    return Send({
      url: '/settle/invoice-inquiry/detail/bl',
      method: 'POST',
      data: { ...params },
      params: {}
    })
  },
  popErpRegNoEdit (params) {
    return Send({
      url: '/settle/invoice/popErpRegNoEdit',
      method: 'POST',
      data: params,
      params: {}
    })
  },
  arrangeDetailInList (lstRefNo) {
    return Send({
      url: '/settle/arranges',
      method: 'POST',
      data: {
        lstRefNo: lstRefNo
      }
    })
  },
  findNotIssuedCount () {
    return Send({
      url: '/settle/invoice/no-issued-count',
      method: 'GET',
      params: {}
    })
  },
  findBlProformaFreightInvoice (data) {
    return Send({
      url: '/settle/invoice/bl-proforma-invoice',
      method: 'POST',
      data: data,
      params: {}
    })
  },
  checkHasCm134I (data) {
    return Send({
      url: '/settle/invoice/check/has-cm134i',
      method: 'GET',
      data: data,
      params: {
        blList: data
      }
    })
  },
  checkHasCm134IFreetime (params) {
    return Send({
      url: '/settle/invoice/check/has-cm134i-freetime',
      method: 'GET',
      params: params
    })
  },
  checkHasCm134IType (blList, type) {
    return Send({
      url: '/settle/invoice/check/has-cm134i-type',
      method: 'GET',
      params: {
        blList: blList,
        type: type
      }
    })
  },
  getBlErrorCodes () {
    return Send({
      url: '/settle/invoice/bl/error-codes',
      method: 'GET',
      data: {},
      params: {}
    })
  },
  getDoDetail (params) {
    return Send({
      url: '/settle/do/info',
      method: 'GET',
      data: {},
      params: params
    })
  },
  getCreditSalesInfo (param) {
    return Send({
      url: '/settle/invoice-credit-sales',
      method: 'get',
      params: param
    })
  },
  checkInvoiceBlAuth (param) {
    return Send({
      url: '/settle/invoice/bl-auth',
      method: 'get',
      params: {
        blNo: param.blNo,
        bkgShprCstNo: param.bkgShprCstNo
      }
    })
  },
  getDrwotAcnInfo (bnkId, bnkPswd) {
    return Send({
      url: '/settle/invoice/drwot-acn-info?bnkId=' + bnkId + '&bnkPswd=' + bnkPswd,
      method: 'get',
      params: {}
    })
  },
  updateDrwotAcnInfoBnkAcct (params) {
    return Send({
      url: '/settle/invoice/update-drwot-acn-info-bnk-acct',
      method: 'POST',
      data: params,
      params: {}
    })
  },
  joinDrwotAcnInfo (params) {
    return Send({
      url: '/settle/invoice/join-drwot-acn-info',
      method: 'POST',
      data: params,
      params: {}
    })
  },
  bankingInfoAndCreditTaxRequest (data) {
    return Send({
      url: '/settle/invoice/banking-info-and-credit-tax',
      method: 'post',
      data: data
    })
  },
  recalculateFreightBillInfo (data) {
    return Send({
      url: '/settle/invoice/recalculate-freight-bill-info',
      method: 'post',
      data: data
    })
  },
  onlyReturnTaxSave (data) {
    return Send({
      url: '/settle/invoice/only-return-tax-save',
      method: 'post',
      data: data
    })
  },
  auxInvoiceCreate (data) {
    return Send({
      url: '/settle/invoice/aux-invoice-create',
      method: 'post',
      data: data
    })
  },
  selectNonFreightInvoice (param) {
    // 청구, 영수화주 정산 내역 목록 조회
    return Send({
      url: '/settle/invoice/non-freight-invoice',
      method: 'get',
      params: param
    })
  },
  getInvApvUser (blNo) {
    return Send({
      url: '/settle/invoice/invoice-apvuser',
      method: 'get',
      params: { blNo: blNo }
    })
  },
  getFirstTaxInvoiceEmailAddr (acshRno) {
    return Send({
      url: '/settle/invoice-inquiry/tax/invoice/first/email/' + acshRno,
      method: 'get'
    })
  },
  getRepairRestitutionList (blNo) {
    return Send({
      url: '/settle/repair/restitution',
      method: 'get',
      params: { blNo: blNo }
    })
  },
  insertRepairRestitutionList (params) {
    return Send({
      url: '/settle/repair/restitution',
      method: 'POST',
      data: params,
      params: {}
    })
  },
  createInitialize (blNoArr) {
      return Send({
        url: '/settle/invoice/create-init-ialize?blNoArr=' + blNoArr,
        method: 'get'
      })
  },
  couponList (data) {
    return Send({
      url: '/settle/invoice/coupon-list',
      method: 'post',
      data: data
    })
  },
  couponApply (data) {
    return Send({
      url: '/settle/invoice/coupon-apply',
      method: 'post',
      data: data
    })
  },
  couponYn (data) {
    return Send({
      url: '/settle/invoice/coupon-yn',
      method: 'post',
      data: data
    })
  }
}
