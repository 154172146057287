var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("form", { attrs: { id: "frm2" } }, [
      _c(
        "div",
        {
          staticClass: "content_box beforetab",
          staticStyle: { height: "100px" },
        },
        [
          _c("div", { staticClass: "fak_title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", { staticClass: "fak_title_sub" }, [
            _c("div", [
              _vm._v("- " + _vm._s(_vm.$t("msg.ONEX010T010.170")) + " "),
            ]),
            _c("div", [
              _vm._v("- " + _vm._s(_vm.$t("msg.ONEX010T010.171")) + " "),
            ]),
            _c("div", [
              _vm._v(" - "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.fakToday,
                      expression: "fakToday",
                    },
                  ],
                  staticClass: "col_4",
                  attrs: { id: "fakToday" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.fakToday = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                _vm._l(_vm.bascDtList, function (bascDt) {
                  return _c(
                    "option",
                    { key: bascDt, domProps: { value: bascDt } },
                    [_vm._v(_vm._s(_vm.formatDate(bascDt, _vm.lang)))]
                  )
                }),
                0
              ),
              _vm._v(" " + _vm._s(_vm.$t("msg.ONEX010T010.169")) + " "),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "content_box mt10" }, [
        _c("div", { staticClass: "flex_box" }, [
          _c("span", { staticClass: "ml_auto cal_btn" }, [
            _c(
              "a",
              {
                staticClass: "button sm",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.exlDownload()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.MYIN020T010.016")))]
            ),
          ]),
        ]),
        _c("div", {
          staticClass: "mt30",
          style: `width: 100%; height: ${_vm.gridHeight}px;`,
          attrs: { id: "realgrid" },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }