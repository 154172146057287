<template>
  <div></div>
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'

export default {
  name: 'VirtualAccountResult',
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          invIssNo: ''
        }
      }
    }
  },
  data () {
    return {
      klnetUrl: ''
    }
  },
  created () {
    // 초기 화면 세팅
    this.init()
  },
  methods: {
    // 가상계좌 발급 화면 단계별 조회
    async init () {
      console.log('result :: {}', this.$route.query)
    }
  }
}
</script>

<style scoped>
</style>
