import Send from '../../trans.client.js'

export default {
  getReportInfoUser (strParams) {
    return Send({
      url: '/trans/report-info/user?' + strParams,
      method: 'get'
    })
  },
  getReportInfoDo (strParams) {
    return Send({
      url: '/trans/report-info/do?' + strParams,
      method: 'get'
    })
  },
  getReportInfoCertificate (strParams) {
    return Send({
      url: '/trans/report-info/certificate?' + strParams,
      method: 'get'
    })
  },
  getReportInfoBlCopy (strParams) {
    return Send({
      url: '/trans/report-info/bl-copy?' + strParams,
      method: 'get'
    })
  },
  getReportInfoBooking (strParams) {
    return Send({
      url: '/trans/report-info/booking-confirm?' + strParams,
      method: 'get'
    })
  },
  getReportInfoPort (strParams) {
    return Send({
      url: '/trans/report-info/port-notice?' + strParams,
      method: 'get'
    })
  },
  getReportInfoDemDet (strParams) {
    return Send({
      url: '/trans/report-info/demdet-app?' + strParams,
      method: 'get'
    })
  },
  getProformaInfoCheck (strParams) {
    return Send({
      url: '/trans/report-info/proforma?' + strParams,
      method: 'get'
    })
  },
  getFreightBlAuthYn (strParams) {
    return Send({
      url: '/trans/report-info/freightbl-auth?' + strParams,
      method: 'get'
    })
  },
  getDemDetInvoice (params) {
    return Send({
      url: '/trans/report-info/dem-det-invoice',
      method: 'get',
      params: params,
      paramsSerializer: paramsObj => {
        const params = new URLSearchParams()
        for (const key in paramsObj) {
          if (Array.isArray(paramsObj[key]) && paramsObj[key].length === 0) {
            continue
          }
          params.append(key, paramsObj[key])
        }
        return params.toString()
      }
    })
  },
  getReportOption (strParams) {
    return Send({
      url: '/trans/report-info/invoice-option?' + strParams,
      method: 'get'
    })
  },
  getInvConfirm (data) {
    return Send({
      url: '/trans/report-info/inv-confirm',
      method: 'post',
      data: data
    })
  }
}
